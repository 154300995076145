import * as React from "react";
import { useEventContainer } from "../../../containers/EventContainer";
import { Hangar, Location, Position, Ramp, Stack } from "../../../types";
import { uuidv4 } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { MoveEntityEvent } from "../Layout";
type Props = {
  location: Location<Ramp | Hangar>;
  stack: Stack;
  setStack: (stack: Stack) => void;
  disableDragProtection?: boolean;
};

export const useOnPositionChange = (props: Props, deps: any[] = []) => {
  const onMoveTenantEvent = useEventContainer<MoveEntityEvent>();
  const { location, stack, setStack, disableDragProtection } = props;
  return React.useCallback((entity_id: string, position: Position) => {
    const tenantMoved = stack.tenants.find((t) => t.entity_id === entity_id);
    const movableObstacleMoved = stack.movableObstacles.find(
      (mo) => mo.entity_id === entity_id
    );

    mixpanel.track("Aircraft Movement", {
      location_name: location.name,
      location_type: location.type,
      location_tenant_type: stack?.isReference ? "reference" : "current",
      aircraft_type: tenantMoved?.aircraft?.model,
      aircraft_tail_number: tenantMoved?.tail_number,
      aircraft_tenant_type: tenantMoved?.type,
    });

    // handle the case when the user drags the plane behind the Current Info box
    const span = Boolean(tenantMoved)
      ? Math.max(tenantMoved.aircraft.length, tenantMoved.aircraft.wingspan) -
        15
      : Math.max(
          movableObstacleMoved.obstacle.length,
          movableObstacleMoved.obstacle.width
        ) - 15;

    if (!disableDragProtection && position.x && position.x < -1 * span) {
      position.x = 0;
    }

    const updatedTenant = stack.tenants.find((t) => t.entity_id === entity_id);
    const tenant = updatedTenant && {
      ...updatedTenant,
      last_hangared: new Date().toISOString(),
      position: {
        id: uuidv4(),
        ...updatedTenant.position,
        ...position,
      },
    };

    const updatedMovableObstacle = stack.movableObstacles.find(
      (mo) => mo.entity_id === entity_id
    );
    const movableObstacle = updatedMovableObstacle && {
      ...updatedMovableObstacle,
      position: {
        id: uuidv4(),
        ...updatedMovableObstacle.position,
        ...position,
      },
    };

    setStack({
      ...stack,
      tenants: stack.tenants.map((t) => (t.id === tenant?.id ? tenant : t)),
      movableObstacles: stack.movableObstacles.map((mo) =>
        mo.id === movableObstacle?.id ? movableObstacle : mo
      ),
    });

    if (updatedTenant) {
      onMoveTenantEvent.emitEvent("moveEntity", {
        data: {
          direction: "anything",
          entity: { entity_type: "tenant", ...tenant },
          previousPosition: updatedTenant.position,
          locationId: location.id,
        },
      });
    }
    if (updatedMovableObstacle) {
      onMoveTenantEvent.emitEvent("moveEntity", {
        data: {
          direction: "anything",
          entity: { entity_type: "movableObstacle", ...movableObstacle },
          previousPosition: updatedMovableObstacle.position,
          locationId: location.id,
        },
      });
    }
  }, deps);
};
