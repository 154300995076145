import * as React from "react";
import { Icon } from "@mui/material";

export const AirplaneTailsIcon = () => (
  <Icon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "currentColor",
          fillOpacity: 1,
        }}
        d="M 62.539062 45.421875 L 62.597656 45.421875 C 65.660156 45.539062 68.539062 47.039062 70.339844 49.441406 L 89.539062 74.699219 C 89.898438 75.121094 90.4375 75.421875 90.980469 75.421875 L 99.980469 75.421875 C 101 75.421875 101.777344 74.640625 101.777344 73.621094 C 101.777344 72.601562 101 71.820312 99.980469 71.820312 L 91.878906 71.820312 L 73.277344 47.28125 C 70.9375 44.21875 67.460938 42.300781 63.621094 41.878906 L 55.878906 31.679688 C 53.300781 28.261719 49.160156 26.21875 44.898438 26.21875 L 34.640625 26.21875 C 34.097656 26.21875 33.558594 26.460938 33.199219 26.941406 C 32.839844 27.421875 32.71875 27.960938 32.898438 28.5 L 42.679688 64.199219 L 29 69.839844 C 27.320312 70.558594 26.300781 72.179688 26.359375 74.039062 C 26.417969 75.898438 27.679688 77.398438 29.417969 77.878906 L 50 83.820312 C 50.058594 83.820312 50.179688 83.878906 50.238281 83.878906 L 46.398438 85.5 C 44.71875 86.21875 43.699219 87.839844 43.757812 89.699219 C 43.820312 91.558594 45.078125 93.058594 46.820312 93.539062 L 67.339844 99.480469 C 72.917969 101.101562 78.679688 101.878906 84.4375 101.878906 L 100.039062 101.878906 C 101.058594 101.878906 101.839844 101.101562 101.839844 100.078125 C 101.839844 99.058594 101.058594 98.28125 100.039062 98.28125 L 84.5 98.28125 C 79.039062 98.28125 73.640625 97.5 68.359375 96 L 47.839844 90.058594 C 47.417969 89.941406 47.359375 89.640625 47.359375 89.460938 C 47.359375 89.28125 47.359375 88.980469 47.777344 88.800781 L 56.71875 85.078125 L 62.898438 82.5 C 63.738281 82.140625 64.160156 81.238281 63.917969 80.339844 L 54.378906 45.421875 L 62.238281 45.421875 C 62.359375 45.421875 62.480469 45.421875 62.539062 45.421875 Z M 62.539062 45.421875 "
      />
    </svg>
  </Icon>
);
