/**
 * Adds a class to a class string
 */
export const addClass = function(existingClasses, newClass) {
  var classes = existingClasses.split(/\s/g);
  var classesHash = {};
  for (var className of classes) {
    classesHash[className] = className;
  }
  classesHash[newClass] = newClass;
  return Object.keys(classesHash).join(" ");
};

/**
 * Removes a class from a class string
 */
export const removeClass = function(existingClasses, removeClass) {
  var classes = existingClasses.split(/\s/g);
  var classesHash = {};
  for (var className of classes) {
    classesHash[className] = className;
  }
  delete classesHash[removeClass];
  return Object.keys(classesHash).join(" ");
};
