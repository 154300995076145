import * as React from "react";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { orderBy } from "lodash";
import { SideIconButton } from "./SideDrawer";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useIdentity } from "../../containers/IdentityContainer";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";

interface Props {}

export const FBOMenu: React.FC<Props> = ({}) => {
  const { logout } = useIdentity();
  const { activeFBO, setActiveFBOId, fbos } = useActiveFBOs();
  const [displayNoFBO, setDisplayNoFBO] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    if (!fbos.length) {
      setDisplayNoFBO(true);
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sortedFBOs = React.useMemo(() => {
    return orderBy(fbos, ["name"], ["asc"]);
  }, [fbos]);

  return (
    <>
      <Stack direction="column" alignItems="center" spacing={-2}>
        <SideIconButton
          onClickRoute={handleMenu}
          label={"Switch FBO"}
          icon={<SwitchAccountIcon />}
        />
        <Typography
          variant="caption"
          color="primary.main"
          noWrap
          align="center"
          sx={{ width: "90%" }}
        >
          {activeFBO?.name}
        </Typography>
      </Stack>
      <Dialog open={displayNoFBO}>
        <DialogTitle>No FBO Found</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You currently do not belong to an FBO. Please contact your system
            administrator to join one.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDisplayNoFBO(false)}>OK</Button>
        </DialogActions>
      </Dialog>
      <Menu
        disablePortal
        keepMounted
        id="menu-fbo"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ zIndex: 99999999999 }}
      >
        {sortedFBOs.map((fbo) => (
          <MenuItem
            key={`active-fbo-menu-item=${fbo.id}`}
            onClick={() => {
              setActiveFBOId(fbo.id);
              window.location.href = "/#/hangars";
              window.location.reload();
            }}
          >
            {activeFBO?.id === fbo.id ? "✓ " : ""}
            {fbo.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
