import * as React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useDropzone } from "react-dropzone";

type Props = {};

export const ConvertSVG: React.FC<{}> = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const theme = useTheme();
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const fileURL = URL.createObjectURL(file);
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async () => {
        setLoading(true);
        const svg = reader.result;
        const payload = {
          svg,
        };
        const url =
          window.location.hostname.indexOf("localhost") > 1
            ? "http://localhost:8008/image"
            : "https://format-svg.airplx.com/image";
        await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = file.name.replace(".svg", ".png");
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
          });
        setLoading(false);
      };
      reader.readAsText(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/svg+xml": [],
    },
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Box
        sx={{
          width: "40%",
          margin: "auto",
          marginTop: 20,
          p: 10,
          textAlign: "center",
          border: `4px solid ${
            isDragActive
              ? theme.palette.success.main
              : theme.palette.primary.main
          }`,
        }}
      >
        <Typography>
          {loading ? "Processing file..." : "Drag your SVG here."}
        </Typography>
      </Box>
    </div>
  );
};
