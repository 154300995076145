import * as React from "react";
import { useParams } from "react-router-dom";
import { AutocompleteResultsContainer } from "../../containers/AutocompleteResultsContainer";
import { HangarsStateContainer } from "../../containers/HangarsStateContainer";
import { MultiHangarStateContainer } from "../../containers/MultiHangarContainer";
import { RampsStateContainer } from "../../containers/RampsStateContainer";
import { TowingEquipmentContainer } from "../../containers/TowingEquipmentContainer";
import { MultiHangarPresenter } from "./MultiHangarPresenter";

type Props = {};

export const MultiHangar: React.FC<Props> = () => {
  const params = useParams();
  const ids = params.ids.split("|");

  return (
    <MultiHangarStateContainer
      initialState={{
        hangarIds: ids,
        activeHangarId: ids[0],
      }}
    >
      <AutocompleteResultsContainer>
        <RampsStateContainer isReference={false}>
          <HangarsStateContainer isReference={false}>
            <TowingEquipmentContainer>
              <MultiHangarPresenter hangarIds={ids} />
            </TowingEquipmentContainer>
          </HangarsStateContainer>
        </RampsStateContainer>
      </AutocompleteResultsContainer>
    </MultiHangarStateContainer>
  );
};
