import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import {
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography
  } from "@mui/material";
import { Ramp } from "../../types";
import { round } from "lodash";

type Props = {
  ramp: Ramp;
  onClickBack?: () => void;
};

export const RampInfoCard: React.FC<Props> = ({ ramp, onClickBack }) => {
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        p: 1,
        maxHeight: "100%",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          {Boolean(onClickBack) && (
            <IconButton onClick={onClickBack}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography>
            {ramp.stack.isReference && `[All Tie Down Tenants] `}
            {ramp.name}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" sx={{ width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Typography textAlign="center">Aircraft</Typography>
            <Tooltip title="# aircraft on the ramp">
              <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
            </Tooltip>
          </Stack>
          <Typography variant="caption" textAlign="center" color="info.dark">
            {`${ramp.stack?.tenants?.length ?? 0}`}
          </Typography>
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack direction="column" sx={{ width: "100%" }}>
          <Typography textAlign="center">Gross Dimensions</Typography>
          <Typography variant="caption" textAlign="center" color="info.dark">
            {round(ramp.depth, 0)}L x {round(ramp.width)}W
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
