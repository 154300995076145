import * as React from "react";
import { HangarsStateContainer } from "../../containers/HangarsStateContainer";
import { InvitationsStateContainer } from "../../containers/InvitationsStateContainer";
import { RampsStateContainer } from "../../containers/RampsStateContainer";
import { SettingsPresenter } from "./SettingsPresenter";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useLocation } from "react-router-dom";

type Props = {};

export const Settings: React.FC<Props> = ({ ...props }) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search ?? "?");
  const { activeFBO } = useActiveFBOs();
  const [activeTab, setActiveTab] = React.useState<string>(
    activeFBO.user_role === "manager"
      ? urlParams.get("tab") ?? "Hangars"
      : "Account"
  );
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openReferenceHangar, setOpenReferenceHangar] = React.useState<boolean>(
    false
  );

  return (
    <HangarsStateContainer isReference={true}>
      <RampsStateContainer isReference={false} includeReferenceImage={true}>
        <InvitationsStateContainer>
          <SettingsPresenter
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            openReferenceHangar={openReferenceHangar}
            setOpenReferenceHangar={setOpenReferenceHangar}
          />
        </InvitationsStateContainer>
      </RampsStateContainer>
    </HangarsStateContainer>
  );
};
