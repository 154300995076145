import * as React from "react";
import { useRamps } from "../hooks/useRamps";
import { Ramp } from "../types";

type Props = {};

// const RampStateContainerState = createContainer(useRampStateContainerState);
const RampsStateContainerState = React.createContext<{
  ramps: Ramp[];
  setRamps: (Ramps: Ramp[]) => void;
  isReference: boolean;
}>({});

export const RampsStateContainer: React.FC<{
  children?: React.ReactNode;
  isReference: boolean;
  includeReferenceImage?: boolean;
}> = ({ children, isReference, includeReferenceImage, ...props }) => {
  const { ready, ramps, setRamps } = useRamps(isReference, false);
  return (
    <RampsStateContainerState.Provider
      value={{
        ramps,
        setRamps,
        isReference,
      }}
    >
      {ready ? children : null}
    </RampsStateContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useRampsState = () => React.useContext(RampsStateContainerState);
