import { Autocomplete, TextField, Typography } from "@mui/material";
import * as React from "react";
import { Ramp, Stack, Tenant } from "../../types";

type Props = {
  ramp: Ramp;
  setStack: (stack: Stack) => void;
};

export const RampSearch: React.FC<Props> = ({ ramp, setStack }) => {
  const options = React.useMemo(
    () =>
      ramp.stack.tenants.sort((a, b) => {
        const cmpTail = a.tail_number.localeCompare(b.tail_number);
        if (cmpTail !== 0) {
          return cmpTail;
        }
        return a.aircraft.model.localeCompare(b.aircraft.model);
      }),
    [ramp.stack.tenants.length]
  );

  return (
    <Autocomplete
      disablePortal
      size="small"
      onChange={(event: any, newValue: Tenant | null) => {
        if (!newValue || !newValue?.id) {
          return;
        }
        setStack({
          ...ramp.stack,
          tenants: ramp.stack.tenants.map((t) => ({
            ...t,
            selected: t.id === newValue.id,
          })),
        });
      }}
      options={options}
      sx={{ width: "100%" }}
      getOptionLabel={(option: Tenant) => {
        return option.tail_number ?? "";
      }}
      renderOption={(props, option, state) => {
        const isInHangar = ramp.stack.tenants.find(
          (tenant) => tenant.id === option.id
        );
        let text = "";
        if (!option.tail_number) {
          text = `${option.aircraft.model}`;
        } else {
          text = `${option.tail_number} - ${option.aircraft.model}`;
        }

        return (
          <li
            {...props}
            key={`search-hangars-${option.id}`}
            style={{
              backgroundColor: isInHangar ? `#c8cfde40` : "transparent",
            }}
          >
            <Typography variant="inherit">{text}</Typography>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search for aircraft"
        />
      )}
    />
  );
};
