import { LinearProgress } from "@mui/material";
import * as React from "react";
import { useLoadingProgress } from "../../containers/LoadingProgressContainer";

type Props = {};

export const LoadingProgress: React.FC<Props> = ({}) => {
  const { show } = useLoadingProgress();

  if (!show) {
    return null;
  }

  return (
    <LinearProgress
      color="success"
      sx={{
        position: "fixed",
        bottom: 0,
        height: 20,
        width: "100vw",
        zIndex: 99999,
      }}
    />
  );
};
