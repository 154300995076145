import * as React from "react";
import { AccountPresenter } from "./AccountPresenter";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useApi } from "../../../containers/ApiContainer";
import { useAsync } from "react-use";
import { useIdentity } from "../../../containers/IdentityContainer";

export const Account: React.FC<{}> = () => {
  const { postgrest } = useApi();
  const { airplxIdentity } = useIdentity();
  const { activeFBO } = useActiveFBOs();

  const state = useAsync(async () => {
    return await postgrest
      .from("users")
      .select()
      .eq("id", airplxIdentity.userid)
      .then(({ data }) => data[0]);
  }, []);

  if (state.loading) {
    return null;
  }
  return <AccountPresenter user={state.value} />;
};
