import * as React from "react";
import { useTheme } from "@mui/material";

export const SexyText: React.FC<{ value: number; text: any }> = ({
  value: initialValue,
  text,
}) => {
  const theme = useTheme();
  const [isFlashing, setIsFlashing] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<number>(initialValue);
  const [previousValue, setPreviousValue] = React.useState<number>();

  React.useEffect(() => {
    if (initialValue === value) return;
    setPreviousValue(value);
    setValue(initialValue);
  }, [initialValue]);

  const startFlash = () => {
    setIsFlashing(true);
    setTimeout(() => {
      setIsFlashing(false);
    }, 500); // Flash for 1 second (1000 milliseconds)
  };

  React.useEffect(() => {
    if (previousValue === undefined) return;
    startFlash();
  }, [value]);

  const isBetter = value > previousValue;

  const backgroundColor = isFlashing
    ? isBetter
      ? theme.palette.primary.main
      : "inherit"
    : "inherit";

  if (!isBetter) {
    return <span>{text}</span>;
  }

  return (
    <span
      style={{
        width: "100%",
        height: "100%",
        color: isFlashing ? "white" : "inherit",
        backgroundColor,
        transition: "background-color 0.5s ease-in-out",
        borderRadius: 2,
        padding: 3,
      }}
    >
      {text}
    </span>
  );
};
