import * as React from "react";
import { useMultipleHangars } from "../hooks/useMultipleHangars";
import { Hangar } from "../types";

type Props = {
  hangar_id: string;
  isReference: boolean;
};

const HangarStateContainerState = React.createContext<{
  hangar: Hangar;
  getHangar: (hangar_id: string) => Hangar;
  setHangar: React.Dispatch<React.SetStateAction<Hangar>>;
  history: any;
  isReference: boolean;
}>({});

export const HangarStateContainer: React.FC<{
  children?: React.ReactNode;
  initialState: Props;
}> = ({ children, initialState, ...props }) => {
  const {
    ready,
    hangars,
    getHangar,
    setHangar,
    setHangars,
    history,
  } = useMultipleHangars([initialState.hangar_id], initialState.isReference);
  const hangar = hangars?.[0];

  return (
    <HangarStateContainerState.Provider
      value={{
        hangar,
        getHangar,
        setHangar,
        history,
        isReference: initialState.isReference,
      }}
    >
      {ready ? children : null}
    </HangarStateContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useHangarState = () => React.useContext(HangarStateContainerState);
