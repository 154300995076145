import * as React from "react";
import { Invitation } from "../types";
import { InviteData, useInvitations } from "../hooks/useInvitations";

type Props = {};

const InvitationsStateContainerState = React.createContext<{
  ready: boolean;
  invitations: Invitation[];
  setInvitations: (invitations: Invitation[]) => void;
  makeInvite: (inviteData: InviteData) => Promise<string>;
}>({});

export const InvitationsStateContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children, ...props }) => {
  const hookData = useInvitations();
  return (
    <InvitationsStateContainerState.Provider
      value={{
        ...hookData,
      }}
    >
      {hookData.ready ? children : null}
    </InvitationsStateContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useInvitationsState = () =>
  React.useContext(InvitationsStateContainerState);
