import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { orderBy } from "lodash";
import * as React from "react";
import { Tenant } from "../../types";

type Props = {
  disableAutocomplete: boolean;
  defaultValue?: string;
  warning?: string;
  tenants?: Array<Tenant>;
  onInputChange?: (tailNumber: string) => void;
  onChange?: (tenant: Tenant) => void;
};

const RGX_VALID_REGISTRATIONS = /[A-Z]/;

export const SelectTailNumber: React.FC<Props> = ({
  disableAutocomplete,
  defaultValue = "",
  warning,
  tenants = [],
  onChange = () => {},
  onInputChange = () => {},
}) => {
  const [value, setValue] = React.useState<string>("");

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  if (disableAutocomplete) {
    return (
      <Stack direction="column" sx={{ width: "100%" }}>
        <TextField
          inputProps={{ testid: "tail_number" }}
          autoFocus
          fullWidth
          size="small"
          label="Tail #"
          onChange={(evt) => {
            let newInputValue = evt.target.value.toLocaleUpperCase();
            if (!RGX_VALID_REGISTRATIONS.test(newInputValue)) {
              // deafult to N since it is the most common
              newInputValue = "N" + newInputValue;
            }
            onInputChange(newInputValue);
            setValue(newInputValue);
          }}
          value={value}
        />
        <Typography variant="caption" color="error">
          {warning}
        </Typography>
      </Stack>
    );
  }

  return (
    <Autocomplete
      size="small"
      fullWidth
      freeSolo
      options={orderBy(tenants, ["tail_number"], ["asc"])}
      getOptionLabel={(option: Tenant | string) => {
        if (typeof option === "string") {
          return option;
        }
        return option.tail_number;
      }}
      filterOptions={(options: Array<Tenant>, state) => {
        const q = state.inputValue.toLocaleLowerCase();
        return options.filter(
          (o) => o.tail_number.toLocaleLowerCase().indexOf(q) > -1
        );
      }}
      onInputChange={(event: any, newInputValue: string) => {
        newInputValue = newInputValue.toLocaleUpperCase();
        if (!RGX_VALID_REGISTRATIONS.test(newInputValue)) {
          // deafult to N since it is the most common
          newInputValue = "N" + newInputValue;
        }
        onInputChange(newInputValue);
        setValue(newInputValue);
      }}
      onChange={(event: any, tenant: Tenant) => {
        onChange(tenant);
      }}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          fullWidth
          label="Tail #"
          inputProps={{
            testid: "tail_number",
            ...params.inputProps,
            onKeyDown: (e) => {
              // i don't want user to ever hit enter in this form
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            },
          }}
        />
      )}
    />
  );
};
