import * as React from "react";
import { HangarsStateContainer } from "../../containers/HangarsStateContainer";
import { StackMasterPresenter } from "./StackMasterPresenter";

type Props = {};

export const StackMaster: React.FC<Props> = ({ ...props }) => {
  return (
    <HangarsStateContainer isReference={false}>
      <StackMasterPresenter />
    </HangarsStateContainer>
  );
};
