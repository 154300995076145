import { pointInPolygon } from "geometric";
import * as React from "react";
import { EntityPolygon, Stack } from "../../../types";

export const useGetIndexOfGhostAircraftHoveringOver = (
  bboxRef: React.RefObject<React.ReactNode>,
  stack: Stack,
  ghostPolygons: EntityPolygon[],
  feetToPixels: number,
  width: number
) => {
  return React.useCallback(
    (evt: React.MouseEvent<HTMLElement>): number => {
      if (!bboxRef.current) {
        return null;
      }
      const bounds = bboxRef.current.getBoundingClientRect();
      const x = (evt.clientX - bounds.left) / feetToPixels;
      const y = (bounds.bottom - evt.clientY) / feetToPixels;
      let i = 0;

      for (const { entity_id, polygon } of ghostPolygons) {
        if (pointInPolygon([x, y], polygon)) {
          return i;
        }
        i++;
      }
    },
    [stack, ghostPolygons, width]
  );
};
