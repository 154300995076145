import * as React from "react";
import { useApi } from "../../containers/ApiContainer";
import { HangarsStateContainer } from "../../containers/HangarsStateContainer";
import { LocationsContainer } from "../../containers/LocationsStateContainer";
import { RampsStateContainer } from "../../containers/RampsStateContainer";
import { useTenants } from "../../hooks/useTenants";
import { Tenant } from "../../types";
import { TenantsPresenter } from "./TenantsPresenter";

type Props = {};

export const Tenants: React.FC<Props> = ({ ...props }) => {
  const [filter, setFilter] = React.useState<string>("");
  const { postgrest } = useApi();
  const { tenants, setTenant, setTenants } = useTenants();
  const [editTenantId, setEditTenantId] = React.useState<string>("");

  const deleteTenant = React.useCallback(
    async (tenant: Tenant) => {
      await postgrest
        .from("tenant")
        .delete()
        .eq("entity_id", tenant.entity_id);
      setTenants(tenants.filter((t) => t.entity_id !== tenant.entity_id));
    },
    [tenants]
  );

  return (
    <RampsStateContainer isReference={false}>
      <HangarsStateContainer isReference={false}>
        <LocationsContainer>
          <TenantsPresenter
            filter={filter}
            tenants={tenants}
            setTenant={setTenant}
            deleteTenant={deleteTenant}
            setFilter={setFilter}
            editTenantId={editTenantId}
            setEditTenantId={setEditTenantId}
          />
        </LocationsContainer>
      </HangarsStateContainer>
    </RampsStateContainer>
  );
};
