import { PostgrestClient } from "@supabase/postgrest-js";
import { Polygon } from "geojson";
import * as React from "react";
import { getPostgrestUrl } from "../../utils";
import Canvas from "../../widgets/useCanvas";
import { drawGeom } from "./LabelingToolPresenter";

const postgrest = new PostgrestClient(getPostgrestUrl());

type Props = {
  geom: Polygon;
  simplify_pct: number;
};

const CANVAS_WIDTH = 600;
const CANVAS_HEIGHT = 600;

export const SimplifiedGeom: React.FC<Props> = ({
  geom,
  simplify_pct,
  ...props
}) => {
  const canvasRef = React.createRef();
  const [simplifiedGeom, setSimplifiedGeom] = React.useState<Polygon>();

  const simplifyTo = async (geom, percent_missing) => {
    return postgrest
      .rpc("simplify_to", {
        geomjson: geom,
        percent_missing,
      })
      .single();
  };

  React.useEffect(() => {
    (async () => {
      const { data } = await simplifyTo(geom, simplify_pct);
      setSimplifiedGeom(data?.geom as Polygon);
    })();
  }, [geom, simplify_pct]);

  const draw = (ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    drawGeom(ctx, simplifiedGeom, {
      outline: {
        color: "#FF4136",
      },
      point: { color: "transparent" },
    });
  };

  if (!simplifiedGeom || !simplify_pct) {
    return <div />;
  }

  return (
    <Canvas
      ref={canvasRef}
      width={CANVAS_WIDTH}
      height={CANVAS_HEIGHT}
      draw={draw}
      style={{ border: "2px solid black" }}
    />
  );
};
