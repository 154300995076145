/**
 * Given a coordinate, if a line was drawn from the 0,0 origin to this coordinate
 * then return the angle of this line as if it was a rotation around the z axis
 * |
 * |         x (2,2)
 * |
 * |
 * |________________________
 * 0,0
 */
export const getClockAngle = function(coord: Coordinate): number {
  var { x, y } = coord;

  // Depending on if the coordinate is in the north-east, south-east, south-west
  // or north-west side of the x and y axises we solve the problem for each
  // quadrant and then add the respective angle multiples of 90°

  // Math.atan will give 180 degree different answers if you use a negative
  // 0 instead of a positive 0 🤷, effectively you're dividing by 0 so doing
  // atan on Infinity and -Infinity
  if (y == 0) {
    y = 0;
  }

  if (x == 0 && y == 0) return 0;

  // This is the north-east section of the axises
  if (x >= 0 && y >= 0) {
    // This gives us the angle between the y-axis and the hypothenus
    return Math.atan(x / y);
  } else if (x >= 0 && y < 0) {
    // The south-east section
    var theta = Math.atan((y * -1) / x);
    // Add 90° to the angle
    return Math.PI / 2 + theta;
  } else if (x < 0 && y < 0) {
    // The south-west section
    var theta = Math.atan((x * -1) / (y * -1));
    // Add 180° to the angle
    return Math.PI + theta;
  } else {
    var theta = Math.atan(y / (x * -1));
    // Add 270° to the angle
    return (Math.PI * 3) / 2 + theta;
  }
};

export interface Coordinate {
  x: number;
  y: number;
}

/**
 * Get the length of the hypotenuse given an x and y length
 */
export const getHypotenuse = function(coord: Coordinate): number {
  return Math.sqrt(Math.pow(coord.x, 2) + Math.pow(coord.y, 2));
};

export const convertDegreesToRadians = function(degrees: number): number {
  return degrees * (Math.PI / 180);
};

export const convertRadiansToDegress = function(radians: number): number {
  return radians / (Math.PI / 180);
};

/**
 * Rotate a coordinate around an origin. 
 * @param {Coordinate} coord The coordinate to rotate
 * @param {number} angle The rotation angle in radians
 * @param {Coordinate?} origin If provided rotates around this origin, otherwise
 * assumes the origin is 0,0
 * @returns {Coordinate}
 */
export const rotateAroundOrigin = function(
  coord: Coordinate,
  angle,
  origin: Coordinate = { x: 0, y: 0 }
): Coordinate {

  var coordRelativeToOrigin = {
    x: coord.x - origin.x,
    y: coord.y - origin.y,
  }

  var { x, y } = coordRelativeToOrigin;
  var cos = Math.cos(angle);
  var sin = Math.sin(angle);

  var rotatedCoordRelativeToOrigin = {
    x: cos * x + sin * y,
    y: cos * y - sin * x,
  }

  return {
    x: rotatedCoordRelativeToOrigin.x + origin.x,
    y: rotatedCoordRelativeToOrigin.y + origin.y,
  }
};
