import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import * as React from "react";

type Props = {
  dumpTo: any;
  setDumpTo: (value: any) => void;
};

export const SelectDumpTo: React.FC<Props> = ({ setDumpTo }) => {
  return (
    <FormControl sx={{ width: 300, mt: 2 }}>
      <FormLabel>To:</FormLabel>
      <RadioGroup defaultValue={"HOLDING_AREA"}>
        <FormControlLabel
          value="HOLDING_AREA"
          control={<Radio />}
          label="Holding Area"
          onClick={() => setDumpTo("HOLDING_AREA")}
        />
        <FormControlLabel
          value="FRONT"
          control={<Radio />}
          label="Front of Hangar"
          onClick={() => setDumpTo("FRONT")}
        />
      </RadioGroup>
    </FormControl>
  );
};
