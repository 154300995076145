import * as React from "react";
import {
  Preferences,
  UserPreferences,
  useUserPreferences as useUserPreferencesHook,
} from "../hooks/useUserPreferences";

// const UserPreferencesContainerState = createContainer(useUserPreferencesContainerState);
const UserPreferencesContainerState = React.createContext<{
  loading: boolean;
  userPreferences: UserPreferences;
  setUserPreferences: (userPreferences: UserPreferences) => void;
  preferences: Preferences;
  setPreferences: (preferences: Preferences) => void;
}>({
  loading: false,
  userPreferences: null,
  setUserPreferences: function() {},
  preferences: {},
  setPreferences: function() {},
});

export const UserPreferencesContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children, ...props }) => {
  const data = useUserPreferencesHook();
  return (
    <UserPreferencesContainerState.Provider value={data}>
      {/* {!data.loading ? children : <Loading />} */}
      {children}
    </UserPreferencesContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useUserPreferences = () =>
  React.useContext(UserPreferencesContainerState);
