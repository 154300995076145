import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  ButtonGroup,
  Divider,
  Fade,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import numeral from "numeral";
import * as React from "react";

type Props = {
  currentZoom: number;
  increment: number;
  onClickPlus: () => void;
  onClickMinus: () => void;
};

export const ZoomControl: React.FC<Props> = ({
  increment = 0.1,
  onClickPlus,
  onClickMinus,
}) => {
  const theme = useTheme();
  const [zoomChange, setZoomChange] = React.useState<number>(0);
  const [showZoomLevel, setShowZoomLevel] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Show the zoom level for 2 seconds
    setShowZoomLevel(true);
    const timer = setTimeout(() => {
      setShowZoomLevel(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [zoomChange]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      spacing={1}
      sx={{
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.mobileStepper - 1,
      }}
    >
      <Fade in={showZoomLevel}>
        <Typography
          align="center"
          variant="caption"
          bgcolor="#ffffff"
          p={0.5}
          borderRadius={2}
        >
          {numeral((100 + 100 * zoomChange * increment) / 100).format("0%")}
        </Typography>
      </Fade>
      <ButtonGroup
        orientation="vertical"
        variant="contained"
        sx={{ backgroundColor: "white" }}
      >
        <Tooltip title="Zoom In" placement="left">
          <IconButton
            onClick={() => {
              if (zoomChange >= 10) {
                return;
              }
              onClickPlus();
              setZoomChange(zoomChange + 1);
            }}
            sx={{
              borderRadius: 0,
              color: "white",
              backgroundColor: "success.main",
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Divider />
        <Tooltip title="Zoom Out" placement="left">
          <IconButton
            onClick={() => {
              if (zoomChange <= -10) {
                return;
              }
              onClickMinus();
              setZoomChange(zoomChange - 1);
            }}
            sx={{
              borderRadius: 0,
              color: "white",
              backgroundColor: "success.main",
            }}
          >
            <RemoveIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </Stack>
  );
};
