import { useCallback, useState } from "react";

type SetStateAction<T> = T | ((prevState: T) => T);
type PredicateFunction<T> = (prevState: T, newState: T) => boolean;

export function useStateHistory<T>(
  initialValue: T,
  predicate: PredicateFunction<T> = (prev, next) => true // Default predicate always returns true
) {
  const [stateHistory, setStateHistory] = useState<T[]>([]);
  const currentState = stateHistory[stateHistory.length - 1];

  const updateState = useCallback(
    (newValue: SetStateAction<T>) => {
      setStateHistory((prev) => {
        const resolvedValue =
          newValue instanceof Function ? newValue(currentState) : newValue;

        // Only update history if the predicate returns true and the new value is different
        if (predicate(currentState, resolvedValue)) {
          return [...prev, resolvedValue];
        }
        // Otherwise, return the current history. we will always replace the last item with the new value
        return [...prev.slice(0, prev.length - 1), resolvedValue];
      });
    },
    [currentState, predicate]
  ); // Ensure predicate is stable or wrapped in useCallback if defined outside the hook

  const undo = useCallback(() => {
    const rollbackToState = stateHistory[stateHistory.length - 1];
    setStateHistory((prev) => {
      if (prev.length > 1) {
        return prev.slice(0, prev.length - 1);
      }
      return prev;
    });
    return rollbackToState;
  }, []);

  return [currentState, updateState, undo] as const;
}
