import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useApi } from "../../containers/ApiContainer";
import { useIdentity } from "../../containers/IdentityContainer";
import { useTokenMaker } from "../../hooks";
import { FBO } from "../../types";

export const ClaimInvite: React.FC<{}> = () => {
  const { inviteId } = useParams();
  const { postgrest } = useApi();
  const { url: tokenMakerURL } = useTokenMaker();
  const { airplxIdentity } = useIdentity();
  const { refreshAirplxToken } = useIdentity();
  const { setActiveFBOId } = useActiveFBOs();
  const [isValidInvite, setIsValidInvite] = React.useState<boolean>();
  const [fbo, setFBO] = React.useState<FBO>();

  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/");
    window.location.reload();
  };

  const claim = async () => {
    await fetch(
      `${tokenMakerURL}/claim/invite/${inviteId}/${airplxIdentity.userid}`
    );
    await refreshAirplxToken();
    setActiveFBOId(fbo.id);
  };

  const checkIfInviteIsValid = async () => {
    const { data } = await postgrest
      .rpc("is_invite_valid", {
        invite_id: inviteId,
        email: airplxIdentity.email,
      })
      .single();
    return data;
  };

  React.useEffect(() => {
    (async () => {
      const result = await checkIfInviteIsValid();
      setIsValidInvite(result.is_valid);
      setFBO({
        id: result.fbo_id,
        name: result.name,
        sop_horizontal_spacing: null,
        sop_overlap: null,
        sop_tug_type: null,
        sop_vertical_spacing: null,
        user_role: null,
        created_at: null,
        nightly_base_rate: null,
        nightly_transient_rate: null,
        subscription: null,
      });
    })();
  }, []);

  if (isValidInvite === null) {
    return null;
  }

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        {isValidInvite ? (
          <Typography>
            Join <u>{fbo?.name}</u>
          </Typography>
        ) : (
          "Invalid Invite"
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isValidInvite ? (
            <Typography>
              Do you want to join{" "}
              <u>
                <b>{fbo?.name}</b>
              </u>{" "}
              on AirPlx?
            </Typography>
          ) : (
            "Your invite has already been claimed. Please ask your system administrator to send a new one."
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isValidInvite ? (
          <>
            <Button onClick={handleClose}>No</Button>
            <Button
              variant="contained"
              onClick={async () => {
                await claim();
                handleClose();
              }}
              autoFocus
            >
              Yes
            </Button>
          </>
        ) : (
          <Button>Ok</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
