import { Alert, Snackbar as MuiSnackbar } from "@mui/material";
import * as React from "react";
import { useSnackbar } from "../../containers/SnackbarContainer";

type Props = {};

export const Snackbar: React.FC<Props> = () => {
  const { notifications, setNotifications } = useSnackbar();

  return (
    <>
      {notifications.map(({ id, open, text, severity, duration = 3000 }) => (
        <React.Fragment key={`snackbar-${id}`}>
          <MuiSnackbar
            open={open}
            autoHideDuration={duration}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() =>
              setNotifications(
                notifications.map((n) =>
                  n.id === id ? { ...n, open: false } : n
                )
              )
            }
          >
            <Alert
              onClose={() =>
                setNotifications(
                  notifications.map((n) =>
                    n.id === id ? { ...n, open: false } : n
                  )
                )
              }
              severity={severity}
              sx={{ width: "100%" }}
            >
              {text}
            </Alert>
          </MuiSnackbar>
        </React.Fragment>
      ))}
    </>
  );
};
