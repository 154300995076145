import { Box, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { useDropzone } from "react-dropzone";

type Props = {
  label: string;
  onUploadImage: (base64Image: string) => void;
  displayPreview?: boolean;
  mimeType?: string;
  style?: any;
};

export const DropImage: React.FC<Props> = ({
  label,
  onUploadImage,
  displayPreview = true,
  mimeType = "image/*",
  style = {},
}) => {
  const [previewURL, setPreviewURL] = React.useState<string>("");
  const theme = useTheme();
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const fileURL = URL.createObjectURL(file);
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const base64Image = reader.result;
          setPreviewURL(base64Image as string);
          onUploadImage(base64Image as string);
        };
        reader.readAsDataURL(file);
      });
    },
    [onUploadImage]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      [mimeType]: [],
    },
    onDrop,
    onDropRejected: () => alert("Incorrect file extension"),
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Box
        sx={{
          width: "calc(100% - 8px)",
          textAlign: "center",
          border: `4px solid ${
            isDragActive
              ? theme.palette.success.main
              : theme.palette.primary.main
          }`,
          ...style,
        }}
      >
        <Typography sx={{ p: 4 }}>
          Drag your{" "}
          <b>
            <u>{label}</u>
          </b>{" "}
          image here.
        </Typography>
      </Box>
      {displayPreview && <img src={previewURL} style={{ maxWidth: "100%" }} />}
    </div>
  );
};
