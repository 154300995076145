import { Coordinate, rotateAroundOrigin } from './math'

/**
 * Rotate a polygon by an angle about an origin. 
 */
export const rotatePolygon = function(polygon, angle: number, origin: Coordinate){

  if (polygon['type'] != 'Polygon')
    throw new Error('Only expects type \'Polygon\'.')

  const viewBoxMaxValue = Math.max(
    ...polygon["coordinates"][0].map((coord) => Math.max(...coord))
  );

  var newPolygon = {
    "type": "Polygon",
    'coordinates': [
      polygon['coordinates'][0].map(([x, y]) => {
        var temp = rotateAroundOrigin(
          { x, y },
          angle,
          origin,
        )
        return [temp.x, temp.y]
      })
    ]
  }
  return newPolygon
}

/**
 * Scales a polygon's dimensions. Assumes scale orgin is 0,0.
 */
export const scalePolygon = function(polygon, scale){
  if (polygon['type'] != 'Polygon')
    throw new Error('Only expects type \'Polygon\'.')
  var newPolygon = {
    "type": "Polygon",
    'coordinates': [
      polygon['coordinates'][0].map(([x, y]) => {
        
        return [x * scale, y * scale]
      })
    ]
  }
  return newPolygon
}


/**
 * Translate a polygon
 */
export const translatePolygon = function(polygon, translation: Coordinate){
  if (polygon['type'] != 'Polygon')
    throw new Error('Only expects type \'Polygon\'.')
  var newPolygon = {
    "type": "Polygon",
    'coordinates': [
      polygon['coordinates'][0].map(([x, y]) => {
        
        return [x += translation.x, y += translation.y]
      })
    ]
  }
  return newPolygon
}
