import { 
  getHypotenuse, 
  getClockAngle, 
  Coordinate, 
  rotateAroundOrigin,
} from './../math/math'


type MovementHistoryItem = {
  // The current mouse position
  mousePos: Coordinate,
  // The current rear wheel position
  rearWheelPos: Coordinate,
  // The current aircraft rotation
  rotation: Number
}

/**
 * Get the movement angle relative to the front of the aircraft.
 * NOTE: This will mutate the passed in array, i.e. it will delete older items
 * of the array that are not required to obtain the most recent movement angle
 */
export const getMovementAngle = function(movementHistory: MovementHistoryItem[]): number | null {
  
  if (movementHistory.length <= 1) return null  

  var currentDto = movementHistory[movementHistory.length - 1]
  var currentPosition = currentDto.mousePos

  var minimumMovement = 5
  // Find the previous movement that was at least the minimum distance away
  for (var i = (movementHistory.length - 2); i--; i >= 0){
    var historicDto = movementHistory[i]
    var historicPosition = historicDto.mousePos
    var translation = {
      x: historicPosition.x - currentPosition.x,
      y: historicPosition.y - currentPosition.y,
    }

    var distance = getHypotenuse(translation)
    if (distance >= minimumMovement){

      // First rotate both positions around the original rear wheel pos
      // so that the angle is relative to the front of the aircraft

      var rearWheelPos = historicDto.rearWheelPos

      var mouse1RelativeToOrigin = {
        x: currentPosition.x - rearWheelPos.x,
        y: currentPosition.y - rearWheelPos.y,
      };
      // Invert the y axis as it's easier to reason about
      mouse1RelativeToOrigin = {
        ...mouse1RelativeToOrigin,
        y: mouse1RelativeToOrigin.y * -1,
      };
      // This is the mouse1 position aligned on the y axis
      var mouse1AlignedOnYOrigin = rotateAroundOrigin(
        mouse1RelativeToOrigin,
        -historicDto.rotation
      );

      var mouse2RelativeToOrigin = {
        x: historicPosition.x - rearWheelPos.x,
        y: historicPosition.y - rearWheelPos.y,
      }
      // Invert the y axis as it's easier to reason about
      mouse2RelativeToOrigin = {
        ...mouse2RelativeToOrigin,
        y: mouse2RelativeToOrigin.y * -1,
      };
      var mouse2AlignedOnYOrigin = rotateAroundOrigin(
        mouse2RelativeToOrigin,
        -historicDto.rotation
      );

      var translation = {
        x: mouse2AlignedOnYOrigin.x - mouse1AlignedOnYOrigin.x,
        y: mouse2AlignedOnYOrigin.y - mouse1AlignedOnYOrigin.y,
      }

      var angle = getClockAngle(translation);
      break;
    }
  }
  // Prune older movement history 
  movementHistory.splice(0, i)

  return Math.PI - angle
}
