import * as React from "react";
import { HangarsStateContainer } from "../../../../containers/HangarsStateContainer";
import { RampStateContainer } from "../../../../containers/RampStateContainer";
import { RampEditorPresenter } from "./RampEditorPresenter";

type Props = {
  ramp_id: string;
  onClose: () => void;
};

export const RampEditor: React.FC<Props> = ({ ramp_id, ...props }) => {
  return (
    <HangarsStateContainer isReference={false}>
      <RampStateContainer
        initialState={{
          ramp_id,
          isReference: false,
          includeReferenceImage: true,
        }}
      >
        <RampEditorPresenter {...props} />
      </RampStateContainer>
    </HangarsStateContainer>
  );
};
