import * as React from "react";
import { Alert } from "@mui/material";
import { HangarsStateContainer } from "../../containers/HangarsStateContainer";
import { RampsPresenter } from "./RampPresenter";
import { RampsStateContainer } from "../../containers/RampsStateContainer";
import { RampStateContainer } from "../../containers/RampStateContainer";
import { TowingEquipmentContainer } from "../../containers/TowingEquipmentContainer";
import { useParams } from "react-router-dom";

type Props = {
  rampId?: string;
  isReference?: boolean;
};

export const Ramp: React.FC<Props> = ({ rampId, isReference, ...props }) => {
  const { id: rampIdFromRoute } = useParams();

  return (
    // RampsStateContainer and HangarsStateContainer might seem weird. it's for the selection menus
    <RampsStateContainer isReference={isReference ?? false}>
      <HangarsStateContainer isReference={isReference ?? false}>
        <RampStateContainer
          initialState={{
            ramp_id: rampId ?? rampIdFromRoute,
            isReference: isReference ?? false,
          }}
        >
          <TowingEquipmentContainer>
            {isReference && (
              <Alert severity="warning">
                This is your Tie Town Tenant Reference ramp. It only shows your
                tie down tenants and is distinct from your active Current Ramp.
              </Alert>
            )}
            <RampsPresenter />
          </TowingEquipmentContainer>
        </RampStateContainer>
      </HangarsStateContainer>
    </RampsStateContainer>
  );
};
