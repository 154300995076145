import * as React from "react";
import { Stack } from "../../../types";
import { RightClick } from "../Layout";
import { ClickedEntityResult } from "./useGetClickedEntity";

type Props = {
  stack: Stack;
  getClickedEntity: (evt: React.MouseEvent<HTMLElement>) => ClickedEntityResult;
  setRightClick: (rightClick: RightClick) => void;
};

export const useOnContextMenu = (props: Props, deps: any[] = []) => {
  const { stack, setRightClick, getClickedEntity } = props;
  return React.useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const { entity, mousePosition } = getClickedEntity(evt);
    if (!entity) {
      return;
    }
    evt.stopPropagation();
    evt.preventDefault();
    setRightClick({
      entity_id: entity.entity_id,
      mouseX: mousePosition.absoluteX,
      mouseY: mousePosition.absoluteY,
    });
  }, deps);
};
