import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import * as React from "react";
import { useLocationsState } from "../../containers/LocationsStateContainer";
import { LocationTextField } from "../../widgets/LocationTextField";

type Props = {
  tenants: any[];
  additionalColumns: GridColDef[];
  hideTheseColumns?: string[];
};

export const TenantGrid: React.FC<Props> = ({
  tenants,
  additionalColumns,
  hideTheseColumns = [],
}) => {
  const { findTenantLocation } = useLocationsState();
  const columns: GridColDef[] = [
    {
      field: "tail_number",
      headerName: "Tail #",
      width: 94,
      editable: false,
    },
    {
      field: "owner_manager",
      headerName: "Owner/Manager",
      width: 167,
      editable: false,
    },
    // {
    //   field: "make",
    //   headerName: "Manufacturer",
    //   width: 132,
    //   editable: false,
    //   valueGetter: (params: GridValueGetterParams) => params.row.aircraft.make,
    // },
    {
      field: "model",
      headerName: "Model",
      width: 175,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.aircraft.model,
    },
    {
      field: "current_location",
      headerName: "Current Location",
      editable: false,
      width: 175,
      valueGetter: (params: GridValueGetterParams) =>
        findTenantLocation(false, params.row)?.name,
      renderCell: (params) => (
        <LocationTextField
          selectedLocationId={
            findTenantLocation(false, params.row)?.location_id
          }
          readOnly
          unassignedText="Not Hangared"
          link
        />
      ),
    },
    {
      field: "assigned_location",
      headerName: "Assigned Location",
      editable: false,
      width: 175,
      valueGetter: (params: GridValueGetterParams) =>
        findTenantLocation(true, params.row)?.name,
      renderCell: (params) => (
        <LocationTextField
          selectedLocationId={findTenantLocation(true, params.row)?.location_id}
          isReference
          readOnly
          link
        />
      ),
    },
    {
      field: "type",
      headerName: "Type",
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.type === "transient") {
          return params.row.type;
        }
        return findTenantLocation(true, params.row)?.type === "ramp"
          ? "tie-down"
          : "base";
      },
    },
    ...additionalColumns,
  ];
  return (
    <DataGrid
      rows={tenants}
      columns={columns.filter(
        (column) => hideTheseColumns.indexOf(column.field) === -1
      )}
      pageSize={100}
      rowsPerPageOptions={[100]}
      disableSelectionOnClick
      getRowId={(row) => row.entity_id}
    />
  );
};
