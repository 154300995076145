import * as md5 from "md5";
import pLimit from "p-limit";
import { getStackPolygons } from "../../../hooks/utils";
import { HangarStack } from "../Hangar";
import { Identity } from "../../../containers/IdentityContainer";
import { orderBy, uniqBy } from "lodash";
import { polygonIntersectsPolygon } from "geometric";
import { uuidv4 } from "../../../utils";
import {
  AutocompletePosition,
  EntityPolygon,
  Hangar,
  ParamSet,
} from "../../../types";
import {
  StackMember,
  oneShotAutocomplete,
  placeIncrementally,
} from "../PlaceIncrementally";

export const distance = (
  a: AutocompletePosition,
  b: AutocompletePosition
): number => {
  return Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);
};

export const findFurthestApart = (
  positions: AutocompletePosition[]
): AutocompletePosition[] => {
  if (positions.length <= 3) {
    return positions;
  }
  const positionsByError = orderBy(positions, ["error"], ["desc"]);
  const bestPosition = positionsByError[0];

  let furthestPositions: AutocompletePosition[] = [];
  let maxDistance = 0;
  for (const position1 of positions.slice(1)) {
    if (position1.id === bestPosition.id) {
      continue;
    }
    for (const position2 of positions.slice(1)) {
      if (position1.id === position2.id) {
        continue;
      }
      if (position2.id === bestPosition.id) {
        continue;
      }
      const totalDistance =
        distance(bestPosition, position1) + distance(bestPosition, position2);
      if (totalDistance > maxDistance) {
        maxDistance = totalDistance;
        furthestPositions = [bestPosition, position1, position2];
      }
    }
  }
  return furthestPositions;
};

const makeAutocompleteHash = (params: ParamSet): string => {
  const { label, image, ...paramsWithoutLabel } = params;
  return md5(JSON.stringify(paramsWithoutLabel));
};

const autocompleteCache = new Map<string, StackMember[]>();

/**
 * Run autocomplete for a given hangar. This is the main entry point for the
 * autocomplete functionality.
 *
 * @param hangar
 * @param paramsSets
 * @param onNewStacks
 * @param onProgress
 * @param airplxIdentity
 * @param airplxToken
 */
export const runAutocomplete = async (
  hangar: Hangar,
  paramSets: ParamSet[],
  airplxToken: string
): Promise<AutocompletePosition[]> => {
  window.globalThis.airplxKillSwitch = 0;
  const N_WORKER_THREADS = Math.min(paramSets.length, 10);
  const limit = pLimit(N_WORKER_THREADS);

  const doit = paramSets.map(async (paramsWithLabel, idx) => {
    return limit(async () => {
      const { label, image, ...paramsWithoutLabel } = paramsWithLabel;
      const hash = makeAutocompleteHash(paramsWithLabel);
      if (autocompleteCache.has(hash)) {
        return autocompleteCache.get(hash);
      }

      // this is for testing
      if (window.location.hostname.indexOf("localhost") > -1) {
        // await sleep(1250);
      }

      const result = await oneShotAutocomplete(paramsWithoutLabel, airplxToken);
      autocompleteCache.set(hash, result);
      return result;
    });
  });

  const t0 = +new Date();
  const results = await Promise.all(doit);
  console.log(`autocomplete took ${+new Date() - t0}ms`);
  const suggestions = results
    .map((stackMembers) => {
      return stackMembers
        .filter(
          (sm) =>
            sm.placement_id === paramSets[0].aircrafts_to_place[0].placement_id
        )
        .map((sm) => ({
          id: uuidv4(),
          hangar_id: hangar.id,
          x: sm.x,
          y: sm.y,
          angle: sm.angle,
          error: sm.error,
        }));
    })
    .flat()
    .filter((suggestion) => {
      return suggestion.x !== null && suggestion.x !== undefined;
    });

  const dedupedSuggestions = uniqBy(
    suggestions,
    (s) => `${s.x}-${s.y}-${s.angle}`
  );

  const orderedSuggestions = orderBy(dedupedSuggestions, ["error"], ["asc"]);
  const suggestedPolygons = getStackPolygons(
    {
      ...hangar.stack,
      tenants: orderedSuggestions.map((position) => ({
        ...hangar.stack.tenants.find((t) => t.selected),
        position: {
          ...hangar.stack.tenants.find((t) => t.selected).position,
          ...position,
        },
      })),
      movableObstacles: [],
    },
    hangar.width,
    1
  );
  const nonOverlappingSuggestions: AutocompletePosition[] = [];
  const nonOverlappingPolygons: EntityPolygon[] = [];
  for (let i = 0; i < suggestedPolygons.length; i++) {
    // check to see if the ith suggestedPolygon overlaps with any of the nonOverlappingSuggestions
    let overlaps = false;
    const ithPolygon = suggestedPolygons[i];
    for (const suggestedPolygon of nonOverlappingPolygons) {
      if (
        polygonIntersectsPolygon(ithPolygon.polygon, suggestedPolygon.polygon)
      ) {
        overlaps = true;
        break;
      }
    }
    if (!overlaps) {
      nonOverlappingSuggestions.push(orderedSuggestions[i]);
      nonOverlappingPolygons.push(ithPolygon);
    }
  }

  return orderedSuggestions.slice(0, 4);
  return orderBy(nonOverlappingSuggestions, ["error"], ["asc"]).slice(0, 4);
  // return findFurthestApart(orderedSuggestions);
};

export const runAllParamSets = async (
  hangar: Hangar,
  paramsSets: ParamSet[],
  onNewStacks: (stacks: HangarStack[]) => void,
  onProgress: (progress: number[]) => void,
  airplxIdentity: Identity,
  airplxToken: string
) => {
  /**
   * Limit to 4 trials running simultaneously. This defacto also limits to 4
   * simultaneous requests because each trial runs each step sequentially.
   */
  const N_WORKER_THREADS = 10;
  const limit = pLimit(N_WORKER_THREADS);
  const currentLayoutOptions = paramsSets.map((paramSet) => ({
    paramSet,
    stackMembers: [],
  }));
  const progress: number[] = paramsSets.map(() => 0);

  onNewStacks(currentLayoutOptions);
  const doit = paramsSets.map(async (paramsWithLabel, idx) => {
    return limit(async () => {
      const { label, image, ...paramsWithoutLabel } = paramsWithLabel;
      let cntr = 0;
      /**
       * We get incremental updates as airplanes are being put into the hangar. So let's
       * show them to the user! TECHNOLOGY!
       */
      for await (let stackMembers of placeIncrementally(
        paramsWithoutLabel,
        hangar.stack.tenants,
        airplxIdentity,
        airplxToken
      )) {
        currentLayoutOptions[idx] = {
          ...currentLayoutOptions[idx],
          stackMembers,
        };
        cntr++;
        // somewhat surprisingly, this stuff still works even though we're now
        // in a function. not exactly sure why...
        progress[idx] = cntr / paramsSets[0].aircrafts_to_place.length;
        onNewStacks([...currentLayoutOptions]);
        onProgress(progress);
      }
      progress[idx] = 1;
      onProgress(progress);
    });
  });
  await Promise.all(doit);
};
