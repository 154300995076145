import * as React from "react";
import { createContainer } from "./StateContainer";
import { useApi } from "./ApiContainer";
import { useAsync } from "react-use";

type Schema = {
  [key: string]: string[];
};

type Props = {};
const useSchemaContainerState = (
  props: Props
): {
  schema: Schema | undefined;
  loading: boolean;
} => {
  const { postgrest } = useApi();
  const { value, loading } = useAsync(async () => {
    const { data } = await postgrest.rpc("columns");
    return Object.fromEntries(
      data.map((row) => [row.table_name, row.column_names])
    );
  });
  return { schema: value, loading };
};
const SchemaContainerState = createContainer(useSchemaContainerState);

export const SchemaContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children, ...props }) => {
  const nested = SchemaContainerState.useContainer();
  return (
    <SchemaContainerState.Provider initialState={{ ...nested, ...props }}>
      {children}
    </SchemaContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useSchema = SchemaContainerState.useContainer;
