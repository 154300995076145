import { Box, Stack } from "@mui/material";
import * as React from "react";
import { useIdentity } from "../../containers/IdentityContainer";
import { SideDrawer } from "./SideDrawer";

interface NavProps {
  children: React.ReactNode;
}

export const Nav: React.FC<NavProps> = ({ children, ...props }) => {
  const { isLoggedIn } = useIdentity();

  return (
    <Stack sx={{ display: "flex", height: "100%" }} direction="row">
      <Box sx={{ height: "100%", maxWidth: "100%" }}>
        {isLoggedIn ? <SideDrawer /> : null}
      </Box>
      <Box
        component="main"
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};
