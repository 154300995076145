import { Box } from "@mui/material";
import * as React from "react";
import { MouseEvent, useEffect, useRef, useState } from "react";

interface ClearCanvasProps {
  children: React.ReactNode;
  active?: boolean;
  onMouseMove?: (
    e: MouseEvent<HTMLCanvasElement>,
    ctx: CanvasRenderingContext2D
  ) => void;
  onDraw?: (ctx: CanvasRenderingContext2D, x: number, y: number) => void;
  onClick?: (
    e: MouseEvent<HTMLCanvasElement>,
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number
  ) => void;
  style?: React.CSSProperties;
  expandBy?: number;
}

export const ClearCanvasOverlay = React.forwardRef<
  HTMLCanvasElement,
  ClearCanvasProps
>(
  (
    { active, children, onMouseMove, onDraw, onClick, expandBy = 1, style },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [isDrawing, setIsDrawing] = useState(false);

    useEffect(
      () => {
        // Update the dimensions of the canvas based on the child component
        const updateDimensions = () => {
          if (containerRef.current) {
            const { offsetWidth, offsetHeight } = containerRef.current;
            setDimensions({
              width: offsetWidth * expandBy,
              height: offsetHeight * expandBy,
            });
          }
        };

        updateDimensions(); // Update dimensions on mount
        window.addEventListener("resize", updateDimensions); // Adjust on window resize

        return () => window.removeEventListener("resize", updateDimensions);
      },
      // if the containerRef changes, update the dimensions
      [containerRef?.current?.offsetWidth, containerRef?.current?.offsetHeight]
    );

    useEffect(() => {
      if (canvasRef.current) {
        const canvas = canvasRef.current;
        canvas.width = dimensions.width;
        canvas.height = dimensions.height;
      }
    }, [dimensions]);

    useEffect(() => {
      // Assign ref if a ref is passed from the parent
      if (ref && canvasRef.current) {
        if (typeof ref === "function") {
          ref(canvasRef.current);
        } else {
          (ref as React.MutableRefObject<HTMLCanvasElement>).current =
            canvasRef.current;
        }
      }
    }, [ref]);

    const handleMouseMove = (e: MouseEvent<HTMLCanvasElement>) => {
      if (onMouseMove && canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        if (ctx) {
          onMouseMove(e, ctx);
        }
      }

      if (isDrawing && canvasRef.current && onDraw) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        if (ctx) {
          onDraw(ctx, x, y);
        }
      }
    };

    const handleMouseDown = () => {
      setIsDrawing(true);
    };

    const handleMouseUp = () => {
      setIsDrawing(false);
    };

    const handleClick = (e: MouseEvent<HTMLCanvasElement>) => {
      if (onClick && canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        if (ctx) {
          onClick(e, ctx, x, y);
        }
      }
    };

    return (
      <Box ref={containerRef} position="relative" display="inline-block">
        {/* Child Component */}
        {children}

        {/* Clear Canvas Overlay */}
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: active ? 100000 : -1, // Ensure the canvas is on top
            width: dimensions.width,
            height: dimensions.height,
            ...style,
          }}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onClick={handleClick}
        />
      </Box>
    );
  }
);
