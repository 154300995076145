import events from "../events/events";
import EventTarget from "../events/EventTarget";
/**
 * @fileOverview A class that's responsible for assisting with handling
 * dragging on an element. It dispatches events when the user is dragging.
 */

var DragHandler = function() {
  EventTarget.call(this);
  this.elements_ = [];

  this.bindedOnMouseDown_ = this.onMouseDown_.bind(this);
  this.bindedOnMouseUp_ = this.onMouseUp_.bind(this);
  this.bindedOnMouseMove_ = this.onMouseMove_.bind(this);
  this.bindedOnMouseLeave_ = this.onMouseLeave_.bind(this);

  this.currentX_ = 0;
  this.currentY_ = 0;
};
DragHandler.prototype = new EventTarget();

DragHandler.prototype.attachTo = function(element) {
  var matchingElementIndex = this.elements_.findIndex((e) => e == element);
  // Check if its already attached
  if (matchingElementIndex != -1) return;
  this.elements_.push(element);
  events.listen(element, "mousedown", this.bindedOnMouseDown_);

  this.addGlobalEventListeners_();
};

DragHandler.prototype.removeFrom = function(element) {
  var matchingElementIndex = this.elements_.findIndex((e) => e == element);
  if (matchingElementIndex == -1) return;

  this.elements_.splice(matchingElementIndex, 1);
  events.unlisten(element, "mousedown", this.bindedOnMouseDown_);
};

DragHandler.prototype.addGlobalEventListeners_ = function() {
  if (this.globalEventListenersAdded_) return;
  this.globalEventListenersAdded_ = true;

  document.body.addEventListener("mouseup", this.bindedOnMouseUp_);
  document.body.addEventListener("mousemove", this.bindedOnMouseMove_);
  document.body.addEventListener("mouseleave", this.bindedOnMouseLeave_);
};

DragHandler.prototype.onMouseDown_ = function(e) {
  clearTimeout(this.dragStartDelay_);
  // This helps prevent a very slight drag if the user nudges the mouse from
  // triggering as a drag
  this.dragStartDelay_ = setTimeout(() => {
    this.dragging_ = true;
  }, 40);
};

DragHandler.prototype.onMouseUp_ = function(e) {
  delete this.dragging_;
  delete this.startDragPos_;
  clearTimeout(this.dragStartDelay_);
  if (!this.dragChange_) return;
  this.currentX_ = this.currentX_ + this.dragChange_.clientX;
  this.currentY_ = this.currentY_ + this.dragChange_.clientY;
  delete this.startDragPos_;
  delete this.dragChange_;
  this.dispatchEvent({
    type: "stop",
    x: this.currentX_,
    y: this.currentY_,
  });
};

DragHandler.prototype.onMouseMove_ = function(e) {
  if (!this.dragging_) return;
  if (!this.startDragPos_) {
    this.startDragPos_ = {
      clientX: e.clientX,
      clientY: e.clientY,
    };
    this.dispatchEvent({
      type: "start",
      x: this.currentX_,
      y: this.currentY_,
    });
  }

  this.dragChange_ = {
    clientX: e.clientX - this.startDragPos_.clientX,
    clientY: e.clientY - this.startDragPos_.clientY,
  };

  this.dispatchMovement_();
};

DragHandler.prototype.onMouseLeave_ = function(e) {
  this.onMouseUp_(e);
};

DragHandler.prototype.dispatchMovement_ = function() {
  if (!this.dragChange_) {
    return this.dispatchEvent({
      type: "move",
      x: this.currentX_,
      y: this.currentY_,
    });
  }
  this.dispatchEvent({
    type: "change",
    x: this.currentX_ + this.dragChange_.clientX,
    y: this.currentY_ + this.dragChange_.clientY,
  });

  return this.dispatchEvent({
    type: "move",
    x: this.currentX_ + this.dragChange_.clientX,
    y: this.currentY_ + this.dragChange_.clientY,
  });
};

DragHandler.prototype.destroy = function() {
  for (var element of this.elements_) {
    events.unlisten(element, "mousedown", this.bindedOnMouseDown_);
  }
  document.body.removeEventListener("mouseup", this.bindedOnMouseUp_);
  document.body.removeEventListener("mousemove", this.bindedOnMouseMove_);
  document.body.removeEventListener("mouseleave", this.bindedOnMouseLeave_);
  EventTarget.prototype.destroy.call(this);
};

/**
 * Resets the x and y offsets to 0
 */
DragHandler.prototype.reset = function() {
  this.currentX_ = 0;
  this.currentY_ = 0;
};

/**
 * Set the current x and y position.
 */
DragHandler.prototype.setPosition = function(coord) {
  this.currentX_ = coord.x;
  this.currentY_ = coord.y;
};

DragHandler.prototype.getPosition = function() {
  return {
    x: this.currentX_,
    y: this.currentY_,
  };
};

export default DragHandler;
