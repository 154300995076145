import * as React from "react";
import { orderBy } from "lodash";
import { pointInPolygon } from "geometric";
import {
  Entity,
  EntityPolygon,
  MovableObstacle,
  Stack,
  Tenant,
} from "../../../types";

type Props = {
  bboxRef: React.RefObject<React.ReactNode>;
  stack: Stack;
  polygons: EntityPolygon[];
  feetToPixels: number;
};

export type ClickedEntityResult = {
  entity: Entity<Tenant | MovableObstacle>;
  mousePosition: {
    x: number;
    y: number;
    absoluteX: number;
    absoluteY: number;
  };
};

/**
 * gets click event coordinates and checks the polygon model to see which airplane
 * was clicked. returns the tenant + position
 */
export const useGetClickedEntity = (props: Props, deps: any[] = []) => {
  const { bboxRef, stack, polygons, feetToPixels } = props;
  return React.useCallback(
    (evt: React.MouseEvent<HTMLElement>): ClickedEntityResult => {
      if (!bboxRef.current) {
        return {
          entity: null,
          mousePosition: { x: null, y: null, absoluteX: null, absoluteY: null },
        };
      }
      const bounds = bboxRef.current.getBoundingClientRect();
      const x = (evt.clientX - bounds.left) / feetToPixels;
      const y = (bounds.bottom - evt.clientY) / feetToPixels;
      let i = 0;
      const clickedEntities: Entity<Tenant | MovableObstacle>[] = [];
      for (const { entity_id, polygon } of polygons) {
        if (pointInPolygon([x, y], polygon)) {
          const tenantFound = stack.tenants.find(
            (t) => t.entity_id === entity_id
          );
          if (tenantFound) {
            clickedEntities.push({
              ...tenantFound,
              entity_type: "tenant",
            });
            continue;
          }
          const movableObstacleFound = stack.movableObstacles.find(
            (t) => t.entity_id === entity_id
          );
          if (movableObstacleFound) {
            clickedEntities.push({
              ...movableObstacleFound,
              entity_type: "movableObstacle",
            });
            continue;
          }
        }
        i++;
      }

      if (!clickedEntities.length) {
        return {
          entity: null,
          mousePosition: {
            x,
            y,
            absoluteX: evt.clientX,
            absoluteY: evt.clientY,
          },
        };
      }
      let entity;
      if (clickedEntities.length === 1) {
        return {
          entity: { ...clickedEntities[0] },
          mousePosition: {
            x,
            y,
            absoluteX: evt.clientX,
            absoluteY: evt.clientY,
          },
        };
      } else {
        // first look for one that's already selected
        entity = clickedEntities.find((t) => t.selected);
        if (entity) {
          return {
            entity,
            mousePosition: {
              x,
              y,
              absoluteX: evt.clientX,
              absoluteY: evt.clientY,
            },
          };
        }

        // otherwise pick the tallest plane
        return {
          entity: orderBy(
            clickedEntities,
            ["tail_height", "height"],
            ["desc", "desc"]
          )[0],
          mousePosition: {
            x,
            y,
            absoluteX: evt.clientX,
            absoluteY: evt.clientY,
          },
        };
      }
    },
    deps
  );
};
