import * as React from "react";
import { Polygon } from "geojson";
import { useApi } from "../containers/ApiContainer";

export type PredefinedObstacle = {
  id: string;
  name: string;
  width: number;
  length: number;
  height: number;
  geom: Polygon;
  geomz: Polygon;
  is_default: boolean;
};

export const usePredefinedObstacle = () => {
  const { postgrest } = useApi();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [predefinedObstacles, setPredefinedObstacles] = React.useState<
    PredefinedObstacle[]
  >();

  React.useEffect(() => {
    (async () => {
      const { data } = await postgrest
        .from("predefined_obstacle")
        .select()
        .order("name");
      setPredefinedObstacles(data);
      setLoading(false);
    })();
  }, []);

  return {
    loading,
    predefinedObstacles: predefinedObstacles ?? [],
    setPredefinedObstacles,
  };
};
