import * as React from "react";

/**
 * This queue is for writing and updating tables. Particularly where we need this is when you're
 * creating a "complex" object that has multiple tables that need to be updated. For example,
 * when you create a new hangar, you need to create a new hangar, new tenants.
 *
 * This is a work in progress. The idea is that you can add a function to the queue, and it will
 * run in the background. If you add a function that depends on another function, it will wait
 * until that function is done before running. This way, you can add a function to create a new
 * hangar, and then add a function to create a new tenant, and it will wait until the hangar is
 * created before creating the tenant.
 */
// export const usePostgresQueue = () => {
//   // our queue. it's pretty simple.
//   const [queue, setQueue] = React.useState<Promise<any>[]>([]);
//   // this is a helper function to add a function to the queue.
//   const addToQueue = (func: () => Promise<any>) => {
//     // we're going to create a new promise that will resolve when the function is done.
//     const promise = new Promise((resolve, reject) => {
//       // we're going to add the function to the queue.
//       setQueue((prev) => [...prev, func]);
//       // we're going to resolve the promise when the function is done.
//       func()
//         .then(resolve)
//         .catch(reject);
//     });
//     // we're going to return the promise.
//     return promise;
//   };
//   // this is a helper function to run the queue.
//   const runQueue = () => {
//     // we're going to create a new promise that will resolve when the queue is done.
//     const promise = new Promise((resolve, reject) => {
//       // we're going to run the queue.
//       queue
//         .reduce((prev, curr) => prev.then(curr), Promise.resolve())
//         .then(resolve)
//         .catch(reject);
//     });
//     // we're going to return the promise.
//     return promise;
//   };

//   // we're going to return the queue and the helper functions.
//   return { queue, addToQueue, runQueue };
// };

type FunctionType = () => Promise<void> | Promise<any>;

interface UseFunctionQueueProps {
  //   functions: FunctionType[];
}

export const useFunctionQueue = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [functions, setFunctions] = React.useState<FunctionType[]>([]);

  React.useEffect(() => {
    async function callFunctions() {
      const functionsToCall = functions.slice(currentIndex);
      await Promise.all(functionsToCall.map((fn) => fn()));
      setCurrentIndex(functions.length);
    }

    callFunctions();
  }, [currentIndex, functions]);

  function addFunctions(newFunctions: FunctionType[]) {
    setFunctions((prevFunctions) => [...prevFunctions, ...newFunctions]);
  }

  function addFunction(newFunction: FunctionType) {
    setFunctions((prevFunctions) => [...prevFunctions, newFunction]);
  }

  function next() {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  }

  return { addFunctions, addFunction, next };
};
