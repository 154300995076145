import {
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { Hangar } from "../../types";
import { SelectDumpTo } from "./SelectDumpTo";

type MultiHangarSelectFormProps = {
  hangars: Hangar[];
  onChange: (selectedHangarIds: string[]) => void;
};

export const MultiHangarSelectForm: React.FC<MultiHangarSelectFormProps> = ({
  hangars,
  onChange,
}) => {
  const [selectedHangarIds, setSelectedHangarIds] = React.useState<string[]>(
    hangars.map((h) => h.id)
  );
  React.useEffect(() => {
    onChange(selectedHangarIds);
  }, [selectedHangarIds]);

  return (
    <List>
      <ListItem key={`checkbox-all`}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedHangarIds.length === hangars.length}
              onClick={(e) => {
                if (e.target.checked) {
                  setSelectedHangarIds(hangars.map((h) => h.id));
                } else {
                  setSelectedHangarIds([]);
                }
              }}
            />
          }
          label={"Select All"}
        />
      </ListItem>
      {hangars.map((hangar) => (
        <ListItem key={`checkbox-${hangar.id}`}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedHangarIds.indexOf(hangar.id) > -1}
                onClick={(e) => {
                  if (e.target.checked) {
                    setSelectedHangarIds([...selectedHangarIds, hangar.id]);
                  } else {
                    setSelectedHangarIds(
                      selectedHangarIds.filter((id) => id !== hangar.id)
                    );
                  }
                }}
              />
            }
            label={hangar.name}
          />
        </ListItem>
      ))}
    </List>
  );
};

type Props = {
  hangars: Hangar[];
  title: string;
  caption: string;
  okText?: string;
  cancelText?: string;
  renderSecondaryAction?: boolean;
  onCancel: () => void;
  onOk: (ids: string[], dumpTo?: string) => void;
};

export const MultiHangarSelect: React.FC<Props> = ({
  title,
  caption,
  cancelText,
  okText,
  hangars,
  onCancel,
  renderSecondaryAction,
  onOk,
}) => {
  const [selectedHangarIds, setSelectedHangarIds] = React.useState<string[]>(
    hangars.map((h) => h.id)
  );
  const [dumpTo, setDumpTo] = React.useState<string>("HOLDING_AREA");
  return (
    <div>
      <Dialog
        open={true}
        fullWidth
        maxWidth={Boolean(renderSecondaryAction) ? "sm" : "xs"}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{caption}</DialogContentText>
          <Stack
            direction="row"
            justifyContent="space-between"
            // alignItems="flex-start"
          >
            <List>
              <ListItem key={`checkbox-all`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedHangarIds.length === hangars.length}
                      onClick={(e) => {
                        if (e.target.checked) {
                          setSelectedHangarIds(hangars.map((h) => h.id));
                        } else {
                          setSelectedHangarIds([]);
                        }
                      }}
                    />
                  }
                  label={"Select All"}
                />
              </ListItem>
              {hangars.map((hangar) => (
                <ListItem key={`checkbox-${hangar.id}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedHangarIds.indexOf(hangar.id) > -1}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setSelectedHangarIds([
                              ...selectedHangarIds,
                              hangar.id,
                            ]);
                          } else {
                            setSelectedHangarIds(
                              selectedHangarIds.filter((id) => id !== hangar.id)
                            );
                          }
                        }}
                      />
                    }
                    label={hangar.name}
                  />
                </ListItem>
              ))}
            </List>
            {Boolean(renderSecondaryAction) && (
              <>
                <Divider
                  orientation="vertical"
                  sx={{ mt: 3, height: "200px" }}
                />
                <SelectDumpTo dumpTo={dumpTo} setDumpTo={setDumpTo} />
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{cancelText ?? "Cancel"}</Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              onOk(selectedHangarIds, dumpTo);
            }}
          >
            {okText ?? "OK"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
