import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useRampState } from "../../../../containers/RampStateContainer";
import { useRampsState } from "../../../../containers/RampsStateContainer";
import { useSnackbar } from "../../../../containers/SnackbarContainer";
import { Ramp } from "../../../../types";
import { RampCanvas, Shape } from "./RampCanvas/RampCanvas";
import { RampEditorForm } from "./RampEditorForm";

type Props = {
  onClose: () => void;
};

export const RampEditorPresenter: React.FC<Props> = ({ onClose }) => {
  const { ramps, setRamps } = useRampsState();
  const { ramp, setRamp } = useRampState();
  const [selectedTool, setSelectedTool] = React.useState<string>("Select");
  const [showReferenceImage, setShowReferenceImage] = React.useState<boolean>(
    true
  );
  const [localRamp, setLocalRamp] = React.useState<Ramp>();
  const [shapes, setShapes] = React.useState<Shape[]>([
    // {
    //   points: [
    //     { x: 317.9269426825494, y: 225.58204620462055 },
    //     { x: 321.4516621544965, y: 454.68881188118775 },
    //     { x: 619.2904575340349, y: 438.8275742574256 },
    //     { x: 589.3303420224839, y: 220.2949669966997 },
    //   ],
    //   type: "Area",
    //   lineStyle: "solid",
    //   lineWidth: 3,
    //   strokeStyle: "rgba(255, 0, 0, 0.5)",
    // },
    // {
    //   points: [
    //     { x: 671.1358289930555, y: 645.8796666666666 },
    //     { x: 662.6597178819444, y: 769.6308888888889 },
    //     { x: 832.1819401041666, y: 778.107 },
    //     { x: 818.6201623263888, y: 622.1465555555556 },
    //   ],
    //   type: "Area",
    //   lineStyle: "solid",
    //   lineWidth: 3,
    //   strokeStyle: "rgba(255, 0, 0, 0.5)",
    // },
    // {
    //   points: [
    //     { x: 145.61694010416664, y: 681.4793333333333 },
    //     { x: 179.5213845486111, y: 834.0493333333333 },
    //     { x: 347.3483845486111, y: 796.7544444444444 },
    //     { x: 316.83438454861107, y: 657.7462222222222 },
    //   ],
    //   type: "Area",
    //   lineStyle: "solid",
    //   lineWidth: 3,
    //   strokeStyle: "rgba(255, 0, 0, 0.5)",
    // },
  ]);
  const snackbar = useSnackbar();

  React.useEffect(() => {
    setLocalRamp(ramp);
  }, [ramp]);

  const handleClose = () => {
    onClose();
  };

  const onClickSaveRamp = () => {
    setRamp(localRamp);
    setRamps(ramps.map((r) => (r.id === localRamp.id ? localRamp : r)));
    snackbar.notify({ text: "Ramp changes saved.", severity: "success" });
  };
  /**
   * make a ramp
   * give it a name
   * upload an image
   * make a reference line to tell us how many pixels = how many feet
   * add an outline
   * add other markings
   */

  if (!localRamp) {
    return null;
  }

  return (
    <Dialog open={true} fullScreen>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{ramp.name}</Typography>

          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ maxHeight: "100%", maxWidth: "100%" }}>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={2} alignItems="space-between">
            <Box sx={{ pt: 1, width: 250 }}>
              <RampEditorForm
                shapes={shapes}
                setShapes={setShapes}
                ramp={localRamp}
                setRamp={setLocalRamp}
                onClickSaveRamp={onClickSaveRamp}
                onClose={onClose}
                showReferenceImage={showReferenceImage}
                setShowReferenceImage={setShowReferenceImage}
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
              />
            </Box>
            <Stack
              direction="column"
              alignItems="center"
              spacing={1}
              width="100%"
              overflow="scroll"
            >
              <RampCanvas
                shapes={shapes}
                setShapes={setShapes}
                showReferenceImage={showReferenceImage}
                ramp={localRamp}
                setRamp={setLocalRamp}
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
              />
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
