import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Account } from "./Account";
import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Typography
  } from "@mui/material";
import { Configuration } from "./Configuration";
import { CornerStrategy } from "../../types";
import { HangarsSettings } from "./HangarSettings/HangarsSettings";
import { InviteDialog } from "./UserManagement/InviteDialog";
import { RampCreateButton } from "./RampsSettings/RampEditor/RampCreateButton";
import { RampsSettings } from "./RampsSettings";
import { RevenueEstimator } from "./RevenueEstimator";
import { StackingSOPs } from "./StackingSOPs";
import { TowingEquipment } from "./TowingEquipment";
import { UberAdmin } from "./UberAdmin";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useHangarsState } from "../../containers/HangarsStateContainer";
import { useIdentity } from "../../containers/IdentityContainer";
import { useRampsState } from "../../containers/RampsStateContainer";
import { UserManagement } from "./UserManagement";
import { v4 as uuidv4 } from "uuid";

type Props = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  openDialog: boolean;
  setOpenDialog: (x: boolean) => void;
  openReferenceHangar: boolean;
  setOpenReferenceHangar: (x: boolean) => void;
};

export const SettingsPresenter: React.FC<Props> = ({
  activeTab,
  setActiveTab,
  openDialog,
  setOpenDialog,
  openReferenceHangar,
  setOpenReferenceHangar,
  ...props
}) => {
  const { ramps, setRamps } = useRampsState();
  const { hangars, setHangars } = useHangarsState();
  const { airplxIdentity } = useIdentity();
  const { activeFBO } = useActiveFBOs();
  return (
    <Box sx={{ ml: 2, mr: 2, height: "100%" }}>
      <Stack direction="row" justifyContent="space-between" sx={{ m: 2 }}>
        <Typography variant="h6">Settings – {activeFBO?.name}</Typography>
        <Stack direction="row" spacing={1}>
          {activeTab === "Team Members" && (
            <InviteDialog fbo_id={activeFBO.id} onClose={() => null} />
          )}
          {activeTab === "Hangars" && airplxIdentity?.isadmin && (
            <Button
              disabled={activeFBO.subscription === "standard"}
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={async () => {
                setHangars(
                  hangars.concat([
                    {
                      fbo_id: activeFBO.id,
                      id: uuidv4(),
                      location_id: null,
                      name: "New Hangar",
                      width: 150,
                      depth: 150,
                      height: 25,
                      left_door: null,
                      right_door: null,
                      obstacles: [],
                      created_at: new Date().toISOString(),
                      garageDoors: [],
                      wall_spacing_back: 0,
                      wall_spacing_left: 0,
                      wall_spacing_right: 0,
                      last_stacked: null,
                      stack: {
                        isReference: false,
                        id: uuidv4(),
                        tenants: [],
                        movableObstacles: [],
                        options: {
                          // these are the defaults
                          wall_spacing_left: 2,
                          wall_spacing_back: 2,
                          wall_spacing_right: 2,
                          horizontal_spacing: activeFBO?.sop_horizontal_spacing,
                          vertical_spacing: activeFBO?.sop_vertical_spacing,
                          overlap: activeFBO?.sop_overlap,
                          tug_spacing: 10,
                          density_threshold: 0.8,
                          xy_weights: [1, 2],
                          corner_strategy: [
                            CornerStrategy.ANGLE_45,
                            CornerStrategy.ANGLE_45,
                          ],
                          towing_equipment_id: null,
                        },
                      },
                    },
                  ])
                );
              }}
            >
              Add Hangar
            </Button>
          )}
          {activeTab === "Ramps" && airplxIdentity?.isadmin && (
            <RampCreateButton
              onClose={() => {
                // nada
              }}
            />
          )}
        </Stack>
      </Stack>
      <Tabs
        value={activeTab}
        onChange={(evt, newValue) => setActiveTab(newValue)}
      >
        {activeFBO.user_role === "manager" && (
          <Tab label="Hangars" value="Hangars" />
        )}
        {activeFBO.user_role === "manager" && (
          <Tab label="Ramps" value="Ramps" />
        )}
        {activeFBO.user_role === "manager" && (
          <Tab label="Stacking SOPs" value="Stacking SOPs" />
        )}
        {activeFBO.user_role === "manager" && (
          <Tab label="Towing Equipment" value="Towing Equipment" />
        )}
        {activeFBO.user_role === "manager" && (
          <Tab label="Estimation" value="Estimation" />
        )}
        {activeFBO.user_role === "manager" && (
          <Tab label="Team Members" value="Team Members" />
        )}
        {activeFBO.user_role === "manager" && (
          <Tab label="Configuration" value="Configuration" />
        )}
        <Tab label="Account" value="Account" />
        {airplxIdentity?.isadmin && <Tab label="AirPlx" value="Workers" />}
      </Tabs>
      <Box sx={{ height: "100%" }}>
        {activeFBO.user_role === "manager" && activeTab === "Team Members" && (
          <UserManagement />
        )}
        {activeFBO.user_role === "manager" && activeTab === "Hangars" && (
          <HangarsSettings
            openDialog={true}
            setOpenDialog={setOpenDialog}
            openReferenceHangar={openReferenceHangar}
            setOpenReferenceHangar={setOpenReferenceHangar}
          />
        )}
        {activeFBO.user_role === "manager" && activeTab === "Stacking SOPs" && (
          <StackingSOPs />
        )}
        {activeFBO.user_role === "manager" &&
          activeTab === "Towing Equipment" && <TowingEquipment />}
        {activeFBO.user_role === "manager" && activeTab === "Estimation" && (
          <RevenueEstimator />
        )}
        {activeFBO.user_role === "manager" && activeTab === "Configuration" && (
          <Configuration />
        )}
        {activeTab === "Account" && <Account />}
        {activeFBO.user_role === "manager" && activeTab === "Ramps" && (
          <RampsSettings />
        )}
        {airplxIdentity?.isadmin && activeTab === "Workers" && <UberAdmin />}
      </Box>
    </Box>
  );
};
