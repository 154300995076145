import * as md5 from "md5";
import { Aircraft } from "../LabelingTool";
import { CustomPlacementOptions } from "./CustomStackDialog";
import { getStackPolygons } from "../../hooks/utils";
import { Polygon, polygonInPolygon, polygonIntersectsPolygon } from "geometric";
import { uniqBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  AircraftToPlace,
  CornerStrategies,
  CornerStrategy,
  FBO,
  ParamSet,
  Preference,
} from "../../types";

export const sortBiggestToSmallest = (
  aircrafts_to_place: AircraftToPlace[],
  aircrafts: Aircraft[]
): AircraftToPlace[] => {
  return [...aircrafts_to_place].sort((a, b) => {
    if (a.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
      return 1;
    }
    if (b.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
      return -1;
    }
    if (
      a.preferences.indexOf(Preference.PREFER_FRONT_ROW) > -1 ||
      a.preferences.indexOf(Preference.PREFER_BACK_ROW) > -1
    ) {
      return -1;
    }
    if (
      b.preferences.indexOf(Preference.PREFER_FRONT_ROW) > -1 ||
      b.preferences.indexOf(Preference.PREFER_BACK_ROW) > -1
    ) {
      return 1;
    }
    if (b.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
      return -1;
    }
    if (a.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
      return 1;
    }
    const aircraftA = aircrafts.find(({ id }) => id === a.id);
    const aircraftB = aircrafts.find(({ id }) => id === b.id);
    return (
      aircraftB.wingspan * aircraftB.length -
      aircraftA.wingspan * aircraftA.length
    );
  });
};

const sortDoubleSwap = (
  aircrafts_to_place: AircraftToPlace[],
  aircrafts: Aircraft[]
): AircraftToPlace[] => {
  const aircraftOrderedBiggestToSmallest = sortBiggestToSmallest(
    aircrafts_to_place,
    aircrafts
  );
  const aircraftOrdereDoubleSwap = [];
  for (let i = 0; i < aircraftOrderedBiggestToSmallest.length; i += 2) {
    if (aircraftOrderedBiggestToSmallest[i + 1]) {
      aircraftOrdereDoubleSwap.push(aircraftOrderedBiggestToSmallest[i + 1]);
    }
    aircraftOrdereDoubleSwap.push(aircraftOrderedBiggestToSmallest[i]);
  }
  return aircraftOrdereDoubleSwap;
};

const sortTripleSwap = (
  aircrafts_to_place: AircraftToPlace[],
  aircrafts: Aircraft[]
): AircraftToPlace[] => {
  const aircraftOrderedBiggestToSmallest = sortBiggestToSmallest(
    aircrafts_to_place,
    aircrafts
  );
  const aircraftOrdereTripleSwap = [];
  for (let i = 0; i < aircraftOrderedBiggestToSmallest.length; i += 3) {
    if (aircraftOrderedBiggestToSmallest[i + 2]) {
      aircraftOrdereTripleSwap.push(aircraftOrderedBiggestToSmallest[i + 2]);
    }
    if (aircraftOrderedBiggestToSmallest[i + 1]) {
      aircraftOrdereTripleSwap.push(aircraftOrderedBiggestToSmallest[i + 1]);
    }
    aircraftOrdereTripleSwap.push(aircraftOrderedBiggestToSmallest[i]);
  }
  return aircraftOrdereTripleSwap;
};

export const generateParamSets = (
  params: ParamSet,
  utilization: number,
  aircrafts: Aircraft[]
): ParamSet[] => {
  let paramSets = [];
  if (utilization < 0.8) {
    paramSets = generateLowUtilizationParamSets(params, aircrafts);
  } else if (params.options.overlap === true) {
    paramSets = generateOverlapParamSets(params, aircrafts);
  } else {
    paramSets = generateStandardParamSets(params, aircrafts);
  }
  // for certain edge cases, it's possible to have duplicates
  return uniqBy(paramSets, (paramSet) => md5(JSON.stringify(paramSet)));
};

export const generateDevParamSets = (
  params: ParamSet,
  aircrafts: Aircraft[],
  customPlacementOptions: CustomPlacementOptions
): ParamSet[] => {
  const paramSets: ParamSet[] = [];
  const xyWeightsToTry = [
    customPlacementOptions?.xy_weights ?? [1, 2],
    [1.5, 1],
  ];
  const cornerStrategiesToTry =
    customPlacementOptions?.corner_strategies ?? CornerStrategies;
  for (const xy_weights of xyWeightsToTry) {
    for (const corner_strategy of cornerStrategiesToTry) {
      paramSets.push({
        run_id: uuidv4(),
        ...params,
        options: {
          ...params.options,
          corner_strategy,
          xy_weights,
        },
        label: `Biggest to Smallest, [${xy_weights}] [${corner_strategy.join(
          ","
        )}], Spacing: ${params.options.horizontal_spacing} ft`,
        aircrafts_to_place: sortBiggestToSmallest(
          params.aircrafts_to_place,
          aircrafts
        ),
      });
      paramSets.push({
        run_id: uuidv4(),
        ...params,
        options: {
          ...params.options,
          corner_strategy,
          xy_weights,
        },
        label: `Double Swap, [${xy_weights}] [${corner_strategy.join(
          ","
        )}], Spacing: ${params.options.horizontal_spacing} ft`,
        aircrafts_to_place: sortDoubleSwap(
          params.aircrafts_to_place,
          aircrafts
        ),
      });
      paramSets.push({
        run_id: uuidv4(),
        ...params,
        options: {
          ...params.options,
          corner_strategy,
          xy_weights,
        },
        label: `Triple Swap, [${xy_weights}] [${corner_strategy.join(
          ","
        )}], Spacing: ${params.options.horizontal_spacing} ft`,
        aircrafts_to_place: sortTripleSwap(
          params.aircrafts_to_place,
          aircrafts
        ),
      });
    }
  }
  return paramSets;
};

export const isCornerOpen = (
  params: ParamSet,
  aircrafts: Aircraft[]
): boolean => {
  const polygons = getStackPolygons(
    {
      id: null,
      isReference: null,
      movableObstacles: [],
      options: params.options,
      tenants: params.locked_aircrafts.map((aircraft) => ({
        id: uuidv4(),
        entity_id: uuidv4(),
        aircraft_id: aircraft.id,
        fbo_id: null,
        is_reference: null,
        owner_manager: null,
        placement_id: null,
        selected: null,
        tail_number: null,
        type: null,
        aircraft: aircrafts.find((a) => a.id === aircraft.id),
        position: {
          // doesn't matter as these are throwaway
          id: uuidv4(),
          stack_id: uuidv4(),
          entity_id: uuidv4(),
          x: aircraft.x,
          y: aircraft.y,
          angle: aircraft.angle,
          is_reference: false,
          preferences: [],
        },
      })),
    },
    params.hangar.width,
    1
  );
  const aircraftBeingPlaced = aircrafts.find(
    (a) => a.id === params.aircrafts_to_place[0].id
  );
  const span = Math.max(
    aircraftBeingPlaced.wingspan,
    aircraftBeingPlaced.length
  );
  let leftCornerOpen = true;
  let rightCornerOpen = true;
  for (const polygon of polygons) {
    const leftCorner: Polygon = [
      [0, params.hangar.depth],
      [span, params.hangar.depth],
      [span, params.hangar.depth - span],
      [0, params.hangar.depth - span],
    ];
    const rightCorner: Polygon = [
      [params.hangar.width, params.hangar.depth],
      [params.hangar.width - span, params.hangar.depth],
      [params.hangar.width - span, params.hangar.depth - span],
      [params.hangar.width, params.hangar.depth - span],
    ];
    if (
      polygonInPolygon(leftCorner, polygon.polygon) ||
      polygonInPolygon(polygon.polygon, leftCorner) ||
      polygonIntersectsPolygon(leftCorner, polygon.polygon)
    ) {
      leftCornerOpen = false;
    }
    if (
      polygonInPolygon(rightCorner, polygon.polygon) ||
      polygonInPolygon(polygon.polygon, rightCorner) ||
      polygonIntersectsPolygon(rightCorner, polygon.polygon)
    ) {
      rightCornerOpen = false;
    }
  }
  return leftCornerOpen || rightCornerOpen;
};

export const generateAutocompleteParamSets = (
  params: ParamSet,
  aircrafts: Aircraft[]
): ParamSet[] => {
  const paramSets: ParamSet[] = [];
  const xyWeightsToTry = [
    [1, 2],
    [1.5, 1],
    // [1, 1],
  ];

  if (isCornerOpen(params, aircrafts)) {
    const xy_weights = [1, 2];
    for (const corner_strategy of [
      [CornerStrategy.ANGLE_SHALLOW, CornerStrategy.ANGLE_SHALLOW],
    ]) {
      const prefsToTry = [[Preference.PREFER_LEFT], [Preference.PREFER_RIGHT]];
      for (const prefToTry of prefsToTry) {
        paramSets.push({
          run_id: uuidv4(),
          ...params,
          options: {
            ...params.options,
            corner_strategy,
            xy_weights,
          },
          label: `Autocomplete, [${xy_weights}] [${corner_strategy}], Spacing: ${params.options.horizontal_spacing} ft`,
          aircrafts_to_place: [
            {
              ...params.aircrafts_to_place[0],
              preferences: [
                ...params.aircrafts_to_place[0].preferences,
                ...prefToTry,
              ],
            },
          ],
        });
      }
    }
    return paramSets;
  }

  const prefsToTry = [
    // [Preference.PREFER_LEFT, Preference.PREFER_BACK],
    // [Preference.PREFER_LEFT, Preference.PREFER_FRONT],
    // [Preference.PREFER_RIGHT, Preference.PREFER_BACK],
    // [Preference.PREFER_RIGHT, Preference.PREFER_FRONT],
    [Preference.PREFER_LEFT_THIRD],
    [Preference.PREFER_MIDDLE_THIRD],
    [Preference.PREFER_RIGHT_THIRD],
  ];
  for (const prefToTry of prefsToTry) {
    for (const xy_weights of [[1, 2]]) {
      paramSets.push({
        run_id: uuidv4(),
        ...params,
        options: {
          ...params.options,
          corner_strategy: CornerStrategies[0],
          xy_weights,
        },
        label: `Autocomplete, [${xy_weights}] [${prefToTry}] [${CornerStrategies[0]}], Spacing: ${params.options.horizontal_spacing} ft`,
        aircrafts_to_place: [
          {
            ...params.aircrafts_to_place[0],
            preferences: [
              ...params.aircrafts_to_place[0].preferences,
              ...prefToTry,
            ],
          },
        ],
      });
    }
  }
  return paramSets;
};

export const generateDevParamSetsForTreeStack = (
  params: ParamSet,
  aircrafts: Aircraft[],
  customPlacementOptions: CustomPlacementOptions
): ParamSet[] => {
  const paramSets: ParamSet[] = [];
  for (const corner_strategy of customPlacementOptions.corner_strategies) {
    paramSets.push({
      run_id: uuidv4(),
      ...params,
      options: {
        ...params.options,
        corner_strategy,
      },
      label: `TreeStack [${corner_strategy.join(",")}], Spacing: ${
        params.options.horizontal_spacing
      } ft`,
      aircrafts_to_place: sortBiggestToSmallest(
        params.aircrafts_to_place,
        aircrafts
      ),
    });
  }
  return paramSets;
};

export const generateStandardParamSets = (
  params: ParamSet,
  aircrafts: Aircraft[]
): ParamSet[] => {
  const error_types = [
    "error_hybrid_magnetic_lines_50_typewriter_50",
    "error_hybrid_magnetic_lines_25_typewriter_75",
    "error_hybrid_magnetic_lines_10_typewriter_90",
    "error_hybrid_magnetic_lines_50_reverse_typewriter_50",
    "error_hybrid_magnetic_lines_25_reverse_typewriter_75",
    "error_hybrid_magnetic_lines_10_reverse_typewriter_90",
  ];

  const aircraftOrderedBiggestToSmallest = [...params.aircrafts_to_place].sort(
    (a, b) => {
      if (a.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
        return 1;
      }
      if (b.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
        return -1;
      }
      if (
        a.preferences.indexOf(Preference.PREFER_FRONT_ROW) > -1 ||
        a.preferences.indexOf(Preference.PREFER_BACK_ROW) > -1
      ) {
        return -1;
      }
      if (
        b.preferences.indexOf(Preference.PREFER_FRONT_ROW) > -1 ||
        b.preferences.indexOf(Preference.PREFER_BACK_ROW) > -1
      ) {
        return 1;
      }
      if (b.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
        return -1;
      }
      if (a.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
        return 1;
      }
      const aircraftA = aircrafts.find(({ id }) => id === a.id);
      const aircraftB = aircrafts.find(({ id }) => id === b.id);
      return (
        aircraftB.wingspan * aircraftB.length -
        aircraftA.wingspan * aircraftA.length
      );
    }
  );

  const aircraftOrdereDoubleSwap = [];
  for (let i = 0; i < aircraftOrderedBiggestToSmallest.length; i += 2) {
    if (aircraftOrderedBiggestToSmallest[i + 1]) {
      aircraftOrdereDoubleSwap.push(aircraftOrderedBiggestToSmallest[i + 1]);
    }
    aircraftOrdereDoubleSwap.push(aircraftOrderedBiggestToSmallest[i]);
  }
  const aircraftsToPlaceOrders = [
    // biggest to smallest
    aircraftOrderedBiggestToSmallest,
    // double swap
    aircraftOrdereDoubleSwap,
  ];

  const labels = [
    "Big to Small, 50/50, Typewriter",
    "Big to Small, 25/75, Typewriter",
    "Big to Small, 10/90, Typewriter",
    "Big to Small, 50/50, Reverse Typewriter",
    "Big to Small, 25/75, Reverse Typewriter",
    "Big to Small, 10/90, Reverse Typewriter",
    "Double Swap, 50/50, Typewriter",
    "Double Swap, 25/75, Typewriter",
    "Double Swap, 10/90, Typewriter",
    "Double Swap, 50/50, Reverse Typewriter",
    "Double Swap, 25/75, Reverse Typewriter",
    "Double Swap, 10/90, Reverse Typewriter",
  ];

  const paramsSets: ParamSet[] = [];

  for (let error_type of error_types) {
    for (let orderedAircraftsToPlace of aircraftsToPlaceOrders) {
      const label = `${labels[paramsSets.length]}, ${
        params.options.overlap ? "3D" : "2D"
      }`;
      paramsSets.push({
        ...params,
        label,
        aircrafts_to_place: orderedAircraftsToPlace,
        options: {
          ...params.options,
          error_type,
        },
      });
    }
  }
  return paramsSets;
};

export const generateLowUtilizationParamSets = (
  params: ParamSet,
  aircrafts: Aircraft[]
): ParamSet[] => {
  const error_types = [
    "error_hybrid_magnetic_lines_50_typewriter_50",
    "error_hybrid_magnetic_lines_25_typewriter_75",
    "error_hybrid_magnetic_lines_10_typewriter_90",
  ];

  const aircraftOrderedBiggestToSmallest = [...params.aircrafts_to_place].sort(
    (a, b) => {
      if (a.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
        return 1;
      }
      if (b.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
        return -1;
      }
      if (
        a.preferences.indexOf(Preference.PREFER_FRONT_ROW) > -1 ||
        a.preferences.indexOf(Preference.PREFER_BACK_ROW) > -1
      ) {
        return -1;
      }
      if (
        b.preferences.indexOf(Preference.PREFER_FRONT_ROW) > -1 ||
        b.preferences.indexOf(Preference.PREFER_BACK_ROW) > -1
      ) {
        return 1;
      }
      if (b.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
        return -1;
      }
      if (a.preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
        return 1;
      }
      const aircraftA = aircrafts.find(({ id }) => id === a.id);
      const aircraftB = aircrafts.find(({ id }) => id === b.id);
      return (
        aircraftB.wingspan * aircraftB.length -
        aircraftA.wingspan * aircraftA.length
      );
    }
  );

  const aircraftsToPlaceOrders = [aircraftOrderedBiggestToSmallest];

  const labels = [
    "Big to Small, 50/50, Typewriter, 1ft spacing",
    "Big to Small, 50/50, Typewriter, 2ft spacing",
    "Big to Small, 50/50, Typewriter, 3ft spacing",
    "Big to Small, 50/50, Typewriter, 4ft spacing",
    "Big to Small, 50/50, Typewriter, 5ft spacing",
    "Big to Small, 25/75, Typewriter, 1 ft spacing",
    "Big to Small, 25/75, Typewriter, 2 ft spacing",
    "Big to Small, 25/75, Typewriter, 3 ft spacing",
    "Big to Small, 25/75, Typewriter, 4 ft spacing",
    "Big to Small, 25/75, Typewriter, 5 ft spacing",
    "Big to Small, 10/90, Typewriter, 1 ft spacing",
    "Big to Small, 10/90, Typewriter, 2 ft spacing",
    "Big to Small, 10/90, Typewriter, 3 ft spacing",
    "Big to Small, 10/90, Typewriter, 4 ft spacing",
    "Big to Small, 10/90, Typewriter, 5 ft spacing",
  ];

  const spacings = [1, 2, 3, 4, 5];

  const paramsSets: ParamSet[] = [];
  for (let orderedAircraftsToPlace of aircraftsToPlaceOrders) {
    for (let error_type of error_types) {
      for (let spacing of spacings) {
        paramsSets.push({
          ...params,
          label: labels[paramsSets.length],
          aircrafts_to_place: orderedAircraftsToPlace,
          options: {
            ...params.options,
            error_type,
            horizontal_spacing: spacing,
          },
        });
      }
    }
  }
  return paramsSets;
};

export const generateOverlapParamSets = (
  params: ParamSet,
  aircrafts: Aircraft[]
): ParamSet[] => {
  // - Do same 12 presets (as in 2D) with full 3D
  // - Do same 12 presets without overlap
  const nonOverlappingParamSets = generateStandardParamSets(
    {
      ...params,
      options: { ...params.options, overlap: false },
    },
    aircrafts
  );
  const overlappingParamSets = generateStandardParamSets(params, aircrafts);
  const paramSets = [...nonOverlappingParamSets, ...overlappingParamSets];

  return paramSets;
};

export enum StackStrategy {
  normal = "NORMAL",
  tight = "TIGHT",
  loose = "LOOSE",
  custom = "CUSTOM",
  dev = "DEV",
}

export const makeParamSetForType = (
  strategy: StackStrategy,
  paramSet: ParamSet,
  customPlacementOptions: CustomPlacementOptions,
  fbo: FBO
): ParamSet => {
  if (strategy === StackStrategy.dev) {
    return customParamSet(paramSet, customPlacementOptions);
  }
  if (strategy === StackStrategy.custom) {
    return customParamSet(paramSet, customPlacementOptions);
  }
  if (strategy === StackStrategy.normal) {
    return normalParamSet(paramSet);
  }
  if (strategy === StackStrategy.loose) {
    return looseParamSet(paramSet, fbo);
  }
  if (strategy === StackStrategy.tight) {
    return tightParamSet(paramSet, fbo);
  }
};

export const tightParamSet = (paramSet: ParamSet, fbo: FBO): ParamSet => {
  return {
    ...paramSet,
    // aircrafts_to_place: paramSet.aircrafts_to_place.map((a) => ({
    //   ...a,
    //   // nuke the preferences
    //   preferences: [],
    // })),
    options: {
      ...paramSet.options,
      xy_weights: [1, 2],
      cloud_position_prioritization: true,
      sample_size: 1000,
      vertical_spacing:
        fbo.sop_vertical_spacing_tight ??
        Math.max(0, fbo.sop_vertical_spacing - 1),
      horizontal_spacing:
        fbo.sop_horizontal_spacing_tight ??
        Math.max(0, fbo.sop_horizontal_spacing - 1),
      overlap: true,
      wall_spacing_back: paramSet.options.wall_spacing_back,
      wall_spacing_left: paramSet.options.wall_spacing_left,
      wall_spacing_right: paramSet.options.wall_spacing_right,
    },
  };
};

export const normalParamSet = (paramSet: ParamSet): ParamSet => {
  return {
    ...paramSet,
    options: {
      ...paramSet.options,
      xy_weights: [1, 2],
      cloud_position_prioritization: true,
      sample_size: 1000,
      overlap: paramSet.options.overlap ?? true,
    },
  };
};

export const looseParamSet = (paramSet: ParamSet, fbo: FBO): ParamSet => {
  return {
    ...paramSet,
    options: {
      ...paramSet.options,
      xy_weights: [1, 2],
      cloud_position_prioritization: true,
      sample_size: 1000,
      overlap: false,
      vertical_spacing:
        fbo.sop_vertical_spacing_loose ?? paramSet.options.vertical_spacing + 1,
      horizontal_spacing:
        fbo.sop_horizontal_spacing_loose ??
        paramSet.options.horizontal_spacing + 1,
      wall_spacing_back: paramSet.options.wall_spacing_back + 1,
      wall_spacing_left: paramSet.options.wall_spacing_left + 1,
      wall_spacing_right: paramSet.options.wall_spacing_right + 1,
    },
  };
};

export const customParamSet = (
  paramSet: ParamSet,
  customPlacementOptions: CustomPlacementOptions
): ParamSet => {
  return {
    ...paramSet,
    aircrafts_to_place: paramSet.aircrafts_to_place.map((a) => ({
      ...a,
      // nuke the preferences
      preferences: a.preferences.filter((pref) => {
        if (
          (pref === Preference.NOSE_IN || pref === Preference.TAIL_IN) &&
          customPlacementOptions.ignore_nose_in_out
        ) {
          return false;
        }
        if (
          (pref === Preference.PREFER_BACK_ROW ||
            pref === Preference.PREFER_FRONT_ROW) &&
          customPlacementOptions.ignore_front_back
        ) {
          return false;
        }
        if (
          pref === Preference.OK_TO_LEAVE_OUT &&
          customPlacementOptions.ignore_outside_hangar
        ) {
          return false;
        }
        return true;
      }),
    })),
    options: {
      ...paramSet.options,
      towing_equipment_id:
        customPlacementOptions.towing_equipment_id ??
        paramSet.options.towing_equipment_id,
      overlap: customPlacementOptions.overlap,
      horizontal_spacing: customPlacementOptions.horizontal_spacing,
      vertical_spacing: customPlacementOptions.vertical_spacing,
      cloud_position_prioritization:
        customPlacementOptions.cloud_position_prioritization,
      sample_size: customPlacementOptions.sample_size,
      corner_strategy: customPlacementOptions.corner_strategies?.[0],
      xy_weights: customPlacementOptions.xy_weights,
      wall_spacing_back: customPlacementOptions.wall_spacing_back,
      wall_spacing_left: customPlacementOptions.wall_spacing_left,
      wall_spacing_right: customPlacementOptions.wall_spacing_right,
    },
  };
};
