import * as React from "react";
import Canvas from "../../widgets/useCanvas";
import { Aircraft } from "./LabelingTool";
import { drawHorizontalLine, drawImage } from "./LabelingToolPresenter";
type Props = {
  img: HTMLImageElement;
  aircraft: Aircraft;
  onClick: (yValue: number) => void;
};

const CANVAS_WIDTH = 600;
const CANVAS_HEIGHT = 600;
export const SideViewCanvas: React.FC<Props> = ({ img, aircraft, onClick }) => {
  const canvasRef = React.createRef();
  const draw = (ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    drawImage(ctx, img);
    aircraft.reference_point_vertical_tail_fin_bottom &&
      drawHorizontalLine(
        ctx,
        aircraft.reference_point_vertical_tail_fin_bottom,
        0,
        CANVAS_WIDTH,
        {
          color: "red",
          width: 0.75,
        }
      );
    aircraft.reference_point_vertical_tail_fin_top &&
      drawHorizontalLine(
        ctx,
        aircraft.reference_point_vertical_tail_fin_top,
        0,
        CANVAS_WIDTH,
        {
          color: "red",
          width: 1,
        }
      );
    aircraft.reference_point_cabin_bottom &&
      drawHorizontalLine(
        ctx,
        aircraft.reference_point_cabin_bottom,
        0,
        CANVAS_WIDTH,
        {
          color: "blue",
          width: 1,
        }
      );
    aircraft.reference_point_cabin_top &&
      drawHorizontalLine(
        ctx,
        aircraft.reference_point_cabin_top,
        0,
        CANVAS_WIDTH,
        {
          color: "blue",
          width: 1,
        }
      );
    aircraft.reference_point_wing_bottom &&
      drawHorizontalLine(
        ctx,
        aircraft.reference_point_wing_bottom,
        0,
        CANVAS_WIDTH,
        {
          color: "purple",
          width: 0.75,
        }
      );
    aircraft.reference_point_wing_top &&
      drawHorizontalLine(
        ctx,
        aircraft.reference_point_wing_top,
        0,
        CANVAS_WIDTH,
        {
          color: "purple",
          width: 0.75,
        }
      );
    aircraft.reference_point_tail_bottom &&
      drawHorizontalLine(
        ctx,
        aircraft.reference_point_tail_bottom,
        0,
        CANVAS_WIDTH,
        {
          color: "orange",
          width: 0.75,
        }
      );
    aircraft.reference_point_tail_top &&
      drawHorizontalLine(
        ctx,
        aircraft.reference_point_tail_top,
        0,
        CANVAS_WIDTH,
        {
          color: "orange",
          width: 0.75,
        }
      );
  };
  return (
    <Canvas
      ref={canvasRef}
      width={CANVAS_WIDTH}
      height={CANVAS_HEIGHT}
      draw={draw}
      onClick={(evt) => {
        const canvas = evt.target;
        const rect = canvas.getBoundingClientRect();
        // const x = Math.round(evt.clientX - rect.left);
        const y = Math.round(evt.clientY - rect.top);
        onClick(y);
      }}
      style={{ border: "2px solid black", cursor: "crosshair" }}
    />
  );
};
