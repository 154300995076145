import { useHangarState } from "../containers/HangarStateContainer";
import { useMultiHangarState } from "../containers/MultiHangarContainer";

export const useThisHangar = () => {
  const singleHangarState = useHangarState();
  const multiHangarState = useMultiHangarState();
  if (multiHangarState.isMultiHangar) {
    return multiHangarState;
  }
  return singleHangarState;
};
