import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { FBOSettings } from "../../../types";
import { ConfigurationPresenter } from "./ConfigurationPresenter";

type Props = {};

export const Configuration: React.FC<Props> = () => {
  const { activeFBO, setFBO } = useActiveFBOs();
  const setSettings = React.useCallback(
    (settings: FBOSettings) => {
      setFBO({
        ...activeFBO,
        settings,
      });
    },
    [activeFBO]
  );
  return (
    <ConfigurationPresenter
      settings={activeFBO.settings}
      setSettings={setSettings}
    />
  );
};
