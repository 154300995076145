import * as React from "react";
import { getPostgrestUrl, getTokenMakerUrl } from "../utils";
import { PostgrestClient } from "@supabase/postgrest-js";

export const usePostgrest = () => {
  const [token, setToken] = React.useState<string>("");
  const postgrest = new PostgrestClient(getPostgrestUrl());
  if (token) {
    postgrest.auth(token);
  }
  return postgrest;
};

export const useTokenMaker = () => {
  const url = getTokenMakerUrl();

  const verifyJWT = async (jwt: string): Promise<string> => {
    const result = await fetch(`${url}/?token=${jwt}`).then((res) =>
      res.json()
    );
    return result.token;
  };
  return {
    url,
    verifyJWT,
  };
};
