import * as React from "react";
import { HangarsPresenter } from "./HangarsPresenter";
import { HangarsStateContainer } from "../../containers/HangarsStateContainer";

type Props = {};

export const Hangars: React.FC<Props> = ({ ...props }) => {
  return (
    <HangarsStateContainer isReference={false}>
      <HangarsPresenter />
    </HangarsStateContainer>
  );
};
