import pLimit from "p-limit";
import { Hangar, ParamSet } from "../../types";
import { Identity } from "../../containers/IdentityContainer";
import { placeIncrementally } from "./PlaceIncrementally";

export type LayoutOption = {
  paramSet: ParamSet;
  stackMembers: any[];
};

export const runParamSets = async (
  hangar: Hangar,
  paramSets: ParamSet[],
  airplxIdentity: Identity,
  airplxToken: string,
  onUpdate: (layoutOptions: LayoutOption[], progress: number[]) => void
) => {
  const N_WORKER_THREADS = 18;
  const limit = pLimit(N_WORKER_THREADS);
  const currentLayoutOptions = paramSets.map((paramSet) => ({
    paramSet,
    stackMembers: [],
  }));
  const progress: number[] = paramSets.map(() => 0);
  const doit = paramSets.map(async (paramsWithLabel, idx) => {
    return limit(async () => {
      const { label, image, ...paramsWithoutLabel } = paramsWithLabel;
      let cntr = 0;
      /**
       * We get incremental updates as airplanes are being put into the hangar. So let's
       * show them to the user! TECHNOLOGY!
       */
      for await (let stackMembers of placeIncrementally(
        paramsWithoutLabel,
        hangar.stack.tenants,
        airplxIdentity,
        airplxToken
      )) {
        currentLayoutOptions[idx] = {
          ...currentLayoutOptions[idx],
          stackMembers,
        };
        cntr++;
        // somewhat surprisingly, this stuff still works even though we're now
        // in a function. not exactly sure why...
        progress[idx] = cntr / paramsWithLabel.aircrafts_to_place.length;
        onUpdate([...currentLayoutOptions], progress);
      }
      progress[idx] = 1;
      onUpdate([...currentLayoutOptions], progress);
    });
  });
  await Promise.all(doit);
  onUpdate([...currentLayoutOptions], progress);
};
