import * as React from "react";
import { useActiveFBOs } from "../containers/ActiveFBOContainer";
import { useApi } from "../containers/ApiContainer";
import { sortHangars } from "../screens/Hangars/HangarsPresenter";
import { Hangar } from "../types";
import { fetchHangars } from "./api";
import useSyncToTable from "./useSyncToTable";

export const useHangars = (isReference: boolean) => {
  const { postgrest } = useApi();
  const { activeFBO } = useActiveFBOs();
  const [ready, setReady] = React.useState<boolean>(false);
  const [hangars, setHangars] = React.useState<Hangar[]>(null);

  useSyncToTable<Hangar[]>({
    thingToWatch: hangars,
    tableName: "hangar",
  });

  // grab the hangar to start
  React.useEffect(() => {
    (async () => {
      const zHangars = await fetchHangars(postgrest, activeFBO, isReference);
      setHangars(
        zHangars.sort((a, b) =>
          sortHangars(a, b, activeFBO?.settings?.hangarDisplayOrder)
        )
      );
      setReady(true);
    })();
  }, []);

  return {
    ready,
    hangars: hangars ?? [],
    setHangars,
  };
};
