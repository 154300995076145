import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { AircraftLastUpdated } from "./AircraftLastUpdated";
import { ButtonRemoveFBO } from "./ButtonRemoveFBO";
import { WorkerStatus } from "./WorkerStatus";

export const UberAdmin: React.FC<{}> = () => {
  const { activeFBO } = useActiveFBOs();

  return (
    <Stack direction="column" spacing={4}>
      <WorkerStatus />
      <AircraftLastUpdated />
      <Stack direction="column" spacing={1}>
        <Typography variant="h6">Danger Zone</Typography>
        <Box>
          <ButtonRemoveFBO />
        </Box>
      </Stack>
    </Stack>
  );
};
