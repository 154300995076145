import { Button, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useMultiHangarState } from "../../../containers/MultiHangarContainer";
import { HOLDING_AREA_ID } from "../../../screens/MultiHangar/HoldingArea";
import { CornerStrategy } from "../../../types";
import { uuidv4 } from "../../../utils";
import { MultiHangarSelectForm } from "../MultiHangarSelect";

type Props = {
  name: string;
  setName: (name: string) => void;
  setOpenNameField: (x: boolean) => void;
  setConfirm: (confirm: {
    open: boolean;
    text: string | React.ReactNode;
    okText: string;
    onOk: () => void;
  }) => void;
};

export const MultiLocationSave: React.FC<Props> = ({
  name,
  setName,
  setOpenNameField,
  setConfirm,
}) => {
  const [selectedHangarIds, setSelectedHangarIds] = React.useState<string[]>(
    []
  );
  const { activeFBO } = useActiveFBOs();
  const { hangars, setHangars } = useMultiHangarState();
  return (
    <Stack direction="column" sx={{ minWidth: 400 }}>
      <MultiHangarSelectForm
        hangars={hangars.filter((h) => h.id !== HOLDING_AREA_ID)}
        onChange={setSelectedHangarIds}
      />
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <TextField
          type="text"
          size="small"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button onClick={() => setOpenNameField(false)}>Cancel</Button>
        <Button
          variant="contained"
          color="success"
          disabled={name.length === 0}
          onClick={() => {
            const save = () => {
              setOpenNameField(false);
              setHangars(
                hangars.map((hangar) => {
                  if (!selectedHangarIds.includes(hangar.id)) {
                    return hangar;
                  }
                  const existingStack = hangar.stacks.find(
                    (s) => s.name === name
                  );
                  const stackId = existingStack?.id || uuidv4();
                  return {
                    ...hangar,
                    stacks: [
                      ...hangar.stacks,
                      {
                        id: stackId,
                        location_id: hangar.location_id,
                        isReference: false,
                        name,
                        tenants: hangar.stack.tenants.map((t) => ({
                          ...t,
                          position: {
                            ...t.position,
                            id: uuidv4(),
                            stack_id: stackId,
                            entity_id: t.entity_id,
                          },
                        })),
                        movableObstacles: hangar.stack.movableObstacles.map(
                          (mo) => ({
                            ...mo,
                            position: {
                              ...mo.position,
                              id: uuidv4(),
                              stack_id: stackId,
                              entity_id: mo.entity_id,
                            },
                          })
                        ),
                        options: {
                          // these are the defaults
                          wall_spacing_left: 2,
                          wall_spacing_back: 2,
                          wall_spacing_right: 2,
                          horizontal_spacing: activeFBO?.sop_horizontal_spacing,
                          vertical_spacing: activeFBO?.sop_vertical_spacing,
                          overlap: activeFBO?.sop_overlap,
                          tug_spacing: 10,
                          density_threshold: 0.8,
                          towing_equipment_id: null,
                          corner_strategy: [
                            CornerStrategy.ANGLE_45,
                            CornerStrategy.ANGLE_45,
                          ],
                          cloud_position_prioritization: true,
                          sample_size: 1000,
                          xy_weights: [1, 2],
                        },
                      },
                    ],
                  };
                })
              );
            };
            const existingStack = hangars
              .flatMap((h) => h.stacks)
              .find((s) => s?.name === name);
            if (existingStack) {
              setConfirm({
                open: true,
                text: `A favorite named '${name}' already exists. Do you want to overwrite it?`,
                okText: "Yes, overwrite it",
                onOk: () => {
                  save();
                },
              });
              return;
            }
            save();
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
