import * as md5 from "md5";
import * as React from "react";
import { AutocompletePosition, Stack, Tenant } from "../../../types";
import { AutocompleteResults } from "../../../containers/AutocompleteResultsContainer";
import { getOrientation } from "../../../screens/Hangar/HangarLayout/utils";
import { HOLDING_AREA_ID } from "../../../screens/MultiHangar/HoldingArea";
import { Preferences } from "../../../hooks/useUserPreferences";
import { useMultiHangarState } from "../../../containers/MultiHangarContainer";
import { useSnackbar } from "../../../containers/SnackbarContainer";

type Props = {
  hangarId: string;
  enabled: boolean;
  stack: Stack;
  preferences: Preferences;
  isInLayout: (tenant: Tenant) => boolean;
  autocomplete?: (tenant: Tenant) => Promise<AutocompletePosition[]>;
  autocompleteResults: AutocompleteResults;
  setAutocompleteResults: (results: AutocompleteResults) => void;
};

export const useAutocompleteTenant = (props: Props, deps: any[] = []) => {
  const snackbar = useSnackbar();
  const { isMultiHangar, hangars } = useMultiHangarState();
  const {
    hangarId,
    enabled,
    stack,
    preferences,
    isInLayout,
    autocomplete,
    autocompleteResults,
    setAutocompleteResults,
  } = props;

  return React.useCallback(async (tenant: Tenant) => {
    if (!enabled) {
      return;
    }
    if (!preferences.enableCoPilot || !autocomplete) {
      console.log(`[autocomplete]: disabled`);
      return;
    }
    if (isInLayout(tenant) && hangarId !== HOLDING_AREA_ID) {
      console.log(`[autocomplete]: tenant is already in layout`);
      return;
    }
    const hash = md5(
      JSON.stringify(isMultiHangar ? hangars.map((h) => h.stack) : stack)
    );
    const currentOrientation = getOrientation(tenant);
    // need to also test that our cached results are still valid based on the
    // other tenants
    if (
      autocompleteResults?.id === tenant.id &&
      currentOrientation === autocompleteResults?.orientation &&
      hash === autocompleteResults?.hash
    ) {
      console.log(`[autocomplete]: tenant already has autocomplete results`);
      return;
    }

    // marker to indicate we've started the autocomplete process
    setAutocompleteResults({
      id: tenant.id,
      hash,
      tenant,
      loading: true,
      orientation: currentOrientation,
      suggestedPositions: [],
    });

    const suggestedPositions = await autocomplete(tenant);
    setAutocompleteResults({
      id: tenant.id,
      hash,
      tenant,
      loading: false,
      orientation: currentOrientation,
      suggestedPositions,
    });

    if (suggestedPositions.length === 0) {
      snackbar.notify({
        text: `No viable positions found.`,
        severity: "warning",
      });
    }
  }, deps);
};

function makeAutocompleteHash(paramsWithLabel: any) {
  throw new Error("Function not implemented.");
}
