import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { useTowingEquipmentState } from "../../containers/TowingEquipmentContainer";
import { Hangar, Stack as StackType } from "../../types";
import { Layout } from "../../widgets/Layout";
import { useDimensions } from "../../widgets/Layout/hooks/useDimensions";
import { CustomPlacementOptions } from "../Hangar/CustomStackDialog";
import { useHangarAutocomplete } from "../Hangar/useHangarAutocomplete";

type Props = {
  hangarId: string;
  width: number;
  customPlacementOptions: CustomPlacementOptions;
  hangars: Hangar[];
  print?: boolean;
};

export const LayoutWrapper: React.FC<Props> = ({
  hangarId,
  width,
  customPlacementOptions,
  print = false,
}) => {
  const {
    activeLocationId,
    setActiveLocationId,
    focus,
    setFocus,
    getHangar,
    setHangar,
  } = useMultiHangarState();

  const hangar = getHangar(hangarId);
  const { towingEquipments: allTowingEquipments } = useTowingEquipmentState();
  const defaultTowingEquipmentId = allTowingEquipments.find(
    (t) => t.in_use && t.is_default
  )?.towing_equipment_id;
  const active = !print && (focus || activeLocationId === hangar.id);

  const { feetToPixels } = useDimensions({
    location: { type: "hangar", ...hangar },
    width,
  });

  const setStack = React.useCallback(
    (stack: StackType) => {
      setHangar({
        ...hangar,
        stack: {
          ...hangar.stack,
          ...stack,
        },
      });
    },
    [hangar, setHangar]
  );

  const autocomplete = useHangarAutocomplete(
    {
      hangar,
      defaultTowingEquipmentId,
      width,
      customPlacementOptions,
    },
    [hangar, customPlacementOptions, defaultTowingEquipmentId]
  );

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      spacing={1}
      pb={`${feetToPixels * 75}px`}
      sx={{ height: "100%" }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ ml: 2, width }}
      >
        {active ? (
          <Chip color="primary" label={hangar.name} sx={{ fontSize: 16 }} />
        ) : (
          <Typography>{hangar.name}</Typography>
        )}
        {print ? null : (
          <IconButton
            // i think it looks better when the icon (not the button outline) is pulled flush
            sx={{ mr: -1 }}
            onClick={() => {
              setActiveLocationId(hangar.id);
              setFocus(Boolean(focus) ? null : hangar.id);
            }}
          >
            {focus ? (
              <CloseFullscreenIcon fontSize="small" />
            ) : (
              <OpenInFullIcon fontSize="small" />
            )}
          </IconButton>
        )}
      </Stack>
      <Box>
        <Layout
          width={width}
          location={{ type: "hangar", ...hangar }}
          stack={hangar?.stack}
          setStack={setStack}
          autocomplete={autocomplete}
          options={{
            hideUnplaced: false,
            readOnly: false,
            printable: print ? true : false,
            selectable: false,
            disableDragProtection: true,
            borderColor: active && !focus ? "black" : null,
          }}
        />
      </Box>
    </Stack>
  );
};
