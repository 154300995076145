import * as React from "react";
import {
  Box,
  Button,
  Chip,
  Stack
  } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { round } from "lodash";
import { useTowingEquipment } from "../../../hooks/useTowingEquipment";

export const TowingEquipmentPresenter: React.FC<{}> = () => {
  const { towingEquipments, setTowingEquipments } = useTowingEquipment();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "length",
      headerName: "Length (ft)",
      width: 125,
      editable: false,
      sortable: false,
      valueFormatter: ({ value }) => (value === 0 ? "N/A" : round(value, 2)),
    },
    {
      field: "width",
      headerName: "Width (ft)",
      width: 125,
      editable: false,
      sortable: false,
      valueFormatter: ({ value }) => (value === 0 ? "N/A" : round(value, 2)),
    },
    {
      field: "height",
      headerName: "Height (ft)",
      width: 125,
      editable: false,
      sortable: false,
      valueFormatter: ({ value }) => (value === 0 ? "N/A" : round(value, 2)),
    },
    {
      field: "tow_bar_length",
      headerName: "Tow Bar Length (ft)",
      width: 175,
      editable: false,
      sortable: false,
      valueFormatter: ({ value }) => (value ? round(value, 2) : "–––"),
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          {!params.row.in_use ? (
            <Button
              size="small"
              variant="contained"
              color="info"
              onClick={() =>
                setTowingEquipments(
                  towingEquipments.map((towingEquipment) => ({
                    ...towingEquipment,
                    in_use:
                      towingEquipment.id === params.row.id
                        ? true
                        : towingEquipment.in_use,
                  }))
                )
              }
            >
              Add
            </Button>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                const newTowingEquipment = towingEquipments.map(
                  (towingEquipment) => ({
                    ...towingEquipment,
                    is_default:
                      towingEquipment.id === params.row.id
                        ? false
                        : towingEquipment.is_default,
                    in_use:
                      towingEquipment.id === params.row.id
                        ? false
                        : towingEquipment.in_use,
                  })
                );
                if (!newTowingEquipment.find((t) => t.is_default)) {
                  const idx = newTowingEquipment.findIndex((t) => t.in_use);
                  if (idx > -1) {
                    newTowingEquipment[idx].is_default = true;
                  }
                }
                setTowingEquipments(newTowingEquipment);
              }}
            >
              Remove
            </Button>
          )}
          {params.row.in_use && !params.row.is_default ? (
            <Button
              size="small"
              variant="contained"
              color="info"
              onClick={() =>
                setTowingEquipments(
                  towingEquipments.map((towingEquipment) => ({
                    ...towingEquipment,
                    is_default:
                      towingEquipment.id === params.row.id ? true : false,
                  }))
                )
              }
            >
              Make Default
            </Button>
          ) : (
            <div />
          )}

          {params.row.is_default && (
            <Chip label="Default" color="primary" variant="outlined" />
          )}
        </Stack>
      ),
    },
  ];
  return (
    <Box sx={{ height: "calc(100% - 150px)", width: "100%" }}>
      <DataGrid
        disableColumnFilter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        rows={towingEquipments}
        columns={columns}
      />
    </Box>
  );
};
