import { contours as d3Contours } from "d3-contour";
// import * as simplify from 'simplify-js';
const simplify = require("simplify-js");

export function imageDataAsRgbaObject(
  imageData: ImageData
): { red: number; green: number; blue: number; alpha: number }[] {
  const data = imageData.data; // => [r,g,b,a,...]

  const pixels = [];
  for (let i = 0; i < data.length; i += 4) {
    pixels.push({
      red: data[i],
      green: data[i + 1],
      blue: data[i + 2],
      alpha: data[i + 3] / 255,
    });
  }
  return pixels;
}

export function createContours(
  imageData: ImageData,
  width: number,
  height: number
): Contour[] {
  const pixels = imageDataAsRgbaObject(imageData);
  const monochromePixels = pixels.map((p) =>
    p.alpha === 0 ? -1 : (p.red + p.green + p.blue) / 3
  );

  const contours = d3Contours().size([width, height])(monochromePixels);

  return contours;
}

interface Contour {
  type: string;
  value: number;
  coordinates: number[][][][];
}

export function simplifyContour(
  contour: Contour,
  shouldSimplify: boolean,
  tolerance: number
): Contour {
  // simplify points
  if (shouldSimplify) {
    const xyPoints = contour.coordinates[0][0].map((coord) => ({
      x: coord[0],
      y: coord[1],
    }));
    const simplifiedPoints = simplify(xyPoints, tolerance);
    contour = {
      ...contour,
      coordinates: [[simplifiedPoints.map((point) => [point.x, point.y])]],
    };
  }
  return contour;
}

export const calculateOutline = (
  imageData: ImageData,
  contourNumber: number = 1,
  dimensions: {
    width: number;
    height: number;
  }
): number[][] => {
  const contours = createContours(
    imageData,
    dimensions.width,
    dimensions.height
  );
  console.log(contours);
  const coordinates = contours[contourNumber].coordinates[0][0];
  const points = coordinates.map((coord) => ({ x: coord[0], y: coord[1] }));
  return simplify(points, 1).map(({ x, y }) => [x, y]);
};
