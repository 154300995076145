import * as React from "react";
import { Icon } from "@mui/material";

export const RampIcon = () => (
  <Icon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "currentColor",
          fillOpacity: 1,
        }}
        d="M 8 4 A 2 2 0 0 0 6.03125 5.6542969 L 6.0292969 5.65625 L 3.03125 23.642578 L 3.0332031 23.644531 A 2 2 0 0 0 3 24 A 2 2 0 0 0 5 26 L 14 26 L 14 21 L 16 21 L 16 26 L 25 26 A 2 2 0 0 0 27 24 A 2 2 0 0 0 26.966797 23.644531 L 26.96875 23.642578 L 23.970703 5.6601562 A 2 2 0 0 0 23.970703 5.65625 L 23.96875 5.65625 A 2 2 0 0 0 22 4 L 16 4 L 16 6 L 14 6 L 14 4 L 8 4 z M 14 8 L 16 8 L 16 11 L 14 11 L 14 8 z M 14 13 L 16 13 L 16 18 L 14 18 L 14 13 z"
      />
    </svg>
  </Icon>
);
