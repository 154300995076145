import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useHangarState } from "../../../containers/HangarStateContainer";
import { useSnackbar } from "../../../containers/SnackbarContainer";
import { GarageDoor, Hangar } from "../../../types";
import {
  ConfirmationDialog,
  Props as ConfirmationDialogProps,
} from "../../../widgets/ConfirmationDialog";
import { ObstacleCanvas } from "./ObstacleCanvas";

type Props = {
  hangar: Hangar;
  setHangar: (hangar: Hangar) => void;
  onClose: () => void;
};

export const HangarEditorPresenter: React.FC<Props> = ({
  hangar,
  setHangar,
  onClose,
}) => {
  const { activeFBO } = useActiveFBOs();
  const { hangar: dbHangar, setHangar: setHangarAgain } = useHangarState();
  const [localHangar, setLocalHangar] = React.useState<Hangar>();
  const [editingWidth, setEditingWidth] = React.useState<number>();
  const [editingDepth, setEditingDepth] = React.useState<number>();
  const [confirmSave, setConfirmSave] = React.useState<ConfirmationDialogProps>(
    null
  );
  const snackbar = useSnackbar();

  React.useEffect(() => {
    setLocalHangar(hangar);
    setEditingWidth(hangar.width);
    setEditingDepth(hangar.depth);
  }, [hangar]);

  const handleClose = () => {
    onClose();
  };

  const onClickSaveHangar = async () => {
    setConfirmSave({
      title: "Save Changes?",
      text: "Are you sure you want to save these changes?",
      onCancel: () => setConfirmSave(null),
      okText: "Save",
      onOk: () => {
        const localHangarWithAdjustedGarageDoors = {
          ...localHangar,
          garageDoors: localHangar.garageDoors.map((gd) => ({
            ...gd,
            x:
              // if it's a left wall, should always be 0
              gd.wall === "left"
                ? 0
                : gd.wall === "right"
                ? // if it's a right wall, should always be the width of the hangar
                  localHangar.width
                : gd.x,
            y:
              gd.wall === "back"
                ? // if it's the back wall, should always be the depth of the hangar
                  localHangar.depth
                : gd.y,
          })),
        };
        setHangar(localHangarWithAdjustedGarageDoors);
        setHangarAgain({
          ...localHangarWithAdjustedGarageDoors,
          stack: {
            ...dbHangar.stack,
          },
        });
        snackbar.notify({ text: "Hangar changes saved.", severity: "success" });
        setConfirmSave(null);
      },
      onClose: () => {
        setConfirmSave(null);
      },
    });
  };

  const setIGarageDoor = React.useCallback(
    (i: number, g: GarageDoor) => {
      const newGarageDoors = localHangar.garageDoors.map((garageDoor, idx) => {
        if (i === idx) {
          return g;
        }
        return garageDoor;
      });
      setLocalHangar({ ...localHangar, garageDoors: newGarageDoors });
    },
    [localHangar]
  );

  return (
    <>
      <Dialog
        maxWidth="xl"
        open={true}
        onClose={handleClose}
        sx={{ height: "95vh" }}
        PaperProps={{ sx: { width: "100%", height: "100%" } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">Hangar Building Wizard</Typography>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="row"
            alignItems="space-between"
            justifyContent="space-around"
            spacing={2}
          >
            <Stack direction="column" spacing={2} sx={{ minWidth: 350 }}>
              <Stack direction="column" spacing={2} sx={{ m: 1 }}>
                <Stack direction="row">
                  <TextField
                    fullWidth
                    size="small"
                    label="Hangar Name"
                    onChange={(evt) =>
                      setLocalHangar({ ...localHangar, name: evt.target.value })
                    }
                    value={localHangar?.name ?? ""}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    disabled={activeFBO.subscription === "standard"}
                    type="number"
                    fullWidth
                    InputProps={{ inputProps: { min: 50, max: 400 } }}
                    size="small"
                    label="Width (ft)"
                    onChange={(evt) =>
                      setEditingWidth(parseFloat(evt.target.value))
                    }
                    onBlur={(evt) =>
                      setLocalHangar({
                        ...localHangar,
                        width: Math.min(400, parseFloat(evt.target.value)),
                      })
                    }
                    value={editingWidth ?? ""}
                  />
                  <TextField
                    disabled={activeFBO.subscription === "standard"}
                    type="number"
                    fullWidth
                    InputProps={{ inputProps: { min: 50, max: 400 } }}
                    size="small"
                    label="Depth (ft)"
                    onChange={(evt) =>
                      setEditingDepth(parseFloat(evt.target.value))
                    }
                    onBlur={(evt) =>
                      setLocalHangar({
                        ...localHangar,
                        depth: Math.min(400, parseFloat(evt.target.value)),
                      })
                    }
                    value={editingDepth ?? ""}
                  />
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2">Door Pockets</Typography>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      disabled={activeFBO.subscription === "standard"}
                      type="number"
                      fullWidth
                      InputProps={{ inputProps: { min: 0, max: 200 } }}
                      size="small"
                      label="Left Door Length (ft)"
                      onChange={(evt) =>
                        setLocalHangar({
                          ...localHangar,
                          left_door: parseFloat(evt.target.value),
                        })
                      }
                      value={localHangar?.left_door ?? ""}
                    />
                    <TextField
                      disabled={activeFBO.subscription === "standard"}
                      type="number"
                      fullWidth
                      InputProps={{ inputProps: { min: 0, max: 200 } }}
                      size="small"
                      label="Right Door Length (ft)"
                      onChange={(evt) =>
                        setLocalHangar({
                          ...localHangar,
                          right_door: parseFloat(evt.target.value),
                        })
                      }
                      value={localHangar?.right_door ?? ""}
                    />
                  </Stack>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2">Door Dimensions</Typography>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      disabled={activeFBO.subscription === "standard"}
                      type="number"
                      fullWidth
                      InputProps={{ inputProps: { min: 1, max: 200 } }}
                      size="small"
                      label="Height (ft)"
                      onChange={(evt) =>
                        setLocalHangar({
                          ...localHangar,
                          height: parseFloat(evt.target.value),
                        })
                      }
                      value={localHangar?.height ?? ""}
                    />
                    <TextField
                      disabled={true}
                      type="number"
                      fullWidth
                      InputProps={{ inputProps: { min: 1, max: 200 } }}
                      size="small"
                      label="Width (ft)"
                      value={
                        localHangar?.width -
                          (localHangar?.left_door + localHangar?.right_door) ??
                        ""
                      }
                    />
                  </Stack>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body2">Garage Doors</Typography>
                    <Button
                      disabled={activeFBO.subscription === "standard"}
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setLocalHangar({
                          ...localHangar,
                          garageDoors: localHangar.garageDoors.concat([
                            {
                              id: uuidv4(),
                              fbo_id: activeFBO.id,
                              hangar_id: localHangar.id,
                              wall: "left",
                              x: 0,
                              y: 0,
                              width: 10,
                            },
                          ]),
                        });
                      }}
                    >
                      Add
                    </Button>
                  </Stack>
                  {localHangar?.garageDoors?.map((garageDoor, idx) => (
                    <Stack
                      key={`garage-door-${idx}`}
                      direction="row"
                      spacing={2}
                    >
                      <TextField
                        disabled={activeFBO.subscription === "standard"}
                        select
                        size="small"
                        label="Wall"
                        fullWidth
                        onChange={(evt) => {
                          const wall = evt.target.value;
                          const x =
                            wall === "back"
                              ? garageDoor.x
                              : wall === "right"
                              ? localHangar?.width
                              : 0;
                          const y =
                            wall !== "back" ? garageDoor.y : localHangar?.depth;
                          setIGarageDoor(idx, {
                            ...garageDoor,
                            wall,
                            x,
                            y,
                          });
                        }}
                        value={garageDoor.wall}
                      >
                        <MenuItem value="left">Left</MenuItem>
                        <MenuItem value="back">Back</MenuItem>
                        <MenuItem value="right">Right</MenuItem>
                      </TextField>

                      <TextField
                        disabled={activeFBO.subscription === "standard"}
                        type="number"
                        fullWidth
                        inputProps={{
                          min: 0,
                          max:
                            garageDoor.wall === "back"
                              ? hangar.width
                              : hangar.depth,
                          step: 1,
                        }}
                        size="small"
                        onChange={(evt) => {
                          const width = parseFloat(evt.target.value);
                          setIGarageDoor(idx, {
                            ...garageDoor,
                            width,
                          });
                        }}
                        value={garageDoor.width}
                        label="Width"
                      />
                      <IconButton
                        disabled={activeFBO.subscription === "standard"}
                        color="error"
                        onClick={() =>
                          setLocalHangar({
                            ...localHangar,
                            garageDoors: localHangar.garageDoors.filter(
                              (x, i) => i !== idx
                            ),
                          })
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Stack>
                  ))}
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2">Wall Spacing</Typography>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      type="number"
                      fullWidth
                      inputProps={{ min: 0, max: 15, step: 0.5 }}
                      size="small"
                      label="Left Wall (ft)"
                      onChange={(evt) =>
                        setLocalHangar({
                          ...localHangar,
                          wall_spacing_left: parseFloat(evt.target.value),
                        })
                      }
                      value={localHangar?.wall_spacing_left ?? ""}
                    />
                    <TextField
                      type="number"
                      fullWidth
                      inputProps={{ min: 0, max: 15, step: 0.5 }}
                      size="small"
                      label="Back Wall (ft)"
                      onChange={(evt) =>
                        setLocalHangar({
                          ...localHangar,
                          wall_spacing_back: parseFloat(evt.target.value),
                        })
                      }
                      value={localHangar?.wall_spacing_back ?? ""}
                    />
                    <TextField
                      type="number"
                      fullWidth
                      inputProps={{ min: 0, max: 15, step: 0.5 }}
                      size="small"
                      label="Right Wall (ft)"
                      onChange={(evt) =>
                        setLocalHangar({
                          ...localHangar,
                          wall_spacing_right: parseFloat(evt.target.value),
                        })
                      }
                      value={localHangar?.wall_spacing_right ?? ""}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Button
                  fullWidth
                  variant="contained"
                  color="inherit"
                  onClick={handleClose}
                >
                  Cancel Setup
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  onClick={onClickSaveHangar}
                >
                  Save Hangar
                </Button>
              </Stack>
            </Stack>
            <ObstacleCanvas hangar={localHangar} setHangar={setLocalHangar} />
          </Stack>
        </DialogContent>
      </Dialog>
      {Boolean(confirmSave) && <ConfirmationDialog {...confirmSave} />}
    </>
  );
};
