import * as React from "react";
import { useApi } from "../containers/ApiContainer";
import { useHangarsState } from "../containers/HangarsStateContainer";
import { useRampsState } from "../containers/RampsStateContainer";
import { Hangar, Location, Ramp, Tenant } from "../types";
import { uuidv4 } from "../utils";

export const useLocations = () => {
  const { postgrest } = useApi();
  const { hangars } = useHangarsState();
  const { ramps } = useRampsState();
  const [locationOverides, setLocationOverides] = React.useState<{
    [key: string]: Location<Ramp | Hangar>;
  }>({});

  const locations: Location<Ramp | Hangar>[] = [
    ...hangars.map((h) => ({
      ...h,
      type: "hangar",
    })),
    ...ramps.map((r) => ({ ...r, type: "ramp" })),
  ];

  const findTenantLocation = React.useCallback(
    (isReference: boolean, tenant: Tenant) => {
      const stackName = isReference ? "reference" : "current";
      const key = `${tenant.id}-${stackName}`;
      // check if the key exists in locationOverrides
      if (key in locationOverides) {
        return locationOverides[key];
      }
      for (const location of locations) {
        const activeStack = location.stacks.find(
          (stack) => stack?.name === stackName
        );
        if (!activeStack) {
          continue;
        }
        const foundTenant = activeStack.tenants.find((t) => t.id === tenant.id);
        if (foundTenant) {
          return location;
        }
      }
    },
    [locations, locationOverides]
  );

  const findTenantLocations = React.useCallback(
    (tenant: Tenant) => {
      return {
        current: findTenantLocation(false, tenant),
        assigned: findTenantLocation(true, tenant),
      };
    },
    [findTenantLocation]
  );

  const sendToLocation = React.useCallback(
    async (
      newLocation: Location<Ramp | Hangar>,
      stackName: string,
      tenant: Tenant
    ) => {
      if (!newLocation) {
        // we've removed the tenant from a given hangar. we need to delete all
        // references to the type of hangar that this one is in.

        await postgrest.rpc("remove_stack_positions_for_entity", {
          entity_id: tenant.entity_id,
          stack_name: stackName,
          position_id_to_keep: null,
        });
        setLocationOverides((locationOverides) => ({
          ...locationOverides,
          [`${tenant.id}-${stackName}`]: null,
        }));

        return;
      }
      const newStack = newLocation.stacks.find((s) => s.name === stackName);
      await postgrest.from("position").upsert({
        id: uuidv4(),
        stack_id: newStack.id,
        entity_id: tenant.entity_id,
        x: null,
        y: null,
        angle: null,
        preferences: tenant.position.preferences,
      });
      setLocationOverides((locationOverides) => ({
        ...locationOverides,
        [`${tenant.id}-${stackName}`]: newLocation,
      }));
    },
    [postgrest, locationOverides, locations]
  );

  return {
    locations,
    locationOverides,
    findTenantLocation,
    findTenantLocations,
    sendToLocation,
  };
};
