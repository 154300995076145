import * as React from "react";
import { FlaggedStacksPresenter } from "./FlaggedStacksPresenter";
import { HangarsStateContainer } from "../../containers/HangarsStateContainer";
import { Stack } from "../../types";
import { useApi } from "../../containers/ApiContainer";

type Props = {};

export type FlaggedStack = {
  id: string;
  fbo_id: string;
  hangar_id: string;
  stack: Stack;
  alternate_stacks: Stack[];
  created_at: string;
};
export const FlaggedStacks: React.FC<Props> = ({ ...props }) => {
  const { postgrest } = useApi();
  const [flaggedStacks, setFlaggedStacks] = React.useState<FlaggedStack[]>([]);

  React.useEffect(() => {
    (async () => {
      const { data } = await postgrest
        .from("flagged_stack")
        .select("*,hangar(*)")
        .order("created_at", { ascending: false });
      setFlaggedStacks(data);
    })();
  }, []);

  return (
    <HangarsStateContainer isReference={false}>
      <FlaggedStacksPresenter flaggedStacks={flaggedStacks} />
    </HangarsStateContainer>
  );
};
