import * as React from "react";
import logo from "../../../airplx-logo-transparent-square-no-text.png";
import { Box, Stack, Typography } from "@mui/material";
import { useIdentity } from "../../containers/IdentityContainer";

export const NoActiveFBO: React.FC<{}> = () => {
  const { airplxIdentity } = useIdentity();
  return (
    <Box
      alignItems="center"
      sx={{
        maxWidth: 600,
        margin: "auto",
        border: "8px solid black",
        borderRadius: 3,
        p: 3,
        mt: "10%",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignContent="center"
        >
          <img src={logo} style={{ width: 50 }} />
          <Typography align="center" variant="h4">
            AirPlx
          </Typography>
        </Stack>
        <Typography variant="subtitle2">
          You are currently logged in, however you do not belong to any FBOs.
          Check your email for your invite, the subject will be{" "}
          <u>
            <b>"Claim AirPlx Invite"</b>
          </u>
          .
          <br />
          <br />
          If you don't have an account, please contact your administrator to
          send you an invitation. Provide them with the following email address:
          <br />
          <br />
          <b>{airplxIdentity?.email}</b>
          <br />
          <br />
          If you already have an account and you think this is an error, please
          contact{" "}
          <u>
            <b>support@airplx.com</b>
          </u>
          .
        </Typography>
      </Stack>
    </Box>
  );
};
