import { Box, Divider, Stack, Typography } from "@mui/material";
import * as numeral from "numeral";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { getMaxDimension } from "./HangarViewWrapper";
import { LayoutWrapper } from "./LayoutWrapper";
import { calculateMultiHangarStats } from "./MultiHangarInfoCard";

type Props = {
  idsToPrint: string[];
};

export const MultiHangarPrintLayout: React.FC<Props> = ({ idsToPrint }) => {
  const { activeFBO } = useActiveFBOs();
  const { hangarIds, hangars: allHangars } = useMultiHangarState();
  const hangars = allHangars
    .filter((h) => hangarIds.indexOf(h.id) > -1)
    .filter((h) => idsToPrint.indexOf(h.id) > -1);

  const {
    nAircraftInHangar,
    nAircraft,
    totalSqftAvailable,
    totalSqftUsed,
    totalSqftDesired,
    totalUtilization,
    totalUtilizationDesired,
    estValue,
    towTime,
  } = calculateMultiHangarStats(activeFBO, hangars);

  const maxDim = getMaxDimension(hangars);

  return (
    <Box>
      <Stack direction="column" justifyContent="space-between">
        <style type="text/css" media="print">
          {`@page { size: landscape; }`}
        </style>
        <Box
          data-id="multi-hangar-layout"
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Stack direction="column">
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Typography>{hangars.length} Hangars</Typography>
                <Divider orientation="vertical" sx={{ height: 20 }} />
                <Typography>
                  {numeral(totalSqftAvailable).format("0,0")}
                  {" ft²"}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              sx={{ width: 500, height: 50 }}
            >
              <Stack direction="column">
                <Typography variant="subtitle1">Aircraft</Typography>
                <Typography
                  variant="caption"
                  textAlign="center"
                  color="info.dark"
                >
                  {nAircraftInHangar}
                </Typography>
              </Stack>
              <Divider orientation="vertical" />
              <Stack direction="column">
                <Typography variant="subtitle1">Utilization</Typography>
                <Typography
                  variant="caption"
                  textAlign="center"
                  color="info.dark"
                >
                  {totalUtilization}%
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          {hangars.map((hangar, idx) => {
            return (
              <React.Fragment key={`print-multihangar-${hangar.id}`}>
                <LayoutWrapper
                  width={(350 * hangar.width) / maxDim} // this causes issues w/ large hangers
                  print={true}
                  customPlacementOptions={null}
                  hangars={hangars}
                  hangarId={hangar.id}
                />
              </React.Fragment>
            );
          })}
        </Box>
      </Stack>
    </Box>
  );
};
