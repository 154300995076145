import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useBenchmarkRates } from "../../../hooks/useBenchmarkRates";
import { RevenueEstimatorPresenter } from "./RevenueEstimatorPresenter";

export const RevenueEstimator: React.FC<{}> = () => {
  const { activeFBO } = useActiveFBOs();
  const { benchmarkRates, setBenchmarkRates } = useBenchmarkRates();

  return <RevenueEstimatorPresenter />;
};
