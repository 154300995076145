import * as React from "react";

// eslint-disable-next-line @typescript-eslint/ban-types
type Empty = {};

interface ContainerProviderProps<State = void> {
  initialState?: State;
  children: React.ReactNode;
}

export interface Container<Value, State = void> {
  Provider: React.ComponentType<ContainerProviderProps<State>>;
  useContainer: () => Value;
}

/**
 * Create a context container from a hook function that returns
 * an object that 'is' the state value.
 *
 * This can be seeded with initial state -- and is pretty clever
 * with type inference for your passed in hook function.
 */
export const createContainer = <Value, State = void>(
  useHook: (initialState?: State) => Value
): Container<Value, State> => {
  // use react context for the actual state
  const Context = React.createContext<Value | Empty>({});

  const Provider = (props: ContainerProviderProps<State>) => {
    const value = useHook(props.initialState) || {};
    return <Context.Provider value={value}>{props.children}</Context.Provider>;
  };

  /**
   * Use the container in a component down the react tree.
   *
   * This can come back with an empty context if no provider
   * is above to avoid errors from destructuring undefined -- instead
   * flow through with a un-contextualized invocation of the state hook.
   *
   * This should 'forgive' a bit of not having a container and in effect
   * devolve to hook use inside the calling component, which will work just
   * like useState -- it's just that lower components on the tree won't be able
   * to see!
   */
  const useContainer = (): Value => {
    const value = React.useContext(Context) || useHook() || {};
    return (value || {}) as unknown as Value;
  };

  return { Provider, useContainer };
};
