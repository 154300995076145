import { Alert } from "@mui/material";
import * as React from "react";
import { useParams } from "react-router-dom";
import { HangarStateContainer } from "../../containers/HangarStateContainer";
import { HangarsStateContainer } from "../../containers/HangarsStateContainer";
import { PredefinedObstaclesContainer } from "../../containers/PredefinedObstaclesContainer";
import { RampsStateContainer } from "../../containers/RampsStateContainer";
import { TowingEquipmentContainer } from "../../containers/TowingEquipmentContainer";
import { ParamSet } from "../../types";
import { HangarPresenter } from "./HangarPresenter";
import { StackMember } from "./PlaceIncrementally";

export type HangarStack = {
  paramSet: ParamSet;
  stackMembers: StackMember[];
};

type Props = {
  hangarId?: string;
  isReference?: boolean;
};

export const Hangar: React.FC<Props> = ({
  hangarId,
  isReference,
  ...props
}) => {
  const { id: hangarIdFromRoute } = useParams();
  const [stacks, setStacks] = React.useState<HangarStack[]>([]);

  return (
    // RampsStateContainer and HangarsStateContainer might seem weird. it's for the selection menus
    <RampsStateContainer isReference={isReference ?? false}>
      <HangarsStateContainer isReference={isReference ?? false}>
        <HangarStateContainer
          initialState={{
            hangar_id: hangarId ?? hangarIdFromRoute,
            isReference: isReference ?? false,
          }}
        >
          <PredefinedObstaclesContainer>
            <TowingEquipmentContainer>
              {isReference && (
                <Alert severity="warning">
                  This is your Base Tenant Reference Hangar. It only shows your
                  base tenants and is distinct from your active Current Hangar.
                </Alert>
              )}

              <HangarPresenter
                id={hangarIdFromRoute}
                stacks={stacks}
                setStacks={setStacks}
                isReference={isReference}
              />
            </TowingEquipmentContainer>
          </PredefinedObstaclesContainer>
        </HangarStateContainer>
      </HangarsStateContainer>
    </RampsStateContainer>
  );
};
