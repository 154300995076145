import { mean } from "lodash";
import { Point } from "./RampCanvas";

// Helper function to check if a point is within a shape or on its edges
export const isPointInShapeOrOnEdge = (
  point: Point,
  shapePoints: Point[]
): boolean => {
  // Handle the case where the shape is a single point
  if (shapePoints.length === 1) {
    return (
      Math.hypot(point.x - shapePoints[0].x, point.y - shapePoints[0].y) < 10
    );
  }

  // Check if the point is inside the shape using ray-casting algorithm
  let isInside = false;
  for (let i = 0, j = shapePoints.length - 1; i < shapePoints.length; j = i++) {
    const xi = shapePoints[i].x,
      yi = shapePoints[i].y;
    const xj = shapePoints[j].x,
      yj = shapePoints[j].y;

    const intersect =
      yi > point.y !== yj > point.y &&
      point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;
    if (intersect) {
      isInside = !isInside;
    }
  }

  // Check if the point is on any of the edges of the shape
  for (let i = 0, j = shapePoints.length - 1; i < shapePoints.length; j = i++) {
    if (pointToSegmentDistance(point, shapePoints[i], shapePoints[j]) < 10) {
      return true;
    }
  }

  return isInside;
};

export const pointToSegmentDistance = (
  point: Point,
  start: Point,
  end: Point
): number => {
  const dx = end.x - start.x;
  const dy = end.y - start.y;
  const lengthSquared = dx * dx + dy * dy;
  let t = ((point.x - start.x) * dx + (point.y - start.y) * dy) / lengthSquared;
  t = Math.max(0, Math.min(1, t));
  const nearestX = start.x + t * dx;
  const nearestY = start.y + t * dy;
  return Math.hypot(point.x - nearestX, point.y - nearestY);
};

export const smoothLine = (points: Point[], smoothingFactor = 0.2): Point[] => {
  if (points.length < 3) return points; // No smoothing needed if less than 3 points

  const smoothedPoints: Point[] = [points[0]]; // Start with the first point

  for (let i = 1; i < points.length - 1; i++) {
    const prev = points[i - 1];
    const curr = points[i];
    const next = points[i + 1];

    const smoothedPoint: Point = {
      x: curr.x + smoothingFactor * (prev.x + next.x - 2 * curr.x),
      y: curr.y + smoothingFactor * (prev.y + next.y - 2 * curr.y),
    };

    smoothedPoints.push(smoothedPoint);
  }

  smoothedPoints.push(points[points.length - 1]); // End with the last point
  return smoothedPoints;
};

export const snapTo45Degrees = (
  lastPoint: Point,
  currentPoint: Point
): Point => {
  // Calculate the difference in x and y
  const dx = currentPoint.x - lastPoint.x;
  const dy = currentPoint.y - lastPoint.y;

  // Calculate the angle in degrees
  const angle = Math.atan2(dy, dx) * (180 / Math.PI);

  // Snap angle to nearest 45 degrees
  const snappedAngle = Math.round(angle / 45) * 45;

  // Convert snapped angle back to radians
  const radians = (snappedAngle * Math.PI) / 180;

  // Calculate new dx and dy using the snapped angle and original distance
  const distance = Math.sqrt(dx * dx + dy * dy);
  const snappedDx = Math.cos(radians) * distance;
  const snappedDy = Math.sin(radians) * distance;

  // Return the new snapped point
  return {
    x: lastPoint.x + snappedDx,
    y: lastPoint.y + snappedDy,
  };
};

export const calculateArea = (points: Point[], scale: number = 1): number => {
  const numPoints = points.length;
  const scaledPoints = points.map((point) => ({
    x: point.x * scale,
    y: point.y * scale,
  }));

  // A shape with less than 3 points has no area
  if (numPoints < 3) {
    return 0;
  }

  let area = 0;

  for (let i = 0; i < numPoints; i++) {
    const j = (i + 1) % numPoints; // Wrap around to the first point
    area +=
      scaledPoints[i].x * scaledPoints[j].y -
      scaledPoints[j].x * scaledPoints[i].y;
  }

  return Math.abs(area / 2);
};

// Helper function to rotate a point around a center point by an angle in degrees
export const rotatePoint = (
  point: Point,
  center: Point,
  angle: number
): Point => {
  const radians = (angle * Math.PI) / 180;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);

  const translatedX = point.x - center.x;
  const translatedY = point.y - center.y;

  return {
    x: translatedX * cos - translatedY * sin + center.x,
    y: translatedX * sin + translatedY * cos + center.y,
  };
};

export const rotatePoints = (points: Point[], angle: number): Point[] => {
  const centerX = mean(points.map((p) => p.x));
  const centerY = mean(points.map((p) => p.y));
  // Rotate each point in the shape by the incremental angle change
  return points.map((point) =>
    rotatePoint(point, { x: centerX, y: centerY }, angle)
  );
};

export const getCenter = (points: Point[]): Point => {
  const total = points.reduce(
    (acc, point) => ({
      x: acc.x + point.x,
      y: acc.y + point.y,
    }),
    { x: 0, y: 0 }
  );

  return {
    x: total.x / points.length,
    y: total.y / points.length,
  };
};

export const adjustSquare = (
  points: Point[],
  draggedIndex: number,
  draggedPoint: Point,
  center: Point
): Point[] => {
  const oppositeIndex = (draggedIndex + 2) % 4; // Opposite vertex in a square
  const newPoints = [...points];

  // Calculate the vector from the center to the dragged point
  const dx = draggedPoint.x - center.x;
  const dy = draggedPoint.y - center.y;

  // Adjust the dragged point
  newPoints[draggedIndex] = draggedPoint;

  // Adjust the opposite point
  newPoints[oppositeIndex] = {
    x: center.x - dx,
    y: center.y - dy,
  };

  // Adjust the adjacent points to maintain proportions
  const adjacent1 = (draggedIndex + 1) % 4;
  const adjacent2 = (draggedIndex + 3) % 4;

  newPoints[adjacent1] = {
    x: center.x - dy,
    y: center.y + dx,
  };

  newPoints[adjacent2] = {
    x: center.x + dy,
    y: center.y - dx,
  };

  return newPoints;
};
