import { orderBy, round, sum } from "lodash";
import * as md5 from "md5";
import { v4 } from "uuid";
import { HangarStack } from "../screens/Hangar";
import { StackMember } from "../screens/Hangar/PlaceIncrementally";
import { Aircraft } from "../screens/LabelingTool";
import { FBO, Hangar, Preference, Stack } from "../types";

export const convertAircraftPreferencesToAlgorithmPreferences = (
  preferences: Preference[] = []
): Preference[] => {
  return preferences.map((pref) => {
    if (pref === Preference.TAIL_WHEEL_NOSE_IN) {
      return Preference.TAIL_IN;
    }
    if (pref === Preference.TAIL_WHEEL_TAIL_IN) {
      return Preference.NOSE_IN;
    }
    return pref;
  });
};

export const md5HashToUUID = (md5Hash: string): string => {
  return (
    md5Hash.substring(0, 8) +
    "-" +
    md5Hash.substring(8, 12) +
    "-" +
    md5Hash.substring(12, 16) +
    "-" +
    md5Hash.substring(16, 20) +
    "-" +
    md5Hash.substring(20)
  ).toLowerCase();
};

export const defaultPreferenceForAircraft = (
  aircraft: Aircraft,
  fbo: FBO
): Preference[] => {
  if (!aircraft) {
    return [Preference.TAIL_IN];
  }
  if (aircraft.tail_wheel === true) {
    return [Preference.TAIL_WHEEL_NOSE_IN];
  }
  if (aircraft.tail_bottom > fbo.sop_orientation_nose_in_threshold) {
    return [Preference.NOSE_IN];
  }
  if (aircraft.tail_top < fbo.sop_orientation_tail_in_threshold) {
    return [Preference.TAIL_IN];
  }

  return [];
};

export const uuidv4 = (): string => v4();
export const consistentId = (...args: any[]): string => {
  return md5HashToUUID(md5(args.map((arg) => arg.toString()).join("")));
};

export const calcUtilization = (
  hangar: Hangar,
  limitToPlaced: boolean = false
): number => {
  const total = sum(
    hangar.stack.tenants
      .filter((t) => (limitToPlaced ? t.position.x != null : true))
      .map(({ aircraft }) => aircraft.wingspan * aircraft.length)
  );
  const area = hangar.depth * hangar.width;
  return round((100 * total) / area, 0);
};

export const calcStackUtilization = (hangar: Hangar, stack: Stack): number => {
  const total = sum(
    stack.tenants
      .filter((t) => t.position.x != null)
      .map(({ aircraft }) => aircraft.wingspan * aircraft.length)
  );
  const area = hangar.depth * hangar.width;
  return round((100 * total) / area, 0);
};

export const sumUtilization = (aircrafts: Aircraft[]): number => {
  const total = sum(
    aircrafts
      .filter((a) => a.id)
      .map((aircraft) => aircraft.wingspan * aircraft.length)
  );
  return total;
};

export const calcTotalError = (placements: StackMember[]) => {
  return sum(placements.map(({ error }) => error));
};

export const sortByScore = (a: HangarStack, b: HangarStack) => {
  if (!b.stackMembers) {
    return -1;
  }
  if (!a.stackMembers) {
    return 1;
  }
  const utilizationDiff =
    sum(b.stackMembers.map(({ area }) => area)) -
    sum(a.stackMembers.map(({ area }) => area));

  // if b utilization > autilization
  if (utilizationDiff > 0) {
    return 1;
  }
  if (utilizationDiff < 0) {
    return -1;
  }
  return Math.sign(
    calcTotalError(a.stackMembers) - calcTotalError(b.stackMembers)
  );
};

export const sortByLowUtilizationScore = (a: HangarStack, b: HangarStack) => {
  if (!b.stackMembers) {
    return 1;
  }
  if (!a.stackMembers) {
    return -1;
  }
  const utilizationDiff =
    sum(b.stackMembers.map(({ area }) => area)) -
    sum(a.stackMembers.map(({ area }) => area));
  if (utilizationDiff !== 0) {
    return utilizationDiff;
  }

  const spacingDiff = Math.sign(
    b.paramSet.options.horizontal_spacing -
      a.paramSet.options.horizontal_spacing
  );
  if (spacingDiff !== 0) {
    return spacingDiff;
  }
  return calcTotalError(a.stackMembers) - calcTotalError(b.stackMembers);
};

export type ScoredHangarStack = {
  stack: HangarStack;
  score: number;
};

export const sortLayoutOptions = (
  hangar: Hangar,
  layoutOptions: HangarStack[],
  overlap: boolean
): ScoredHangarStack[] => {
  if (!layoutOptions.length) {
    return [];
  }
  const layoutOptionsWithScore: ScoredHangarStack[] = layoutOptions.map(
    (stack) => ({
      stack,
      score: sum(
        stack.stackMembers
          .filter((m) => Boolean(m.x) && Boolean(m.area))
          .map(({ area }) => area)
      ),
    })
  );
  const layoutOptionsByScore: ScoredHangarStack[] = orderBy(
    layoutOptionsWithScore,
    ["score"],
    ["desc"]
  );
  return layoutOptionsByScore;
};

export const isDev = (): boolean => {
  return window.location.origin.indexOf("localhost") > -1;
};

export const isStaging = (): boolean => {
  return window.location.origin.indexOf("staging.airplx.com") > -1;
};

export const isProd = (): boolean => {
  return window.location.origin.indexOf("app.airplx.com") > -1;
};

const ENVS = {
  production: {
    tokenmaker: "https://tm.airplx.com",
    postgrest: "https://dbrst.airplx.com",
  },
  staging: {
    tokenmaker: "https://ts.airplx.com",
    postgrest: "https://apis.airplx.com",
  },
  dev: {
    tokenmaker: "http://localhost:9009",
    postgrest: "http://localhost:3333",
  },
};

export const getTokenMakerUrl = (): string => {
  if (isDev()) {
    return ENVS.dev.tokenmaker;
  }
  if (isStaging()) {
    return ENVS.staging.tokenmaker;
  }
  return ENVS.production.tokenmaker;
};

export const getPostgrestUrl = (): string => {
  if (isDev()) {
    return ENVS.dev.postgrest;
  }
  if (isStaging()) {
    return ENVS.staging.postgrest;
  }
  return ENVS.production.postgrest;
};

export const envName = (): string => {
  if (isDev()) {
    return "dev";
  }
  if (isStaging()) {
    return "staging";
  }
  if (isProd()) {
    return "production";
  }
  return "prod";
};
