import { Stack } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useApi } from "../../../containers/ApiContainer";
import { useIdentity } from "../../../containers/IdentityContainer";
import { useInvitationsState } from "../../../containers/InvitationsStateContainer";
import { useSnackbar } from "../../../containers/SnackbarContainer";
import { FBOEmployee } from "../../../types";
import { InvitationsPresenter } from "./InvitationsPresenter";
import { UsersPresenter } from "./UsersPresenter";

type Props = {
  users: FBOEmployee[];
  refreshUsers: () => void;
  setFilter: (text: string) => void;
};

export const UserManagementPresenter: React.FC<Props> = ({
  users,
  refreshUsers,
  setFilter,
}) => {
  const { invitations, setInvitations } = useInvitationsState();
  const { postgrest } = useApi();
  const { activeFBO, fbos } = useActiveFBOs();
  const { airplxIdentity } = useIdentity();
  const snackbar = useSnackbar();

  return (
    <Stack direction="row" spacing={2}>
      <UsersPresenter
        users={users}
        setFilter={setFilter}
        onChangeRole={(user_id, value) => {
          if (user_id === airplxIdentity.userid) {
            snackbar.notify({
              text: "You can't change your own role.",
              severity: "error",
            });
            return;
          }
          postgrest
            .rpc("change_role", {
              fbo_id: activeFBO.id,
              userid: user_id,
              new_role: value,
            })
            .then(({ data }) => {
              console.info("ch-ch-changed!!!");
              refreshUsers();
            });
        }}
        onDeleteUser={async (user_id) => {
          if (user_id === airplxIdentity.userid) {
            snackbar.notify({
              text: "You can't delete yourself.",
              severity: "error",
            });
            return;
          }
          await postgrest
            .from("fbo_employees")
            .delete()
            .eq("fbo_id", activeFBO.id)
            .eq("user_id", user_id);
          refreshUsers();
        }}
      />
      <InvitationsPresenter
        invitations={invitations}
        setInvitations={setInvitations}
      />
    </Stack>
  );
};
