import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { sum } from "lodash";
import * as numeral from "numeral";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import { getStackPolygons, removeNonNumericAndParse } from "../../hooks/utils";
import { FBO, Hangar, Tenant } from "../../types";
import { SexyText } from "../../widgets/SexyText/SexyText";
import {
  calculateTotalArea,
  calculateUtilization,
  findTenantsFullyInHangar,
} from "../Hangar/HangarInfoCard";
import {
  calcEstimateTowTime,
  estimateValue,
} from "../Hangars/HangarsPresenter";
import { HOLDING_AREA_ID } from "./HoldingArea";

export const calculateMultiHangarStats = (
  activeFBO: FBO,
  allHangars: Hangar[]
) => {
  const hangars = allHangars.filter((h) => h.id !== HOLDING_AREA_ID);
  const polygons = hangars.map((hangar) =>
    getStackPolygons(hangar.stack, hangar.width, 1, true)
  );
  const nAircraftInHangar = sum(
    hangars.map((hangar, idx) => {
      const entityPolygons = polygons[idx];
      return findTenantsFullyInHangar(hangar, entityPolygons).length;
    })
  );

  const nAircraft = sum(hangars.map((hangar) => hangar.stack.tenants.length));
  const totalSqftAvailable = sum(
    hangars.map((hangar) =>
      calculateTotalArea(
        hangar,
        activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false
      )
    )
  );
  const totalSqftUsed = sum(
    hangars.map((hangar, idx) => {
      const entityPolygons = polygons[idx];
      return calculateUtilization(
        hangar,
        entityPolygons,
        true,
        activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false,
        true
      );
    })
  );
  const totalSqftDesired = sum(
    hangars.map((hangar, idx) => {
      const entityPolygons = polygons[idx];
      return calculateUtilization(
        hangar,
        entityPolygons,
        null,
        activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false,
        true
      );
    })
  );
  const totalUtilization = Math.round(
    (totalSqftUsed / totalSqftAvailable) * 100
  );

  const totalUtilizationDesired = Math.round(
    (totalSqftDesired / totalSqftAvailable) * 100
  );
  const estValue = sum(
    hangars.map((hangar, idx) => {
      const entityPolygons = polygons[idx];
      return removeNonNumericAndParse(
        estimateValue(
          activeFBO,
          hangar,
          entityPolygons,
          activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false
        )
      );
    })
  );
  const towTime = sum(
    hangars.map((hangar, idx) => {
      const entityPolygons = polygons[idx];
      return removeNonNumericAndParse(
        calcEstimateTowTime(hangar, entityPolygons)
      );
    })
  );

  return {
    nAircraftInHangar,
    nAircraft,
    totalSqftAvailable,
    totalSqftUsed,
    totalSqftDesired,
    totalUtilization,
    totalUtilizationDesired,
    estValue,
    towTime,
  };
};

type Props = {};

export const MultiHangarInfoCard: React.FC<Props> = () => {
  const { activeFBO } = useActiveFBOs();
  const displayEstimatedValue =
    activeFBO.user_role === "manager"
      ? activeFBO?.settings?.displayEstimatedValueForManager ?? true
      : activeFBO?.settings?.displayEstimatedValueForOperator ?? true;
  const navigate = useNavigate();
  const onClickBack = () => navigate("/hangars");
  const { hangars, setHangars, setActiveLocationId } = useMultiHangarState();

  const {
    nAircraftInHangar,
    nAircraft,
    totalSqftAvailable,
    totalSqftUsed,
    totalSqftDesired,
    totalUtilization,
    totalUtilizationDesired,
    estValue,
    towTime,
  } = useDebouncedValue(
    () => calculateMultiHangarStats(activeFBO, hangars),
    100,
    [[hangars.map((h) => h.stack)]]
  );

  const allTenants = React.useMemo(
    () => hangars.flatMap((hangar) => hangar.stack.tenants),
    [hangars.map((h) => h.stack.tenants)]
  );
  const allTenantsInHangar = React.useMemo(
    () =>
      hangars.flatMap((hangar) => {
        const entityPolygons = getStackPolygons(
          hangar.stack,
          hangar.width,
          1,
          true
        );
        return findTenantsFullyInHangar(hangar, entityPolygons);
      }),
    [hangars.map((h) => h.stack)]
  );

  return (
    <Box
      sx={{
        p: 1,
        borderBottomColor: "info.main",
        borderBottomWidth: 2,
        borderBottomStyle: "solid",
        borderLeft: "1px solid lightgrey",
        borderRight: "1px solid lightgrey",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
          {Boolean(onClickBack) && (
            <IconButton onClick={onClickBack}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography>
            {hangars.filter((h) => h.id !== HOLDING_AREA_ID).length} Hangars
          </Typography>
        </Stack>
      </Stack>
      {/* start stats */}
      <Stack direction="column" spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="body2" textAlign="center">
                Aircraft
              </Typography>
              <Tooltip title="# aircraft inside all hangars / # aircraft wanted inside all hangars">
                <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
              </Tooltip>
            </Stack>
            <Typography variant="caption" textAlign="center" color="info.dark">
              <span style={{ color: "inherit" }}>
                <SexyText value={nAircraftInHangar} text={nAircraftInHangar} />{" "}
              </span>
              / {nAircraft}
            </Typography>
          </Stack>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Stack direction="column" sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="body2" textAlign="center">
                Utilization
              </Typography>
              <Tooltip title="Utilization of aircraft inside hangars / Utilization of aircraft wanted inside">
                <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
              </Tooltip>
            </Stack>
            <Typography variant="caption" textAlign="center" color="info.dark">
              <span style={{ color: "inherit" }}>
                <SexyText
                  value={totalUtilization}
                  text={`${totalUtilization}%`}
                />{" "}
              </span>
              / {totalUtilizationDesired}%
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="body2" textAlign="center">
                Total Sqft
              </Typography>
              <Tooltip title="Square footage of all hangars">
                <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
              </Tooltip>
            </Stack>
            <Typography variant="caption" textAlign="center" color="info.dark">
              <SexyText
                value={totalSqftAvailable}
                text={numeral(totalSqftAvailable).format("0,0")}
              />
              {" ft²"}
            </Typography>
          </Stack>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Stack direction="column" sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="body2" textAlign="center">
                Total Utilized
              </Typography>
              <Tooltip title="Utilized square footage across all hangars">
                <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
              </Tooltip>
            </Stack>
            <Typography variant="caption" textAlign="center" color="info.dark">
              <SexyText
                value={totalSqftUsed}
                text={`${numeral(totalSqftUsed).format("0,0")} ft²`}
              />
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          {displayEstimatedValue && (
            <>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body2" textAlign="center">
                    Est. Value
                  </Typography>
                  <Tooltip title="Estimated value of all hangars. This includes all base tenants and any transients you're able to fit in the hangars.">
                    <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
                  </Tooltip>
                </Stack>
                <Typography
                  variant="caption"
                  textAlign="center"
                  color="info.dark"
                >
                  <SexyText value={estValue} text={`$${estValue} / day`} />
                </Typography>
              </Stack>

              <Divider orientation="vertical" variant="middle" flexItem />
            </>
          )}

          <Stack direction="column" sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="body2" textAlign="center">
                Est. Tow Time
              </Typography>
              <Tooltip title="Estimated time it would take to tow every aircraft out of the hangars.">
                <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
              </Tooltip>
            </Stack>
            <Typography variant="caption" textAlign="center" color="info.dark">
              {`${towTime} min`}
            </Typography>
          </Stack>
        </Stack>
        <Autocomplete
          disablePortal
          size="small"
          onChange={(event: any, newValue: Tenant | null) => {
            if (!newValue || !newValue?.id) {
              return;
            }
            const selectedTenantHangar = hangars.find((hangar) =>
              hangar.stack.tenants.find((tenant) => tenant.id === newValue.id)
            );
            setActiveLocationId(selectedTenantHangar.id);
            setHangars(
              hangars.map((hangar) => ({
                ...hangar,
                stack: {
                  ...hangar.stack,
                  tenants: hangar.stack.tenants.map((tenant) => {
                    if (tenant.id === newValue.id) {
                      return {
                        ...tenant,
                        selected: true,
                      };
                    }
                    return {
                      ...tenant,
                      selected: false,
                    };
                  }),
                },
              }))
            );
          }}
          options={allTenants.sort((a, b) => {
            const cmpTail = a.tail_number.localeCompare(b.tail_number);
            if (cmpTail !== 0) {
              return cmpTail;
            }
            return a.aircraft.model.localeCompare(b.aircraft.model);
          })}
          sx={{ width: "100%" }}
          getOptionLabel={(option: Tenant) => {
            return option.tail_number ?? "";
          }}
          renderOption={(props, option, state) => {
            const isInHangar = allTenantsInHangar.find(
              (tenant) => tenant.id === option.id
            );
            let text = "";
            if (!option.tail_number) {
              text = `${option.aircraft.model}`;
            } else {
              text = `${option.tail_number} - ${option.aircraft.model}`;
            }

            return (
              <li
                {...props}
                key={`search-hangars-${option.id}`}
                style={{
                  backgroundColor: isInHangar ? `#c8cfde40` : "transparent",
                }}
              >
                <Typography variant="inherit">{text}</Typography>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search for aircraft"
            />
          )}
        />
      </Stack>
    </Box>
  );
};
