import * as React from "react";
import { useHangars } from "../hooks/useHangars";
import { Hangar } from "../types";

const HangarsStateContainerState = React.createContext<{
  ready: boolean;
  hangars: Hangar[];
  setHangars: (hangars: Hangar[]) => void;
}>({});

export const HangarsStateContainer: React.FC<{
  children?: React.ReactNode;
  isReference: boolean;
}> = ({ children, isReference, ...props }) => {
  const { ready, hangars, setHangars } = useHangars(isReference);
  return (
    <HangarsStateContainerState.Provider
      value={{
        ready,
        hangars,
        setHangars,
      }}
    >
      {ready ? children : null}
    </HangarsStateContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useHangarsState = () =>
  React.useContext(HangarsStateContainerState);
