/**
 * @fileOverview This is a base class to inherit from when building low level
 * components. It follows a pattern of creating all the DOM elements inside
 * the `createDom` method and then when you're ready to attach the component
 * to the DOM you call `render`. It inherits from the EventTarget class
 * so that components can dispatch events.
 */

import DomHelper from "../dom/DomHelper";
import EventTarget from "../events/EventTarget";

/**
 * @constructor
 */
const Component = function() {
  EventTarget.call(this);
  this.dom_ = new DomHelper();
};
Component.prototype = new EventTarget();

Component.prototype.createDom = function() {};

Component.prototype.render = function(container) {
  if (!this.element_) {
    this.createDom();
  }

  container.appendChild(this.element_);
};

Component.prototype.getElement = function() {
  /*if (!this.element_) {
    this.createDom()
  }*/
  return this.element_;
};

Component.prototype.destroy = function() {
  if (this.element_) {
    if (this.element_.parentNode)
      this.element_.parentNode.removeChild(this.element_);
  }
  delete this.element_;
};

export default Component;
