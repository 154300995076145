import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useHangarsState } from "../../../containers/HangarsStateContainer";
import { Hangar as HangarType } from "../../../types";
import { Hangar } from "../../Hangar";
import { HangarEditor } from "./HangarEditor";
import { HangarsGridPresenter } from "./HangarsGridPresenter";

type Props = {
  openDialog: boolean;
  setOpenDialog: (x: boolean) => void;
  openReferenceHangar: boolean;
  setOpenReferenceHangar: (x: boolean) => void;
};

export const HangarsSettings: React.FC<Props> = ({
  openDialog,
  setOpenDialog,
  openReferenceHangar,
  setOpenReferenceHangar,
  ...props
}) => {
  const { hangars, setHangars } = useHangarsState();
  const [selectedHangar, setSelectedHangar] = React.useState<HangarType>();

  React.useEffect(() => {
    if (selectedHangar) {
      setSelectedHangar(hangars.find((h) => h.id === selectedHangar.id));
    }
  }, [hangars]);

  return (
    <Box sx={{ height: "100%" }}>
      <HangarsGridPresenter
        hangars={hangars}
        onSelectHangar={(hangar) => {
          setSelectedHangar(hangar);
          setOpenDialog(true);
        }}
        onSelectModifyBaseTenants={(hangar: HangarType) => {
          setSelectedHangar(hangar);
          window.location.href = `/#/reference-hangars/${hangar.id}`;
        }}
      />
      {openDialog && (
        <HangarEditor
          hangar={selectedHangar}
          setHangar={(hangar) =>
            setHangars(hangars.map((h) => (h.id === hangar.id ? hangar : h)))
          }
          onClose={() => {
            setSelectedHangar(null);
            setOpenDialog(false);
          }}
        />
      )}
      <Dialog open={openReferenceHangar} fullScreen sx={{ zIndex: 99999 }}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Base Tenants for {selectedHangar?.name}</Typography>

            <IconButton onClick={() => setOpenReferenceHangar(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Hangar isReference={true} hangarId={selectedHangar?.id} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
