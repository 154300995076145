import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import logo from "../../../airplx-logo-transparent.png";
import { useFeatureFlags } from "../../containers/FeatureFlagsContainer";
import { useRampState } from "../../containers/RampStateContainer";
import { Layout } from "../../widgets/Layout";

type Props = {};

export const RampPrintLayout: React.FC<Props> = ({}) => {
  const { isConference } = useFeatureFlags();
  const { ramp } = useRampState();

  const width = React.useMemo(() => {
    const standardWidth = 800;
    const standardDepth = 800;

    if (ramp.depth >= ramp.width) {
      const ratio = ramp.width / ramp.depth;
      return standardDepth * ratio;
    }

    return standardWidth;
  }, []);

  return (
    <Box>
      <Stack direction="column" justifyContent="space-between">
        <style type="text/css" media="print">
          {`@page { size: ${
            ramp.depth > ramp.width ? "portrait" : "landscape"
          }; }`}
        </style>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="column">
            <Typography variant="h5">{ramp.name}</Typography>
            <Typography>
              {ramp.width}w x {ramp.depth}d
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ width: 500, height: 50 }}
          >
            <Stack direction="column">
              <Typography variant="subtitle1">Aircraft</Typography>
              <Typography
                variant="caption"
                textAlign="center"
                color="info.dark"
              >
                {ramp.stack.tenants.length}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <br />
        <Layout
          width={width}
          location={{ type: "ramp", ...ramp }}
          stack={ramp?.stack}
          setStack={() => null}
          options={{
            hideUnplaced: false,
            readOnly: true,
            printable: false,
            selectable: false,
          }}
        />
      </Stack>
      {isConference && (
        <Stack
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
          }}
        >
          <Typography align="right">
            Automatically optimized by
            <br />
            Contact us at www.airplx.com or peter@airplx.com.
          </Typography>
          <img src={logo} style={{ width: 175 }} />
        </Stack>
      )}
    </Box>
  );
};
