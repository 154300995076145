import * as React from "react";
import { Box } from "@mui/material";

type Props = {
  type?: string;
  feetToPixels: number;
  position: string;
  width: number;
  offset?: number;
};

export const Door: React.FC<Props> = ({
  type = "hangar",
  offset = 0,
  feetToPixels,
  position,
  width,
}) => {
  if (type === "garage") {
    if (position === "back") {
      return (
        <Box
          data-type="garage_door"
          sx={{
            position: "absolute",
            top: 0,
            left: feetToPixels * offset,
            width: feetToPixels * width,
            height: feetToPixels * 2,
            backgroundColor: "#FFAC1C80",
          }}
        />
      );
    }
    return (
      <Box
        data-type="garage_door"
        sx={{
          position: "absolute",
          top: feetToPixels * offset,
          height: feetToPixels * width,
          left: position === "left" && 0,
          right: position === "right" && 0,
          width: feetToPixels * 2,
          backgroundColor: "#FFAC1C80",
        }}
      />
    );
  }

  return (
    <Box
      data-type="garage_door"
      sx={{
        position: "absolute",
        bottom: 0,
        left: position === "left" && 0,
        right: position === "right" && 0,
        width: feetToPixels * width,
        height: feetToPixels * 2,
        backgroundColor: "info.dark",
      }}
    />
  );
};
