import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button, IconButton, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { round } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useApi } from "../../../containers/ApiContainer";
import { useIdentity } from "../../../containers/IdentityContainer";
import { useRampsState } from "../../../containers/RampsStateContainer";
import { ConfirmationDialog } from "../../../widgets/ConfirmationDialog";
import { RampEditor } from "./RampEditor";

export const RampsSettingsPresenter: React.FC<{}> = () => {
  const { activeFBO, setFBO } = useActiveFBOs();
  const { airplxIdentity } = useIdentity();
  const { postgrest } = useApi();
  const { ramps, setRamps } = useRampsState();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      editable: false,
    },
    {
      field: "depth",
      headerName: "Gross Dimensions",
      width: 250,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.width
          ? `${round(params.row.width, 0)}w x ${round(params.row.depth, 0)}d`
          : "---",
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 400,
      renderCell: (params) => {
        const [modifyRamp, setModifyRamp] = React.useState<boolean>(false);
        const [confirmDelete, setConfirmDelete] = React.useState<boolean>(
          false
        );
        return (
          <Stack direction="row" spacing={1}>
            {modifyRamp && (
              <RampEditor
                ramp_id={params.row.id}
                onClose={() => setModifyRamp(false)}
              />
            )}

            {(airplxIdentity?.isadmin ||
              activeFBO.subscription === "autostacking_with_edit") && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  color="inherit"
                  onClick={() => setModifyRamp(true)}
                >
                  Modify Ramp
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="info"
                  onClick={() => setConfirmDelete(true)}
                >
                  Delete Ramp
                </Button>
              </>
            )}
            {airplxIdentity?.isadmin && (
              <>
                <IconButton
                  onClick={() => {
                    const index = ramps.findIndex(
                      (h) => h.id === params.row.id
                    );
                    if (index === 0) {
                      return;
                    }
                    // move index up one position in hangars array
                    const newRamps = [
                      ...ramps.slice(0, index - 1),
                      ramps[index],
                      ramps[index - 1],
                      ...ramps.slice(index + 1),
                    ];
                    const order = newRamps.map((h) => h.id);
                    setRamps(newRamps);
                    setFBO({
                      ...activeFBO,
                      settings: {
                        ...activeFBO.settings,
                        rampDisplayOrder: order,
                      },
                    });
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    const index = ramps.findIndex(
                      (h) => h.id === params.row.id
                    );
                    if (index === ramps.length - 1) {
                      return;
                    }
                    // move index back one position in hangars array
                    const newRamps = [
                      ...ramps.slice(0, index),
                      ramps[index + 1],
                      ramps[index],
                      ...ramps.slice(index + 2),
                    ];
                    const order = newRamps.map((h) => h.id);
                    setRamps(newRamps);
                    setFBO({
                      ...activeFBO,
                      settings: {
                        ...activeFBO.settings,
                        rampDisplayOrder: order,
                      },
                    });
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </>
            )}

            {confirmDelete && (
              <ConfirmationDialog
                title={`Are you sure you want to delete ${params.row.name}?`}
                text="This ramp and all of its tenant info will be deleted. Any base tenants for this ramp will become unassigned."
                typeToConfirm
                okText="Delete Ramp"
                onCancel={() => setConfirmDelete(false)}
                onOk={async () => {
                  // delete the hangar!
                  await postgrest.rpc("delete_ramp", {
                    ramp_id: params.row.id,
                  });
                  setRamps(ramps.filter((r) => r.id !== params.row.id));
                }}
                onClose={() => setConfirmDelete(false)}
              />
            )}
          </Stack>
        );
      },
    },
  ];
  return (
    <Stack direction="column" sx={{ height: "100%" }}>
      <div style={{ height: "calc(100% - 150px)", width: "100%" }}>
        <DataGrid
          rows={ramps}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
      </div>
    </Stack>
  );
};
