import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { formatDistance } from "date-fns";
import * as React from "react";
import { useIdentity } from "../../containers/IdentityContainer";
import { Tenant } from "../../types";
type Props = {
  variant?: "contained" | "outlined" | "text" | undefined;
  tenant: Tenant;
  setTenant: (tenant: Tenant) => void;
};

export const ButtonNotes: React.FC<Props> = ({
  variant,
  tenant,
  setTenant,
}) => {
  const { airplxIdentity } = useIdentity();
  const [open, setOpen] = React.useState<boolean>(false);
  const [localTenant, setLocalTenant] = React.useState<Tenant>(tenant);

  React.useEffect(() => {
    if (open) {
      setLocalTenant(tenant);
    }
  }, [open]);

  return (
    <>
      <Button
        color={variant === "contained" ? "info" : "inherit"}
        variant={variant ?? "text"}
        onClick={() => setOpen(true)}
      >
        Notes
      </Button>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>Aircraft Notes</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <TextField
              autoFocus
              margin="dense"
              label="Notes"
              type="text"
              fullWidth
              placeholder="Departure Time: 05/24/2025 12:00 PM"
              multiline
              minRows={10}
              onChange={(evt) => {
                setLocalTenant({
                  ...tenant,
                  note: evt.target.value,
                  note_last_updated: new Date().toISOString(),
                  note_last_updated_by: airplxIdentity?.email ?? "Unknown",
                });
              }}
              value={localTenant.note ?? ""}
            />
            {Boolean(tenant.note_last_updated) && (
              <Typography variant="body2">
                Last update:{" "}
                {formatDistance(
                  new Date(tenant.note_last_updated),
                  new Date(),
                  { addSuffix: true }
                )}{" "}
                ({new Date(tenant.note_last_updated).toLocaleDateString()}) by{" "}
                {tenant.note_last_updated_by}
              </Typography>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              setTenant(localTenant);
              setOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
