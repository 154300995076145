import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useLocationsState } from "../../containers/LocationsStateContainer";
import { AddTenantButton } from "../../screens/Settings/HangarSettings/AddTenantButton";
import {
  Hangar,
  Location,
  Ramp,
  Stack as StackType,
  Tenant,
} from "../../types";
import { AircraftListItem } from "./AircraftListItem";

type Props = {
  isAlgorithmTesting?: boolean;
  location: Location<Hangar | Ramp>;
  stack: StackType;
  setStack: (stack: StackType) => void;
  width?: number;
};

const sortTenants = (tenants: Tenant[]): Tenant[] => {
  return tenants.sort((a: Tenant, b: Tenant) => {
    // if (a.selected) {
    //   return -1;
    // }
    // if (b.selected) {
    //   return 1;
    // }
    if (!a.tail_number) {
      return 1;
    }
    if (!b.tail_number) {
      return -1;
    }
    if (!a.tail_number?.toLowerCase().startsWith("n")) {
      return 1;
    }
    if (!b.tail_number?.toLowerCase().startsWith("n")) {
      return -1;
    }
    if (a.tail_number > b.tail_number) {
      return 1;
    }
    if (a.tail_number < b.tail_number) {
      return -1;
    }
    return 1;
  });
};

export const CurrentAircraft: React.FC<Props> = ({
  location,
  stack,
  setStack,
  width,
}) => {
  const { sendToLocation } = useLocationsState();
  const sortedTenants = React.useMemo(
    () =>
      [...stack.tenants]
        .sort((a, b) => {
          if (a.selected) {
            return -1;
          }
          if (b.selected) {
            return 1;
          }
          return a.tail_number.localeCompare(b.tail_number);
        })
        .filter((t) => t.position.stack_id === stack.id)
        .filter((tenant) => tenant.aircraft?.make),
    [stack.tenants]
  );

  const onAddAircraft = React.useCallback(
    (tenant: Tenant | Tenant[]) => {
      if (Array.isArray(tenant)) {
        setStack({
          ...stack,
          tenants: [...tenant, ...stack.tenants],
        });
        return;
      }
      setStack({
        ...stack,
        tenants: [tenant, ...stack.tenants],
      });
    },
    [stack, setStack]
  );

  const setTenant = React.useCallback(
    (tenant: Tenant) => {
      setStack({
        ...stack,
        tenants: stack.tenants.map((t) => (t.id === tenant.id ? tenant : t)),
      });
    },
    [stack, setStack]
  );

  const onDeleteAircraft = React.useCallback(
    (tenant: Tenant) => {
      setStack({
        ...stack,
        tenants: stack.tenants.filter(
          (t) => t.placement_id !== tenant.placement_id
        ),
      });
    },
    [stack, setStack]
  );

  const onSelectAircraft = React.useCallback(
    (tenant: Tenant) => {
      setStack({
        ...stack,
        tenants: stack.tenants.map((t) => ({
          ...t,
          selected:
            t.placement_id === tenant.placement_id ? !t.selected : false,
        })),
      });
    },
    [stack, setStack]
  );

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ minWidth: width ?? 400, maxHeight: "100%", overflow: "hidden" }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pr: 1, pl: 1 }}
      >
        <Typography>Current Aircraft</Typography>
        <AddTenantButton
          onAdd={onAddAircraft}
          location={location}
          sendToLocation={sendToLocation}
          stack={stack}
          setStack={setStack}
        />
      </Stack>
      <Box sx={{ height: "100%", overflow: "scroll", overflowX: "hidden" }}>
        {sortedTenants.map((tenant, idx) => (
          <React.Fragment key={`current-aircraft-${tenant.id}`}>
            <AircraftListItem
              showBottomBorder={idx + 1 === stack.tenants.length}
              stack={stack}
              setStack={setStack}
              tenant={tenant}
              setTenant={setTenant}
              onSelectAircraft={onSelectAircraft}
              onDeleteAircraft={onDeleteAircraft}
            />
          </React.Fragment>
        ))}
      </Box>
    </Stack>
  );
};
