import mixpanel from "mixpanel-browser";

const dummy: Record<string, any> = new Proxy(
  {},
  {
    get(target, propKey) {
      if (propKey.toString() === "people") {
        return dummy;
      }
      return (data: any) => {
        console.info(
          `Mixpanel Dev: called with ${propKey.toString()} => ${JSON.stringify(
            data
          )}`
        );
      };
    },
  }
);

mixpanel.init("62aa25aa93870b1f6f43b1e11def428b", { debug: false });

export default window.location.hostname === "app.airplx.com" ? mixpanel : dummy;
