import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button, IconButton, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useApi } from "../../../containers/ApiContainer";
import { useHangarsState } from "../../../containers/HangarsStateContainer";
import { useIdentity } from "../../../containers/IdentityContainer";
import { Hangar } from "../../../types";
import { ConfirmationDialog } from "../../../widgets/ConfirmationDialog";
import { sortHangars } from "../../Hangars/HangarsPresenter";

type Props = {
  hangars: Hangar[];
  onSelectHangar: (h: Hangar) => void;
  onSelectModifyBaseTenants: (h: Hangar) => void;
};

export const HangarsGridPresenter: React.FC<Props> = ({
  hangars,
  onSelectHangar,
  onSelectModifyBaseTenants,
  ...props
}) => {
  const { activeFBO, setFBO } = useActiveFBOs();
  const { postgrest } = useApi();
  const { airplxIdentity } = useIdentity();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Hangar Name",
      width: 250,
      editable: false,
    },
    {
      field: "depth",
      headerName: "Dimensions",
      width: 250,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.width}w x ${params.row.depth}d x ${params.row.height}h`,
    },
    {
      field: "spacing",
      headerName: "Wall Spacing",
      width: 250,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `L: ${params.row.wall_spacing_left ?? 2}ft R: ${params.row
          .wall_spacing_right ?? 2}ft B: ${params.row.wall_spacing_back ??
          2}ft`,
    },
    {
      field: "base_tenants",
      headerName: "Base Tenants",
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.stack?.tenants?.length || 0,
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 500,
      renderCell: (params) => {
        const { hangars, setHangars } = useHangarsState();
        const [editHeatmap, setEditHeatmap] = React.useState<boolean>(false);
        const [confirmDelete, setConfirmDelete] = React.useState<boolean>(
          false
        );
        return (
          <Stack direction="row" spacing={1}>
            {(airplxIdentity?.isadmin ||
              activeFBO.subscription === "autostacking_with_edit") && (
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => onSelectHangar(params.row)}
              >
                Modify Hangar
              </Button>
            )}
            {airplxIdentity?.isadmin && (
              <Button
                size="small"
                variant="contained"
                color="info"
                onClick={() => setConfirmDelete(true)}
              >
                Delete Hangar
              </Button>
            )}
            {airplxIdentity?.isadmin && (
              <>
                <IconButton
                  onClick={() => {
                    const index = hangars.findIndex(
                      (h) => h.id === params.row.id
                    );
                    if (index === 0) {
                      return;
                    }
                    // move index up one position in hangars array
                    const newHangars = [
                      ...hangars.slice(0, index - 1),
                      hangars[index],
                      hangars[index - 1],
                      ...hangars.slice(index + 1),
                    ];
                    const order = newHangars.map((h) => h.id);
                    setHangars(newHangars);
                    setFBO({
                      ...activeFBO,
                      settings: {
                        ...activeFBO.settings,
                        hangarDisplayOrder: order,
                      },
                    });
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    const index = hangars.findIndex(
                      (h) => h.id === params.row.id
                    );
                    if (index === hangars.length - 1) {
                      return;
                    }
                    // move index back one position in hangars array
                    const newHangars = [
                      ...hangars.slice(0, index),
                      hangars[index + 1],
                      hangars[index],
                      ...hangars.slice(index + 2),
                    ];
                    const order = newHangars.map((h) => h.id);
                    setHangars(newHangars);
                    setFBO({
                      ...activeFBO,
                      settings: {
                        ...activeFBO.settings,
                        hangarDisplayOrder: order,
                      },
                    });
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </>
            )}

            {confirmDelete && (
              <ConfirmationDialog
                title={`Are you sure you want to delete ${params.row.name}?`}
                text="This hangar and all of its tenant info will be deleted. Any base tenants for this hangar will become unassigned."
                typeToConfirm
                okText="Delete Hangar"
                onCancel={() => setConfirmDelete(false)}
                onOk={async () => {
                  // delete the hangar!
                  await postgrest.rpc("delete_hangar", {
                    hangar_id: params.row.id,
                  });
                  setHangars(hangars.filter((h) => h.id !== params.row.id));
                }}
                onClose={() => setConfirmDelete(false)}
              />
            )}
          </Stack>
        );
      },
    },
  ];
  return (
    <div style={{ height: "calc(100% - 150px)", width: "100%" }}>
      <DataGrid
        rows={[...hangars].sort((a, b) =>
          sortHangars(a, b, activeFBO?.settings?.hangarDisplayOrder)
        )}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        disableSelectionOnClick
      />
    </div>
  );
};
