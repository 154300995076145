import { Box } from "@mui/material";
import * as React from "react";
import {
  Obstacle as ObstacleType,
  ObstacleType as ObstacleTypeType,
} from "../../../types";

type Props = ObstacleType & {
  feetToPixels: number;
};

export const OBSTACLE_STYLES = {
  [ObstacleTypeType.OBSTRUCTION]: {
    backgroundColor: "white",
  },
  [ObstacleTypeType.NO_TOW_AREA]: {
    backgroundColor: "transparent",
    outlineColor: "red",
    outlineWidth: "2px",
    outlineStyle: "dashed",
  },
  [ObstacleTypeType.UNOBSTRUCTIVE]: {
    backgroundColor: "#3c6ce990",
  },
  [ObstacleTypeType.CAUTION]: {
    backgroundColor: "#ECE81A90",
  },
};

export const Obstacle: React.FC<Props> = ({
  feetToPixels,
  x,
  y,
  type,
  width,
  depth,
}) => (
  <Box
    data-type={`obstacle-${type}`}
    sx={{
      zIndex: type === ObstacleTypeType.OBSTRUCTION ? 2 : 1,
      position: "absolute",
      top: feetToPixels * y,
      left: feetToPixels * x,
      width: feetToPixels * width,
      height: feetToPixels * depth,
      ...OBSTACLE_STYLES[type],
    }}
  ></Box>
);
