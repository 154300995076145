import * as React from "react";
import { createContainer } from "./StateContainer";

type Props = {};
const useLoadingProgressContainer = (props: Props) => {
  const [show, setShow] = React.useState<boolean>(false);
  return {
    show,
    setShow,
  };
};
const LoadingProgressContainerState = createContainer(
  useLoadingProgressContainer
);

export const LoadingProgressContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children, ...props }) => {
  const loadingProgress = LoadingProgressContainerState.useContainer();
  return (
    <LoadingProgressContainerState.Provider
      initialState={{ ...loadingProgress, ...props }}
    >
      {children}
    </LoadingProgressContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useLoadingProgress = LoadingProgressContainerState.useContainer;
