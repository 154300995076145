import { RefObject, useEffect } from "react";
import React = require("react");

type DrawFunction = (context: CanvasRenderingContext2D) => void;

const useCanvas = (
  canvasRef: RefObject<HTMLCanvasElement>,
  draw: DrawFunction
): RefObject<HTMLCanvasElement> => {
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext("2d");
    if (!context) return;

    draw(context);

    return () => {};
  }, [canvasRef, draw]);

  // Perform translation only once
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext("2d");
    if (context) {
      context.translate(0.5, 0.5);
    }
  }, []);

  return canvasRef;
};

type CanvasProps = {
  draw: DrawFunction;
  width: number;
  height: number;
} & React.CanvasHTMLAttributes<HTMLCanvasElement>;

const Canvas = React.forwardRef<HTMLCanvasElement, CanvasProps>(
  ({ draw, width, height, ...rest }, ref) => {
    const canvasRef = useCanvas(ref as RefObject<HTMLCanvasElement>, draw);

    return (
      <canvas
        id="canvas"
        ref={canvasRef}
        width={width}
        height={height}
        {...rest}
      />
    );
  }
);

export default Canvas;
