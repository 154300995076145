import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Hangar, Ramp, Stack } from "../../types";
import { MovableObstacleGrid } from "./MovableObstacleGrid";
import { usePredefinedObstacle } from "../../hooks/usePredefinedObstacle";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

type Props = {
  location: Hangar | Ramp;
  stack: Stack;
  setStack: (stack: Stack) => void;
};

export const AddMovableObstacle: React.FC<Props> = ({
  location,
  stack,
  setStack,
}) => {
  const { predefinedObstacles } = usePredefinedObstacle();
  const [open, setOpen] = React.useState<boolean>(false);
  React.useEffect(() => {
    //
  }, [open]);

  return (
    <>
      <Button
        variant="contained"
        color="info"
        startIcon={<AddIcon />}
        onClick={async () => setOpen(true)}
      >
        Add Movable Obstacle
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="lg"
      >
        <DialogTitle>Add Movable Obstacle</DialogTitle>
        <DialogContent sx={{ width: 900, height: 600 }}>
          <MovableObstacleGrid
            location={location}
            movableObstacles={predefinedObstacles}
            stack={stack}
            setStack={setStack}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => setOpen(false)}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
