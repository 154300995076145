import * as geometric from "geometric";
import { anglediff } from "../../../hooks/utils";
import { getObstacleGeometry } from "../../Settings/HangarSettings/ObstacleCanvas";
import { sum } from "lodash";
import {
  Hangar,
  ObstacleSlim,
  ObstacleType,
  Preference,
  Tenant,
} from "../../../types";

export const getOrientation = (tenant: Tenant): Preference => {
  const angle = tenant.position.angle ?? defaultTenantAngle(tenant);
  const isGreaterThan90 = anglediff(0, angle) > 90;
  if (tenant.aircraft.tail_wheel) {
    return isGreaterThan90
      ? Preference.TAIL_WHEEL_TAIL_IN
      : Preference.TAIL_WHEEL_NOSE_IN;
  }

  return isGreaterThan90 ? Preference.TAIL_IN : Preference.NOSE_IN;
};

export const defaultTenantAngle = (tenant: Tenant): number => {
  if (
    tenant.position.preferences.indexOf(Preference.TAIL_IN) > -1 ||
    tenant.position.preferences.indexOf(Preference.TAIL_WHEEL_NOSE_IN) > -1
  ) {
    return 180;
  }
  return 0;
};

export const wrapUnplacedAirplanes = (
  tenants: Tenant[],
  width: number,
  feetToPixels: number,
  singleRow: boolean = false,
  isHoldingArea: boolean = false
): Tenant[] => {
  if (isHoldingArea) {
    return wrapHoldingAreaUnplacedAirplanes(tenants, width, feetToPixels);
  }
  /**
   * go through each tenant. if it's unplaced, then assign values
   * x and y should return after > width
   */
  const tenantsToReturn: Tenant[] = [];
  let currentX = 0;
  let currentYRow = 1;
  let rowStartingY = isHoldingArea
    ? -(window.innerHeight - 230) / feetToPixels
    : 0;
  let maxLengthForRow = 0;
  for (const tenant of tenants) {
    if (tenant.position.x == null) {
      tenantsToReturn.push({
        ...tenant,
        position: {
          ...tenant.position,
          angle: defaultTenantAngle(tenant),
          x: currentX,
          y:
            -Math.max(tenant.aircraft.wingspan, tenant.aircraft.length) -
            rowStartingY,
        },
      });
      currentX += Math.max(tenant.aircraft.wingspan, tenant.aircraft.length);
      maxLengthForRow = Math.max(maxLengthForRow, tenant.aircraft.length);
      if (currentX * feetToPixels > width) {
        currentX = 0;
        rowStartingY +=
          singleRow && isHoldingArea === false ? 0 : maxLengthForRow;
        maxLengthForRow = 0;
        currentYRow += singleRow && isHoldingArea === false ? 0 : 1;
      }
      continue;
    }
    tenantsToReturn.push(tenant);
  }
  return tenantsToReturn;
};

export const getHoldingAreaHeightInPixels = (): number => {
  const element = document.querySelector(
    'div[data-location-id="holding-area"] canvas'
  );
  if (!element) {
    return 0;
  }
  const rect = element.getBoundingClientRect();
  return rect.height;
};

/**
 * We're going to create positions for the unplaced tenants and arrange
 * them in a grid format around the holding area.
 */
export const wrapHoldingAreaUnplacedAirplanes = (
  tenants: Tenant[],
  width: number,
  feetToPixels: number
): Tenant[] => {
  /**
   * go through each tenant. if it's unplaced, then assign values
   * x and y should return after > width
   */
  const tenantsInSizeOrder = tenants
    .sort((a, b) => {
      const sqftA = a.aircraft.length * a.aircraft.wingspan;
      const sqftB = b.aircraft.length * b.aircraft.wingspan;
      return sqftB - sqftA;
    })
    .filter((tenant) => tenant.position.x == null);

  const holdingAreaHeight = getHoldingAreaHeightInPixels() / feetToPixels;
  let currentMaxX = 0;
  let currentMaxDepth = 0;
  const tenantsToReturn: Tenant[] = [];
  const rowHeights = [0];
  const widthInFeet = width / feetToPixels;
  for (const tenant of tenantsInSizeOrder) {
    const span = Math.max(tenant.aircraft.wingspan, tenant.aircraft.length);
    // check to see if it's going to go off the screen. if so, then move to the next line
    if (currentMaxX + span > widthInFeet) {
      rowHeights.push(currentMaxDepth);
      tenantsToReturn.push({
        ...tenant,
        position: {
          ...tenant.position,
          angle: defaultTenantAngle(tenant),
          x: 0,
          y: holdingAreaHeight - span - sum(rowHeights),
        },
      });
      currentMaxDepth = span;
      currentMaxX = span;
    } else {
      tenantsToReturn.push({
        ...tenant,
        position: {
          ...tenant.position,
          angle: defaultTenantAngle(tenant),
          x: currentMaxX,
          y: holdingAreaHeight - span - sum(rowHeights),
        },
      });
      currentMaxDepth = Math.max(span, currentMaxDepth);
      currentMaxX += span;
    }
  }
  return [
    ...tenants.filter((tenant) => tenant.position.x != null),
    ...tenantsToReturn,
  ];
};

export const formatObstacles = (hangar: Hangar): ObstacleSlim[] => {
  const obstacles: ObstacleSlim[] = [];
  for (const obstacle of hangar.obstacles) {
    if (obstacle.type === ObstacleType.UNOBSTRUCTIVE) {
      continue;
    }
    if (obstacle.type === ObstacleType.CAUTION) {
      continue;
    }
    // need to flip the y axis for postgis
    let o: ObstacleSlim = {
      height: obstacle.height,
      geom: {
        type: "Polygon",
        coordinates: [
          obstacle.geom.coordinates
            .flat()
            .map(([x, y]) => [x, hangar.depth - y]),
        ],
      },
    };

    // it's easier to do this here instead of on the backend.
    // we're going to add in wall spacing to the obstacle if is touching the wall

    // if is it is touching the left wall and the top wall (top left corner)
    if (
      Math.min(...obstacle.geom.coordinates[0].map(([x, y]) => x)) === 0 &&
      Math.min(...obstacle.geom.coordinates[0].map(([x, y]) => y)) === 0
    ) {
      const geom = getObstacleGeometry(obstacle, {
        right: hangar.wall_spacing_left,
        bottom: hangar.wall_spacing_back,
      });
      o = {
        height: obstacle.height,
        geom: {
          type: "Polygon",
          coordinates: [
            geom.coordinates.flat().map(([x, y]) => [x, hangar.depth - y]),
          ],
        },
      };
      // if is it is touching the right wall and the top wall (top right corner)
    } else if (
      Math.max(...obstacle.geom.coordinates[0].map(([x, y]) => x)) ===
        hangar.width &&
      Math.min(...obstacle.geom.coordinates[0].map(([x, y]) => y)) === 0
    ) {
      const geom = getObstacleGeometry(obstacle, {
        left: hangar.wall_spacing_right,
        bottom: hangar.wall_spacing_back,
      });
      o = {
        height: obstacle.height,
        geom: {
          type: "Polygon",
          coordinates: [
            geom.coordinates.flat().map(([x, y]) => [x, hangar.depth - y]),
          ],
        },
      };
      // is it touching the left wall?
    } else if (
      Math.min(...obstacle.geom.coordinates[0].map(([x, y]) => x)) === 0
    ) {
      const geom = getObstacleGeometry(obstacle, {
        right: hangar.wall_spacing_left,
        top: hangar.wall_spacing_left,
        bottom: hangar.wall_spacing_left,
      });
      o = {
        height: obstacle.height,
        geom: {
          type: "Polygon",
          coordinates: [
            geom.coordinates.flat().map(([x, y]) => [x, hangar.depth - y]),
          ],
        },
      };
    }
    // is it touching the top wall?
    else if (
      Math.min(...obstacle.geom.coordinates[0].map(([x, y]) => y)) === 0
    ) {
      const geom = getObstacleGeometry(obstacle, {
        right: hangar.wall_spacing_back,
        left: hangar.wall_spacing_back,
        bottom: hangar.wall_spacing_back,
      });
      o = {
        height: obstacle.height,
        geom: {
          type: "Polygon",
          coordinates: [
            geom.coordinates.flat().map(([x, y]) => [x, hangar.depth - y]),
          ],
        },
      };
    }
    // is it touching the right wall?
    else if (
      Math.max(...obstacle.geom.coordinates[0].map(([x, y]) => x)) ===
      hangar.width
    ) {
      const geom = getObstacleGeometry(obstacle, {
        top: hangar.wall_spacing_right,
        left: hangar.wall_spacing_right,
        bottom: hangar.wall_spacing_right,
      });
      o = {
        height: obstacle.height,
        geom: {
          type: "Polygon",
          coordinates: [
            geom.coordinates.flat().map(([x, y]) => [x, hangar.depth - y]),
          ],
        },
      };
    }
    obstacles.push(o);
  }

  for (const { obstacle, position } of hangar.stack.movableObstacles) {
    const basePolygon: geometric.Polygon = obstacle.geom.coordinates[0].map(
      ([x, y]) => [x + position.x, y + position.y]
    );
    const center = Math.max(obstacle.width, obstacle.length) / 2;
    const pivotPoint: geometric.Point = [
      center + position.x,
      center + position.y,
    ];
    const polygon = geometric.polygonRotate(
      basePolygon,
      // geometric rotates the other way, so negate the angle
      -position.angle,
      pivotPoint
    );
    obstacles.push({
      height: obstacle.height,
      geom: {
        type: "Polygon",
        coordinates: [polygon],
      },
    });
  }
  return obstacles;
};
