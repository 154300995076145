import SearchIcon from "@mui/icons-material/Search";
import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useLocationsState } from "../../../containers/LocationsStateContainer";
import { useTenants } from "../../../hooks/useTenants";
import {
  Hangar,
  Location,
  Ramp,
  Stack as StackType,
  Tenant,
  TenantType,
} from "../../../types";
import {
  consistentId,
  defaultPreferenceForAircraft,
  uuidv4,
} from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { lastHangaredText } from "../../../utils/phrasing";
import { ConfirmationDialog } from "../../../widgets/ConfirmationDialog";
import { Aircraft } from "../../LabelingTool";
import { TenantGrid } from "../../TransientsAndTenants/TenantGrid";

type Props = {
  location: Location<Hangar | Ramp>;
  stack: StackType;
  setStack: (stack: StackType) => void;
  tenantType: TenantType;
  filterBy?: (tenant: Tenant) => boolean;
  isReference: boolean;
  checkAircraftHeight: (aircraft: Aircraft) => void;
};

export const TenantSelector: React.FC<Props> = ({
  location,
  stack,
  setStack,
  tenantType,
  filterBy = (t) => true,
  isReference,
  checkAircraftHeight,
}) => {
  const { activeFBO } = useActiveFBOs();
  const [filter, setFilter] = React.useState<string>("");
  const { sendToLocation, findTenantLocation } = useLocationsState();
  const { tenants } = useTenants();

  const tenantsToDisplay = React.useMemo(
    () =>
      tenants
        .filter(filterBy)
        .filter((t) => {
          if (!Boolean(filter)) {
            return true;
          }

          const currentHangarName = findTenantLocation(false, t)?.name;
          const assignedHangarName = findTenantLocation(true, t)?.name;
          const queryString = `${t.tail_number} - ${t.aircraft.make} - ${t.aircraft.model} - ${currentHangarName} - ${assignedHangarName}`.toLocaleLowerCase();
          return queryString.indexOf(filter.toLocaleLowerCase()) > -1;
        })
        .sort((a: Tenant, b: Tenant) => {
          const aAssignedHangarName = findTenantLocation(false, a)?.name;
          const bAssignedHangarName = findTenantLocation(false, b)?.name;
          if (aAssignedHangarName === bAssignedHangarName) {
            return a.tail_number.localeCompare(b.tail_number);
          }
          if (aAssignedHangarName === location.location_id) {
            return -1;
          }
          if (bAssignedHangarName === location.location_id) {
            return 1;
          }
          return a.tail_number.localeCompare(b.tail_number);
        }),
    [tenants, filter, filterBy]
  );

  const additionalColumns: GridColDef[] = [];
  if (tenantType === TenantType.TRANSIENT) {
    additionalColumns.push({
      field: "last_hangared",
      headerName: "Last Hangared",
      width: 175,
      editable: false,
      renderCell: (params) =>
        lastHangaredText(
          params.value,
          Boolean(findTenantLocation(false, params.row))
        ),
    });
  }
  additionalColumns.push({
    field: "action",
    headerName: "",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      const [tenantToAdd, setTenantToAdd] = React.useState<Tenant>();
      const [confirmAddText, setConfirmAddText] = React.useState<string>();

      if (stack?.tenants?.find((t) => t.id === params.row.id)) {
        return (
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={async () => {
              await sendToLocation(
                null,
                isReference ? "reference" : "current",
                params.row
              );
              setStack({
                ...stack,
                tenants: stack.tenants.filter((t) => t.id !== params.row.id),
              });
            }}
          >
            Remove Aircraft
          </Button>
        );
      }

      return (
        <>
          <Button
            size="small"
            variant="contained"
            color="inherit"
            onClick={async () => {
              /**
               * this gets called from:
               *  - Base Tenant 'Add Aircraft' button => make sure selectedTenant is in the hangar. make sure tenant has a position
               * in the base hangar (and current hangar?)
               *  - Current Hangar 'Add Aircraft' button => make sure selectedTenant is in the hangar. add a position for the
               * current hangar. if adding as base, make position for base also.
               *  - Tenants/Transients 'Add Aircraft' button'
               *
               **/
              const selectedTenant: Tenant = { ...params.row };
              const tenant: Tenant = {
                ...selectedTenant,
                placement_id: uuidv4(),
                aircraft: params.row.aircraft,
                selected: false,
                type: isReference ? TenantType.BASE : tenantType,
                position: {
                  id: consistentId(selectedTenant.entity_id, stack.isReference),
                  stack_id: stack.id,
                  entity_id: selectedTenant.entity_id,
                  x: null,
                  y: null,
                  angle: null,
                  preferences: defaultPreferenceForAircraft(
                    params.row.aircraft,
                    activeFBO
                  ),
                },
              };

              if (isReference && tenantType === TenantType.TRANSIENT) {
                setTenantToAdd(tenant);
                setConfirmAddText(
                  location.type === "hangar"
                    ? "We’ll assign this new Base Tenant to this hangar. If you need to change default hangar assignments, you can do that by editing this specific aircraft."
                    : "We’ll assign this new Tie-Down Tenant to this ramp. If you need to change default ramp assignments, you can do that by editing this specific aircraft."
                );
                return;
              }

              mixpanel.track("Add Aircraft", {
                aircraft_tenant_type: tenant.type,
                aircraft_type: tenant.aircraft.model,
                aircraft_tail_number: tenant.tail_number,
              });
              await sendToLocation(
                location,
                isReference ? "reference" : "current",
                tenant
              );

              setStack({
                ...stack,
                tenants: [tenant, ...stack.tenants],
              });
              checkAircraftHeight(params.row.aircraft);
            }}
          >
            Add Aircraft
          </Button>
          {Boolean(confirmAddText) && Boolean(tenantToAdd) && (
            <ConfirmationDialog
              title="Please Confirm"
              text={confirmAddText}
              onCancel={() => {
                setConfirmAddText(null);
              }}
              onOk={() => {
                setStack({
                  ...stack,
                  tenants: [tenantToAdd, ...stack.tenants],
                });
                checkAircraftHeight(params.row.aircraft);
                setTenantToAdd(null);
              }}
              onClose={() => {
                setConfirmAddText(null);
              }}
            />
          )}
        </>
      );
    },
  });

  const hideTheseColumns = ["owner_manager", "make"];
  if (tenantType === TenantType.TRANSIENT) {
    hideTheseColumns.push("type");
    hideTheseColumns.push("assigned_location");
  }

  return (
    <Stack direction="column" spacing={1} sx={{ height: "100%" }}>
      <TextField
        size="small"
        fullWidth
        placeholder="Search by tenant name or Tail #"
        inputProps={{
          ["data-testid"]: "tenant-filter",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(evt) => setFilter(evt.target.value)}
      />
      <TenantGrid
        tenants={tenantsToDisplay}
        additionalColumns={additionalColumns}
        hideTheseColumns={hideTheseColumns}
      />
    </Stack>
  );
};
