import { Box, Button, Grid, Stack } from "@mui/material";
import { round, sum } from "lodash";
import * as React from "react";
import Canvas from "../../widgets/useCanvas";
import { DropImage } from "./DropImage";
import { LabelingInputForm } from "./LabelingInputForm";
import { Aircraft } from "./LabelingTool";
import { SideViewCanvas } from "./SideViewCanvas";
import { SimplifiedGeom } from "./SimplifiedGeom";
import { calculateOutline } from "./contour";

const average = (numbers: number[]): number => {
  return sum(numbers) / numbers.length;
};

const makeRectangle = (points: number[][]): number[][] => {
  const point1 = points[0];
  const point2 = points[1];
  return [
    [...point1],
    [point1[0], point2[1]],
    [...point2],
    [point2[0], point1[1]],
    [...point1],
  ];
};

// draws the background image of the aircraft
export const drawImage = (ctx, img) => {
  const xScale = ctx.canvas.width / img.width;
  const yScale = ctx.canvas.height / img.height;
  const scale = Math.min(xScale, yScale);

  let offsetY = 0;
  if (xScale < yScale) {
    offsetY = (img.height * yScale - img.height * xScale) / 2;
  }

  // put the image on the canvas
  ctx.drawImage(img, 0, 0 + offsetY, img.width * scale, img.height * scale);
};

const drawVerticalLine = (ctx, x, options = {}) => {
  ctx.beginPath();
  ctx.setLineDash([]);
  ctx.lineWidth = options.width || 4;
  ctx.strokeStyle = options.color || "red";
  ctx.moveTo(x - ctx.lineWidth / 2, 0);
  ctx.lineTo(x - ctx.lineWidth / 2, ctx.canvas.height);
  ctx.stroke();
  ctx.closePath();
};

export const drawHorizontalLine = (
  ctx,
  y,
  xStart = 0,
  xEnd = 600,
  options = {}
) => {
  ctx.beginPath();
  ctx.setLineDash([]);
  ctx.lineWidth = options.width || 2;
  ctx.strokeStyle = options.color || "hotpink";
  ctx.moveTo(xStart || 0, y);
  ctx.lineTo(xEnd || ctx.canvas.width, y);
  ctx.stroke();
  ctx.closePath();
};

const drawRectangle = (ctx, shape) => {
  ctx.beginPath();
  ctx.setLineDash([2]);
  ctx.lineWidth = 2;
  ctx.strokeStyle = "black";
  ctx.moveTo(shape[0][0], shape[0][1]);
  ctx.lineTo(shape[1][0], shape[0][1]);
  ctx.lineTo(shape[1][0], shape[1][1]);
  ctx.lineTo(shape[0][0], shape[1][1]);
  ctx.lineTo(shape[0][0], shape[0][1]);
  ctx.stroke();
  ctx.closePath();
};

const drawCircle = (ctx, geom) => {
  if (!geom) {
    return;
  }
  const { coordinates } = geom;
  ctx.lineWidth = 4;
  ctx.strokeStyle = "black";
  ctx.fillStyle = "black";
  ctx.moveTo(coordinates[0] - 2, coordinates[1] - 2);
  ctx.arc(coordinates[0] - 2, coordinates[1] - 2, 5, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.fill();
  ctx.closePath();
};

const iterCoordinates = (geom): number[][][] => {
  if (geom.type === "Polygon") {
    return [...geom.coordinates];
  }
  if (geom.type === "MultiPolygon") {
    return [...geom.coordinates.flat()];
  }
  if (geom.type === "GeometryCollection") {
    return [...geom.geometries.map(iterCoordinates).flat()];
  }
  return [...geom.coordinates];
};

export const drawText = (ctx, geom, geomtext, aircraft: Aircraft) => {
  if (!geom || !geomtext) {
    return;
  }

  const labels = [];
  if (
    geom.type.toUpperCase() === "MULTIPOLYGON" ||
    geom.type.toUpperCase() === "MULTIPOLYGON ZM"
  ) {
    const cs = geomtext
      .slice(17, -1)
      .split("),(")
      .map((x) =>
        x
          .replaceAll("(", "")
          .replaceAll(")", "")
          .split(",")
      )
      .map((cs) => cs.map((i) => i.split(" ").map(parseFloat)));

    for (let c of cs) {
      const zm = c[0].slice(2).map((v) => round(v, 2));
      labels.push(zm);
    }
  }

  if (!labels.length) {
    return;
  }
  let i = 0;
  for (let coordinates of iterCoordinates(geom)) {
    ctx.font = "bold 3px Arial";
    ctx.fillStyle = "black";
    ctx.textAlign = "center";
    const textX = average(
      coordinates.filter(([x, _]) => x > 0).map(([x, _]) => x)
    );
    const textY = average(
      coordinates.filter((_, y) => y > 0).map(([_, y]) => y)
    );
    const text = `${labels[i][0]}-${labels[i][1]}`;
    ctx.fillText(text, textX, textY);
    i++;
  }
};

// draws the background image of the aircraft
export const drawGeom = (ctx, geom, options = {}) => {
  if (!geom) {
    return;
  }
  for (let coordinates of iterCoordinates(geom)) {
    for (let i = 0; i < coordinates.length; i++) {
      const [x1, y1] = coordinates[i];
      // draws a point on the image
      ctx.fillStyle = options.point?.color || "#ff0000";
      ctx.fillRect(x1 - 4, y1 - 4, 8, 8);

      if (i > coordinates.length - 2) {
        continue;
      }
      // connect the points with a blue line
      const [x2, y2] = coordinates[i + 1];
      ctx.beginPath();
      ctx.setLineDash([]);
      ctx.lineWidth = options.outline?.lineWidth || 2;
      ctx.strokeStyle = options.outline?.color || "steelblue";
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
      ctx.closePath();
    }

    if (!options.fillStyle) {
      continue;
    }

    ctx.fillStyle = options.fillStyle;
    ctx.beginPath();
    ctx.moveTo(coordinates[0][0], coordinates[0][1]);
    for (let i = 0; i < coordinates.length; i++) {
      const [x1, y1] = coordinates[i];
      // draws a point on the image

      if (i > coordinates.length - 2) {
        continue;
      }
      // connect the points with a blue line
      const [x2, y2] = coordinates[i + 1];

      ctx.lineTo(x2, y2);
    }
    ctx.fill();
  }
};

const CANVAS_WIDTH = 600;
const CANVAS_HEIGHT = 600;

type Props = {
  aircrafts: Aircraft[];
  aircraft: Aircraft;
  setAircraft: (a: Aircraft) => void;
  img: HTMLImageElement;
  imgSide: HTMLImageElement;
  gisIntersection: (geom1: any, geom2: any) => Promise<any>;
};

export const LabelingToolPresenter: React.FC<Props> = ({
  img,
  imgSide,
  aircrafts,
  aircraft,
  setAircraft,
  gisIntersection,
  ...props
}) => {
  const [select, setSelect] = React.useState<boolean>(false);
  const [shapes, setShapes] = React.useState<number[][][]>([]);
  const [taggableArea, setTaggableArea] = React.useState<string>("");
  const [contourNumber, setContourNumber] = React.useState<number>(1);
  const [geomBeingSelected, setGeomBeingSelected] = React.useState<string>("");
  const canvasRef = React.createRef();

  const setAircraftValue = (key: string, value: any) => {
    setAircraft((aircraft: Aircraft) => ({ ...aircraft, [key]: value }));
  };

  const draw = (ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    drawImage(ctx, img);
    drawCircle(ctx, aircraft.nose);
    drawVerticalLine(ctx, aircraft.midline, { color: "limegreen", width: 1 });
    drawHorizontalLine(
      ctx,
      aircraft.front_wheels,
      ctx.canvas.width * 0.3,
      ctx.canvas.width * 0.7
    );
    drawHorizontalLine(
      ctx,
      aircraft.rear_wheels,
      ctx.canvas.width * 0.3,
      ctx.canvas.width * 0.7
    );
    drawGeom(ctx, aircraft.geom);
    const heightAreaOptions = {
      fillStyle: "#3D997040",
      point: { color: "#3D9970" },
      outline: { color: "#3D9970" },
    };

    drawGeom(ctx, aircraft.wing_geom, heightAreaOptions);
    drawGeom(ctx, aircraft.tail_geom, heightAreaOptions);
    drawGeom(ctx, aircraft.vertical_tail_fin_geom, heightAreaOptions);
    drawGeom(ctx, aircraft.propeller_geom, {
      fillStyle: null,
      point: { color: "transparent" },
      outline: { color: "black" },
    });

    if (shapes.length) {
      for (let shape of shapes) {
        if (shape.length < 2) {
          continue;
        }
        drawGeom(
          ctx,
          { coordinates: [shape] },
          {
            fillStyle: "#ff7f5040",
            point: { color: "coral" },
            outline: { color: "coral" },
          }
        );
      }
    }
  };

  const onClickAutoOutline = () => {
    if (!img) {
      return;
    }
    // we have an in memory canvas so we don't use anything we've marked up
    // on the user displayed canvas in the contour calculation.
    const inMemoryCanvas = document.createElement("canvas");
    inMemoryCanvas.width = CANVAS_WIDTH;
    inMemoryCanvas.height = CANVAS_HEIGHT;
    const inMemoryContext = inMemoryCanvas.getContext("2d");
    drawImage(inMemoryContext, img);
    if (aircraft.propeller_geom) {
      drawGeom(inMemoryContext, aircraft.propeller_geom, {
        fillStyle: "transparent",
        point: { color: "transparent" },
        outline: { color: "black" },
      });
    }
    const imageData = inMemoryContext.getImageData(
      0,
      0,
      CANVAS_WIDTH,
      CANVAS_HEIGHT
    );
    const outline = calculateOutline(imageData, contourNumber, {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
    });
    const geom = {
      type: "Polygon",
      coordinates: [outline],
    };

    setAircraftValue("geom", geom);
  };

  const onClickNose = () => {
    setGeomBeingSelected("nose");
  };

  const handleMouseDown = (evt) => {
    if (!select) {
      return;
    }
    const canvas = evt.target;
    const rect = canvas.getBoundingClientRect();
    const x = Math.round(evt.clientX - rect.left);
    const y = Math.round(evt.clientY - rect.top);
    // starts as just a point
    setShapes([
      makeRectangle([
        [x, y],
        [x, y],
      ]),
    ]);
  };

  const handleMouseMove = (evt) => {
    if (!select || !shapes.length || shapes[0].length === 0) {
      return;
    }
    const canvas = evt.target;
    const rect = canvas.getBoundingClientRect();
    const x = Math.round(evt.clientX - rect.left);
    const y = Math.round(evt.clientY - rect.top);
    setShapes([makeRectangle([shapes[0][0], [x, y]])]);
  };

  const handleMouseExit = async (evt) => {
    if (!select || !shapes.length || shapes[0].length === 0) {
      return;
    }
    const canvas = evt.target;
    const rect = canvas.getBoundingClientRect();
    const x = Math.round(evt.clientX - rect.left);
    const y = Math.round(evt.clientY - rect.top);
    const selectedRectangle = shapes[0];

    const selectedGeometry = {
      type: "GeometryCollection",
      geometries: [
        {
          type: "Polygon",
          coordinates: [selectedRectangle],
        },
      ],
    };

    if (geomBeingSelected !== "vertical_tail_fin_geom") {
      const mirroredRegion = [
        [canvas.width - x, y],
        [canvas.width - selectedRectangle[0][0], selectedRectangle[0][1]],
      ];
      selectedGeometry.geometries.push({
        type: "Polygon",
        coordinates: [makeRectangle(mirroredRegion)],
      });
    }

    let intersection;
    if (geomBeingSelected === "propeller_geom") {
      intersection = selectedGeometry;
    } else {
      const { data } = await gisIntersection(aircraft.geom, selectedGeometry);
      intersection = data;
    }

    setAircraftValue(geomBeingSelected, intersection);
    setShapes([]);
    setSelect(false);
  };

  const onClickCalculateFromReferencePoints = () => {
    //
  };
  React.useEffect(() => {
    if (
      aircraft.reference_point_vertical_tail_fin_top &&
      aircraft.reference_point_cabin_bottom
    ) {
      const fields = [
        "reference_point_wing_bottom",
        "reference_point_wing_top",
        "reference_point_tail_bottom",
        "reference_point_tail_top",
        "reference_point_vertical_tail_fin_bottom",
        "reference_point_vertical_tail_fin_top",
        "reference_point_cabin_bottom",
        "reference_point_cabin_top",
      ];
      const newValues = {};
      for (let field of fields) {
        if (aircraft[field]) {
          const h = Math.abs(
            aircraft.reference_point_vertical_tail_fin_top -
              aircraft.reference_point_cabin_bottom
          );

          const computedValue =
            (aircraft.tail_height / h) *
            Math.abs(aircraft[field] - aircraft.reference_point_cabin_bottom);
          const actualField = field.replace("reference_point_", "");
          newValues[actualField] = computedValue;
        }
        // newValues.cabin_bottom = 0;
        // newValues.cabin_top = aircraft.tail_height;
        // newValues.vertical_tail_fin_bottom = newValues.cabin_bottom;
        setAircraft({ ...aircraft, ...newValues });
      }
    }
  }, [
    aircraft?.reference_point_wing_bottom,
    aircraft?.reference_point_wing_top,
    aircraft?.reference_point_tail_bottom,
    aircraft?.reference_point_tail_top,
    aircraft?.reference_point_vertical_tail_fin_bottom,
    aircraft?.reference_point_vertical_tail_fin_top,
    aircraft?.reference_point_cabin_bottom,
    aircraft?.reference_point_cabin_top,
  ]);

  const onUploadSideImage = (base64Image) => {
    setAircraftValue("side_image", base64Image);
  };

  return (
    <Grid container spacing={3} sx={{ p: 1 }}>
      <Grid item md={3}>
        <LabelingInputForm
          aircrafts={aircrafts}
          aircraft={aircraft}
          setAircraft={setAircraft}
          setAircraftValue={setAircraftValue}
          onClickAutoOutline={onClickAutoOutline}
          onClickNose={onClickNose}
          contourNumber={contourNumber}
          setContourNumber={setContourNumber}
          select={select}
          setSelect={setSelect}
          setShapes={setShapes}
          geomBeingSelected={geomBeingSelected}
          setGeomBeingSelected={setGeomBeingSelected}
          taggableArea={taggableArea}
          onChangeTaggableArea={setTaggableArea}
          onClickCalculateFromReferencePoints={
            onClickCalculateFromReferencePoints
          }
        />
      </Grid>
      <Grid item md={9}>
        {Boolean(aircraft.linked_to) ? (
          <div />
        ) : (
          <Stack direction="column" alignItems="center">
            <Stack direction="row" alignItems="center" sx={{ height: "100%" }}>
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
                sx={{ width: "100%" }}
              >
                {Boolean(aircraft.image) ? (
                  <Stack>
                    <Canvas
                      ref={canvasRef}
                      width={CANVAS_WIDTH}
                      height={CANVAS_HEIGHT}
                      draw={draw}
                      style={{
                        border: "2px solid black",
                        backgroundColor: "#c8cfde",
                      }}
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseExit}
                      onMouseLeave={handleMouseExit}
                      onClick={(evt) => {
                        const canvas = evt.target;
                        const rect = canvas.getBoundingClientRect();
                        const x = Math.round(evt.clientX - rect.left);
                        const y = Math.round(evt.clientY - rect.top);
                        if (geomBeingSelected === "nose") {
                          setAircraftValue("nose", {
                            type: "point",
                            coordinates: [x, y],
                          });
                          setGeomBeingSelected(null);
                        }
                        if (geomBeingSelected === "midline") {
                          setAircraftValue("midline", x);
                          setGeomBeingSelected(null);
                        }
                        if (geomBeingSelected === "front_wheels") {
                          setAircraftValue("front_wheels", y);
                          setGeomBeingSelected(null);
                        }
                        if (geomBeingSelected === "rear_wheels") {
                          setAircraftValue("rear_wheels", y);
                          setGeomBeingSelected(null);
                        }
                        setSelect(false);
                      }}
                    />
                    <Button
                      size="small"
                      onClick={() => setAircraftValue("image", "")}
                    >
                      remove image
                    </Button>
                  </Stack>
                ) : (
                  <DropImage
                    label="Top View"
                    mimeType="image/png"
                    onUploadImage={(base64Image) =>
                      setAircraftValue("image", base64Image)
                    }
                  />
                )}
                {Boolean(aircraft.side_image) ? (
                  <Stack>
                    <SideViewCanvas
                      img={imgSide}
                      aircraft={aircraft}
                      onClick={(y) => {
                        setAircraftValue(taggableArea, y);
                        const nextTaggableArea = {
                          reference_point_wing_bottom:
                            "reference_point_wing_top",
                          reference_point_wing_top:
                            "reference_point_tail_bottom",
                          reference_point_tail_bottom:
                            "reference_point_tail_top",
                          reference_point_tail_top:
                            "reference_point_vertical_tail_fin_bottom",
                          reference_point_vertical_tail_fin_bottom:
                            "reference_point_vertical_tail_fin_top",
                          reference_point_vertical_tail_fin_top:
                            "reference_point_cabin_bottom",
                          reference_point_cabin_bottom:
                            "reference_point_cabin_top",
                          reference_point_cabin_top: "reference_point_wing_top",
                        };
                        setTaggableArea(nextTaggableArea[taggableArea]);
                      }}
                    />
                    <Button
                      size="small"
                      onClick={() => setAircraftValue("side_image", "")}
                    >
                      remove image
                    </Button>
                  </Stack>
                ) : (
                  <DropImage
                    label="Side View"
                    onUploadImage={onUploadSideImage}
                  />
                )}
              </Stack>
            </Stack>
            <Box sx={{ width: 600 }}>
              <SimplifiedGeom
                geom={aircraft.geom}
                simplify_pct={aircraft.simplify_pct}
              />
            </Box>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};
