import * as React from "react";
import { Icon } from "@mui/material";

export const HangarIcon = () => (
  <Icon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "currentColor",
          fillOpacity: 1,
        }}
        d="M 24.78125 12 L 7.839844 16.941406 C 7.558594 17.082031 7.351562 17.367188 7.351562 17.648438 L 7.351562 35.292969 C 7.351562 35.71875 7.632812 36 8.058594 36 L 12.292969 36 L 12.292969 24 C 12.292969 23.574219 12.574219 23.292969 13 23.292969 L 37 23.292969 C 37.425781 23.292969 37.707031 23.574219 37.707031 24 L 37.707031 36 L 41.941406 36 C 42.367188 36 42.648438 35.71875 42.648438 35.292969 L 42.648438 17.648438 C 42.648438 17.367188 42.441406 17.082031 42.160156 16.941406 L 25.21875 12 Z M 13.707031 24.707031 L 13.707031 36 L 36.292969 36 L 36.292969 24.707031 Z M 13.707031 24.707031 "
      />
    </svg>
  </Icon>
);
