import * as React from "react";
import { AutocompleteResults } from "../../../containers/AutocompleteResultsContainer";
import {
  AutocompletePosition,
  Hangar,
  Location,
  Ramp,
  Stack,
  Tenant,
} from "../../../types";
import mixpanel from "../../../utils/mixpanel";
import { RightClick } from "../Layout";
import { ClickedEntityResult } from "./useGetClickedEntity";

const spliceInTenant = (
  stack: Stack,
  movedTenant: Tenant,
  newPosition: AutocompletePosition
): Tenant[] => {
  const newTenants = [...stack.tenants];
  const newTenant = {
    ...movedTenant,
    position: {
      ...movedTenant.position,
      stack_id: stack.id,
      x: newPosition.x,
      y: newPosition.y,
      angle: newPosition.angle,
    },
  };
  if (newTenants.find((t) => t.entity_id === newTenant.entity_id)) {
    return newTenants.map((t) =>
      t.entity_id === newTenant.entity_id ? newTenant : t
    );
  }
  newTenants.push(newTenant);
  return newTenants;
};

type Props = {
  location: Location<Ramp | Hangar>;
  stack: Stack;
  setStack: (stack: Stack) => void;
  rightClick: RightClick;
  getClickedEntity: (evt: React.MouseEvent<HTMLElement>) => ClickedEntityResult;
  getIndexOfGhostAircraftHoveringOver: (
    evt: React.MouseEvent<HTMLElement>
  ) => number;
  autocompleteResults: AutocompleteResults;
  setAutocompleteResults: (results: AutocompleteResults) => void;
};

export const useOnClick = (
  {
    location,
    stack,
    setStack,
    autocompleteResults,
    setAutocompleteResults,
    rightClick,
    getClickedEntity,
    getIndexOfGhostAircraftHoveringOver,
  }: Props,
  deps: any[] = []
) => {
  return React.useCallback((evt: React.MouseEvent<HTMLElement>) => {
    if (rightClick) {
      return;
    }
    const { entity } = getClickedEntity(evt);
    if (!entity) {
      const clickedGhostPolygonIndex = getIndexOfGhostAircraftHoveringOver(evt);
      if (clickedGhostPolygonIndex > -1) {
        const tenantMoved = { ...autocompleteResults.tenant };
        if (!tenantMoved) {
          return;
        }
        const suggestedPosition = autocompleteResults.suggestedPositions.filter(
          (p) => p.hangar_id === location.id
        )[clickedGhostPolygonIndex];
        mixpanel.track("Autocomplete", {
          location_name: location.name,
          location_type: "hangar",
          suggestion_index: clickedGhostPolygonIndex,
        });

        const tenants = spliceInTenant(stack, tenantMoved, suggestedPosition);
        setStack({
          ...stack,
          tenants,
        });
        // clear the results but keep the id and orientation. prevents from running
        // another autocomplete
        setAutocompleteResults({
          ...autocompleteResults,
          hash: null,
          tenant: null,
          loading: false,
          suggestedPositions: [],
        });
      }
      return;
    }

    setStack({
      ...stack,
      tenants: stack.tenants.map((t) => ({
        ...t,
        selected: t.entity_id === entity.entity_id ? true : false,
      })),
      movableObstacles: stack.movableObstacles.map((mo) => ({
        ...mo,
        selected: mo.entity_id === entity.entity_id ? true : false,
      })),
    });

    return entity;
  }, deps);
};
