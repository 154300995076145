export const activeFeatures = () => {
  return {
    isProduction: window.location.hostname === "app.airplx.com",
    isStaging: window.location.hostname === "staging.airplx.com",
    isDev: window.location.hostname === "localhost",
    isConference: false,
    isMaintenance: false,
    maintenanceEndTime: `March 24 2023 9:15am EST`,
  };
};
