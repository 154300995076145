import * as React from "react";
import { useLocations as useLocationsHook } from "../hooks/useLocations";
import { Hangar, Location, Ramp, Tenant } from "../types";

const LocationsContainerState = React.createContext<{
  locationOverides: {
    [key: string]: Location<Ramp | Hangar>;
  };
  locations: Location<Ramp | Hangar>[];
  findTenantLocation: (
    isReference: boolean,
    tenant: Tenant
  ) => Location<Ramp | Hangar>;
  findTenantLocations: (
    tenant: Tenant
  ) => { current: Location<Ramp | Hangar>; assigned: Location<Ramp | Hangar> };
  sendToLocation: (
    location: Location<Ramp | Hangar>,
    stackName: string,
    tenant: Tenant
  ) => void;
}>({});

export const LocationsContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children, ...props }) => {
  const data = useLocationsHook();
  return (
    <LocationsContainerState.Provider value={data}>
      {children}
    </LocationsContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useLocationsState = () =>
  React.useContext(LocationsContainerState);
