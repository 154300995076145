import * as React from "react";
import { getStackPolygons } from "../../../hooks/utils";
import { Stack } from "../../../types";

type Props = {
  stack: Stack;
  width: number;
  feetToPixels: number;
  isHoldingArea?: boolean;
};

export const usePolygons = (props: Props, deps: any[] = []) => {
  const { stack, width, feetToPixels } = props;
  const polygons = React.useMemo(
    () =>
      getStackPolygons(
        stack,
        width,
        feetToPixels,
        null,
        props.isHoldingArea ?? false
      ),
    deps
  );
  return {
    polygons,
  };
};
