import * as React from "react";
import { CustomPlacementOptions } from "./CustomStackDialog";
import { formatObstacles, getOrientation } from "./HangarLayout/utils";
import { generateAutocompleteParamSets } from "./GenerateParamSets";
import { getStackPolygons } from "../../hooks/utils";
import { HOLDING_AREA_ID } from "../MultiHangar/HoldingArea";
import { Polygon, polygonInPolygon } from "geometric";
import { runAutocomplete } from "./RunStack/run";
import { useIdentity } from "../../containers/IdentityContainer";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import {
  AutocompletePosition,
  Hangar,
  ParamSet,
  Preference,
  Tenant,
} from "../../types";

const convertOrientation = (orientation: Preference): Preference => {
  switch (orientation) {
    case Preference.TAIL_WHEEL_NOSE_IN:
      return Preference.NOSE_IN;
    case Preference.TAIL_WHEEL_TAIL_IN:
      return Preference.TAIL_IN;
    default:
      return orientation;
  }
};

type Props = {
  hangar: Hangar;
  defaultTowingEquipmentId: string;
  width: number;
  customPlacementOptions: CustomPlacementOptions;
};

export const useHangarAutocomplete = (props: Props, deps: any[] = []) => {
  const { isMultiHangar, hangars: allHangars } = useMultiHangarState();
  const hangars = allHangars.filter((h) => h.id !== HOLDING_AREA_ID);
  const { airplxToken } = useIdentity();
  const {
    hangar,
    width,
    defaultTowingEquipmentId,
    customPlacementOptions,
  } = props;

  const runAutocompleteForHangar = React.useCallback(
    async (hangar: Hangar, tenant: Tenant): Promise<AutocompletePosition[]> => {
      const polygons = getStackPolygons(
        hangar.stack,
        width,
        1,
        isMultiHangar,
        hangar.id === HOLDING_AREA_ID
      );
      console.log(`running autocomplete on ${tenant.aircraft.id}`);
      const orientation = getOrientation(tenant);

      const options = isMultiHangar
        ? {
            ...customPlacementOptions,
            ...hangar.stack.options,
            towing_equipment_id: defaultTowingEquipmentId,
            sample_size: 1000,
          }
        : {
            ...hangar.stack.options,
            ...customPlacementOptions,
            towing_equipment_id: defaultTowingEquipmentId,
            sample_size: 1000,
          };

      const baseParamSetForAutocomplete: ParamSet = {
        run_id: null,
        label: "autocomplete",
        // slim down the hangar for the API call
        hangar: {
          id: hangar.id,
          width: hangar.width,
          depth: hangar.depth,
          left_door: hangar.left_door,
          right_door: hangar.right_door,
          garage_doors: hangar.garageDoors ?? [],
        },
        // slim down the tenant so it's just what we need to do the placement
        aircrafts_to_place: [
          {
            id: tenant.aircraft.id,
            placement_id: tenant.placement_id,
            angles_to_try: [],
            sectors_to_try: [],
            preferences: [convertOrientation(orientation)],
          },
        ],
        locked_aircrafts: hangar.stack.tenants
          .filter((t) => t.id != tenant.id)
          .filter((t) => {
            const { polygon } = polygons.find(
              (p) => p.entity_id === t.entity_id
            );
            const layoutPolygon: Polygon = [
              [0, 0],
              [hangar.width, 0],
              [hangar.width, hangar.depth],
              [0, hangar.depth],
              [0, 0],
            ];
            return polygonInPolygon(polygon, layoutPolygon);
          })
          .map((tenant) => ({
            id: tenant.aircraft.id,
            placement_id: tenant.placement_id,
            x: tenant.position.x,
            y: tenant.position.y,
            angle: tenant.position.angle,
          })),
        obstacles: formatObstacles(hangar),
        options,
      };
      const aircrafts = hangar.stack.tenants.map((t) => t.aircraft);
      const isTenantInHangar = hangar.stack.tenants.find(
        (t) => t.id === tenant.id
      );
      if (!isTenantInHangar) {
        aircrafts.push(tenant.aircraft);
      }
      const paramSetsForAutocomplete = generateAutocompleteParamSets(
        baseParamSetForAutocomplete,
        aircrafts
      );
      console.log(
        `[paramSetsForAutocomplete][${paramSetsForAutocomplete.length}]`,
        paramSetsForAutocomplete
      );
      const hangarForAutocomplete = {
        ...hangar,
        stack: {
          ...hangar.stack,
          tenants: isTenantInHangar
            ? hangar.stack.tenants
            : [...hangar.stack.tenants, tenant],
        },
      };
      return await runAutocomplete(
        hangarForAutocomplete,
        paramSetsForAutocomplete,
        airplxToken
      );
    },
    deps
  );

  return React.useCallback(
    async (tenant: Tenant) => {
      if (isMultiHangar) {
        const doit = await Promise.all(
          hangars.map((hangar) => runAutocompleteForHangar(hangar, tenant))
        );
        return doit.flat();
      }
      return runAutocompleteForHangar(hangar, tenant);
    },
    [deps]
  );
};
