import * as React from "react";
import { useInterval } from "react-use";
import { useApi } from "../../../../containers/ApiContainer";
import { AircraftLastUpdatedPresenter } from "./AircraftLastUpdatedPresenter";

export type ExportChecksum = {
  table_name: string;
  checksum: string;
  last_updated: string;
};

export const AircraftLastUpdated: React.FC<{}> = () => {
  const { postgrest } = useApi();
  const [exportChecksums, setExportChecksums] = React.useState<
    ExportChecksum[]
  >([]);

  React.useEffect(() => {
    (async () => {
      const result = await postgrest.rpc("export_checksum");
      console.log("result", result);
      setExportChecksums(result.data);
    })();
  }, []);

  useInterval(async () => {
    const result = await postgrest.rpc("export_checksum");
    setExportChecksums(result.data);
  }, 10 * 1000);
  return <AircraftLastUpdatedPresenter exportChecksums={exportChecksums} />;
};
