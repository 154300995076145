import AddIcon from "@mui/icons-material/Add";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import NearMeIcon from "@mui/icons-material/NearMe";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import StraightenIcon from "@mui/icons-material/Straighten";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TextureIcon from "@mui/icons-material/Texture";
import TitleIcon from "@mui/icons-material/Title";
import { Button, Divider, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useHangarsState } from "../../../../containers/HangarsStateContainer";
import { useMeasuringToolState } from "../../../../containers/MeasuringToolContainer";
import { Shape } from "./RampCanvas/RampCanvas";

type Props = {
  selectedTool: string;
  setSelectedTool: (tool: string) => void;
  shapes: Shape[];
  setShapes: (shapes: Shape[]) => void;
};

export const Toolbar: React.FC<Props> = ({
  selectedTool,
  setSelectedTool,
  shapes,
  setShapes,
}) => {
  const { hangars } = useHangarsState();
  const measuringToolState = useMeasuringToolState();
  const tools = [
    {
      name: "Select",
      icon: <NearMeIcon />,
      onClick: () => {
        console.log("Select");
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Area",
      icon: <TextureIcon />,
      onClick: () => {
        console.log("Area");
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Line",
      icon: <LinearScaleIcon />,
      onClick: () => {
        console.log("Line");
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Painted T",
      icon: <TitleIcon />,
      onClick: () => {
        setShapes([
          ...shapes,
          {
            type: "T",
            points: [
              { x: 0, y: 0 },
              { x: 100, y: 0 },
              { x: 100, y: 100 },
              { x: 0, y: 100 },
            ],
            strokeStyle: "black",
          },
        ]);
        setSelectedTool("Select");
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Circle",
      icon: <RadioButtonUncheckedIcon />,
      onClick: () => {
        setShapes([
          ...shapes,
          {
            type: "Circle",
            points: [
              { x: 0, y: 0 },
              { x: 100, y: 0 },
              { x: 100, y: 100 },
              { x: 0, y: 100 },
            ],
            strokeStyle: "black",
          },
        ]);
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Text",
      icon: <TextFieldsIcon />,
      onClick: () => {
        console.log("Text");
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Measuring",
      icon: <StraightenIcon />,
      onClick: () => {
        measuringToolState.setActive(true);
      },
    },
  ];
  return (
    <Stack direction="column" alignItems="flex-start" spacing={1}>
      <Typography variant="subtitle2">Tools</Typography>
      <Divider flexItem />
      {tools.map((tool) => (
        <Button
          startIcon={tool.icon}
          variant={selectedTool === tool.name ? "contained" : "text"}
          onClick={() => {
            setSelectedTool(tool.name);
            tool.onClick();
          }}
        >
          {tool.name}
        </Button>
      ))}
      <Typography variant="subtitle2">Hangars</Typography>
      <Divider flexItem />
      {hangars.map((hangar) => (
        <Button
          variant="text"
          onClick={() => {
            console.log(`${hangar.width} ft x ${hangar.depth} ft`);
            setShapes([
              ...shapes,
              {
                type: "Area",
                points: [
                  { x: 0, y: 0 },
                  { x: hangar.width, y: 0 },
                  { x: hangar.width, y: hangar.depth },
                  { x: 0, y: hangar.depth },
                ],
                strokeStyle: "coral",
              },
            ]);
          }}
          startIcon={<AddIcon />}
        >
          {hangar.name}
        </Button>
      ))}

      <Divider flexItem />
    </Stack>
  );
};
