import * as React from "react";
import { Box } from "@mui/material";
import { Hangar } from "../../types";
import { HangarLeftPanelWrapper } from "./HangarLeftPanelWrapper";
import { HangarStack } from "../Hangar";
import { HOLDING_AREA_ID } from "./HoldingArea";
import { LayoutToolbarWrapper } from "./LayoutToolbarWrapper";
import { LayoutWrapper } from "./LayoutWrapper";
import { MultiHangarInfoCard } from "./MultiHangarInfoCard";
import { StackStrategy } from "../Hangar/GenerateParamSets";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useAutocompleteResults } from "../../containers/AutocompleteResultsContainer";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import {
  CustomPlacementOptions,
  defaultCustomPlacementOptions,
} from "../Hangar/CustomStackDialog";

export const DIMENSION_BUFFER = 1.25;
export const MULTIHANGAR_VIEW_WIDTH = 500;
export const MULTIHANGAR_VIEW_HEIGHT = 500;

export const getMultiHangarViewDimensions = (
  hangar: Hangar,
  zoom: number,
  expanded: boolean
) => {
  // const aspectRatios = hangars.map((h) => h.width / h.depth);
  if (expanded) {
    return {
      width: 900,
      height: (900 * hangar.depth) / hangar.width,
    };
  }
  const aspectRatio = hangar.width / hangar.depth;
  if (aspectRatio > 1.75) {
    return {
      width: MULTIHANGAR_VIEW_WIDTH * zoom * 1.75,
      height: MULTIHANGAR_VIEW_HEIGHT * zoom * 1.75,
    };
  }

  return {
    width: MULTIHANGAR_VIEW_WIDTH * zoom,
    height: MULTIHANGAR_VIEW_HEIGHT * zoom,
  };
};

export const getMaxDimension = (hangars: Hangar[]): number => {
  const maxWidth = Math.max(...hangars.map((h) => h.width));
  const mathDepth = Math.max(...hangars.map((h) => h.depth));
  const maxDim = Math.max(maxWidth, mathDepth);
  return maxDim;
};

export const getFeetToPixels = (
  hangars: Hangar[],
  zoom: number,
  focus: string
): number => {
  if (Boolean(focus)) {
    return (
      (600 * zoom) /
      hangars.find((h) => h.id !== HOLDING_AREA_ID && h.id === focus).width
    );
  }
  const maxDim = getMaxDimension(
    hangars.filter((h) => h.id !== HOLDING_AREA_ID)
  );
  const benchmarkDim = maxDim * DIMENSION_BUFFER;
  const feetToPixels = (MULTIHANGAR_VIEW_WIDTH / benchmarkDim) * zoom;
  return feetToPixels;
};

export const getBoxWidth = (
  hangar: Hangar,
  zoom: number,
  expanded: boolean,
  feetToPixels: number
): number => {
  if (expanded) {
    return 600 * zoom;
  }
  return hangar.width * feetToPixels;
};

type Props = {
  feetToPixels: number;
  hangarId: string;
};

export const HangarViewWrapper: React.FC<Props> = ({
  feetToPixels,
  hangarId,
}) => {
  const {
    zoom,
    activeLocationId,
    setActiveLocationId,
    focus,
    hangars,
    getHangar,
    setHangars,
  } = useMultiHangarState();

  const hangar = getHangar(hangarId);
  const { activeFBO } = useActiveFBOs();
  const [stackStrategy, setStackStrategy] = React.useState<StackStrategy>(
    StackStrategy.normal
  );
  const [stacks, setStacks] = React.useState<HangarStack[]>([]);
  const { autocompleteResults } = useAutocompleteResults();

  // TODO: this won't work
  const [customPlacementOptions, setCustomPlacementOptions] = React.useState<
    CustomPlacementOptions
  >(defaultCustomPlacementOptions(activeFBO, null));

  // to make things simpler, we're going to pre-scale the width and height of the hangar
  // so that each hangar has the same aspect ratio
  // default that 200ft will equal 450px
  const boxWidth = getBoxWidth(hangar, zoom, focus === hangar.id, feetToPixels);

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          zIndex: 1000,
          top: 0,
          left: 72,
          display: activeLocationId === hangar.id ? "block" : "none",
        }}
      >
        {activeLocationId === hangar.id && (
          <>
            <MultiHangarInfoCard />
            <HangarLeftPanelWrapper
              hangarId={hangar.id}
              width={300}
              stacks={stacks}
              setStacks={setStacks}
              stackStrategy={stackStrategy}
              setStackStrategy={setStackStrategy}
              customPlacementOptions={customPlacementOptions}
              setCustomPlacementOptions={setCustomPlacementOptions}
              viewableHangars={hangars}
              activeHangarId={activeLocationId}
              setActiveHangarId={setActiveLocationId}
            />
          </>
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: 1000,
          top: 0,
          left: 372,
          width: "100%",
          display: activeLocationId === hangar.id ? "block" : "none",
        }}
      >
        <LayoutToolbarWrapper hangarId={hangar.id} setStacks={setStacks} />
      </Box>
      <Box
        m={0}
        p={0}
        onMouseOver={() => {
          const hangarWithEntitySelected = hangars.find((h) => {
            if (h.stack.tenants.find((t) => t.selected)) {
              return true;
            }
            if (h.stack.movableObstacles.find((mo) => mo.selected)) {
              return true;
            }
            return false;
          });
          if (!hangarWithEntitySelected) {
            return;
          }
          if (activeLocationId === hangarWithEntitySelected.id) {
            return;
          }
          // TODO
        }}
        onMouseLeave={() => {
          // TODO
        }}
        onMouseDown={() => {
          setActiveLocationId(hangar.id);
          // here we are looking to handle the case when multiple airplanes can theoretically be
          // selected in a multihangar setting. the problem is that this messes with the click event
          // inside of the layout––but only when clicking on a Ghost Aircraft in a different hangar than
          // the airplane is currently in
          return;
        }}
        onMouseUp={() => {
          // TODO
        }}
        sx={{
          position: "relative",
        }}
      >
        <Box>
          <LayoutWrapper
            hangarId={hangar.id}
            width={boxWidth}
            customPlacementOptions={customPlacementOptions}
            hangars={hangars}
          />
        </Box>
      </Box>
    </>
  );
};
