import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useApi } from "../../../containers/ApiContainer";
import { useIdentity } from "../../../containers/IdentityContainer";
import { FBOEmployee } from "../../../types";
import { UserManagementPresenter } from "./UserManagementPresenter";

export const UserManagement: React.FC<{}> = () => {
  const { postgrest } = useApi();
  const { airplxIdentity } = useIdentity();
  const { activeFBO } = useActiveFBOs();
  const [filter, setFilter] = React.useState<string>("");
  const [users, setUsers] = React.useState<FBOEmployee[]>([]);

  const fetchUsers = async () => {
    if (!activeFBO) {
      return;
    }
    const { data: users } = await postgrest
      .from("fbo_employees")
      .select()
      .eq("fbo_id", activeFBO.id);
    setUsers(users);
  };

  React.useEffect(() => {
    fetchUsers();
  }, [activeFBO.id]);

  const usersToDisplay = users.filter((t) => {
    // if the current user is not an airplx employee, filter out all @airplx.com emails
    if (!airplxIdentity?.email.toString().endsWith("@airplx.com")) {
      if (t.user_display_name.endsWith("@airplx.com")) {
        return false;
      }
    }
    return Boolean(filter)
      ? `${t.user_display_name}`
          .toLocaleLowerCase()
          .indexOf(filter.toLocaleLowerCase()) > -1
      : true;
  });

  return (
    <UserManagementPresenter
      users={usersToDisplay}
      refreshUsers={fetchUsers}
      setFilter={setFilter}
    />
  );
};
