import { Typography } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useLocationsState } from "../../containers/LocationsStateContainer";
import { Hangar, Location, Ramp, Stack, Tenant } from "../../types";
import { ConfirmationDialog } from "../../widgets/ConfirmationDialog";
import { EditTenantPresenter } from "./EditTenantPresenter";

type Props = {
  displayTypeAndLocation?: boolean;
  stack?: Stack;
  setStack?: (h: Stack) => void;
  tenant: Tenant;
  setTenant: (tenant: Tenant) => void;
  onClose: () => void;
};

export const EditTenant: React.FC<Props> = ({
  displayTypeAndLocation = false,
  stack,
  setStack,
  tenant,
  setTenant,
  onClose,
  ...props
}) => {
  const { activeFBO } = useActiveFBOs();
  const {
    locations,
    findTenantLocations,
    sendToLocation,
  } = useLocationsState();
  const [localTenant, setLocalTenant] = React.useState<Tenant>(tenant);
  const [
    localCurrentLocation,
    setLocalCurrentLocation,
  ] = React.useState<Location<Ramp | Hangar> | null>();
  const [
    localAssignedLocation,
    setLocalAssignedLocation,
  ] = React.useState<Location<Ramp | Hangar> | null>();

  React.useEffect(() => {
    const { current, assigned } = findTenantLocations(tenant);
    setLocalCurrentLocation(current);
    setLocalAssignedLocation(assigned);
  }, [tenant, locations]);

  const onSave = async () => {
    const { current, assigned } = findTenantLocations(tenant);
    if (Boolean(stack) && current?.id !== localCurrentLocation?.id) {
      setStack({
        ...stack,
        tenants: stack.tenants.filter((t) => t.id !== localTenant.id),
      });
    }
    if (Boolean(stack) && assigned?.id !== localAssignedLocation?.id) {
      setStack({
        ...stack,
        tenants: stack.tenants.filter((t) => t.id !== localTenant.id),
      });
    }
    setTenant(localTenant);
    // TODO: could this be simplified / get rid of sendToLocation?
    if (current?.id !== localCurrentLocation?.id) {
      await sendToLocation(localCurrentLocation, "current", localTenant);
    }
    if (assigned?.id !== localAssignedLocation?.id) {
      await sendToLocation(localAssignedLocation, "reference", localTenant);
    }
  };

  if (activeFBO.user_role !== "manager" && tenant.type === "base") {
    return (
      <ConfirmationDialog
        title={"Insufficient Permission"}
        text={
          <Typography variant="body1">
            Insufficient permission to modify a base tenant. Currently you have
            the role of <u>{activeFBO.user_role}</u>. You need to be an{" "}
            <u>manager</u> to modify a base tenant. Please contact your AirPlx
            admin if you think this is a mistake.
          </Typography>
        }
        onOk={function(): void {
          // nothing to do
        }}
        onClose={function(): void {
          onClose();
        }}
      />
    );
  }

  return (
    <EditTenantPresenter
      displayTypeAndLocation={displayTypeAndLocation}
      isReference={stack?.isReference ?? false}
      tenant={localTenant}
      setTenant={setLocalTenant}
      currentLocation={localCurrentLocation}
      setCurrentLocation={setLocalCurrentLocation}
      assignedLocation={localAssignedLocation}
      setAssignedLocation={setLocalAssignedLocation}
      onSave={onSave}
      onClose={onClose}
    />
  );
};
