import * as React from "react";
import { Invitation } from "../types";
import { useActiveFBOs } from "../containers/ActiveFBOContainer";
import { useApi } from "../containers/ApiContainer";
import { usePrevious } from "react-use";

export type InviteData = {
  fbo_id: string;
  email: string;
  default_role: string;
};

export const useInvitations = () => {
  const { postgrest } = useApi();
  const { activeFBO } = useActiveFBOs();
  const [invitations, setInvitations] = React.useState<Invitation[]>();
  const previousInvitations = usePrevious(invitations);

  const fetchInvitations = async () => {
    const { data: invitations } = await postgrest
      .from("invitations")
      .select()
      .eq("fbo_id", activeFBO.id)
      .eq("claimed", false);
    setInvitations(invitations ?? []);
  };

  // grab the hangar to start
  React.useEffect(() => {
    fetchInvitations();
  }, []);

  const makeInvite = React.useCallback(async (invite: InviteData): Promise<
    string
  > => {
    const { data: inviteId } = await postgrest.rpc("make_invite", invite);
    await fetchInvitations();
    return `${window.location.origin}/#/claim-invite/${inviteId}`;
  }, []);

  React.useEffect(() => {
    (async () => {
      if (!invitations || !previousInvitations) {
        return;
      }

      if (invitations.length < previousInvitations.length) {
        const invitationsToDelete = previousInvitations.filter(
          (p) => !invitations.find((i) => i.id === p.id)
        );
        await postgrest
          .from("invitations")
          .delete()
          .in(
            "id",
            invitationsToDelete.map(({ id }) => id)
          );
      }
    })();
  });

  return {
    ready: Boolean(invitations),
    invitations: invitations ?? [],
    setInvitations,
    makeInvite,
  };
};
