import { Box } from "@mui/material";
import { Position } from "geojson";
import * as React from "react";
import { useAsync } from "react-use";
import { v4 as uuidv4 } from "uuid";
import { useApi } from "../../../../containers/ApiContainer";
import { Marking, MarkingType, Ramp } from "../../../../types";
import Canvas from "../../../../widgets/useCanvas";

export type CanvasStyleOptions = {
  fillStyle?: string;
  strokeStyle?: string;
  lineWidth?: string;
};

export const traversePoints = (
  ctx: CanvasRenderingContext2D,
  points: Position[],
  style: any,
  feetToPixels: (ft: number) => number,
  closePolygon: boolean,
  readOnly: boolean
) => {
  let isFirst = true;
  for (const point of points) {
    const pointInPixels = point.map(feetToPixels);
    if (!readOnly) {
      ctx.fillRect(
        pointInPixels[0] - style.lineWidth,
        ctx.canvas.height - pointInPixels[1] - style.lineWidth,
        style.lineWidth * 2,
        style.lineWidth * 2
      );
    }
    if (isFirst) {
      ctx.moveTo(pointInPixels[0], ctx.canvas.height - pointInPixels[1]);
      isFirst = false;
    } else {
      ctx.lineTo(pointInPixels[0], ctx.canvas.height - pointInPixels[1]);
    }
  }
  if (closePolygon && points?.length) {
    const pointInPixels = points[0].map(feetToPixels);
    ctx.lineTo(pointInPixels[0], ctx.canvas.height - pointInPixels[1]);
  }
};

export const drawMarking = (
  ctx: CanvasRenderingContext2D,
  marking: Marking,
  feetToPixels: (ft: number) => number,
  readOnly: boolean,
  styleOverrides: any = {}
) => {
  ctx.beginPath();
  ctx.save();

  const style = {
    fillStyle: "magenta",
    strokeStyle: "magenta",
    lineWidth: 2,
    lineCap: null,
  };

  if (marking.type === MarkingType.OUTLINE) {
    style.fillStyle = "#3c6ce9";
    style.strokeStyle = "#3c6ce9";
    style.lineWidth = readOnly ? 2 : 2;
  } else if (marking.type === MarkingType.RED_SOLID) {
    style.strokeStyle = "red";
    style.fillStyle = "red";
    style.lineWidth = readOnly ? 2 : 2;
  } else if (marking.type === MarkingType.GREY) {
    style.strokeStyle = "grey";
    style.fillStyle = "grey";
    style.lineWidth = readOnly ? 2 : 2;
  } else if (marking.type === MarkingType.WHITE_SOLID) {
    style.strokeStyle = "#FCFAFF";
    style.fillStyle = "#FCFAFF";
    style.lineWidth = readOnly ? 2 : 2;
  } else if (marking.type === MarkingType.WHITE_DASHED) {
    style.strokeStyle = "#FCFAFF";
    style.fillStyle = "#FCFAFF";
    style.lineWidth = readOnly ? 2 : 2;
    ctx.setLineDash([10]);
  } else if (marking.type === MarkingType.YELLOW_SOLID) {
    style.strokeStyle = "#eed202";
    style.fillStyle = "#eed202";
    style.lineWidth = readOnly ? 2 : 2;
  } else if (marking.type === MarkingType.YELLOW_SOLID_CURVED) {
    style.strokeStyle = "#eed202";
    style.fillStyle = "#eed202";
    style.lineWidth = readOnly ? 2 : 2;
    style.lineCap = "round";
  } else if (marking.type === MarkingType.YELLOW_DASHED) {
    style.strokeStyle = "#eed202";
    style.fillStyle = "#eed202";
    style.lineWidth = readOnly ? 2 : 2;
    ctx.setLineDash([10]);
  } else if (marking.type === MarkingType.REFERENCE_DISTANCE) {
    style.strokeStyle = "#e3c783";
    style.fillStyle = "#e3c783";
    style.lineWidth = readOnly ? 2 : 2;
  } else {
    ctx.restore();
    return;
  }

  ctx.fillStyle = marking.selected ? "hotpink" : style.fillStyle;
  ctx.strokeStyle = marking.selected ? "hotpink" : style.strokeStyle;
  ctx.lineWidth = style.lineWidth;

  const points = marking?.geom.coordinates.flat() ?? [];

  traversePoints(
    ctx,
    points,
    style,
    feetToPixels,
    marking.type === MarkingType.OUTLINE,
    readOnly
  );
  ctx.stroke();

  if (marking.type === MarkingType.OUTLINE) {
    ctx.beginPath();
    traversePoints(ctx, points, style, feetToPixels, true, readOnly);
    ctx.fillStyle = styleOverrides.fillStyle ?? "#D3D3D360";
    ctx.fill();
  }
  ctx.restore();
};

type ReadOnlyProps = {
  width: number;
  ramp: Ramp;
  backgroundColor: string;
  showReferenceImage?: boolean;
};

export const RampCanvasReadOnly: React.FC<ReadOnlyProps> = ({
  width,
  ramp,
  backgroundColor,
  showReferenceImage = false,
}) => {
  const { postgrest } = useApi();
  const canvasRef = React.createRef<HTMLCanvasElement>();
  const pixelsToFeetRatio = (ramp?.width ?? 1) / width;
  const canvasWidth = width;
  const canvasHeight = (canvasWidth * ramp?.depth) / ramp?.width;
  const pixelsToFeet = (px: number) => pixelsToFeetRatio * px;
  const feetToPixels = (feet: number) => feet / pixelsToFeetRatio;

  const rampImage = useAsync(async () => {
    if (ramp.id && showReferenceImage) {
      const { data } = await postgrest
        .from("ramp")
        .select("reference_image")
        .eq("id", ramp.id)
        .single();
      return data.reference_image;
    }
    return null;
  }, [showReferenceImage, ramp.reference_image]);

  const draw = React.useCallback(
    (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      // draw stuff!
      if (ramp?.outline) {
        drawMarking(
          ctx,
          {
            id: uuidv4(),
            ramp_id: ramp.id,
            type: MarkingType.OUTLINE,
            geom: ramp.outline,
          },
          feetToPixels,
          true,
          { fillStyle: backgroundColor }
        );
      }

      for (const marking of ramp.markings) {
        drawMarking(ctx, marking, feetToPixels, true);
      }
    },
    [ramp, width]
  );

  return (
    <Box
      sx={{
        cursor: "inherit",
        position: "relative",
        backgroundColor: "transparent",
      }}
    >
      <Canvas
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
        draw={draw}
        style={{
          backgroundImage: showReferenceImage && `url(${rampImage?.value})`,
          backgroundSize: showReferenceImage && "cover",
        }}
      />
    </Box>
  );
};
