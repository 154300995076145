import * as React from "react";
import { useActiveFBOs } from "../containers/ActiveFBOContainer";
import { useApi } from "../containers/ApiContainer";
import { sortRamps } from "../screens/Ramps/RampsPresenter";
import { Ramp } from "../types";
import { fetchRamps } from "./api";
import useSyncToTable from "./useSyncToTable";

export const useRamps = (
  isReference: boolean,
  includeReferenceImage: boolean = true
) => {
  const { postgrest } = useApi();
  const { activeFBO } = useActiveFBOs();
  const [ready, setReady] = React.useState<boolean>(false);
  const [ramps, setRamps] = React.useState<Ramp[]>(null);

  useSyncToTable<Ramp[]>({
    thingToWatch: ramps,
    tableName: "ramp",
  });

  // grab the ramp to start
  React.useEffect(() => {
    (async () => {
      const data = (
        await fetchRamps(
          postgrest,
          activeFBO,
          isReference,
          [],
          includeReferenceImage
        )
      ).map((r) => ({
        ...r,
      }));
      setRamps(
        data.sort((a, b) =>
          sortRamps(a, b, activeFBO?.settings?.rampDisplayOrder)
        )
      );
      setReady(true);
    })();
  }, []);

  return {
    ready,
    ramps: ramps ?? [],
    setRamps,
  };
};
