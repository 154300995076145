import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useLocationsState } from "../../../containers/LocationsStateContainer";
import { useThisHangar } from "../../../hooks/useThisHangar";
import { Hangar, Location, Ramp, Tenant } from "../../../types";
import { lastHangaredText } from "../../../utils/phrasing";
import { LocationTextField } from "../../../widgets/LocationTextField";

type Props = {
  selectedTenant: Tenant;
  onAdd: (tenant: Tenant) => void;
  isReference: boolean;
  location: Location<Hangar | Ramp>;
};

export const AddSelectedTenant: React.FC<Props> = ({
  selectedTenant,
  onAdd,
  isReference,
  location,
}) => {
  const { findTenantLocation } = useLocationsState();
  const { hangar } = useThisHangar();

  const tenantLocation = findTenantLocation(isReference, selectedTenant);
  const isInThisHangar =
    tenantLocation?.location_id === location?.location_id &&
    hangar?.stack?.tenants?.some((t) => t.id === selectedTenant.id);

  const currentTenantLocation = findTenantLocation(false, selectedTenant);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Tail #</TableCell>
          <TableCell>Model</TableCell>
          <TableCell>Current Location</TableCell>
          <TableCell>Last Hangared</TableCell>
          <TableCell>Type</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{selectedTenant.tail_number}</TableCell>
          <TableCell>{selectedTenant.aircraft.model}</TableCell>
          <TableCell>
            <LocationTextField
              selectedLocationId={currentTenantLocation?.location_id}
              readOnly
              unassignedText="Not Hangared"
            />
          </TableCell>
          <TableCell>
            {lastHangaredText(
              selectedTenant.last_hangared,
              Boolean(currentTenantLocation)
            )}
          </TableCell>
          <TableCell>{selectedTenant.type}</TableCell>
          <TableCell>
            {isInThisHangar ? (
              <Typography variant="caption">In This Hangar</Typography>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  onAdd({
                    ...selectedTenant,
                  });
                }}
              >
                Add
              </Button>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
