[
  { "aircraft_id": "01649b18-177c-47cc-b162-a273a1ad4acc", "count": 70225 },
  { "aircraft_id": "031f82cc-1e24-41c6-be62-f67040fbd3f0", "count": 60025 },
  { "aircraft_id": "036e6050-9af1-483a-9406-9424a38d7214", "count": 59536 },
  { "aircraft_id": "0453caf3-203c-4461-a920-0f43a16efcee", "count": 60025 },
  { "aircraft_id": "0458638e-f9e2-4fb1-bab9-bc24ec5e5dc3", "count": 58564 },
  { "aircraft_id": "0625bc0c-7f73-4cee-836c-31f434824781", "count": 51984 },
  { "aircraft_id": "066615ff-108b-450e-82b0-5abf726cfdf7", "count": 59536 },
  { "aircraft_id": "06fe7134-97b1-4143-b7a5-5c4c6a67485f", "count": 51984 },
  { "aircraft_id": "072610c0-6f66-4763-9de1-db97fe829b72", "count": 50625 },
  { "aircraft_id": "08f76ed1-2394-4610-bbd0-3398d353e8a6", "count": 51529 },
  { "aircraft_id": "090247c4-633b-404e-bed9-bf7de06e19db", "count": 61009 },
  { "aircraft_id": "0996279e-d90e-4f24-a99b-2fa4c15ad60d", "count": 50625 },
  { "aircraft_id": "0c2dd127-fcd3-4860-b8b3-e5285b144645", "count": 51984 },
  { "aircraft_id": "0c3a395e-1441-46a2-8ec9-a9533503941a", "count": 64516 },
  { "aircraft_id": "0d4e36ae-7841-47cb-9725-7a8c994d7bca", "count": 63001 },
  { "aircraft_id": "0df9d0e6-91ff-434f-adb8-d718976ef101", "count": 50625 },
  { "aircraft_id": "0e38f34f-121a-4f90-aa6d-64aea620e4db", "count": 55696 },
  { "aircraft_id": "0e952c28-c43f-49ed-be9b-7e811cb2710d", "count": 60025 },
  { "aircraft_id": "0fa8bf52-cd44-4078-a045-234af0860a6f", "count": 58564 },
  { "aircraft_id": "105b8979-e2b9-4555-b69b-7ace775eed53", "count": 51984 },
  { "aircraft_id": "10dec469-f512-473e-88cf-0bea09afbb3f", "count": 59536 },
  { "aircraft_id": "117682b6-8fb5-4152-af50-748eb1ab4cc8", "count": 50176 },
  { "aircraft_id": "14854cfe-8757-4fcd-801c-1d9de910541c", "count": 72361 },
  { "aircraft_id": "14f31748-b6a8-4659-b36e-62d82a38921f", "count": 51984 },
  { "aircraft_id": "150c45a7-8203-4fe5-84ad-dcb341441587", "count": 52441 },
  { "aircraft_id": "15f3593c-f5d2-422c-ad7b-1ea6f72bfe1d", "count": 50625 },
  { "aircraft_id": "17834433-1f02-4f5b-a95f-81638148be73", "count": 55696 },
  { "aircraft_id": "17fb31e1-fb19-47e1-845c-83dd949a9374", "count": 56169 },
  { "aircraft_id": "1cb72af9-3229-4d31-a380-e903ca675ce8", "count": 57600 },
  { "aircraft_id": "1f5985e9-c24b-4ba6-afc1-39e6e6cbf5e8", "count": 59536 },
  { "aircraft_id": "202f607f-f02d-4aea-b881-544f4e7567e2", "count": 61009 },
  { "aircraft_id": "2050eccf-02b6-4409-a7e9-3e40df0ee788", "count": 87025 },
  { "aircraft_id": "20b5d43d-356d-4872-a23f-101169ba97e1", "count": 50625 },
  { "aircraft_id": "20f3ff21-b711-406c-a550-63157a3b9988", "count": 61504 },
  { "aircraft_id": "219e5891-19bb-42fa-981d-09523abcfa6f", "count": 51984 },
  { "aircraft_id": "223b6b88-b94c-4ba4-8ed3-5c8f88c5989f", "count": 55696 },
  { "aircraft_id": "238cdc2b-0535-46c5-b7cb-60a0b24cfd45", "count": 51529 },
  { "aircraft_id": "2453d752-669f-4cf5-80e5-834f96f8e79c", "count": 51076 },
  { "aircraft_id": "24c500d4-0f9d-4b2f-a7c2-06ee5ab2d800", "count": 90601 },
  { "aircraft_id": "250c4de6-c7c4-447c-b9b8-10d472ebc299", "count": 51076 },
  { "aircraft_id": "25dac745-be7b-4b91-a888-02de54d81afd", "count": 54756 },
  { "aircraft_id": "2649611e-62e6-4c5d-93a7-206f16bfe7d2", "count": 51076 },
  { "aircraft_id": "2915c571-a0ab-410e-8ef9-0fa24a139501", "count": 51984 },
  { "aircraft_id": "2a296bbf-4ed3-4a41-bf10-475010072d17", "count": 56169 },
  { "aircraft_id": "2ad811aa-06d4-4990-9894-90f28e727681", "count": 51984 },
  { "aircraft_id": "2b818953-bf35-43b3-be41-867c434d2d3a", "count": 50176 },
  { "aircraft_id": "2c98383c-c690-49a4-86cd-f6dffcf1ec81", "count": 52900 },
  { "aircraft_id": "2e754d74-adbc-4306-afa6-b362d2ef51d2", "count": 50176 },
  { "aircraft_id": "306d0c30-8c0d-4e5f-bc57-8bcfa9e26a01", "count": 52441 },
  { "aircraft_id": "309bd15a-0747-42c2-b9cd-43277467620a", "count": 70225 },
  { "aircraft_id": "30d7864f-5597-429e-bf0c-1bee18056c30", "count": 51984 },
  { "aircraft_id": "319fb7ce-ea49-4518-8bc9-9ad626a91873", "count": 59536 },
  { "aircraft_id": "32c2ddc6-ae7a-4beb-83ae-919b4eb7610f", "count": 53824 },
  { "aircraft_id": "3385b4db-e41d-47d6-a078-20d75dc5bb7a", "count": 51076 },
  { "aircraft_id": "34a9120e-018d-450b-8523-f3806d9fe6a8", "count": 49729 },
  { "aircraft_id": "361c7b92-e305-4c25-be37-1b0571d2ee57", "count": 87025 },
  { "aircraft_id": "365749cf-8851-44f3-a125-e62b34492660", "count": 59536 },
  { "aircraft_id": "36fe3fd8-ac79-47ad-a3a4-cf54e11a54e4", "count": 50625 },
  { "aircraft_id": "370c4aed-b63d-4fba-8a5d-673df531f4fa", "count": 70225 },
  { "aircraft_id": "3729432b-90ee-45ca-b696-914c9111bd37", "count": 90601 },
  { "aircraft_id": "3846a66c-fbed-48f1-824d-d4bab2056374", "count": 50625 },
  { "aircraft_id": "38bce97f-13e4-4e63-b0a4-ae4b8ad00d39", "count": 68121 },
  { "aircraft_id": "38f43cd8-8831-40a6-9376-c56dd629926e", "count": 63504 },
  { "aircraft_id": "39fd2605-b5d0-4ce7-af7f-27ec1fe5e4ce", "count": 50625 },
  { "aircraft_id": "3aa5155e-404f-4714-9892-cdf6b42b6cbc", "count": 55225 },
  { "aircraft_id": "3abc2e30-245a-4520-898f-027e12317cae", "count": 69169 },
  { "aircraft_id": "3ac052b7-f108-4463-bac0-dcc07a7b2a37", "count": 59536 },
  { "aircraft_id": "3af87609-6518-4016-8dbd-a3edbaf07d6e", "count": 48841 },
  { "aircraft_id": "3bda19aa-d700-4d73-973b-345e43226e3d", "count": 55696 },
  { "aircraft_id": "3ef55d15-566b-4a40-b6f7-8442546f80b1", "count": 52900 },
  { "aircraft_id": "3f0e9057-bc2d-4543-8b18-13e7f683c62d", "count": 61504 },
  { "aircraft_id": "3fd089ac-060a-4162-96d1-3af3904328be", "count": 52441 },
  { "aircraft_id": "3ff7dba3-857e-4d87-8658-5364c241d1a4", "count": 59536 },
  { "aircraft_id": "42f80e46-695b-4cb4-b4d7-5cd9a2027488", "count": 54289 },
  { "aircraft_id": "447f13b4-0d42-4457-a78a-4eefab522cc8", "count": 72361 },
  { "aircraft_id": "44f386b6-bdb4-4116-92a2-16c1cb812e2a", "count": 60025 },
  { "aircraft_id": "45003eee-f39a-42b3-98a0-2e1f7522083a", "count": 59049 },
  { "aircraft_id": "450da9ed-b52a-462d-983f-449be300365a", "count": 57121 },
  { "aircraft_id": "453fdfc2-a79b-458e-aa0f-df454a1cbc6e", "count": 61504 },
  { "aircraft_id": "459d565a-f0b0-4f09-ac22-dc230a31d076", "count": 53361 },
  { "aircraft_id": "45d3e0d3-e842-4495-be9a-1bcfa2a607a3", "count": 51984 },
  { "aircraft_id": "46baa078-5110-4b2d-a2c3-8dfe685a76e6", "count": 50625 },
  { "aircraft_id": "491781a8-8101-4e81-aa71-53b5b3a3d8ab", "count": 55696 },
  { "aircraft_id": "492af825-45af-498a-bb2b-b93c6e2edb86", "count": 53361 },
  { "aircraft_id": "496c8d4e-9d75-4898-8ead-e8f22369c76f", "count": 54756 },
  { "aircraft_id": "49c086b5-1398-4699-a806-60104f34fc0a", "count": 55696 },
  { "aircraft_id": "4bd6960a-9391-413b-a441-c8003aebcd96", "count": 64516 },
  { "aircraft_id": "4c9621b6-c6ed-4ebd-82a3-7eb03375d7b0", "count": 65025 },
  { "aircraft_id": "4cb6cd7a-6a18-4074-845f-3212730b0ab5", "count": 52441 },
  { "aircraft_id": "4d78018f-6747-43df-bca8-23d08446aa10", "count": 77284 },
  { "aircraft_id": "4d913ce5-8bd3-4b1d-b58b-bddf9e42d73d", "count": 70756 },
  { "aircraft_id": "4dc85875-da40-4414-b370-d6c8bfef260a", "count": 72361 },
  { "aircraft_id": "4e49c227-0f11-4f13-966d-1f7847339ba1", "count": 61009 },
  { "aircraft_id": "4e5d2123-7b24-422c-8c9d-c620223caff8", "count": 51984 },
  { "aircraft_id": "4ebd43f5-3c79-4e48-8d71-cddee8bd53b6", "count": 72900 },
  { "aircraft_id": "5020f97e-23ca-4e58-aacc-d4c0a520620b", "count": 76729 },
  { "aircraft_id": "53264e27-3c3c-415e-80d7-9cc1a274a2d8", "count": 57600 },
  { "aircraft_id": "5344e2f3-3051-49e5-af40-a16e8d992949", "count": 50176 },
  { "aircraft_id": "54bef36c-5322-43dc-87c2-4a1bcb54d210", "count": 51076 },
  { "aircraft_id": "54cd260a-12b4-4744-86bf-dfcca559da94", "count": 70756 },
  { "aircraft_id": "55b59322-cac2-4400-b457-0943ccd857a9", "count": 55225 },
  { "aircraft_id": "56a2c098-67e1-44bc-af87-47dd383d5bb7", "count": 47961 },
  { "aircraft_id": "5744450a-0578-405f-8e12-8baee530131a", "count": 63504 },
  { "aircraft_id": "5794419c-3c26-481f-8ce7-3a454e2358ec", "count": 63504 },
  { "aircraft_id": "5954fcad-369b-4352-93d4-772e32725239", "count": 58081 },
  { "aircraft_id": "599a491b-4dc5-4b03-b4e9-ce7f10a638c1", "count": 54289 },
  { "aircraft_id": "5be6d8f1-60ed-47f2-8c86-f2b7102d2575", "count": 53824 },
  { "aircraft_id": "5c20225d-bf42-4aa3-8a54-8a4aa6612a0a", "count": 61504 },
  { "aircraft_id": "5c405c26-68bf-48c9-a8f6-6559a2dd6b7e", "count": 55696 },
  { "aircraft_id": "5d3cefa7-781e-4547-9386-de5495dd5765", "count": 69696 },
  { "aircraft_id": "5ee9026f-a77b-4863-b482-784bffa14ded", "count": 51984 },
  { "aircraft_id": "5f4ef5cf-65e8-4669-8ef2-b9812649f1e8", "count": 68644 },
  { "aircraft_id": "60511826-267a-4769-9861-1b2f26b7f221", "count": 50625 },
  { "aircraft_id": "61f9628d-b615-4cce-a7e0-09a1bf20e35d", "count": 87025 },
  { "aircraft_id": "631d9e33-7246-4bd5-beaf-61d3bfcf6934", "count": 59049 },
  { "aircraft_id": "63b600e1-a52e-4d86-9b6d-db747c6485cb", "count": 63504 },
  { "aircraft_id": "6464e763-7f1c-4e20-9537-181723120c5c", "count": 78961 },
  { "aircraft_id": "67f0c702-834a-4bda-8763-91eb9c52187b", "count": 49729 },
  { "aircraft_id": "69775337-6d29-473f-a541-ef7e757c2b43", "count": 90601 },
  { "aircraft_id": "6afe3528-b3a1-46b6-a792-d37dca797f29", "count": 49729 },
  { "aircraft_id": "6b2e2c3d-a2b4-453f-8640-224934918366", "count": 55225 },
  { "aircraft_id": "6c034d22-dd68-4e31-8618-94a09519058c", "count": 63504 },
  { "aircraft_id": "6c136d15-53f0-4057-bb81-0261a359db4d", "count": 59536 },
  { "aircraft_id": "6c25df3b-ff7c-44c1-a3d3-b2e6e49e0c01", "count": 51076 },
  { "aircraft_id": "6c4851c2-2357-4b57-8623-96c936603e12", "count": 49729 },
  { "aircraft_id": "6ca980ca-2dc9-47f3-94d6-ac63d08dcd0e", "count": 50625 },
  { "aircraft_id": "6cd002ed-cd7c-46f0-acb1-5a1f139ffc2c", "count": 65536 },
  { "aircraft_id": "6d80b076-4a44-4901-b026-701f50596e85", "count": 59049 },
  { "aircraft_id": "6e1b0368-d95b-417d-815d-c4dc99ca7fcb", "count": 49729 },
  { "aircraft_id": "6e253bb2-d1c8-4c82-a3b5-ad8794115dc5", "count": 57600 },
  { "aircraft_id": "6eec0f5f-2bab-4da9-b0b8-8f70dd948942", "count": 52441 },
  { "aircraft_id": "6f1f2c3b-66de-4568-8f87-868b699ebba3", "count": 49284 },
  { "aircraft_id": "6f24288d-3c48-4bad-bf19-b5ee6ee179d1", "count": 51529 },
  { "aircraft_id": "6fccce55-34d0-4b5c-b816-a7c133cc9f1c", "count": 48841 },
  { "aircraft_id": "6fe23d61-1367-4df9-b0ee-9faa8b5e18da", "count": 51984 },
  { "aircraft_id": "6fe731f2-86c1-4c94-bec2-2370a613ff3b", "count": 51984 },
  { "aircraft_id": "700b358c-b65e-436d-8ce5-4f2410a127a5", "count": 65025 },
  { "aircraft_id": "709ef6f9-0e8c-4540-8c41-3f827589b5e1", "count": 54289 },
  { "aircraft_id": "711fd2ff-cffe-4834-b811-850834897c8b", "count": 55225 },
  { "aircraft_id": "72a49887-db6f-45d4-81d8-9316c9d66936", "count": 87025 },
  { "aircraft_id": "72c759c6-ec50-49a5-8352-9afd649ab2c6", "count": 52441 },
  { "aircraft_id": "76658409-8fff-4d22-a344-7b00d3ef189c", "count": 51076 },
  { "aircraft_id": "772850a4-0c17-4ac0-a1c0-c0f6042cfbfc", "count": 55696 },
  { "aircraft_id": "776a03ac-4a12-4dff-972b-8cf1665fe7b7", "count": 56169 },
  { "aircraft_id": "779f31c3-ae43-4a1a-93c3-1f51faaf345b", "count": 70225 },
  { "aircraft_id": "78342823-7db8-43f7-8887-e58e9074ee10", "count": 51076 },
  { "aircraft_id": "791e86c9-2d71-40cc-b382-d0094670a16c", "count": 51529 },
  { "aircraft_id": "7929fcc0-cc46-4f83-8858-31e852bd5602", "count": 72900 },
  { "aircraft_id": "7a5f5203-9377-4754-a02f-dc0ccf6bc3be", "count": 77284 },
  { "aircraft_id": "7be78379-38d5-4285-9af1-a5d5c40d2165", "count": 56169 },
  { "aircraft_id": "7d2ee735-e1aa-4a68-ad18-321ab293191a", "count": 65025 },
  { "aircraft_id": "7d975ecb-6287-4138-88a3-f90169df4dd6", "count": 63001 },
  { "aircraft_id": "7e95d386-985f-4ae4-add2-78fcf9465ad2", "count": 50176 },
  { "aircraft_id": "7ea21055-1677-4b53-ad70-d241421c5387", "count": 43264 },
  { "aircraft_id": "7fbb172b-b770-490c-a188-b8842ba3b88a", "count": 51984 },
  { "aircraft_id": "800404a8-0aed-4d99-b25b-5269011f8a52", "count": 77284 },
  { "aircraft_id": "805f48d4-f267-458a-9ee1-0a0769d05e17", "count": 64516 },
  { "aircraft_id": "80ea3d07-6f8b-4900-9ee0-6aee29ad3158", "count": 57121 },
  { "aircraft_id": "80fa4b2b-68b6-4f6b-a4b1-cb09bdb01490", "count": 52441 },
  { "aircraft_id": "80fc1aae-22c4-4422-b2fa-df98453e17f4", "count": 50176 },
  { "aircraft_id": "81624059-eee6-46dd-908f-da1d5f979719", "count": 65536 },
  { "aircraft_id": "81be48a2-d52a-4bfd-a2ef-632b0f86327d", "count": 55225 },
  { "aircraft_id": "8257dd9c-a533-4289-aedf-f7947495120f", "count": 77284 },
  { "aircraft_id": "8341a5d9-bd32-4b9c-804c-09446666bd10", "count": 66564 },
  { "aircraft_id": "83f9918a-ef41-4545-b671-b69b9260e420", "count": 51529 },
  { "aircraft_id": "841f4d13-8a38-4120-912b-60f236759d59", "count": 57600 },
  { "aircraft_id": "849c2ffe-050f-43d5-b2b8-c789c7c41314", "count": 55696 },
  { "aircraft_id": "84afec76-6fb6-48f5-a546-dc883f133a22", "count": 50625 },
  { "aircraft_id": "850d7f7b-acf7-4edf-b3b5-46bf4b822b5e", "count": 77284 },
  { "aircraft_id": "856d4c72-7115-49d6-b157-1785a2221bb9", "count": 51076 },
  { "aircraft_id": "88505ec9-85bb-46ce-a0c4-320637740403", "count": 69696 },
  { "aircraft_id": "89639c8b-50fb-4e15-b4ca-35e994360b39", "count": 69696 },
  { "aircraft_id": "89a7aa2f-9822-4353-8c15-07c8f0fc7ed5", "count": 52900 },
  { "aircraft_id": "8ba456a0-3058-4be1-993c-337a654cb2dd", "count": 57600 },
  { "aircraft_id": "8c5f781d-ceae-4cb5-9e85-dfdb83190953", "count": 51984 },
  { "aircraft_id": "8cd777ac-14ad-419a-aa33-f70b05a9bcd1", "count": 49284 },
  { "aircraft_id": "8cec5b73-c898-4641-982a-585f0756c94a", "count": 54289 },
  { "aircraft_id": "8d4abc40-c29d-4588-864e-07e4f4c6242e", "count": 51076 },
  { "aircraft_id": "8d4e5c3a-2c3e-435c-bdf1-619b6b84a61b", "count": 51076 },
  { "aircraft_id": "8d53d129-63a3-4fec-882e-dc255247aa68", "count": 86436 },
  { "aircraft_id": "8e0183ae-35b8-4ce1-8358-675b321228e3", "count": 51529 },
  { "aircraft_id": "8e1f0f2f-6213-40db-992e-1a30e82825ba", "count": 53824 },
  { "aircraft_id": "8ee4c266-a2df-4608-a0d3-4c3a71f8b615", "count": 59536 },
  { "aircraft_id": "8f89648a-af3f-4a19-9018-31f01e0ce5b6", "count": 51076 },
  { "aircraft_id": "8f95d990-4cdd-4947-86c2-15f6d043a4fd", "count": 55696 },
  { "aircraft_id": "8fdc7915-7f39-4eb8-86d1-48b4ecca3023", "count": 50176 },
  { "aircraft_id": "90d3ab9c-f0e3-4ec0-87a6-1ac70d66c725", "count": 51984 },
  { "aircraft_id": "9230794f-3ebc-4c84-84ae-ed0daae9d86f", "count": 63001 },
  { "aircraft_id": "92892a51-e413-4735-8f4e-9b3e2a1c33c6", "count": 90000 },
  { "aircraft_id": "92d26139-ff7c-44d2-98b0-6b282bbf84d9", "count": 55225 },
  { "aircraft_id": "92fea6c9-ed7e-4e16-9e7b-38247f65bb96", "count": 50625 },
  { "aircraft_id": "93051cf9-9ce3-4ced-9726-731ca130debc", "count": 87025 },
  { "aircraft_id": "930be7d3-ea98-4eee-9f72-1cc155090f1a", "count": 59536 },
  { "aircraft_id": "9485d7b9-3eb3-49d7-90de-8b42fbb67f2e", "count": 50176 },
  { "aircraft_id": "94d2c94c-f976-42d4-b74a-bc3a7a3558ed", "count": 71289 },
  { "aircraft_id": "959d3100-a75d-4476-ae84-368b5ac97e8f", "count": 50625 },
  { "aircraft_id": "96bca297-d888-4f60-adab-9757fa0b7966", "count": 49729 },
  { "aircraft_id": "96f2403a-70d1-4c20-8022-93ae5106d25c", "count": 58081 },
  { "aircraft_id": "97044e19-aaa1-479a-8fa2-da07880e91ab", "count": 54289 },
  { "aircraft_id": "970be9a5-300f-4088-8dff-133791e388b9", "count": 55225 },
  { "aircraft_id": "98203742-cb8b-49ca-a884-5840afd8c390", "count": 52441 },
  { "aircraft_id": "9a5a3567-9bc6-44c7-8c52-bf14c001163a", "count": 50625 },
  { "aircraft_id": "9a9804ac-99d3-41bb-9040-1615933a78f6", "count": 59536 },
  { "aircraft_id": "9ac7ac7b-c8a8-402b-832f-8fa7a0f67a70", "count": 56169 },
  { "aircraft_id": "9b68e9e4-ae25-4a8f-9061-493dba335c6c", "count": 52900 },
  { "aircraft_id": "9c324784-53cc-4fa3-bbab-b7a49c5a0ce6", "count": 51984 },
  { "aircraft_id": "9d78caad-6f55-4e4b-8f8f-496c65f9d380", "count": 50625 },
  { "aircraft_id": "9d8c825f-bf86-4cd4-bc18-3348d3e90854", "count": 56169 },
  { "aircraft_id": "9dfc4690-05a1-462a-968a-c6383629f304", "count": 53361 },
  { "aircraft_id": "9e1fc90b-4a97-429f-9169-4f0c4830d156", "count": 61504 },
  { "aircraft_id": "9ef1a202-15a5-473e-8479-2b37a77948d3", "count": 49729 },
  { "aircraft_id": "9fa9ec6f-8f26-479e-b0da-0f90936c2176", "count": 87025 },
  { "aircraft_id": "9fc355c7-6652-48cf-bec6-2b2956af07a4", "count": 56169 },
  { "aircraft_id": "a002ba9b-8f84-428e-b97c-a4f4ffab1286", "count": 55225 },
  { "aircraft_id": "a0a49b54-3dd7-4876-8c01-b2f768e771b0", "count": 77284 },
  { "aircraft_id": "a1a3478e-b017-437a-a754-8b6e7c34a9d3", "count": 69696 },
  { "aircraft_id": "a279da59-f2b7-4962-a00d-94169c1fa5ad", "count": 52441 },
  { "aircraft_id": "a318d93c-9b01-4875-9505-b9ac74bac148", "count": 64009 },
  { "aircraft_id": "a4f08d21-1410-4c4c-b495-8dabbe178dac", "count": 52441 },
  { "aircraft_id": "a500ac24-8e55-46b3-9614-4890da85839a", "count": 49284 },
  { "aircraft_id": "a66282db-b6a9-47b6-92dc-4aca01b7f5cc", "count": 50625 },
  { "aircraft_id": "a66530e5-6525-4d27-8604-77721dcba26d", "count": 50625 },
  { "aircraft_id": "a76f5ef2-beb2-4d89-9984-428538bad60e", "count": 51529 },
  { "aircraft_id": "a7a563a5-8ce0-42ab-886e-ff9fd15ef76a", "count": 52441 },
  { "aircraft_id": "a86bc346-2f64-4f9d-911e-5bd22d1e3c0e", "count": 82369 },
  { "aircraft_id": "a881f195-07da-41d8-80ad-abe78ce7431d", "count": 65025 },
  { "aircraft_id": "a8c79801-a3fa-4aa1-936f-ef1292c2c3d3", "count": 55696 },
  { "aircraft_id": "a92da450-4d72-4940-b9a1-6ff1d7c720f7", "count": 61009 },
  { "aircraft_id": "aa0a2a14-1c71-483f-a541-4c8ed4af8ef9", "count": 60025 },
  { "aircraft_id": "aa0ab598-f41f-4602-9a79-1739d83721b5", "count": 50176 },
  { "aircraft_id": "ab130036-5e4d-424a-aadb-7aa199f4387c", "count": 54756 },
  { "aircraft_id": "aba3b4b1-2bdc-414d-990d-7e447e5532a4", "count": 70225 },
  { "aircraft_id": "abd1366f-879f-4405-ad0b-30fe2e3ca56a", "count": 51984 },
  { "aircraft_id": "abdfc7c9-1cbd-446f-8697-4ed8bfd3811c", "count": 63001 },
  { "aircraft_id": "abe908c1-cb6d-4dde-8bb5-1e9c77e2e70f", "count": 52900 },
  { "aircraft_id": "ad57073e-901d-41b3-b21e-0e2ab06c43e1", "count": 54756 },
  { "aircraft_id": "adf4075f-a14c-45e3-88a4-fdb272103450", "count": 49284 },
  { "aircraft_id": "aed4d605-d11f-47d6-80de-061b05fed180", "count": 52441 },
  { "aircraft_id": "b02527fb-1f12-4dfe-a523-6432346088db", "count": 50625 },
  { "aircraft_id": "b2cd0c3d-4dee-44bf-971a-e1595d796c8c", "count": 51984 },
  { "aircraft_id": "b2e05e2b-5a5b-49f0-a418-70258a65430b", "count": 51076 },
  { "aircraft_id": "b31e1a07-b6e1-4d9a-8158-187d91e5b839", "count": 69696 },
  { "aircraft_id": "b35aa43d-5461-4aa4-85a5-8c5e5038ef03", "count": 82369 },
  { "aircraft_id": "b384e0bd-7dd1-4c70-b6b1-e902420eb402", "count": 50176 },
  { "aircraft_id": "b3859b31-eafd-4cf8-8eb6-0f53d19e03bc", "count": 51984 },
  { "aircraft_id": "b3b07b4b-dca3-4032-b86a-d17b97247d14", "count": 50625 },
  { "aircraft_id": "b3c44513-2578-4991-8682-2f8f26fa3aa1", "count": 72361 },
  { "aircraft_id": "b3eff267-b1b5-47a5-ab95-64e92068f586", "count": 49729 },
  { "aircraft_id": "b4caa9b8-088f-4011-87b7-3408ebe606be", "count": 62001 },
  { "aircraft_id": "b5b5d633-9ebb-45d6-900d-70eac1ae67cf", "count": 64009 },
  { "aircraft_id": "b624231b-eeb9-4f6c-a217-75415703c1e5", "count": 61009 },
  { "aircraft_id": "b63e7b52-e619-44b5-b4d6-d47004d72fcf", "count": 54289 },
  { "aircraft_id": "b850c4fa-8b01-4951-ae89-b8eda85617aa", "count": 54289 },
  { "aircraft_id": "b88c03e5-9c49-453e-8cce-40247298ed45", "count": 55696 },
  { "aircraft_id": "b8cde0a5-7725-49f0-aef4-2179edcf93fb", "count": 51984 },
  { "aircraft_id": "b97e80d3-e310-486d-ad51-824f89aa0a5b", "count": 52441 },
  { "aircraft_id": "b9b6fdba-d66d-4f2d-aae4-bf5094618d1d", "count": 51984 },
  { "aircraft_id": "bc54f069-59e4-42e5-82ca-e43c24b98814", "count": 59049 },
  { "aircraft_id": "bce730af-1ba1-45f1-bf1c-2d5737d96a31", "count": 61504 },
  { "aircraft_id": "bdd2a2fa-0473-4886-8a6b-f7876d221c95", "count": 59049 },
  { "aircraft_id": "be05d674-26e6-474c-8f53-53c158de2e53", "count": 65536 },
  { "aircraft_id": "be1defcf-6ea3-42e2-9193-c64472f1c612", "count": 65025 },
  { "aircraft_id": "be3000d9-5b61-441c-9483-3d528a87e9e9", "count": 58081 },
  { "aircraft_id": "be6f5407-e0c0-4fe5-b83d-2a61f9fd8698", "count": 50176 },
  { "aircraft_id": "beadc09f-0994-4292-9392-ba0b0d55930f", "count": 49729 },
  { "aircraft_id": "bed3b163-d8eb-4133-bc5e-3d3ca0c52c88", "count": 50625 },
  { "aircraft_id": "bfea67ef-1ec9-468a-9825-2561c92a4f16", "count": 59049 },
  { "aircraft_id": "c07e4dbe-7022-4f43-a937-79ca40d977ef", "count": 90601 },
  { "aircraft_id": "c0c606af-e736-4c98-ba1d-dcb067241580", "count": 59049 },
  { "aircraft_id": "c13e3a43-7524-4462-9a14-0e020cc546f4", "count": 50176 },
  { "aircraft_id": "c1d60079-9f9a-4367-b2c7-4dd470e554b4", "count": 86436 },
  { "aircraft_id": "c2ddcb88-9dbd-4c53-a2d2-db57c04a4552", "count": 66564 },
  { "aircraft_id": "c3d7ddf2-23ec-42e0-b22c-47a32e8b19fd", "count": 58081 },
  { "aircraft_id": "c4526310-0ec6-45c8-b539-7a34f14b90b8", "count": 59536 },
  { "aircraft_id": "c463641b-f21e-42b4-bdc7-f744f2264f77", "count": 55225 },
  { "aircraft_id": "c487fb9a-1769-43cc-a138-eee977db0bec", "count": 56169 },
  { "aircraft_id": "c798146b-7aeb-4403-bc82-9810b062ab56", "count": 81225 },
  { "aircraft_id": "c90846ca-07ac-4167-9269-52becf95b86c", "count": 51984 },
  { "aircraft_id": "c9544942-851d-44d8-b03f-ddb575ce7872", "count": 54289 },
  { "aircraft_id": "c9726303-75df-4748-81d7-54386e9ebd00", "count": 71289 },
  { "aircraft_id": "cb63c0ec-249d-4a95-9553-c5831a94c00c", "count": 52441 },
  { "aircraft_id": "cbbfdf04-260c-4598-8566-e45aabb95f3a", "count": 57600 },
  { "aircraft_id": "cbc6f2c0-712e-4be5-9fd1-a349bfc4aa3b", "count": 51984 },
  { "aircraft_id": "cca143ae-6243-4f56-8c5e-c0fea98a0c72", "count": 51984 },
  { "aircraft_id": "ccedfe1d-0be9-4e72-a909-db245865a502", "count": 56169 },
  { "aircraft_id": "ccf8243f-5540-48c3-9204-e011ff1992e0", "count": 52441 },
  { "aircraft_id": "ce1fd647-c43f-42c1-aa0e-66010e5a6f0c", "count": 49284 },
  { "aircraft_id": "ce23fd7f-2d32-4bf1-86c6-bc57d97d1ef5", "count": 49729 },
  { "aircraft_id": "ce5d59b8-9486-4881-a75b-f222f5b9794f", "count": 51984 },
  { "aircraft_id": "ced5f5cf-2441-4f86-8efb-6dc05926e070", "count": 50625 },
  { "aircraft_id": "cf515db4-f575-4529-a721-2095e30493f3", "count": 51984 },
  { "aircraft_id": "d1fafb27-18c9-4094-9d26-ccd702ab5089", "count": 69696 },
  { "aircraft_id": "d3e69994-9ef9-41e5-af97-395c3275aa29", "count": 59536 },
  { "aircraft_id": "d4ba2506-383c-47b6-8873-2ec0727e716f", "count": 51984 },
  { "aircraft_id": "d5d79a52-e86d-43e7-b077-29441fd56f0c", "count": 50176 },
  { "aircraft_id": "d84ff575-f949-47bb-a840-ade846b048b5", "count": 50625 },
  { "aircraft_id": "d92908dc-7196-428a-a47b-b88f5248d927", "count": 50625 },
  { "aircraft_id": "d93a4a6b-1c9f-4833-bb4f-f0bcef1eddb6", "count": 49284 },
  { "aircraft_id": "d94166a7-9513-4df1-b305-e0fd76ac6391", "count": 69696 },
  { "aircraft_id": "d94d7bb6-a4ea-4a68-aab9-5f2b0a984d80", "count": 53361 },
  { "aircraft_id": "d968e01d-d36b-4397-976c-ed6108b48ba6", "count": 54756 },
  { "aircraft_id": "da9b04b8-910d-4710-8af0-0871c0c0151d", "count": 58081 },
  { "aircraft_id": "daeed3e3-507f-4f37-aafd-cfe547b45bdb", "count": 51984 },
  { "aircraft_id": "daf6d557-a414-472d-b304-726ed9f06c4c", "count": 60025 },
  { "aircraft_id": "dbb1e18f-5e6b-449b-ab99-937475bfbe0e", "count": 48841 },
  { "aircraft_id": "dbc6a417-b15e-4587-93f5-e02580c03085", "count": 54756 },
  { "aircraft_id": "dcbd7da4-125c-4fa2-9d68-2a8edda7f98f", "count": 50625 },
  { "aircraft_id": "de4411db-5b3e-4e4c-a16a-c3df5e1dcc5a", "count": 50176 },
  { "aircraft_id": "decc03ac-4eee-4574-a9ea-9194953e4284", "count": 55696 },
  { "aircraft_id": "df7df0f4-fe61-4312-8aed-799b4e20c50f", "count": 51076 },
  { "aircraft_id": "e035cd93-7344-469a-b3cb-aa0ce28a94d9", "count": 67081 },
  { "aircraft_id": "e09d7055-6b4d-476d-bc8e-f9c09b75263e", "count": 55225 },
  { "aircraft_id": "e1f779e8-a7c9-4af3-8d3a-cfbef81410fc", "count": 42849 },
  { "aircraft_id": "e213f72b-1bd7-465d-98b9-06e9210b10e8", "count": 51076 },
  { "aircraft_id": "e246fe58-2ec4-415d-81bc-985371db3fe9", "count": 61009 },
  { "aircraft_id": "e3295b26-810a-427d-ba00-6e1fdeef9dc2", "count": 55696 },
  { "aircraft_id": "e3607a43-0077-4fc8-8d43-8aba011577de", "count": 56169 },
  { "aircraft_id": "e3a52329-7b05-4278-91e8-1a109200fb9a", "count": 55696 },
  { "aircraft_id": "e440cb80-a043-46ec-9704-8c9715cef50b", "count": 54756 },
  { "aircraft_id": "e45b7e9c-4e82-4c72-85e9-6e17d4531002", "count": 52900 },
  { "aircraft_id": "e510949d-63d4-44a8-9b89-2725956eb42a", "count": 61504 },
  { "aircraft_id": "e5b50efa-325e-4f3e-9371-57bd762164d4", "count": 52900 },
  { "aircraft_id": "e637fab5-111b-4049-91f2-642bae8cdb5e", "count": 51529 },
  { "aircraft_id": "e7f5f4ec-d9e3-4227-94f6-1a102b7a44a3", "count": 53824 },
  { "aircraft_id": "e97dc27d-de22-415b-ba74-8be5210e4d17", "count": 55696 },
  { "aircraft_id": "ea9582f1-31c1-4878-b933-3c03b467b542", "count": 52900 },
  { "aircraft_id": "eab35a1d-29dd-4bc7-98ea-6d67c9c2bccc", "count": 61504 },
  { "aircraft_id": "eb48871b-fb37-4f2d-9442-27f83c523822", "count": 51076 },
  { "aircraft_id": "ebc39fa4-d828-4eaa-ba3a-cf1eb8ad0e7d", "count": 67081 },
  { "aircraft_id": "ec9b6164-89ad-4e05-9548-7c0beca29e8d", "count": 64516 },
  { "aircraft_id": "ed59f5ae-5305-4efc-a33e-09137c40b130", "count": 69696 },
  { "aircraft_id": "ed73b06c-80f4-4c07-b8c8-493ef6ddfe37", "count": 51076 },
  { "aircraft_id": "edd82e36-533a-4fa0-b188-2cfb5c002a62", "count": 77284 },
  { "aircraft_id": "ee64353c-dcee-44be-8e37-a2ca6708ba5a", "count": 55225 },
  { "aircraft_id": "eee90118-8379-45c5-8f6d-eb8d3b296d34", "count": 50625 },
  { "aircraft_id": "eefbdda4-2a28-4da6-9286-8ef7d8a19526", "count": 56169 },
  { "aircraft_id": "ef00fe4c-efd4-4989-b82a-00df25d5cb2e", "count": 72361 },
  { "aircraft_id": "ef912d2d-daa6-406b-874a-5da9791c287a", "count": 59536 },
  { "aircraft_id": "f039325b-d052-4602-aae1-5d076f663d17", "count": 68121 },
  { "aircraft_id": "f03b9f2d-5475-4af7-856e-2d47e3634c1d", "count": 81796 },
  { "aircraft_id": "f23f0595-4343-47bc-9446-5b3bd5249eea", "count": 56644 },
  { "aircraft_id": "f28cc9e9-294a-4607-9615-62d645695ec7", "count": 48400 },
  { "aircraft_id": "f3b8f550-2016-4934-b93f-9de21f78d9de", "count": 50176 },
  { "aircraft_id": "f44268eb-f205-4f15-943c-c01aa3cae4c8", "count": 68644 },
  { "aircraft_id": "f50a013d-b7bb-4f12-a99b-07083132f982", "count": 62001 },
  { "aircraft_id": "f59aa479-0607-4eb7-8b8b-ce0755d5dc58", "count": 71289 },
  { "aircraft_id": "f6018117-7c63-4f55-b6c0-1252ed664d41", "count": 57600 },
  { "aircraft_id": "f797ff51-3dcc-422e-a753-c0957032df35", "count": 51984 },
  { "aircraft_id": "fad1c892-d0bf-4e65-abc0-5086365df628", "count": 90000 },
  { "aircraft_id": "faea06e9-6ac6-4bf6-9320-4bb0ac051208", "count": 55696 },
  { "aircraft_id": "fbbb5071-40f2-437f-8aca-5f4d95409c6d", "count": 59536 },
  { "aircraft_id": "fc49cdfe-c03b-457f-8c46-e8bcb27d4298", "count": 69696 },
  { "aircraft_id": "fd052a6e-8e82-43d3-b55e-1eca7ee985ae", "count": 50176 },
  { "aircraft_id": "fd3ae1de-1454-496c-8735-b910449c25a5", "count": 48400 },
  { "aircraft_id": "fd62d0c2-0aa6-49d4-873e-a310cd359853", "count": 56169 },
  { "aircraft_id": "fd749e09-d172-494a-81a7-d08f98e39056", "count": 70225 },
  { "aircraft_id": "feb32ce2-c196-478b-804e-f8392e06345d", "count": 74529 },
  { "aircraft_id": "ffba55ac-0637-42ac-84cf-70bb807e9852", "count": 52900 },
  { "aircraft_id": "fff8aab0-98ae-4a88-8277-48246273ab21", "count": 69696 }
]
