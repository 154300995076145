import EditNoteIcon from "@mui/icons-material/EditNote";
import { Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import * as React from "react";

type Props = {
  note: string;
};

export const NoteTooltip: React.FC<Props> = ({ note }) => {
  return (
    <Tooltip disableHoverListener={!Boolean(note)} title={note ?? ""}>
      <EditNoteIcon
        fontSize="small"
        sx={{ color: !Boolean(note) ? grey[500] : "inherit" }}
      />
    </Tooltip>
  );
};
