import * as React from "react";
import { useRamp } from "../hooks/useRamp";
import { Ramp } from "../types";

type Props = {
  ramp_id: string;
  isReference: boolean;
  includeReferenceImage?: boolean;
};

const RampStateContainerState = React.createContext<{
  ramp: Ramp;
  setRamp: React.Dispatch<React.SetStateAction<Ramp>>;
  history: any;
  isReference: boolean;
  includeReferenceImage?: boolean;
}>({});

export const RampStateContainer: React.FC<{
  children?: React.ReactNode;
  initialState: Props;
}> = ({ children, initialState, ...props }) => {
  const { ready, ramp, setRamp, history } = useRamp(
    initialState.ramp_id,
    initialState.isReference ?? false,
    initialState.includeReferenceImage ?? false
  );
  return (
    <RampStateContainerState.Provider
      value={{
        ramp,
        setRamp,
        history,
        isReference: initialState.isReference,
      }}
    >
      {ready ? children : null}
    </RampStateContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useRampState = () => React.useContext(RampStateContainerState);
