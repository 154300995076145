import "../css/DraggablePlane.css";
import { addClass, removeClass } from "../css/css";
import DragHandler from "../dom/DragHandler";
import events from "../events/events";
import { debounce } from "../fs/misc";
import { MinimalAircraft } from "../types";
import {
  Coordinate,
  convertRadiansToDegress,
  getClockAngle,
  getHypotenuse,
  rotateAroundOrigin,
} from "./../math/math";
import { getMovementAngle } from "./../misc/movement";
import AntiClockwiseArrowIcon from "./../svg/AntiClockwiseArrow";
import ClockwiseArrowIcon from "./../svg/ClockwiseArrow";
import Component from "./Component";
import PlanePolygon from "./PlanePolygon";

/**
 * @fileOverview Provides a draggable Aircraft that pivots around it's rear
 * wheels.
 *
 * It will distpatch an event when the orientation or x,y position changes.
 */

type DraggablePlaneOptions = {
  // Highlights the plane is selected.
  selected: boolean;
  readOnly?: boolean;
  label?: string;
  labelWidth?: number;
  hotkey?: string;
  forceDisplayLabel?: boolean;
  hideDragIndicator?: boolean;
  /**
   * If provided it will render the aircraft's spacing buffer polygon inside
   * this container. It's designed in mind with putting underneath the main
   * layer where all aircraft are rendered so that the spacing buffer polygons
   * do not overlap any of the main aircraft UI.
   */
  spacingBufferOverlay: Element;
  note: string;
};

const defaultOptions: DraggablePlaneOptions = {
  selected: false,
  readOnly: false,
  forceDisplayLabel: false,
  hideDragIndicator: false,
  spacingBufferOverlay: null,
  note: null,
};

var DraggablePlane = function(
  aircraft: MinimalAircraft,
  options: DraggablePlaneOptions = defaultOptions
) {
  Component.call(this);
  // Clone the aircraft so any changes to its props won't affect this
  // interaction
  this.aircraft = {
    ...aircraft,
    position: {
      ...aircraft.position,
    },
  };

  // Chances are this won't trigger unless there's a non 0 starting rotation
  // but still throw as a way to help catch mistakes when non radians are
  // used
  if (aircraft.position.angle > Math.PI * 2) {
    throw new Error(
      `Aircraft angle should be in radians not degrees ${aircraft.position.angle}`
    );
  }
  this.type_ = aircraft.type;
  this.rotation_ = aircraft.position.angle || 0;

  this.wheelRotationLimit_ = aircraft.wheel_rotation_limit;
  // For testing
  //this.wheelRotationLimit_ = 45
  //this.wheelRotationLimit_ = null

  this.frontWheelDragHandler_ = new DragHandler();
  this.frontWheelDragHandler_.setPosition(this.getFrontWheelsPos_());
  this.aircraftDragHandler_ = new DragHandler();
  this.aircraftDragHandler_.setPosition(this.getRearWheelsPos_());
  this.rotationNEDragHandler_ = new DragHandler();
  this.rotationSEDragHandler_ = new DragHandler();
  this.rotationSWDragHandler_ = new DragHandler();
  this.rotationNWDragHandler_ = new DragHandler();
  this.bindedOnFrontWheelDragHandlerStart_ = this.onFrontWheelDragHandlerStart_.bind(
    this
  );
  this.bindedOnFrontWheelDragHandlerMove_ = this.onFrontWheelDragHandlerMove_.bind(
    this
  );
  this.bindedOnFrontWheelDragHandlerStop_ = this.onFrontWheelDragHandlerStop_.bind(
    this
  );
  this.bindedOnAircraftDragHandlerStart_ = this.onAircraftDragHandlerStart_.bind(
    this
  );
  this.bindedOnAircraftDragHandlerMove_ = this.onAircraftDragHandlerMove_.bind(
    this
  );
  this.bindedOnAircraftDragHandlerChange_ = this.onAircraftDragHandlerChange_.bind(
    this
  );
  this.bindedOnAircraftDragHandlerStop_ = this.onAircraftDragHandlerStop_.bind(
    this
  );
  this.bindedComputeFrame_ = this.computeFrame_.bind(this);
  this.bindedOnClick_ = this.onClick_.bind(this);
  this.bindedOnRightClick_ = this.onRightClick_.bind(this);
  this.debouncedMarkExtremeAngle_ = debounce(this.markExtremeAngle_, 40);
  this.bindedOnRotationNEDragHandlerStart_ = this.onRotationNEDragHandlerStart_.bind(
    this
  );
  this.bindedOnRotationNEDragHandlerMove_ = this.onRotationNEDragHandlerMove_.bind(
    this
  );
  this.bindedOnRotationNEDragHandlerStop_ = this.onRotationNEDragHandlerStop_.bind(
    this
  );
  this.bindedOnRotationSEDragHandlerStart_ = this.onRotationSEDragHandlerStart_.bind(
    this
  );
  this.bindedOnRotationSEDragHandlerMove_ = this.onRotationSEDragHandlerMove_.bind(
    this
  );
  this.bindedOnRotationSEDragHandlerStop_ = this.onRotationSEDragHandlerStop_.bind(
    this
  );
  this.bindedOnRotationSWDragHandlerStart_ = this.onRotationSWDragHandlerStart_.bind(
    this
  );
  this.bindedOnRotationSWDragHandlerMove_ = this.onRotationSWDragHandlerMove_.bind(
    this
  );
  this.bindedOnRotationSWDragHandlerStop_ = this.onRotationSWDragHandlerStop_.bind(
    this
  );
  this.bindedOnRotationNWDragHandlerStart_ = this.onRotationNWDragHandlerStart_.bind(
    this
  );
  this.bindedOnRotationNWDragHandlerMove_ = this.onRotationNWDragHandlerMove_.bind(
    this
  );
  this.bindedOnRotationNWDragHandlerStop_ = this.onRotationNWDragHandlerStop_.bind(
    this
  );
  this.movementHistory_ = [];

  // We use this as a flag to know the instance has been destroyed
  this.alive_ = true;

  const {
    selected,
    readOnly,
    forceDisplayLabel,
    hideDragIndicator,
    spacingBufferOverlay,
    label,
    hotkey,
    note,
    labelWidth,
  } = options;
  this.selected_ = !!selected;
  this.readOnly_ = readOnly;
  this.forceDisplayLabel_ = forceDisplayLabel;
  this.hideDragIndicator_ = hideDragIndicator;
  this.spacingBufferOverlay_ = spacingBufferOverlay;
  this.label_ = label;
  this.labelWidth_ = labelWidth;
  this.hotkey_ = hotkey;
  this.note_ = note;
};
DraggablePlane.prototype = new Component();

/**
 * Get the plane's rear wheel position relative to the top left.
 */
DraggablePlane.prototype.getRearWheelsPos_ = function(): Coordinate {
  return {
    x: this.aircraft.position.x + this.aircraft.width / 2,
    y: this.aircraft.position.y + this.aircraft.rear_wheels,
  };
};

/**
 * Get the plane's front wheel position relative to the top left.
 */
DraggablePlane.prototype.getFrontWheelsPos_ = function(): Coordinate {
  var rearWheelsPos = this.getRearWheelsPos_();
  return rotateAroundOrigin(
    {
      x: rearWheelsPos.x,
      y: rearWheelsPos.y - this.getDistanceBetweenWheels_(),
    },
    -this.rotation_,
    rearWheelsPos
  );
};

/**
 * Get the distance between the front and rear wheels.
 */
DraggablePlane.prototype.getDistanceBetweenWheels_ = function(): number {
  return this.aircraft.rear_wheels - this.aircraft.front_wheels;
};

DraggablePlane.prototype.createDom = function() {
  const c = this.dom_.createElement.bind(this.dom_);

  var origin = c("div", { class: "origin" }, c("span"));
  this.originElement_ = origin;
  if (this.hideDragIndicator_) {
    this.frontWheelsDragElement_ = c("div", { class: "" }, c("span"));
  } else {
    const frontWheelsClass =
      this.type_ === "base" ? "frontWheels tenant" : "frontWheels";
    this.frontWheelsDragElement_ = c(
      "div",
      { class: frontWheelsClass },
      c("span")
    );
  }
  // This is only used for debugging, the user does not interact with this
  // element, it only shows where the position of the front wheel drag handler
  // is located
  this.frontWheelDragIndicator_ = c(
    "div",
    { class: "frontWheelDragIndicator" },
    c("span")
  );
  this.aircraftDragIndicator_ = c(
    "div",
    { class: "aircraftDragIndicator" },
    c("span")
  );

  this.aircraftTransformOriginIndicator_ = c(
    "div",
    { class: "originIndicator" },
    c("span")
  );

  var viewBoxMaxValue = Math.max(
    ...this.aircraft.geom["coordinates"][0].map((coord) => Math.max(...coord))
  );
  this.planePolygon_ = new PlanePolygon(
    this.aircraft.geom,
    // The aircraft polygon should always be square
    `0 0 ${viewBoxMaxValue} ${viewBoxMaxValue}`
  );
  this.planePolygon_.createDom();

  // Create the rotation drag handles
  this.rotationHandleNW_ = c("div", { class: "rotationHandle nw" }, c("span"));
  this.rotationHandleNE_ = c("div", { class: "rotationHandle ne" }, c("span"));
  this.rotationHandleSE_ = c("div", { class: "rotationHandle se" }, c("span"));
  this.rotationHandleSW_ = c("div", { class: "rotationHandle sw" }, c("span"));

  this.rotationHandleHitboxNE_ = c(
    "div",
    { class: "rotationHandleHitbox ne" },
    c("span")
  );
  this.rotationHandleHitboxSE_ = c(
    "div",
    { class: "rotationHandleHitbox se" },
    c("span")
  );
  this.rotationHandleHitboxSW_ = c(
    "div",
    { class: "rotationHandleHitbox sw" },
    c("span")
  );
  this.rotationHandleHitboxNW_ = c(
    "div",
    { class: "rotationHandleHitbox nw" },
    c("span")
  );

  this.clockwiseArrowNE_ = new ClockwiseArrowIcon();
  this.clockwiseArrowNE_.createDom();
  this.clockwiseArrowNE_
    .getElement()
    .setAttribute("class", "ClockwiseArrow ne");
  this.clockwiseArrowSE_ = new ClockwiseArrowIcon();
  this.clockwiseArrowSE_.createDom();
  this.clockwiseArrowSE_
    .getElement()
    .setAttribute("class", "ClockwiseArrow se");
  this.clockwiseArrowSW_ = new ClockwiseArrowIcon();
  this.clockwiseArrowSW_.createDom();
  this.clockwiseArrowSW_
    .getElement()
    .setAttribute("class", "ClockwiseArrow sw");
  this.clockwiseArrowNW_ = new ClockwiseArrowIcon();
  this.clockwiseArrowNW_.createDom();
  this.clockwiseArrowNW_
    .getElement()
    .setAttribute("class", "ClockwiseArrow nw");
  this.antiClockwiseArrowNE_ = new AntiClockwiseArrowIcon();
  this.antiClockwiseArrowNE_.createDom();
  this.antiClockwiseArrowNE_
    .getElement()
    .setAttribute("class", "AntiClockwiseArrow ne");
  this.antiClockwiseArrowSE_ = new AntiClockwiseArrowIcon();
  this.antiClockwiseArrowSE_.createDom();
  this.antiClockwiseArrowSE_
    .getElement()
    .setAttribute("class", "AntiClockwiseArrow se");
  this.antiClockwiseArrowSW_ = new AntiClockwiseArrowIcon();
  this.antiClockwiseArrowSW_.createDom();
  this.antiClockwiseArrowSW_
    .getElement()
    .setAttribute("class", "AntiClockwiseArrow sw");
  this.antiClockwiseArrowNW_ = new AntiClockwiseArrowIcon();
  this.antiClockwiseArrowNW_.createDom();
  this.antiClockwiseArrowNW_
    .getElement()
    .setAttribute("class", "AntiClockwiseArrow nw");

  // This will end up being attached to the spacing buffer container if one
  // exists, which is an element that is external to this component
  this.spacingBufferAircraftContainer_ = c("div", {
    id: `spacing-buffer-aircraft-container-${this.aircraft.entity_id}`,
    class: "DraggablePlane-SpacingBufferAircraftContainer",
  });

  if (this.aircraft.spacing_buffer_geom) {
    this.createBufferPlanePolygon_();
  }

  var aircraftImage = c(
    "div",
    {
      [`data-entity_id`]: this.aircraft.entity_id,
      class: "aircraftImage",
    },
    this.planePolygon_.getElement(),
    this.aircraftTransformOriginIndicator_,
    this.rotationHandleNW_,
    this.rotationHandleNE_,
    this.rotationHandleSE_,
    this.rotationHandleSW_,
    this.clockwiseArrowNE_.getElement(),
    this.clockwiseArrowSE_.getElement(),
    this.clockwiseArrowSW_.getElement(),
    this.clockwiseArrowNW_.getElement(),
    this.antiClockwiseArrowNE_.getElement(),
    this.antiClockwiseArrowSE_.getElement(),
    this.antiClockwiseArrowSW_.getElement(),
    this.antiClockwiseArrowNW_.getElement(),
    this.rotationHandleHitboxNE_,
    this.rotationHandleHitboxSE_,
    this.rotationHandleHitboxSW_,
    this.rotationHandleHitboxNW_
  );

  this.aircraftImage_ = aircraftImage;

  this.tailNumberTextElement_ = c("span");
  let tailNumberElementClass = "tailNumber";
  if (this.hotkey_) {
    tailNumberElementClass += " hotkey";
  }
  if (this.note_) {
    tailNumberElementClass += " note";
  }
  this.tailNumberElement_ = c(
    "div",
    { class: tailNumberElementClass },
    c(
      "span",
      null,
      this.tailNumberTextElement_,
      c("div", { class: "noteTooltip" }, c("span", null, this.note_ ?? ""))
    )
  );

  this.tailNumberTextElement_.style.width = this.labelWidth_
    ? `${this.labelWidth_}px`
    : "";

  const mainClass = `DraggablePlane${this.selected_ ? " selected" : ""}${
    this.readOnly_ ? " readOnly" : ""
  }`;

  this.element_ = c(
    "div",
    {
      class: mainClass,
    },
    aircraftImage,
    origin,
    this.frontWheelsDragElement_,
    this.frontWheelDragIndicator_,
    this.aircraftDragIndicator_,
    this.tailNumberElement_
  );

  if (this.forceDisplayLabel_) {
    this.element_.className = addClass(this.element_.className, "forceShow");
  }

  // Add max angle of rotation indicators
  if (this.wheelRotationLimit_) {
    var angleLeft = c("div", { class: "wheelAngle" });
    angleLeft.style.transform = `rotate(${180 - this.wheelRotationLimit_}deg)`;
    var angleRight = c("div", {
      class: "wheelAngle wheelAngleRight",
    });
    angleRight.style.transform = `rotate(${180 + this.wheelRotationLimit_}deg)`;
    this.frontWheelsDragElement_.appendChild(angleLeft);
    this.frontWheelsDragElement_.appendChild(angleRight);
    this.angleLeft_ = angleLeft;
    this.angleRight_ = angleRight;

    // For testing add the rear wheel angle, i.e when moving the plane backwards
    var rearAngleLeft = c("div", {
      class: "wheelAngleRear",
    });
    rearAngleLeft.style.transform = `rotate(${this.wheelRotationLimit_}deg)`;
    var rearAngleRight = c("div", {
      class: "wheelAngleRear wheelAngleRearRight",
    });
    rearAngleRight.style.transform = `rotate(${-this.wheelRotationLimit_}deg)`;
    this.frontWheelsDragElement_.appendChild(rearAngleLeft);
    this.frontWheelsDragElement_.appendChild(rearAngleRight);
  } else if (this.wheelRotationLimit_ === null) {
    // There's unlimited rotation specified
    var unlimitedAngle = c("div", {
      class: "unlimitedWheelAngle",
    });
    this.frontWheelsDragElement_.appendChild(unlimitedAngle);
    this.unlimitedAngle_ = unlimitedAngle;
  }
};

DraggablePlane.prototype.render = function(container) {
  Component.prototype.render.call(this, container);

  if (!this.readOnly_) {
    this.addEventListeners_();
  }

  this.paint_();
};

DraggablePlane.prototype.addEventListeners_ = function() {
  events.listen(
    this.frontWheelDragHandler_,
    "start",
    this.bindedOnFrontWheelDragHandlerStart_
  );
  events.listen(
    this.frontWheelDragHandler_,
    "move",
    this.bindedOnFrontWheelDragHandlerMove_
  );
  events.listen(
    this.frontWheelDragHandler_,
    "stop",
    this.bindedOnFrontWheelDragHandlerStop_
  );
  events.listen(
    this.aircraftDragHandler_,
    "start",
    this.bindedOnAircraftDragHandlerStart_
  );
  events.listen(
    this.aircraftDragHandler_,
    "move",
    this.bindedOnAircraftDragHandlerMove_
  );
  events.listen(
    this.aircraftDragHandler_,
    "change",
    this.bindedOnAircraftDragHandlerChange_
  );
  events.listen(
    this.aircraftDragHandler_,
    "stop",
    this.bindedOnAircraftDragHandlerStop_
  );
  events.listen(
    this.rotationNEDragHandler_,
    "start",
    this.bindedOnRotationNEDragHandlerStart_
  );
  events.listen(
    this.rotationNEDragHandler_,
    "move",
    this.bindedOnRotationNEDragHandlerMove_
  );
  events.listen(
    this.rotationNEDragHandler_,
    "stop",
    this.bindedOnRotationNEDragHandlerStop_
  );
  events.listen(
    this.rotationSEDragHandler_,
    "start",
    this.bindedOnRotationSEDragHandlerStart_
  );
  events.listen(
    this.rotationSEDragHandler_,
    "move",
    this.bindedOnRotationSEDragHandlerMove_
  );
  events.listen(
    this.rotationSEDragHandler_,
    "stop",
    this.bindedOnRotationSEDragHandlerStop_
  );
  events.listen(
    this.rotationSWDragHandler_,
    "start",
    this.bindedOnRotationSWDragHandlerStart_
  );
  events.listen(
    this.rotationSWDragHandler_,
    "move",
    this.bindedOnRotationSWDragHandlerMove_
  );
  events.listen(
    this.rotationSWDragHandler_,
    "stop",
    this.bindedOnRotationSWDragHandlerStop_
  );
  events.listen(
    this.rotationNWDragHandler_,
    "start",
    this.bindedOnRotationNWDragHandlerStart_
  );
  events.listen(
    this.rotationNWDragHandler_,
    "move",
    this.bindedOnRotationNWDragHandlerMove_
  );
  events.listen(
    this.rotationNWDragHandler_,
    "stop",
    this.bindedOnRotationNWDragHandlerStop_
  );
  events.listen(this.planePolygon_, "mouseup", this.bindedOnClick_);
  events.listen(this.planePolygon_, "contextmenu", this.bindedOnRightClick_);

  this.frontWheelDragHandler_.attachTo(this.frontWheelsDragElement_);
  this.aircraftDragHandler_.attachTo(this.planePolygon_.getPolygonElement());
  this.rotationNEDragHandler_.attachTo(this.rotationHandleHitboxNE_);
  this.rotationSEDragHandler_.attachTo(this.rotationHandleHitboxSE_);
  this.rotationSWDragHandler_.attachTo(this.rotationHandleHitboxSW_);
  this.rotationNWDragHandler_.attachTo(this.rotationHandleHitboxNW_);
};

DraggablePlane.prototype.removeEventListeners_ = function() {
  events.unlisten(
    this.frontWheelDragHandler_,
    "start",
    this.bindedOnFrontWheelDragHandlerStart_
  );
  events.unlisten(
    this.frontWheelDragHandler_,
    "move",
    this.bindedOnFrontWheelDragHandlerMove_
  );
  events.unlisten(
    this.frontWheelDragHandler_,
    "stop",
    this.bindedOnFrontWheelDragHandlerStop_
  );
  events.unlisten(
    this.aircraftDragHandler_,
    "start",
    this.bindedOnAircraftDragHandlerStart_
  );
  events.unlisten(
    this.aircraftDragHandler_,
    "move",
    this.bindedOnAircraftDragHandlerMove_
  );
  events.unlisten(
    this.aircraftDragHandler_,
    "change",
    this.bindedOnAircraftDragHandlerChange_
  );
  events.unlisten(
    this.aircraftDragHandler_,
    "stop",
    this.bindedOnAircraftDragHandlerStop_
  );
  events.unlisten(
    this.rotationNEDragHandler_,
    "start",
    this.bindedOnRotationNEDragHandlerStart_
  );
  events.unlisten(
    this.rotationNEDragHandler_,
    "move",
    this.bindedOnRotationNEDragHandlerMove_
  );
  events.unlisten(
    this.rotationNEDragHandler_,
    "stop",
    this.bindedOnRotationNEDragHandlerStop_
  );
  events.unlisten(
    this.rotationSEDragHandler_,
    "start",
    this.bindedOnRotationSEDragHandlerStart_
  );
  events.unlisten(
    this.rotationSEDragHandler_,
    "move",
    this.bindedOnRotationSEDragHandlerMove_
  );
  events.unlisten(
    this.rotationSEDragHandler_,
    "stop",
    this.bindedOnRotationSEDragHandlerStop_
  );
  events.unlisten(
    this.rotationSWDragHandler_,
    "start",
    this.bindedOnRotationSWDragHandlerStart_
  );
  events.unlisten(
    this.rotationSWDragHandler_,
    "move",
    this.bindedOnRotationSWDragHandlerMove_
  );
  events.unlisten(
    this.rotationSWDragHandler_,
    "stop",
    this.bindedOnRotationSWDragHandlerStop_
  );
  events.unlisten(
    this.rotationNWDragHandler_,
    "start",
    this.bindedOnRotationNWDragHandlerStart_
  );
  events.unlisten(
    this.rotationNWDragHandler_,
    "move",
    this.bindedOnRotationNWDragHandlerMove_
  );
  events.unlisten(
    this.rotationNWDragHandler_,
    "stop",
    this.bindedOnRotationNWDragHandlerStop_
  );
  events.unlisten(this.planePolygon_, "mouseup", this.bindedOnClick_);
  events.unlisten(this.planePolygon_, "contextmenu", this.bindedOnRightClick_);

  // TODO Add detachFrom method for drag handlers
};

/**
 * Fires when the user begins dragging the front wheel.
 */
DraggablePlane.prototype.onFrontWheelDragHandlerStart_ = function(e) {
  this.element_.className = addClass(
    this.element_.className,
    "frontWheelDragging"
  );
  this.onDraggingStart_();
};

/**
 * Fires when the user starts dragging the aircraft
 */
DraggablePlane.prototype.onAircraftDragHandlerStart_ = function(e) {
  this.onDraggingStart_();
};

/**
 * Fire when the user starts dragging, causes the UI to update on each
 * animation frame.
 */
DraggablePlane.prototype.onDraggingStart_ = function() {
  this.dragging_ = true;
  this.element_.className = addClass(this.element_.className, "dragging");

  window.requestAnimationFrame(this.bindedComputeFrame_);
  this.dispatchEvent({
    type: "start",
  });
};

/**
 * Fires when the user is dragging the front wheel
 */
DraggablePlane.prototype.onFrontWheelDragHandlerMove_ = function(e) {
  var dragPos = e;
  var rearWheelsPos = this.getRearWheelsPos_();

  // Here we check if the front wheel is turning at an angle that is too extreme
  // for the maximum front wheel rotation
  var isFrontWheelAngleTooExtreme = (() => {
    if (!this.wheelRotationLimit_) return false;

    this.movementHistory_.push({
      mousePos: dragPos,
      rearWheelPos: rearWheelsPos,
      rotation: this.rotation_,
    });

    var angle = getMovementAngle(this.movementHistory_);
    if (!angle) return false;

    var d = convertRadiansToDegress(angle);
    d = (d + 360) % 360;

    var maxAngle = this.wheelRotationLimit_;

    var topSideAntiClockwiseAngleLimit = 360 - maxAngle;
    var topSideClockwiseAngleLimit = maxAngle;
    var bottomSideAntiClockwiseAngleLimit = 180 + maxAngle;
    var bottomSideClockwiseAngleLimit = 180 - maxAngle;

    if (d < topSideAntiClockwiseAngleLimit && d >= 270) {
      return true;
    }
    if (d > topSideClockwiseAngleLimit && d <= 90) {
      return true;
    }
    if (d < bottomSideClockwiseAngleLimit && d > 90) {
      return true;
    }

    if (d > bottomSideAntiClockwiseAngleLimit && d < 270) {
      return true;
    }
    return false;
  })();

  if (this.extremeAngle_ != isFrontWheelAngleTooExtreme) {
    this.extremeAngle_ = isFrontWheelAngleTooExtreme;
    // Only fire this if the value changes, so that we get the right behaviour
    // on the debounce, i.e. it'll ignore flip-flopping
    this.debouncedMarkExtremeAngle_(isFrontWheelAngleTooExtreme);
  }

  var mouseRelativeToOrigin = {
    x: dragPos.x - rearWheelsPos.x,
    y: dragPos.y - rearWheelsPos.y,
  };

  var rotation = getClockAngle({
    x: mouseRelativeToOrigin.x,
    y: mouseRelativeToOrigin.y, // * -1,
  });
  // We do PI minus rotation here because the y coordinate we're working with
  // is inversed respective to the needed rotation, i.e.
  // 0 aligns to the top of the screen not the bottom
  this.rotation_ = Math.PI - rotation;

  // Get the new origin / rear wheel pos based on moving along the plane axis
  var currentHypothenus = getHypotenuse(mouseRelativeToOrigin);
  var scaleChange =
    (currentHypothenus - this.getDistanceBetweenWheels_()) / currentHypothenus;
  var originMovement = {
    x: mouseRelativeToOrigin.x * scaleChange,
    y: mouseRelativeToOrigin.y * scaleChange,
  };

  this.aircraft.position.x = this.aircraft.position.x + originMovement.x;
  this.aircraft.position.y = this.aircraft.position.y + originMovement.y;

  // TODO Put this in the aircraft drag handler and the rotating drag handler
  // functions if relying on it
  this.dispatchEvent({
    type: "change",
    x: this.aircraft.position.x,
    y: this.aircraft.position.y,
    angle: this.rotation_,
  });
};

DraggablePlane.prototype.onAircraftDragHandlerMove_ = function(e) {
  this.aircraft.position.x = e.x - this.aircraft.width / 2;
  this.aircraft.position.y = e.y - this.aircraft.rear_wheels;
};

DraggablePlane.prototype.onAircraftDragHandlerChange_ = function(e) {
  this.dispatchEvent({
    type: "change",
    x: e.x - this.aircraft.width / 2,
    y: e.y - this.aircraft.rear_wheels,
    angle: this.rotation_,
  });
};

/**
 * Fires when the user stops dragging the front wheel
 */
DraggablePlane.prototype.onFrontWheelDragHandlerStop_ = function(e) {
  this.element_.className = removeClass(
    this.element_.className,
    "frontWheelDragging"
  );
  this.onDraggingStop_();
};

/**
 * Fires when the user stops dragging the aircraft.
 */
DraggablePlane.prototype.onAircraftDragHandlerStop_ = function(e) {
  this.onDraggingStop_();
};

/**
 * Fire after stopping dragging the aircraft.
 */
DraggablePlane.prototype.onDraggingStop_ = function() {
  this.dragging_ = false;
  this.element_.className = removeClass(this.element_.className, "dragging");

  this.resetDragHandlerPositions_();

  this.dispatchEvent({
    type: "dragEnd",
    x: this.aircraft.position.x,
    y: this.aircraft.position.y,
    angle: this.rotation_,
  });
};

DraggablePlane.prototype.computeFrame_ = function() {
  this.paint_();

  if (this.dragging_ || this.rotating_)
    window.requestAnimationFrame(this.bindedComputeFrame_);
};

/**
 * Call this at any time to update the aircraft on the screen. It uses the
 * following air craft properties
 * Requires the following properties to be set.
 * - `this.aircraft.width`
 * - `this.aircraft.height`
 * - `this.aircraft.rear_wheels`
 * - `this.rotation_`
 * - `this.aircraft.image`
 */
DraggablePlane.prototype.paint_ = function() {
  var frontWheelDragPos = this.frontWheelDragHandler_.getPosition();
  this.frontWheelDragIndicator_.style.top = frontWheelDragPos.y + "px";
  this.frontWheelDragIndicator_.style.left = frontWheelDragPos.x + "px";

  var aircraftDragPos = this.aircraftDragHandler_.getPosition();
  this.aircraftDragIndicator_.style.top = aircraftDragPos.y + "px";
  this.aircraftDragIndicator_.style.left = aircraftDragPos.x + "px";

  var frontWheelPos = this.getFrontWheelsPos_();
  var rearWheelsPos = this.getRearWheelsPos_();

  this.frontWheelsDragElement_.style.top = frontWheelPos.y + "px";
  this.frontWheelsDragElement_.style.left = frontWheelPos.x + "px";

  if (this.readOnly_) {
    this.frontWheelsDragElement_.firstChild.style.backgroundColor =
      "transparent";
  }
  var translation = {
    x: rearWheelsPos.x - this.aircraft.width / 2,
    y: rearWheelsPos.y - this.aircraft.rear_wheels,
  };

  // The spacing buffer is slightly larger than the aircraft dimensions
  // Here we figure out its translation so that it aligns with the aircraft
  // image position but overlaps equally on all sides
  if (this.aircraft.spacing_buffer_geom_dimensions) {
    var spacingBufferTranslation = {
      x:
        rearWheelsPos.x -
        this.aircraft.spacing_buffer_geom_dimensions.width / 2,
      y: rearWheelsPos.y - this.aircraft.rear_wheels,
    };

    // The aircraft and buffer polygon are aligned on the Y axis, i.e the x
    // cordinate in the centre of both are exactly the same, the only
    // variation is in the y direction because the buffer polygon is larger.
    // All rotation is taken care of by the css rotate prop but we need to
    // account for the slight difference in the y direction as this won't
    // be accounted for by the css rotation as the alignment will be slightly
    // off
    var mod = {
      x: 0,
      y: -(
        (this.aircraft.spacing_buffer_geom_dimensions.height -
          this.aircraft.height) /
        2
      ),
    };
    var mod2 = rotateAroundOrigin(mod, -this.rotation_, { x: 0, y: 0 });
    spacingBufferTranslation.x += mod2.x;
    spacingBufferTranslation.y += mod2.y;
  }

  // TODO Do this via a transform for an optimisation
  this.aircraftImage_.style.backgroundImage = `url(${this.aircraft.image})`;
  this.aircraftImage_.style.left = translation.x + "px";
  this.aircraftImage_.style.top = translation.y + "px";
  this.aircraftImage_.style.transform = `rotate(${this.rotation_}rad)`;
  this.aircraftImage_.style.transformOrigin = `${this.aircraft.width / 2}px ${
    this.aircraft.rear_wheels
  }px`;
  this.aircraftImage_.style.width = `${this.aircraft.width}px`;
  this.aircraftImage_.style.height = `${this.aircraft.height}px`;

  if (this.aircraft.spacing_buffer_geom_dimensions) {
    this.spacingBufferAircraftContainer_.style.left =
      spacingBufferTranslation.x + "px";
    this.spacingBufferAircraftContainer_.style.top =
      spacingBufferTranslation.y + "px";
    this.spacingBufferAircraftContainer_.style.transform = `rotate(${this.rotation_}rad)`;
    this.spacingBufferAircraftContainer_.style.transformOrigin = `${this
      .aircraft.spacing_buffer_geom_dimensions.width / 2}px ${
      this.aircraft.rear_wheels
    }px`;
    this.spacingBufferAircraftContainer_.style.width = `${this.aircraft.spacing_buffer_geom_dimensions.width}px`;
    this.spacingBufferAircraftContainer_.style.height = `${this.aircraft.spacing_buffer_geom_dimensions.height}px`;

    if (this.spacingBufferOverlay_) {
      if (!this.spacingBufferAircraftContainer_.parentNode) {
        // remove all existing spacing buffer aircraft containers in the DOM
        const aircraftSpacingBufferContainerId = `spacing-buffer-aircraft-container-${this.aircraft.entity_id}`;
        const existingSpacingBufferAircraftContainers = document.querySelectorAll(
          `#${aircraftSpacingBufferContainerId}`
        );
        existingSpacingBufferAircraftContainers.forEach((el) =>
          el.parentNode.removeChild(el)
        );

        this.spacingBufferOverlay_.appendChild(
          this.spacingBufferAircraftContainer_
        );
      }
    } else {
      if (this.spacingBufferAircraftContainer_.parentNode)
        this.spacingBufferAircraftContainer_.parentNode.removeChild(
          this.spacingBufferAircraftContainer_
        );
    }
  }

  if (!this.planePolygon_.getElement()) {
    return;
  }
  this.planePolygon_.getElement().style.left = "0px";
  this.planePolygon_.getElement().style.top = "0px";

  this.aircraftTransformOriginIndicator_.style.left = `${this.aircraft.width /
    2}px`;
  this.aircraftTransformOriginIndicator_.style.top = `${this.aircraft.rear_wheels}px`;

  this.originElement_.style.top = rearWheelsPos.y + "px";
  this.originElement_.style.left = rearWheelsPos.x + "px";

  this.frontWheelsDragElement_.style.transform = `rotate(${this.rotation_}rad)`;
  this.frontWheelsDragElement_.style.transformOrigin = `0 0`;

  this.tailNumberElement_.style.top = rearWheelsPos.y + "px";
  this.tailNumberElement_.style.left = rearWheelsPos.x + "px";
  if (this.note_) {
    this.tailNumberElement_.className = addClass(
      this.tailNumberElement_.className,
      "note"
    );
  } else {
    this.tailNumberElement_.className = removeClass(
      this.tailNumberElement_.className,
      "note"
    );
  }
  // do this no matter what. handles when user deletes the note
  const noteTooltipContent = this.tailNumberElement_.querySelector(
    ".noteTooltip span"
  );
  if (noteTooltipContent) {
    noteTooltipContent.textContent = this.note_;
  }

  this.aircraftTransformOriginIndicator_.style.left = `${this.aircraft.width /
    2}px`;
  this.aircraftTransformOriginIndicator_.style.top = `${this.aircraft.rear_wheels}px`;

  this.planePolygon_.getElement().style.width = `${this.aircraft.width}px`;
  this.planePolygon_.getElement().style.height = `${this.aircraft.height}px`;

  this.tailNumberTextElement_.innerText = this.hotkey_ ?? this.label_;

  this.tailNumberTextElement_.style.width = this.labelWidth_
    ? `${this.labelWidth_}px`
    : "";
};

DraggablePlane.prototype.destroy = function() {
  if (!this.readOnly_) {
    this.removeEventListeners_();
  }
  if (this.spacingBufferAircraftContainer_.parentNode)
    this.spacingBufferAircraftContainer_.parentNode.removeChild(
      this.spacingBufferAircraftContainer_
    );
  this.frontWheelDragHandler_.destroy();
  this.planePolygon_.destroy();
  Component.prototype.destroy.call(this);
  delete this.movementHistory_;
  delete this.alive_;
  delete this.dragging_;
};

type UpdateDraggablePlaneAircraftProps = {
  width?: number;
  height?: number;
  front_wheels?: number;
  rear_wheels?: number;
  position?: {
    x: number;
    y: number;
    angle: number;
  };
  image?: string;
  /**
   * In degrees
   * Specify null for unlimited rotation or undefined or not specified for
   * no known rotation.
   */
  wheel_rotation_limit?: number;
  /**
   * The plane Polygon geom
   * The dimensions of this geom do not matter, it will be confined to the area
   * represented by the aircraft width and height. That said, the polygon must
   * be anchored to 0,0, i.e. the most left and most upper points on the
   * polygon must have a value of 0
   */
  geom: Object;
  /**
   * The polygon used to illustrate the spacing buffer around the aircraft. To
   * be used in unison with the `spacingBufferOverlay` prop of a
   * `DraggablePlane` instance. The dimensions of this polygon do not matter,
   * they will be confined to the area defined by spacing_buffer_geom_dimensions.
   * That said, the polygon must be anchored to 0,0, i.e. the most left and most
   * upper points on the polygon must have a value of 0
   */
  spacing_buffer_geom?: Object;
  /**
   * The size of the spacing buffer geom in pixels.
   */
  spacing_buffer_geom_dimensions?: {
    width: number;
    height: number;
  };
};

/**
 * Pass in any props that you want to update on the aircraft, if props are
 * omitted then that aspect won't be updated.
 *
 * Note to remove the spacing buffer_geom, you must pass in null, if you
 * pass in undefined it will be treated as "don't change".
 */
DraggablePlane.prototype.updateAircraft = function(
  aircraft: UpdateDraggablePlaneAircraftProps
) {
  if (aircraft.image !== undefined && aircraft.image != this.aircraft.image) {
    this.aircraftImage_.style.backgroundImage = `url(${this.aircraft.image})`;
  }

  // If we now have a buffer polygon then create it
  var shouldCreatePolygon =
    aircraft.spacing_buffer_geom && !this.aircraft.spacing_buffer_geom;

  // Else if we now don't have a buffer then delete it
  var shouldDeletePolygon =
    aircraft.spacing_buffer_geom === null && this.aircraft.spacing_buffer_geom;

  this.aircraft = {
    ...this.aircraft,
    ...aircraft,
  };

  if (aircraft.position) {
    this.aircraft = {
      ...this.aircraft,
      position: {
        ...aircraft.position,
      },
    };

    // Chances are this won't trigger unless there's a non 0 starting rotation
    // but still throw as a way to help catch mistakes when non radians are
    // used
    if (aircraft.position.angle > Math.PI * 2) {
      throw new Error("Aircraft angle should be in radians not degrees");
    }
    this.rotation_ = aircraft.position.angle || 0;

    this.resetDragHandlerPositions_();
  }

  if (shouldCreatePolygon) {
    this.createBufferPlanePolygon_();
  }

  if (shouldDeletePolygon && this.bufferPlanePolygon_) {
    this.bufferPlanePolygon_.destroy();
    delete this.bufferPlanePolygon_;
  }

  this.paint_();
};

/**
 * Mark the rotation indicators as at an extreme angle.
 */
DraggablePlane.prototype.markExtremeAngle_ = function(value: boolean) {
  if (value) {
    this.frontWheelsDragElement_.className = addClass(
      this.frontWheelsDragElement_.className,
      "extremeAngle"
    );
  } else {
    this.frontWheelsDragElement_.className = removeClass(
      this.frontWheelsDragElement_.className,
      "extremeAngle"
    );
  }
};

DraggablePlane.prototype.onClick_ = function() {
  if (this.dragging_ || this.rotating_) {
    // Don't fire the tap if we were dragging or rotating
    return;
  }
  this.dispatchEvent({
    // It's actually a mouse up event but we'll fire as a click, this is so
    // we have control over only triggering a click if its not a drag
    type: "click",
  });
};

DraggablePlane.prototype.onRightClick_ = function(event) {
  if (this.dragging_ || this.rotating_) {
    // Don't fire the tap if we were dragging or rotating
    return;
  }
  this.dispatchEvent({
    // It's actually a mouse up event but we'll fire as a click, this is so
    // we have control over only triggering a click if its not a drag
    type: "rightClick",
    event,
  });
};

/**
 * Set that the aircraft is selected.
 */
DraggablePlane.prototype.setSelected = function(val: boolean) {
  this.selected_ = val;
  if (this.element_) {
    if (this.selected_) {
      const paddingThreshhold = 50;
      if (
        this.aircraft.width < paddingThreshhold ||
        this.aircraft.height < paddingThreshhold
      ) {
        this.element_.className = addClass(this.element_.className, "padded");
      }
      this.element_.className = addClass(this.element_.className, "selected");
    } else {
      this.element_.className = removeClass(
        this.element_.className,
        "selected"
      );
    }
  }
};

DraggablePlane.prototype.setLabel = function(val: string) {
  this.label_ = val;
  this.paint_();
};

DraggablePlane.prototype.setHotkey = function(val: string) {
  this.hotkey_ = val;
  this.paint_();
};

DraggablePlane.prototype.setNote = function(val: string) {
  this.note_ = val;
  this.paint_();
};

DraggablePlane.prototype.setLabelWidth = function(val: number) {
  this.labelWidth_ = val;
  this.paint_();
};

DraggablePlane.prototype.setForceDisplayLabel = function(val: boolean) {
  this.forceDisplayLabel_ = val;
  if (this.forceDisplayLabel_) {
    this.element_.className = addClass(this.element_.className, "forceShow");
  } else {
    this.element_.className = removeClass(this.element_.className, "forceShow");
  }
};

/**
 * Fired when the user starts dragging the north east dragger
 */
DraggablePlane.prototype.onRotationNEDragHandlerStart_ = function() {
  var topRightPos = {
    x: this.aircraft.position.x + this.aircraft.width,
    y: this.aircraft.position.y,
  };
  // Adjust it to match current rotation

  this.rotationNEDragHandlerStartPos_ = rotateAroundOrigin(
    topRightPos,
    -this.rotation_,
    this.getRearWheelsPos_()
  );
  this.onRotatingStart_();
};

/**
 * Fired when the user starts dragging the south east dragger
 */
DraggablePlane.prototype.onRotationSEDragHandlerStart_ = function() {
  var bottomRightPos = {
    x: this.aircraft.position.x + this.aircraft.width,
    y: this.aircraft.position.y + this.aircraft.height,
  };
  // Adjust it to match current rotation
  this.rotationSEDragHandlerStartPos_ = rotateAroundOrigin(
    bottomRightPos,
    -this.rotation_,
    this.getRearWheelsPos_()
  );
  this.onRotatingStart_();
};

/**
 * Fired when the user starts dragging the south west dragger
 */
DraggablePlane.prototype.onRotationSWDragHandlerStart_ = function() {
  var bottomLeftPos = {
    x: this.aircraft.position.x,
    y: this.aircraft.position.y + this.aircraft.height,
  };
  // Adjust it to match current rotation
  this.rotationSWDragHandlerStartPos_ = rotateAroundOrigin(
    bottomLeftPos,
    -this.rotation_,
    this.getRearWheelsPos_()
  );
  this.onRotatingStart_();
};

/**
 * Fired when the user starts dragging the north west dragger
 */
DraggablePlane.prototype.onRotationNWDragHandlerStart_ = function() {
  var topLeftPos = {
    x: this.aircraft.position.x,
    y: this.aircraft.position.y,
  };
  // Adjust it to match current rotation
  this.rotationNWDragHandlerStartPos_ = rotateAroundOrigin(
    topLeftPos,
    -this.rotation_,
    this.getRearWheelsPos_()
  );
  this.onRotatingStart_();
};

/**
 * Fired when the North-East rotation drag handler is moved.
 */
DraggablePlane.prototype.onRotationNEDragHandlerMove_ = function(e) {
  // Get the current absolute position of the mouse
  var mousePos = {
    x: this.rotationNEDragHandlerStartPos_.x + e.x,
    y: this.rotationNEDragHandlerStartPos_.y + e.y,
  };
  // Get the relative position of the mouse to the origin / rear wheels
  var rotationOrigin = this.getRearWheelsPos_();
  var relativeMousePosToOrigin = {
    x: mousePos.x - rotationOrigin.x,
    y: (mousePos.y - rotationOrigin.y) * -1,
  };
  // Get the angle of rotation
  var newRotation = getClockAngle(relativeMousePosToOrigin);

  // Because this is the top right rotation handle then that handle is
  // that rotation from the origin so adjust back by that amount to get the
  // y axis rotation
  var angleToNECorner = getClockAngle({
    x: this.aircraft.width / 2,
    y: this.aircraft.rear_wheels,
  });
  newRotation -= angleToNECorner;

  this.onRotatingSet_(newRotation);
};

/**
 * Fired when the South-East rotation drag handler is moved.
 */
DraggablePlane.prototype.onRotationSEDragHandlerMove_ = function(e) {
  // Get the current absolute position of the mouse
  var mousePos = {
    x: this.rotationSEDragHandlerStartPos_.x + e.x,
    y: this.rotationSEDragHandlerStartPos_.y + e.y,
  };
  // Get the relative position of the mouse to the origin / rear wheels
  var rotationOrigin = this.getRearWheelsPos_();
  var relativeMousePosToOrigin = {
    x: mousePos.x - rotationOrigin.x,
    y: (mousePos.y - rotationOrigin.y) * -1,
  };
  // Get the angle of rotation
  var newRotation = getClockAngle(relativeMousePosToOrigin);

  // Because this is the bottom right rotation handle then that handle is
  // approx 135° from the rear wheels of the plane
  var angleToSECorner = getClockAngle({
    x: this.aircraft.width / 2,
    y: this.aircraft.rear_wheels - this.aircraft.width,
  });
  newRotation -= angleToSECorner;

  this.onRotatingSet_(newRotation);
};

/**
 * Fired when the South-West rotation drag handler is moved.
 */
DraggablePlane.prototype.onRotationSWDragHandlerMove_ = function(e) {
  // Get the current absolute position of the mouse
  var mousePos = {
    x: this.rotationSWDragHandlerStartPos_.x + e.x,
    y: this.rotationSWDragHandlerStartPos_.y + e.y,
  };
  // Get the relative position of the mouse to the origin / rear wheels
  var rotationOrigin = this.getRearWheelsPos_();
  var relativeMousePosToOrigin = {
    x: mousePos.x - rotationOrigin.x,
    y: (mousePos.y - rotationOrigin.y) * -1,
  };
  // Get the angle of rotation
  var newRotation = getClockAngle(relativeMousePosToOrigin);

  // Because this is the bottom right rotation handle then that handle is
  // approx 225° from the rear wheels of the plane
  var angleToSWCorner = getClockAngle({
    x: -this.aircraft.width / 2,
    y: this.aircraft.rear_wheels - this.aircraft.width,
  });
  newRotation -= angleToSWCorner;

  this.onRotatingSet_(newRotation);
};

/**
 * Fired when the North-West rotation drag handler is moved.
 */
DraggablePlane.prototype.onRotationNWDragHandlerMove_ = function(e) {
  // Get the current absolute position of the mouse
  var mousePos = {
    x: this.rotationNWDragHandlerStartPos_.x + e.x,
    y: this.rotationNWDragHandlerStartPos_.y + e.y,
  };
  // Get the relative position of the mouse to the origin / rear wheels
  var rotationOrigin = this.getRearWheelsPos_();
  var relativeMousePosToOrigin = {
    x: mousePos.x - rotationOrigin.x,
    y: (mousePos.y - rotationOrigin.y) * -1,
  };
  // Get the angle of rotation
  var newRotation = getClockAngle(relativeMousePosToOrigin);

  // Because this is the bottom right rotation handle then that handle is
  // approx 315° from the rear wheels of the plane
  var angleToNWCorner = getClockAngle({
    x: -this.aircraft.width / 2,
    y: this.aircraft.rear_wheels,
  });
  newRotation -= angleToNWCorner;

  this.onRotatingSet_(newRotation);
};

DraggablePlane.prototype.onRotationNEDragHandlerStop_ = function(e) {
  this.rotationNEDragHandler_.setPosition({ x: 0, y: 0 });
  this.onRotatingStop_();
};

DraggablePlane.prototype.onRotationSEDragHandlerStop_ = function(e) {
  this.rotationSEDragHandler_.setPosition({ x: 0, y: 0 });
  this.onRotatingStop_();
};

DraggablePlane.prototype.onRotationSWDragHandlerStop_ = function(e) {
  this.rotationSWDragHandler_.setPosition({ x: 0, y: 0 });
  this.onRotatingStop_();
};

DraggablePlane.prototype.onRotationNWDragHandlerStop_ = function(e) {
  this.rotationNWDragHandler_.setPosition({ x: 0, y: 0 });
  this.onRotatingStop_();
};

/**
 * Fire when the user starts rotating, causes the UI to update on each
 * animation frame.
 */
DraggablePlane.prototype.onRotatingStart_ = function() {
  this.rotating_ = true;
  this.element_.className = addClass(this.element_.className, "rotating");

  window.requestAnimationFrame(this.bindedComputeFrame_);
};

/**
 * Set the rotation value when the user is rotating the aircraft.
 */
DraggablePlane.prototype.onRotatingSet_ = function(newRotation) {
  this.element_.className = removeClass(
    this.element_.className,
    "rotatingAntiClockwise"
  );
  this.element_.className = removeClass(
    this.element_.className,
    "rotatingClockwise"
  );
  if (this.rotation_) {
    if (newRotation + Math.PI * 2 > this.rotation_ + Math.PI * 2) {
      this.element_.className = addClass(
        this.element_.className,
        "rotatingClockwise"
      );
    } else {
      this.element_.className = addClass(
        this.element_.className,
        "rotatingAntiClockwise"
      );
    }
  }

  this.rotation_ = newRotation;
};

/**
 * Fire when the user stops rotating.
 */
DraggablePlane.prototype.onRotatingStop_ = function() {
  this.rotating_ = false;
  this.element_.className = removeClass(this.element_.className, "rotating");
  this.element_.className = removeClass(
    this.element_.className,
    "rotatingAntiClockwise"
  );
  this.element_.className = removeClass(
    this.element_.className,
    "rotatingClockwise"
  );
  // We need to reset the drag handler positions
  this.resetDragHandlerPositions_();
  this.dispatchEvent({
    type: "rotateEnd",
    x: this.aircraft.position.x,
    y: this.aircraft.position.y,
    angle: this.rotation_,
  });
};

/**
 * Returns true if the user is currently rotating, dragging or moving the plane
 */
DraggablePlane.prototype.isBeingInteractedWith = function(): boolean {
  return this.dragging_ || this.rotating_;
};

/**
 * If set then the aircraft spacing buffer polygon will be rendered inside this
 * container at the same position the aircraft is rendered.
 * To not render the spacing buffer overlay then set this to null.
 */
DraggablePlane.prototype.setSpacingBufferOverlay = function(
  spacingBufferOverlay
) {
  this.spacingBufferOverlay_ = spacingBufferOverlay;
  if (this.element_) this.paint_();
};

DraggablePlane.prototype.createBufferPlanePolygon_ = function() {
  this.bufferPlanePolygon_ = new PlanePolygon(
    this.aircraft.spacing_buffer_geom
  );
  this.bufferPlanePolygon_.setFillColor("red");
  this.bufferPlanePolygon_.setOpacity(0.5);
  this.bufferPlanePolygon_.createDom();
  this.spacingBufferAircraftContainer_.appendChild(
    this.bufferPlanePolygon_.getElement()
  );
};

/**
 * This needs to be called after finishing interacting with a drag handler
 * it repositions the internal values of all drag handlers based on the
 * current aircraft position.
 */
DraggablePlane.prototype.resetDragHandlerPositions_ = function() {
  this.frontWheelDragHandler_.setPosition(this.getFrontWheelsPos_());
  this.aircraftDragHandler_.setPosition(this.getRearWheelsPos_());
};

export default DraggablePlane;
