import * as React from "react";
import { EventPayload } from "../../containers/EventContainer";
import { Entity, Hangar, MovableObstacle, Position, Tenant } from "../../types";
import { MoveEntityEvent } from "../../widgets/Layout";
import { HCP } from "./useMultiHangarLayoutPolygons";

export const entityCenter = (
  entity: Entity<Tenant | MovableObstacle>,
  position: Position
) => {
  return entity.aircraft
    ? {
        x: position.x + entity.aircraft.wingspan / 2,
        y: position.y + entity.aircraft.length / 2,
      }
    : {
        x: position.x + entity.obstacle.width / 2,
        y: position.y + entity.obstacle.length / 2,
      };
};

export const findNewHangar = (
  previousHCP: HCP,
  hangars: Hangar[],
  layoutPolygons: HCP[],
  entity: Entity<Tenant | MovableObstacle>,
  position: Position
): Hangar => {
  const center = entityCenter(entity, position);

  // first check to see if the aircraft is inside of a hangar container. if it's not then pick
  // the hangar that is closest to the point.
  // TODO: turn this into a function and implement the second part
  const found = layoutPolygons.find((hcp) => {
    const [x, y] = hcp.polygon[0];
    const [x2, y2] = hcp.polygon[2];
    const isInHangar =
      center.x >= x && center.x <= x2 && center.y >= y && center.y <= y2;
    if (isInHangar) {
      return isInHangar;
    }

    return false;
  });

  if (found) {
    return hangars.find((h) => h.id === found.id);
  }
  // otherwise, find the nearest hangar
  const distanceFromHangarPolygon = (hcp) => {
    const x = hcp.x + hcp.width / 2;
    const y = hcp.y + hcp.depth / 2;
    return Math.sqrt((center.x - x) ** 2 + (center.y - y) ** 2);
  };
  const nearest = layoutPolygons.sort(
    (a, b) => distanceFromHangarPolygon(a) - distanceFromHangarPolygon(b)
  )[0];
  return hangars.find((h) => h.id === nearest.id);
};

/**
 * I need a way to check if a plane goes into another hangar. If that happens,
 * then we need to move the plane out of its original hangar and into the new one.
 */
export const useHandleOnMoveEntity = () => {
  return React.useCallback((payload: EventPayload<MoveEntityEvent>) => {
    const { entity, locationId: previousLocationId } = payload.data;
    // DO SOME SCHTUFF
  }, []);
};
