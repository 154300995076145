import * as React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import ReplayIcon from "@mui/icons-material/Replay";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useIdentity } from "../../../containers/IdentityContainer";
import { useUserPreferences } from "../../../containers/UserPreferencesContainer";
import {
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";

type Props = {
  user: {
    id: string;
    web_id: string;
    roles: string;
  };
};

export const AccountPresenter: React.FC<Props> = ({ user }) => {
  const { activeFBO } = useActiveFBOs();
  const { airplxIdentity, refreshAirplxToken, logout } = useIdentity();
  const { preferences, setPreferences } = useUserPreferences();
  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      spacing={2}
      sx={{ m: 1, width: 550 }}
    >
      <TextField fullWidth disabled value={user.id} label="User ID" />
      <TextField fullWidth disabled value={user.web_id} label="Web ID" />
      <TextField
        fullWidth
        disabled
        value={activeFBO.user_role}
        label="User Role"
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={preferences.hideLockReminderAlert ?? false}
            onChange={() =>
              setPreferences({
                ...preferences,
                hideLockReminderAlert: !preferences.hideLockReminderAlert,
              })
            }
          />
        }
        label="Hide Locked Aircraft Reminder"
        labelPlacement="start"
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={preferences.forceDisplayTailNumber ?? false}
            onChange={() =>
              setPreferences({
                ...preferences,
                forceDisplayTailNumber: !preferences.forceDisplayTailNumber,
              })
            }
          />
        }
        label="Show Aircraft Labels"
        labelPlacement="start"
      />
      <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
        <TextField
          fullWidth
          disabled
          size="small"
          label="Account Type"
          value={airplxIdentity.isadmin ? "Admin" : "FBO User"}
        />
        <Button
          variant="contained"
          color="success"
          startIcon={<ReplayIcon />}
          onClick={refreshAirplxToken}
        >
          Refresh
        </Button>
      </Stack>
      <Button
        variant="contained"
        color="error"
        startIcon={<LogoutIcon />}
        onClick={logout}
      >
        Logout
      </Button>
    </Stack>
  );
};
