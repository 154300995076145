import * as React from "react";
import { AIRPLX_TOKEN } from "./IdentityContainer";
import { createContainer } from "./StateContainer";
import { getPostgrestUrl } from "../utils";
import { PostgrestClient } from "@supabase/postgrest-js";
import { useFunctionQueue } from "../hooks/usePostgresQueue";
import { useLocalStorage } from "react-use";

type Props = {
  children?: React.ReactNode;
};

/**
 */
const useApiContainerState = (props: Props) => {
  const [token] = useLocalStorage(AIRPLX_TOKEN, "", { raw: true });
  const queue = useFunctionQueue();

  const postgrest = React.useMemo(() => {
    const cli = new PostgrestClient(getPostgrestUrl());
    cli.auth(token as string);
    return cli;
  }, [token]);

  // TODO
  return {
    postgrest,
    queue,
    helpers: {
      deleteTenant: async (tenantId: string) => {
        return postgrest
          .from("tenant")
          .delete()
          .eq("id", tenantId);
      },
    },
  };
};

const ApiContainerState = createContainer(useApiContainerState);

/**
 * Set up an identity container scope. This should really only be in the
 * application once -- and way way up at the root.
 */
export const ApiContainer: React.FC<Props> = ({ children, ...props }) => {
  return (
    <ApiContainerState.Provider initialState={props}>
      {children}
    </ApiContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useApi = ApiContainerState.useContainer;
