import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, Dialog, DialogContent } from "@mui/material";
import { Ramp } from "../../../../types";
import { RampInitialize } from "./RampIniitalize";
import { useRampsState } from "../../../../containers/RampsStateContainer";

type Props = {
  onClose: () => void;
};

export const RampCreateButton: React.FC<Props> = ({ onClose }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { ramps, setRamps } = useRampsState();
  return (
    <>
      <Button
        variant="contained"
        color="success"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        Add Ramp
      </Button>
      <Dialog
        open={open}
        maxWidth="xl"
        onClose={() => setOpen(false)}
        sx={{ maxHeight: "95vh" }}
        PaperProps={{ sx: { maxHeight: "100%" } }}
      >
        <DialogContent sx={{ maxWidth: "100%" }}>
          <RampInitialize
            onSave={(ramp: Ramp) => {
              setOpen(false);
              setRamps(ramps.concat([ramp]));
            }}
            onClose={() => setOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
