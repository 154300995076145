import * as React from "react";
import { createContext, useContext } from "react";

export type EventPayload<T = any> = {
  data: T;
};

export type EventListener<T = any> = (payload: EventPayload<T>) => void;

export type EventContainerContextType<T = any> = {
  emitEvent: (eventType: string, payload: EventPayload<T>) => void;
  addEventListener: (eventType: string, callback: EventListener<T>) => void;
  removeEventListener: (eventType: string, callback: EventListener<T>) => void;
};

const EventContainerContext = createContext<EventContainerContextType | null>(null);

export const useEventContainer = <T = any>(): EventContainerContextType<T> => {
  const context = useContext(EventContainerContext);
  if (!context) {
    throw new Error('useEventContainer must be used within an EventContainerProvider');
  }
  return context as EventContainerContextType<T>;
};

type EventContainerProviderProps = {
  children: React.ReactNode;
};

export const EventContainerProvider: React.FC<EventContainerProviderProps> = ({ children }) => {
  const emitEvent = <T = any>(eventType: string, payload: EventPayload<T>) => {
    if (eventListeners[eventType]) {
      eventListeners[eventType].forEach((listener) => listener(payload));
    }
  };

  const addEventListener = <T = any>(eventType: string, callback: EventListener<T>) => {
    if (!eventListeners[eventType]) {
      eventListeners[eventType] = [];
    }
    eventListeners[eventType].push(callback);
  };

  const removeEventListener = <T = any>(eventType: string, callback: EventListener<T>) => {
    if (eventListeners[eventType]) {
      eventListeners[eventType] = eventListeners[eventType].filter(
        (listener) => listener !== callback
      );
    }
  };

  const eventListeners: { [eventType: string]: EventListener<any>[] } = {};

  const contextValue: EventContainerContextType = {
    emitEvent,
    addEventListener,
    removeEventListener,
  };

  return (
    <EventContainerContext.Provider value={contextValue}>
      {children}
    </EventContainerContext.Provider>
  );
};
