import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { StackingSOPsPresenter } from "./StackingSOPsPresenter";

export const StackingSOPs: React.FC<{}> = () => {
  const { activeFBO, setFBO } = useActiveFBOs();

  const updateSOP = React.useCallback(
    async (field, value) => {
      setFBO({
        ...activeFBO,
        [field]: value,
      });
    },
    [activeFBO]
  );

  return (
    <StackingSOPsPresenter
      fbo={activeFBO}
      setFBO={setFBO}
      updateSOP={updateSOP}
    />
  );
};
