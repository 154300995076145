import { MultiPolygon } from "geojson";
import * as geometric from "geometric";
import * as md5 from "md5";
import { wrapUnplacedAirplanes } from "../screens/Hangar/HangarLayout/utils";
import { EntityPolygon, Position, Stack } from "../types";

export const positionMultiPolygon = (
  geomz: MultiPolygon,
  position: Position
): MultiPolygon => {
  const updatedCoordinates = geomz.coordinates.map((coords) => {
    return coords.map((c) =>
      c.map((p) => [
        p[0] + position.x,
        p[1] + position.y,
        p[2], // z
        p[3], // m
      ])
    );
  });
  return {
    ...geomz,
    coordinates: updatedCoordinates,
  };
};

export const rotateMultiPolygon = (
  geomz: MultiPolygon,
  pivotPoint: geometric.Point,
  position: Position
): MultiPolygon => {
  const updatedCoordinates = geomz.coordinates.map((coords) => {
    return coords.map((subpolygon) =>
      geometric
        .polygonRotate(
          subpolygon.map((c) => [c[0], c[1]]),
          // geometric rotates the other way, so negate the angle
          -position.angle,
          pivotPoint
        )
        .map((c) => [c[0], c[1], subpolygon[0][2], subpolygon[0][3]])
    );
  });
  return {
    ...geomz,
    coordinates: updatedCoordinates,
  };
};

export const getStackPolygons = (
  stack: Stack,
  layoutWidth: number,
  feetToPixels: number,
  isMultiHangar: boolean = false,
  isHoldingArea: boolean = false
): EntityPolygon[] => {
  const tenantPolygons = wrapUnplacedAirplanes(
    stack.tenants,
    layoutWidth,
    feetToPixels,
    isMultiHangar,
    isHoldingArea
  ).map(({ entity_id, position, aircraft }) => {
    /**
     * take the scaled polygon, get it into position, then rotate it on the correct pivot
     * point. the result should be a polygon that matches the outline of each aircraft.
     * we can use this to detect things like collisions
     */
    const basePolygon: geometric.Polygon = aircraft.scaled_geom_full.coordinates[0].map(
      ([x, y]) => [x + position.x, y + position.y]
    );

    const pivotPoint: geometric.Point = [
      aircraft.pivot_point.coordinates[0] + position.x,
      aircraft.pivot_point.coordinates[1] + position.y,
    ];
    const polygon = geometric.polygonRotate(
      basePolygon,
      // geometric rotates the other way, so negate the angle
      -position.angle,
      pivotPoint
    );

    return {
      entity_id,
      polygon,
      hash: md5(JSON.stringify(polygon)),
      bounds: geometric.polygonBounds(polygon),
    };
  });
  const movableObstaclePolygons = stack.movableObstacles.map(
    ({ entity_id, position, obstacle }) => {
      const basePolygon: geometric.Polygon = obstacle.geom.coordinates[0].map(
        ([x, y]) => [x + position.x, y + position.y]
      );
      const center = Math.max(obstacle.width, obstacle.length) / 2;
      const pivotPoint: geometric.Point = [
        center + position.x,
        center + position.y,
      ];
      const polygon = geometric.polygonRotate(
        basePolygon,
        // geometric rotates the other way, so negate the angle
        -position.angle,
        pivotPoint
      );
      return {
        entity_id,
        polygon,
        hash: md5(JSON.stringify(polygon)),
        bounds: geometric.polygonBounds(polygon),
      };
    }
  );

  return [...tenantPolygons, ...movableObstaclePolygons];
};

export function arraysEqual<T>(array1: T[], array2: T[]): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    const item1 = array1[i];
    const item2 = array2[i];

    if (typeof item1 !== typeof item2) {
      return false;
    }

    if (Array.isArray(item1) && Array.isArray(item2)) {
      if (!arraysEqual(item1, item2)) {
        return false;
      }
    } else if (typeof item1 === "object" && typeof item2 === "object") {
      if (!objectsEqual(item1, item2)) {
        return false;
      }
    } else if (item1 !== item2) {
      return false;
    }
  }

  return true;
}

export function objectsEqual<T extends { [key: string]: any }>(
  object1: T,
  object2: T
): boolean {
  const keys1 = Object.keys(object1 ?? {});
  const keys2 = Object.keys(object2 ?? {});

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    if (typeof value1 !== typeof value2) {
      return false;
    }

    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (!arraysEqual(value1, value2)) {
        return false;
      }
    } else if (typeof value1 === "object" && typeof value2 === "object") {
      if (!objectsEqual(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
}

export const anglediff = (a: number, b: number) => {
  return Math.abs(((a - b + 180) % 360) - 180);
};

export const removeNonNumericAndParse = (value: string): number | null => {
  // Remove all non-numeric characters and "." from the string
  const cleanedValue = value.replace(/[^0-9.]/g, "");

  // Attempt to parse the cleaned value to a number
  const parsedNumber = Number(cleanedValue);

  // Return the parsed number if it's not NaN, otherwise return null
  return isNaN(parsedNumber) ? null : parsedNumber;
};

export const copyImageAndUrlToClipboard = (
  canvas: HTMLCanvasElement,
  linkUrl: string,
  type: string = "hangar"
): void => {
  // Add the image data to the clipboard data object
  const html = `<div>
      <img src="${canvas.toDataURL()}" />
      <p>Edit ${type} here: <a href="${linkUrl}">${linkUrl}</a></p>
    </div>`;

  const leBlob = new Blob([html], { type: "text/html" });
  const item = new ClipboardItem({ "text/html": leBlob });
  navigator.clipboard.write([item]);
};

export const parseMultiPolygonZM = (wkt: string): MultiPolygon => {
  // Remove 'MULTIPOLYGON ZM' prefix and surrounding parentheses
  const cleanedWkt = wkt
    .replace("MULTIPOLYGON ZM ", "")
    .trim()
    .slice(1, -1);

  // Split into individual polygons
  const polygons = cleanedWkt.split(")),((");

  // Process each polygon
  const coordinates = polygons.map((polygon) => {
    // Remove outer parentheses if present
    const cleanedPolygon = polygon.replace(/\(|\)/g, "");
    // Split the polygon into rings
    const rings = cleanedPolygon.split("),(");

    // Process each ring
    return rings.map((ring) => {
      // Split the ring into points
      const points = ring.split(",");
      // Process each point
      return points.map((point) => {
        // Split the point into coordinates and convert to numbers
        const [x, y, z, m] = point
          .trim()
          .split(/\s+/)
          .map(Number);
        return [x, y, z, m];
      });
    });
  });

  // Construct GeoJSON MultiPolygon
  const geojson: MultiPolygon = {
    type: "MultiPolygon",
    coordinates,
  };

  return geojson;
};
