import * as React from "react";
import { AutocompleteAircraft } from "../../screens/Settings/HangarSettings/AutocompleteAircraft";
import { ConfirmationDialog } from "../ConfirmationDialog";
import {
  Hangar,
  Location,
  Ramp,
  Tenant
  } from "../../types";
import { LocationTextField } from "../LocationTextField";
import { SelectTailNumber } from "../SelectTailNumber";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useLocationsState } from "../../containers/LocationsStateContainer";
import { useTenants } from "../../hooks/useTenants";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

type Props = {
  displayTypeAndLocation: boolean;
  isReference: boolean;
  tenant: Tenant;
  setTenant: (t: Tenant) => void;
  currentLocation: Location<Ramp | Hangar>;
  setCurrentLocation: (l: Location<Ramp | Hangar>) => void;
  assignedLocation: Location<Ramp | Hangar>;
  setAssignedLocation: (l: Location<Ramp | Hangar>) => void;
  onSave: () => Promise<void>;
  onClose: () => void;
};

export const EditTenantPresenter: React.FC<Props> = ({
  displayTypeAndLocation,
  tenant,
  setTenant,
  currentLocation,
  setCurrentLocation,
  assignedLocation,
  setAssignedLocation,
  onSave,
  onClose,
}) => {
  const { activeFBO } = useActiveFBOs();
  const { locations } = useLocationsState();
  const { tenants } = useTenants();
  const [showConfirmation, setShowConfirmation] = React.useState<{
    text: string;
    onOk: () => void;
  }>({
    text: "",
    onOk: () => {
      //
    },
  });
  const handleClose = () => {
    onClose();
  };

  const isDuplicateTailNumber = React.useMemo(() => {
    return Boolean(
      tenants
        .filter((t) => t.tail_number)
        .find(
          (t) =>
            t.id !== tenant.id &&
            t.tail_number.toLocaleUpperCase() ===
              tenant.tail_number.toLocaleUpperCase()
        )
    );
  }, [tenants, tenant]);

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Edit Aircraft</DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <Stack direction="column" spacing={2} sx={{ mt: 1, width: "100%" }}>
          <AutocompleteAircraft
            label={"Select an Aircraft"}
            onSelect={(aircraft) => {
              setTenant({ ...tenant, aircraft_id: aircraft.id, aircraft });
            }}
            defaultSelected={tenant.aircraft}
          />
          <Stack direction="row" spacing={2}>
            <SelectTailNumber
              disableAutocomplete={true}
              warning={isDuplicateTailNumber && "Duplicate tail number"}
              tenants={tenants}
              defaultValue={tenant.tail_number}
              onInputChange={(tailNumber: string) => {
                setTenant({ ...tenant, tail_number: tailNumber });
              }}
            />
            <Stack direction="column" sx={{ width: "100%" }}>
              <TextField
                inputProps={{ testid: "owner_manager" }}
                size="small"
                fullWidth
                label="Owner/Manager"
                onChange={(evt) =>
                  setTenant({ ...tenant, owner_manager: evt.target.value })
                }
                value={tenant.owner_manager}
              />
              <Typography variant="caption" color="error"></Typography>
            </Stack>
          </Stack>

          {displayTypeAndLocation && (
            <>
              <Stack direction="row" spacing={2}>
                <TextField
                  disabled={activeFBO.user_role !== "manager"}
                  select
                  size="small"
                  fullWidth
                  label="Type"
                  onChange={(evt) => {
                    if (evt.target.value === "transient") {
                      setShowConfirmation({
                        text:
                          "Making this Base Tenant into a Transient means this aircraft is no longer based in your hangars. It will remain on your transient list of aircraft.",
                        onOk: () => {
                          setTenant({
                            ...tenant,
                            type: evt.target.value,
                          }),
                            setAssignedLocation(null);
                        },
                      });

                      return;
                    }
                    if (evt.target.value === "base") {
                      setShowConfirmation({
                        text:
                          "We’ll assign this new Base Tenant to this hangar. If you need to change default hangar assignments, you can do that by editing this specific aircraft.",
                        onOk: () =>
                          setTenant({ ...tenant, type: evt.target.value }),
                      });
                      return;
                    }
                  }}
                  value={tenant.type}
                  sx={{ height: "100%" }}
                >
                  <MenuItem value="base">Tenant</MenuItem>
                  <MenuItem value="transient">Transient</MenuItem>
                </TextField>
                <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                  <LocationTextField
                    disabled={
                      activeFBO?.user_role === "operator" ||
                      tenant.type !== "base"
                    }
                    isReference={true}
                    unassignedText={
                      tenant.type === "base" ? "Unassigned" : "N/A"
                    }
                    selectedLocationId={assignedLocation?.location_id}
                    onChange={(newLocationId) => {
                      const newLocation = locations.find(
                        (l) => l.location_id === newLocationId
                      );
                      setAssignedLocation(newLocation);
                    }}
                  />
                  <LocationTextField
                    isReference={false}
                    selectedLocationId={currentLocation?.location_id}
                    unassignedText="Not Hangared"
                    onChange={(newLocationId) => {
                      const newLocation = locations.find(
                        (l) => l.location_id === newLocationId
                      );
                      setCurrentLocation(newLocation);
                    }}
                  />
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
        {Boolean(showConfirmation?.text) && (
          <ConfirmationDialog
            title="Please Confirm"
            text={showConfirmation?.text}
            onCancel={() => {
              // do nothing
            }}
            onOk={() => showConfirmation?.onOk()}
            onClose={() => {
              setShowConfirmation({
                text: "",
                onOk: () => {
                  //
                },
              });
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={isDuplicateTailNumber}
          variant="contained"
          color="success"
          onClick={async () => {
            await onSave();
            handleClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
