import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { mean, round, sum } from "lodash";
import * as numeral from "numeral";
import * as React from "react";
import { useMeasure } from "react-use";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useApi } from "../../../containers/ApiContainer";
import { useHangarState } from "../../../containers/HangarStateContainer";
import { useMultiHangarState } from "../../../containers/MultiHangarContainer";
import { useThisHangar } from "../../../hooks/useThisHangar";
import { Hangar, Stack as StackType } from "../../../types";
import { sortLayoutOptions, uuidv4 } from "../../../utils";
import * as PositionCounts from "../../../utils/PositionCounts.json";
import { HangarStack } from "../Hangar";
import { StackResult } from "./StackResult";

// helper function for calculating the dimensions of one of the hangar stacks
// based on the dimensions of the hangar and the dimensions of the screen
const calculateStackResultHeight = (
  containerDimensions: { width: number; height: number },
  hangar: Hangar
): number => {
  const { width, height } = containerDimensions;

  const cellWidth = width / 2;
  const cellHeight = height / 2;
  const hangarAspectRatio = hangar.width / hangar.depth;
  if (hangar.width > hangar.depth) {
    return Math.min(cellHeight / hangarAspectRatio, cellWidth);
  }
  return cellHeight;
  return Math.min(cellWidth * hangarAspectRatio, cellHeight);
};

type Props = {
  open: boolean;
  hangarId: string;
  status: string;
  stacks: HangarStack[];
  progress: number[];
  onClose: () => void;
  isAlgorithmTesting?: boolean;
  hangar?: Hangar;
};

type DisplayableStack = {
  label?: string;
  stack: StackType;
};

export const EvaluatingStackDialog: React.FC<Props> = (props) => {
  const [
    ref,
    { width: containerWidth, height: containerHeight },
  ] = useMeasure();
  const {
    open,
    status,
    stacks,
    progress,
    onClose,
    isAlgorithmTesting = false,
  } = props;
  const { postgrest } = useApi();
  const { activeFBO } = useActiveFBOs();
  const { isReference } = useHangarState();
  const { isMultiHangar } = useMultiHangarState();
  const { hangar: stateHangar, getHangar, setHangar } = useThisHangar();
  const hangar =
    props.hangar ?? isMultiHangar ? getHangar(props.hangarId) : stateHangar;
  const [startTime, setStartTime] = React.useState<number>();
  const [endTime, setEndTime] = React.useState<number>();

  const handleClose = () => {
    onClose();
  };

  const stacksToDisplay: DisplayableStack[] = React.useMemo(() => {
    const sortedHangarStacks = sortLayoutOptions(hangar, stacks, false).slice(
      0,
      Math.min(isAlgorithmTesting ? stacks.length : 4, stacks.length)
    );

    return sortedHangarStacks.map(
      (scoredHangarStack, idx): DisplayableStack => {
        const stackId = uuidv4();
        const stack = {
          id: stackId,
          options: scoredHangarStack.stack.paramSet.options,
          tenants: scoredHangarStack.stack.stackMembers.map((stackMember) => ({
            ...hangar.stack.tenants.find(
              (t) => t.placement_id === stackMember.placement_id
            ),
            selected: false, // overwrite or keep?
            position: {
              ...hangar.stack.tenants.find(
                (t) => t.placement_id === stackMember.placement_id
              ).position,
              x: stackMember.x,
              y: stackMember.y,
              angle: stackMember.angle,
              stack_id: stackId,
            },
          })),
          movableObstacles: hangar.stack.movableObstacles,
          isReference: hangar.stack.isReference,
        };
        return {
          label: `${scoredHangarStack.stack.paramSet.label} - ${round(
            scoredHangarStack.score,
            2
          )}`,
          stack,
        };
      }
    );
  }, [props.hangar, stacks]);

  React.useEffect(() => {
    if (status === "in progress") {
      setStartTime(+new Date());
    }
    if (status === "complete") {
      setEndTime(+new Date());
    }
  }, [status]);

  const timeElapsed =
    Boolean(startTime) &&
    Boolean(endTime) &&
    round((endTime - startTime) / 1000, 1);

  const percentOf200x200 = (hangar?.width * hangar?.depth) / (200 * 200);

  const totalPositionsToEvaluate = sum(
    hangar?.stack?.tenants?.map(
      (t) =>
        percentOf200x200 *
        16 * // avg number of angles per position
        (PositionCounts.find((p) => p.aircraft_id === t.aircraft_id)?.count ??
          10000)
    )
  );

  const totalScenariosEvaluated =
    mean(progress) * progress.length * totalPositionsToEvaluate || 0;
  const totalScenarios = progress.length * totalPositionsToEvaluate;
  const scenariosProgress =
    totalScenarios === 0
      ? `Preparing for takeoff...`
      : `${numeral(
          totalScenariosEvaluated,
          "0,0"
        ).format()} scenarios evaluated`;

  const stackResultHeight = calculateStackResultHeight(
    {
      width: containerWidth,
      height: window.innerHeight - 325,
    },
    hangar
  );

  return (
    <Dialog
      ref={ref}
      open={open}
      onClose={() => {
        // nothing
      }}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography>
            {status === "complete" ? "Select a Stack" : "Evaluating Stack"}
          </Typography>

          <IconButton
            onClick={() => {
              // user clicks on area outside of of modal window. kill!
              window.globalThis.airplxKillSwitch = 2;
              window.globalThis.airplxAborter?.abort();
              handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 0, width: "100%" }}>
        <Stack direction="column" spacing={1} sx={{ minWidth: 800 }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            {stacksToDisplay.map(({ label, stack }) => (
              <Grid
                key={`stack-result-${stack.id}`}
                item
                xs={12}
                md={6}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                {isAlgorithmTesting && (
                  <Typography
                    sx={{ width: "100%" }}
                    align="center"
                    variant="caption"
                    component="div"
                  >
                    {label}
                  </Typography>
                )}
                <StackResult
                  height={stackResultHeight}
                  status={status}
                  isReference={isReference}
                  stack={stack}
                  hangar={hangar}
                  setHangar={setHangar}
                  onClose={onClose}
                  onFlagResult={async (id, isFlagged) => {
                    if (!isFlagged) {
                      // delete matching records in flagged_stack
                      await postgrest
                        .from("flagged_stack")
                        .delete()
                        .eq("id", id);
                      return;
                    }
                    // add to flagged_stack
                    const flaggedStack = stacksToDisplay.find(
                      (s) => id === s.stack.id
                    ).stack;
                    const alternate_stacks = stacksToDisplay
                      .filter((s) => s.stack.id !== id)
                      .map(({ stack }) => stack);

                    await postgrest.from("flagged_stack").insert({
                      id,
                      fbo_id: activeFBO.id,
                      hangar_id: hangar.id,
                      stack: flaggedStack,
                      alternate_stacks,
                    });
                  }}
                />
              </Grid>
            ))}
          </Grid>
          {status === "complete" ? (
            <Box></Box>
          ) : (
            <Box>
              <LinearProgress
                color="success"
                variant="determinate"
                sx={{ height: 10, width: "100%" }}
                value={100 * mean(progress)}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 1 }}
              >
                <Typography variant="body2">{scenariosProgress}</Typography>
              </Stack>
            </Box>
          )}
        </Stack>
        {status == "complete" && (
          <Stack direction="row" justifyContent="center">
            <Typography variant="body2" component="div">
              Stacking finished in {timeElapsed}s. Not happy with your stack?{" "}
              <Typography
                variant="body2"
                component="span"
                color="success.main"
                onClick={handleClose}
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
              >
                Try modifying your requirements.
              </Typography>
            </Typography>
          </Stack>
        )}
      </DialogContent>

      {status === "complete" ? (
        <div />
      ) : (
        <DialogActions sx={{ pb: 2 }}>
          <Button
            disabled={window.globalThis.airplxKillSwitch > 0}
            color="error"
            onClick={() => {
              window.globalThis.airplxKillSwitch = 2;
              window.globalThis.airplxAborter.abort();
              handleClose();
            }}
          >
            Cancel Stack
          </Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            disabled={window.globalThis.airplxKillSwitch === 1}
            variant="contained"
            color="success"
            onClick={() => {
              window.globalThis.airplxKillSwitch = 1;
              window.globalThis.airplxAborter.abort();
            }}
          >
            End Stacking Early
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
