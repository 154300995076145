import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import * as React from "react";

export type ConfirmationDialogProps = Props;
export type Props = {
  title: string;
  text: string | React.ReactNode;
  onCancel?: () => void;
  onOk: () => void;
  onClose: () => void;
  okText?: string;
  cancelText?: string;
  typeToConfirm?: boolean;
};

export const ConfirmationDialog: React.FC<Props> = ({
  title,
  text,
  okText,
  cancelText,
  onCancel,
  onOk,
  onClose,
  typeToConfirm,
}) => {
  const [confirmText, setConfirmText] = React.useState<string>("");

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onOk();
      onClose();
    }
  };

  return (
    <Dialog open onKeyDown={handleKeyDown}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <br />
        {typeToConfirm && (
          <Stack direction="column" spacing={1}>
            <DialogContentText>
              Type 'delete' to confirm deletion.
            </DialogContentText>
            <TextField
              fullWidth
              size="small"
              placeholder="delete"
              onChange={(evt) => setConfirmText(evt.target.value)}
              value={confirmText}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        {Boolean(onCancel) && (
          <Button
            onClick={() => {
              onCancel();
              onClose();
            }}
          >
            {cancelText ?? "Cancel"}
          </Button>
        )}
        <Button
          variant="contained"
          color="success"
          disabled={typeToConfirm && confirmText !== "delete"}
          onClick={() => {
            onOk();
            onClose();
          }}
        >
          {okText ?? "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
