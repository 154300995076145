import {
  rotatePolygon,
  translatePolygon,
} from "./DraggableAircraftNew/math/geometry";

export const truncateAt15Chars = (text: string): string => {
  if (text.length <= 15) {
    return text;
  }
  return text.slice(0, 15) + "...";
};

/**
 * We do some work here to figure out what size the buffer polygon
 * should be because we know the scale of it is 1 foot = 1 pixel and
 * we have a feet to pixels ratio used to display the hanger
 * We also do some work here to massage the polygon into
 * a format we can more easily display on the screen, i.e.
 *   - rotate by 180 so it faces the same way as the aircraft
 *   - align the left and top most points at position 0 so that when
 *     displayed inside an SVG viewbox it filles the whole space and
 *     doesn't get cut off by the sides (if it has a negative value)
 * If it makes it more optimial, these changes could be done server
 * side to avoid needing to do them on the client.
 */
export const getSpacingBufferDimensions = (isHangar, aircraft, params) => {
  const { feetToPixels } = params;

  const geom = isHangar
    ? aircraft.geom_scaled_buffer
    : aircraft.ramp_geom_scaled_buffer;

  var yCoords = geom["coordinates"][0].map((coord) => coord[1]);
  var xCoords = geom["coordinates"][0].map((coord) => coord[0]);
  var maxY = Math.max(...yCoords);
  var minY = Math.min(...yCoords);
  var maxX = Math.max(...xCoords);
  var minX = Math.min(...xCoords);

  // The Geometry for the geom_scaled_buffer is not at the same scale
  // as the geom object, it is at the scale 1 foot = 1 pixel (or SVG unit)
  // The geom object is the aircraft within a 600x600 square
  // We are using the above to figure out the dimensions of the
  // geom_scaled_buffer which we can then use against the current hangar
  // scale to get the true pixel size of the spacing buffer
  var spacingBufferImageDimensions = {
    width: maxX - minX,
    height: maxY - minY,
  };

  var tempGeomScaledBuffer = geom;

  // The polygon will get turned into an SVG so lets align all points
  // to 0,0
  tempGeomScaledBuffer = translatePolygon(tempGeomScaledBuffer, {
    x: -minX,
    y: -minY,
  });

  // Knowing the SVG dimensions we can now rotate around the mid point
  // because the geom_scaled_buffer is at a 180 compared to the geom
  // polygon
  tempGeomScaledBuffer = rotatePolygon(tempGeomScaledBuffer, Math.PI, {
    x: spacingBufferImageDimensions.width / 2,
    y: spacingBufferImageDimensions.height / 2,
  });

  // Do the scale to get the pixel size
  spacingBufferImageDimensions.width *= feetToPixels;
  spacingBufferImageDimensions.height *= feetToPixels;

  return {
    spacing_buffer_geom: tempGeomScaledBuffer,
    spacing_buffer_geom_dimensions: spacingBufferImageDimensions,
  };
};
