import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { MuiColorInput } from "mui-color-input";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Shape } from "./RampCanvas";
import { rotatePoint, smoothLine } from "./math";

export const PREFIXED_COLORS = [
  {
    label: "OUTLINE",
    color: "#3c6ce9",
  },
  {
    label: "RED_SOLID",
    color: "#FF0000",
  },
  {
    label: "GREY",
    color: "#808080",
  },
  {
    label: "WHITE",
    color: "#FCFAFF",
  },
  {
    label: "YELLOW",
    color: "#eed202",
  },
  {
    label: "REFERENCE_DISTANCE",
    color: "#e3c783",
  },
];

export const SHAPE_COLORS = [
  "rgba(255, 0, 0, 0.5)",
  "rgba(255, 255, 0, 0.5)",
  "rgba(0, 255, 0, 0.5)",
  "rgba(0, 0, 255, 0.5)",
];

export const TEXT_COLORS = [
  "black",
  "white",
  "red",
  "green",
  "blue",
  "yellow",
  "purple",
  "orange",
  "pink",
  "brown",
];

export const LINE_STYLES = ["solid", "dashed", "dotted"];

type Props = {
  contextMenu: {
    x: number;
    y: number;
    shapeIndex: number;
  } | null;
  setContextMenu: (contextMenu: Props["contextMenu"]) => void;
  onClose: () => void;
  shapes: Shape[];
  setShapes: (shapes: Shape[]) => void;
};

export const ShapeEditDialog: React.FC<Props> = ({
  contextMenu,
  setContextMenu,
  onClose,
  shapes,
  setShapes,
}) => {
  const shape = shapes[contextMenu?.shapeIndex];

  return (
    <Dialog open={!!contextMenu} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <Stack direction="column" spacing={1} alignItems="flex-start">
          <Typography variant="h6">Edit Shape</Typography>
          {/* select color */}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              size="small"
              fullWidth
              label="Pre-fixed Colors"
              select
              value={shapes[contextMenu?.shapeIndex]?.strokeStyle}
              onChange={(event) => {
                const updatedShapes = [...shapes];
                updatedShapes[contextMenu.shapeIndex].strokeStyle =
                  event.target.value;
                setShapes(updatedShapes);
              }}
            >
              {PREFIXED_COLORS.map((option) => (
                <MenuItem key={option.label} value={option.color}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <MuiColorInput
              format="hex"
              fullWidth
              size="small"
              label="Custom Color"
              onChange={(color) => {
                const updatedShapes = [...shapes];
                updatedShapes[contextMenu.shapeIndex].strokeStyle = color;
                setShapes(updatedShapes);
              }}
              value={shapes[contextMenu?.shapeIndex]?.strokeStyle}
            />
          </Stack>
          {/* line style */}
          <TextField
            size="small"
            fullWidth
            label="Line Style"
            select
            value={shapes[contextMenu?.shapeIndex]?.lineStyle}
            onChange={(event) => {
              const updatedShapes = [...shapes];
              updatedShapes[contextMenu.shapeIndex].lineStyle =
                event.target.value;
              setShapes(updatedShapes);
            }}
          >
            {[...LINE_STYLES].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            type="number"
            label="Line Width"
            inputProps={{ min: 1, max: 20 }}
            onChange={(event) => {
              const updatedShapes = [...shapes];
              updatedShapes[contextMenu.shapeIndex].lineWidth = Number(
                event.target.value
              );
              setShapes(updatedShapes);
            }}
            value={shapes[contextMenu?.shapeIndex]?.lineWidth}
          />
          <TextField
            size="small"
            type="number"
            label="Font Size"
            inputProps={{ min: 10, max: 64 }}
            onChange={(event) => {
              const updatedShapes = [...shapes];
              updatedShapes[contextMenu.shapeIndex].fontSize = Number(
                event.target.value
              );
              setShapes(updatedShapes);
            }}
            value={shapes[contextMenu?.shapeIndex]?.fontSize}
          />
          <TextField size="small" label="Text" />
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Rotate</Typography>
            <IconButton
              onClick={() => {
                const centerX =
                  shape.points.reduce((sum, p) => sum + p.x, 0) /
                  shape.points.length;
                const centerY =
                  shape.points.reduce((sum, p) => sum + p.y, 0) /
                  shape.points.length;
                // Rotate each point in the shape by the incremental angle change
                const rotatedPoints = shape.points.map((point) =>
                  rotatePoint(point, { x: centerX, y: centerY }, -1)
                );
                const updatedShapes = [...shapes];
                updatedShapes[contextMenu.shapeIndex].points = rotatedPoints;
                setShapes(updatedShapes);
              }}
            >
              <RotateLeftIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                const centerX =
                  shape.points.reduce((sum, p) => sum + p.x, 0) /
                  shape.points.length;
                const centerY =
                  shape.points.reduce((sum, p) => sum + p.y, 0) /
                  shape.points.length;
                // Rotate each point in the shape by the incremental angle change
                const rotatedPoints = shape.points.map((point) =>
                  rotatePoint(point, { x: centerX, y: centerY }, 1)
                );
                const updatedShapes = [...shapes];
                updatedShapes[contextMenu.shapeIndex].points = rotatedPoints;
                setShapes(updatedShapes);
              }}
            >
              <RotateRightIcon />
            </IconButton>
          </Stack>
        </Stack>
        <DialogActions>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Button
              color="error"
              onClick={() => {
                const updatedShapes = [...shapes];
                updatedShapes.splice(contextMenu.shapeIndex, 1);
                setShapes(updatedShapes);
                setContextMenu(null);
              }}
            >
              Delete
            </Button>
            <Stack direction="row" spacing={1}>
              {shape?.type === "Line" && (
                <Button
                  onClick={() => {
                    const updatedShapes = [...shapes];
                    updatedShapes[contextMenu.shapeIndex].points = smoothLine(
                      updatedShapes[contextMenu.shapeIndex].points
                    );
                    setShapes(updatedShapes);
                  }}
                >
                  Smooth Line
                </Button>
              )}
              <Button
                onClick={() => {
                  const updatedShapes = [...shapes];
                  updatedShapes.push({ ...shape });
                  setShapes(updatedShapes);
                }}
              >
                Copy
              </Button>
              <Button onClick={onClose}>Cancel</Button>
              <Button variant="contained" onClick={onClose}>
                OK
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
