import {
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import * as React from "react";
import { FBOSettings } from "../../../types";

type Props = {
  settings: FBOSettings;
  setSettings: (settings: FBOSettings) => void;
};

export const ConfigurationPresenter: React.FC<Props> = ({
  settings,
  setSettings,
}) => {
  return (
    <Stack direction="column" spacing={1} sx={{ maxWidth: 1000, p: 2 }}>
      <Stack direction="column">
        <Typography>Display Estimated Value?</Typography>
        <Stack direction="column">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={(evt) => {
                  setSettings({
                    ...settings,
                    displayEstimatedValueForManager: evt.target.checked,
                  });
                }}
                checked={settings?.displayEstimatedValueForManager ?? true}
              />
            }
            label="Managers"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={(evt) => {
                  setSettings({
                    ...settings,
                    displayEstimatedValueForOperator: evt.target.checked,
                  });
                }}
                checked={settings?.displayEstimatedValueForOperator ?? true}
              />
            }
            label="Operators"
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="column">
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography>
            Ignore Dead Space in Utilization Calculations?
          </Typography>
          <Switch
            checked={settings?.ignoreDeadSpaceForUtilization ?? false}
            onChange={(evt) =>
              setSettings({
                ...settings,
                ignoreDeadSpaceForUtilization: evt.target.checked,
              })
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
