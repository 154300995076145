import * as React from "react";
import { Hangar } from "../../../types";
import { HangarEditorPresenter } from "./HangarEditorPresenter";
import { HangarStateContainer } from "../../../containers/HangarStateContainer";

type Props = {
  hangar: Hangar;
  setHangar: (hangar: Hangar) => void;
  onClose: () => void;
};

export const HangarEditor: React.FC<Props> = (props) => {
  if (!props.hangar?.id) {
    return <div />;
  }
  return (
    <HangarStateContainer
      initialState={{
        hangar_id: props.hangar?.id,
        isReference: false,
      }}
    >
      <HangarEditorPresenter {...props} />
    </HangarStateContainer>
  );
};
