import * as React from "react";
import { createContainer } from "./StateContainer";

type Props = {};
const useDraggableAircraftContainerState = (props: Props) => {
  const [placementIdBeingDragged, setPlacementIdBeingDragged] = React.useState<
    string
  >("");
  return {
    isTugging: (id: string) => id === placementIdBeingDragged,
    setIsTugging: (id: string) => setPlacementIdBeingDragged(id),
  };
};
const DraggableAircraftContainerState = createContainer(
  useDraggableAircraftContainerState
);

export const DraggableAircraftContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children, ...props }) => {
  const nested = DraggableAircraftContainerState.useContainer();
  return (
    <DraggableAircraftContainerState.Provider
      initialState={{ ...nested, ...props }}
    >
      {children}
    </DraggableAircraftContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useDraggableAircraft =
  DraggableAircraftContainerState.useContainer;
