import * as React from "react";
import { useApi } from "../containers/ApiContainer";
import { useIdentity } from "../containers/IdentityContainer";
import { usePrevious } from "react-use";
import { v4 as uuidv4 } from "uuid";

export type UserPreferences = {
  id: string;
  user_id: string;
  preferences: Preferences;
};
export type Preferences = { [key: string]: any };

export const useUserPreferences = () => {
  const { postgrest } = useApi();
  const { airplxIdentity } = useIdentity();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [userPreferences, setUserPreferences] = React.useState<
    UserPreferences
  >();
  const previousUserPreferences = usePrevious(userPreferences);
  React.useEffect(() => {
    (async () => {
      if (!airplxIdentity?.userid) {
        setLoading(false);
        return;
      }
      const { data } = await postgrest
        .from("user_preferences")
        .select()
        .single();
      setUserPreferences({
        ...data,
        id: data?.id ?? uuidv4(),
        user_id: data?.user_id ?? airplxIdentity?.userid,
        preferences: data?.preferences ?? {},
      });
      setLoading(false);
    })();
  }, []);

  React.useEffect(() => {
    if (!userPreferences || !previousUserPreferences) {
      return;
    }
    (async () => {
      await postgrest.from("user_preferences").upsert({
        ...userPreferences,
      });
    })();
  }, [userPreferences]);

  const setPreferences = React.useCallback(
    (preferences: Preferences) => {
      setUserPreferences({
        ...userPreferences,
        preferences,
      });
    },
    [userPreferences]
  );

  return {
    loading,
    userPreferences,
    setUserPreferences,
    preferences: userPreferences?.preferences ?? {},
    setPreferences,
  };
};
