import { Divider, Stack } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AutocompleteResultsContainer } from "../../containers/AutocompleteResultsContainer";
import { useRampState } from "../../containers/RampStateContainer";
import { Stack as StackType } from "../../types";
import { CurrentAircraft } from "../../widgets/CurrentAircraft/CurrentAircraft";
import { Layout } from "../../widgets/Layout";
import { LayoutToolbar } from "../../widgets/LayoutToolbar";
import { ZoomControl } from "../../widgets/ZoomControl";
import { RampInfoCard } from "./RampInfoCard";
import { RampPrintLayout } from "./RampPrintLayout";
import { RampSearch } from "./RampSearch";

export const INFO_AND_CURRENT_AIRCRAFT_WIDTH = 400;

export const RampsPresenter: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { ramp, setRamp, history } = useRampState();

  const [width, setWidth] = React.useState<number>(700);

  const div = React.useCallback((node) => {
    const heightOffsetForRamp = 150;
    if (node?.offsetWidth && node?.offsetHeight) {
      const widthOffsetForRamp =
        (heightOffsetForRamp * ramp.width) / ramp.depth;
      const widthBasedOnAvaiableWidth = node.offsetWidth;
      const widthBasedOnAvailableHeight =
        (node.offsetHeight * ramp.width) / ramp.depth - heightOffsetForRamp;
      setWidth(
        Math.min(widthBasedOnAvaiableWidth, widthBasedOnAvailableHeight)
      );
    }
  }, []);

  const setStack = React.useCallback(
    (stack: StackType) => {
      setRamp((ramp) => ({
        ...ramp,
        stack: {
          ...ramp.stack,
          ...stack,
        },
      }));
    },
    [ramp, setRamp]
  );

  return (
    <AutocompleteResultsContainer>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{ height: "100%", width: "100%" }}
      >
        <Stack direction="row" sx={{ width: "100%", height: "100%" }}>
          <Stack
            direction="column"
            spacing={1}
            sx={{
              maxHeight: "100%",
              width: INFO_AND_CURRENT_AIRCRAFT_WIDTH,
              borderLeft: "1px solid lightgrey",
              borderRight: "1px solid lightgrey",
            }}
          >
            <RampInfoCard ramp={ramp} onClickBack={() => navigate("/ramps")} />
            <RampSearch ramp={ramp} setStack={setStack} />
            <Divider />
            <CurrentAircraft
              location={{ type: "ramp", ...ramp }}
              stack={ramp.stack}
              setStack={(stack) => {
                setRamp({
                  ...ramp,
                  stack: {
                    ...ramp.stack,
                    ...stack,
                  },
                });
              }}
            />
          </Stack>
          <Stack
            direction="column"
            sx={{
              flex: 1,
              height: "100%",
              width: "100%",
            }}
            ref={div}
          >
            <LayoutToolbar
              location={{ type: "ramp", ...ramp }}
              setLocation={({ type, ...location }) =>
                setRamp({ ...ramp, ...location })
              }
              stack={ramp.stack}
              setStack={setStack}
              setStacks={() => {
                // don't need this b/c not autostacking
              }}
              onClickUndo={() => {
                history && history.undo();
              }}
              renderOnPrint={() => <RampPrintLayout />}
            />

            <div
              style={{
                flex: 1,
                // This stops the aircraft being dragged outside of this section
                // overflow: "scroll",
                position: "relative",
                height: "100%",
              }}
            >
              <Layout
                width={width}
                location={{ type: "ramp", ...ramp }}
                stack={ramp?.stack}
                setStack={setStack}
                options={{
                  hideUnplaced: false,
                  readOnly: false,
                  printable: false,
                  selectable: false,
                }}
              />
            </div>
          </Stack>
          <ZoomControl
            currentZoom={width}
            increment={0.1}
            onClickPlus={() => setWidth(width * 1.1)}
            onClickMinus={() => setWidth(width * 0.9)}
          />
        </Stack>
      </Stack>
    </AutocompleteResultsContainer>
  );
};
