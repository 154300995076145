import * as React from "react";
import { PredefinedObstacle } from "../hooks/usePredefinedObstacle";

const PredefinedObstaclesContainerState = React.createContext<{
  loading: boolean;
  predefinedObstacles: PredefinedObstacle[];
  setPredefinedObstacles: (PredefinedObstacles: PredefinedObstacle[]) => void;
}>({
  loading: false,
  predefinedObstacles: [],
  setPredefinedObstacles: function() {},
});

export const PredefinedObstaclesContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children, ...props }) => {
  const data = usePredefinedObstaclesState();
  return (
    <PredefinedObstaclesContainerState.Provider value={data}>
      {/* {!data.loading ? children : <Loading />} */}
      {children}
    </PredefinedObstaclesContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const usePredefinedObstaclesState = () =>
  React.useContext(PredefinedObstaclesContainerState);
