import { Aircraft } from "../../../LabelingTool";
import { MovableObstacle } from "../../../../types";

/* calculates what the height and width of the HTML aircraft should be based on
  the wingspan and length of the aircraft. there are some key assumptions here:
    - each image is a square (600x600px)
    - if the airplane is wider than it is long, the wings will touch the left and right edge of the image
    - if the airplane is longer than it is wide, the nose/tail will touch the top and bottom edge of the image
*/
export const calculateAircraftImageDimensions = (
  pixelToFeet: number,
  placedAircraft: Aircraft
): {
  width: number;
  height: number;
} => {
  let height, width;
  // aircraft is longer than it is wide, we can assume the length = 600px
  if (placedAircraft.length > placedAircraft.wingspan) {
    height = pixelToFeet * placedAircraft.length;
    width = height * (placedAircraft.wingspan / placedAircraft.length);
  } else {
    // aircraft is wider than it is long, we can assume the wingspan = 600px
    width = pixelToFeet * placedAircraft.wingspan;
    height = width * (placedAircraft.length / placedAircraft.wingspan);
  }

  // we take the max because everything becomes a square
  return {
    width: Math.max(width, height),
    height: Math.max(width, height),
  };
};

export const calculateObstacleImageDimensions = (
  pixelToFeet: number,
  movableObstacle: MovableObstacle
): {
  width: number;
  height: number;
} => {
  let height, width;
  // aircraft is longer than it is wide, we can assume the length = 600px
  if (movableObstacle.obstacle.length > movableObstacle.obstacle.width) {
    height = pixelToFeet * movableObstacle.obstacle.length;
    width =
      height *
      (movableObstacle.obstacle.width / movableObstacle.obstacle.length);
  } else {
    // aircraft is wider than it is long, we can assume the wingspan = 600px
    width = pixelToFeet * movableObstacle.obstacle.width;
    height =
      width *
      (movableObstacle.obstacle.length / movableObstacle.obstacle.width);
  }

  // we take the max because everything becomes a square
  return {
    width: Math.max(width, height),
    height: Math.max(width, height),
  };
};
