import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Polygon } from "geojson";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { Hangar, Obstacle, ObstacleType } from "../../../types";
import { uuidv4 } from "../../../utils";
import Canvas from "../../../widgets/useCanvas";
import { DraggableGarageDoor } from "./DraggableGarageDoor";
import { DraggableObstacle } from "./DraggableObstacle";
import {
  clearBackgroundForObstacles,
  drawDistanceMarkers,
  drawDoors,
  drawGarageDoorMarkers,
  drawSpacingBuffers,
  drawUnobstructiveObstacles,
} from "./ObstacleCanvasDrawers";

interface IObstacle {
  x: number;
  y: number;
  width: number;
  depth: number;
}

type ObstacleSpacing = {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
};

export const getObstacleGeometry = (
  obstacle: IObstacle,
  spacing: ObstacleSpacing = {}
): Polygon => {
  return {
    type: "Polygon",
    coordinates: [
      [
        // top left
        [obstacle.x - (spacing.left ?? 0), obstacle.y - (spacing.top ?? 0)],
        // top right
        [
          obstacle.x + obstacle.width + (spacing.right ?? 0),
          obstacle.y - (spacing.top ?? 0),
        ],
        // bottom right
        [
          obstacle.x + obstacle.width + (spacing.right ?? 0),
          obstacle.y + obstacle.depth + (spacing.bottom ?? 0),
        ],
        // bottom left
        [
          obstacle.x - (spacing.left ?? 0),
          obstacle.y + obstacle.depth + (spacing.bottom ?? 0),
        ],
        // top left
        [obstacle.x - (spacing.left ?? 0), obstacle.y - (spacing.top ?? 0)],
      ],
    ],
  } as Polygon;
};

type ObstacleToolbarProps = {
  hangar: Hangar;
  onAdd: (obstacle: Obstacle) => void;
};

export const ObstacleToolbar: React.FC<ObstacleToolbarProps> = ({
  hangar,
  onAdd,
}) => {
  const { activeFBO } = useActiveFBOs();
  const [open, setOpen] = React.useState<boolean>(false);
  const [obstacle, setObstacle] = React.useState<Obstacle>();

  React.useEffect(() => {
    setObstacle({
      fbo_id: activeFBO.id,
      hangar_id: hangar.id,
      id: uuidv4(),
      x: 0,
      y: 0,
      width: 20,
      depth: 20,
      height: null,
      geom: getObstacleGeometry({
        x: 0,
        y: 0,
        width: 20,
        depth: 20,
      }),
      type:
        activeFBO.subscription === "standard"
          ? ObstacleType.NO_TOW_AREA
          : ObstacleType.OBSTRUCTION,
    });
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  if (!obstacle) {
    return <div />;
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        sx={{ mb: 1 }}
      >
        <Stack direction="column" spacing={1}>
          <Tooltip title="Add Obstacle">
            <Button
              variant="contained"
              color="info"
              startIcon={<AddIcon />}
              onClick={() => setOpen(true)}
            >
              Add Obstacle
            </Button>
          </Tooltip>
          <Stack direction="row" spacing={1} alignItems="center">
            <InfoIcon fontSize="small" />
            <Typography variant="caption">
              right-click to delete an obstacle
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Obstacle/No Tow Area</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <TextField
              fullWidth
              size="small"
              select
              required
              value={obstacle.type}
              onChange={(evt) =>
                setObstacle({
                  ...obstacle,
                  type: evt.target.value as ObstacleType,
                })
              }
            >
              <MenuItem
                value={ObstacleType.OBSTRUCTION}
                disabled={activeFBO.subscription === "standard"}
              >
                obstruction
              </MenuItem>
              <MenuItem value={ObstacleType.NO_TOW_AREA}>no tow area</MenuItem>
              <MenuItem value={ObstacleType.UNOBSTRUCTIVE}>
                unobstructive
              </MenuItem>
              <MenuItem value={ObstacleType.CAUTION}>caution</MenuItem>
            </TextField>
            <TextField
              type="number"
              fullWidth
              required
              inputProps={{
                min: 1,
                max: 1000,
                step: 0.1,
              }}
              size="small"
              onChange={(evt) =>
                setObstacle({
                  ...obstacle,
                  width: parseFloat(evt.target.value),
                })
              }
              value={obstacle.width}
              label="Width (feet)"
            />
            <TextField
              type="number"
              fullWidth
              required
              inputProps={{
                min: 1,
                max: 1000,
                step: 0.1,
              }}
              size="small"
              onChange={(evt) =>
                setObstacle({
                  ...obstacle,
                  depth: parseFloat(evt.target.value),
                })
              }
              value={obstacle.depth}
              label="Depth (feet)"
            />
            <TextField
              type="number"
              fullWidth
              inputProps={{
                min: 1,
                max: 100,
                step: 0.1,
              }}
              size="small"
              onChange={(evt) =>
                setObstacle({
                  ...obstacle,
                  height: parseFloat(evt.target.value),
                })
              }
              value={obstacle.height}
              label="Height (feet)"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              onAdd({
                ...obstacle,
                geom: getObstacleGeometry(obstacle),
              });
              handleClose();
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

type Props = {
  hangar: Hangar;
  setHangar: (h: Hangar) => void;
};

export const ObstacleCanvas: React.FC<Props> = ({ hangar, setHangar }) => {
  const [canvasHeight, setCanvasHeight] = React.useState<number>(0);
  const div = React.useCallback(
    (node) => {
      if (node?.offsetWidth && node?.offsetHeight) {
        const maxHeight = Math.min(
          node.parentNode.parentNode.parentNode.offsetHeight - 100,
          // 999999999,
          node?.offsetWidth * (hangar.depth / hangar.width)
        );
        setCanvasHeight(maxHeight);
      }
    },
    [hangar?.width]
  );

  const canvasRef = React.createRef();
  const canvasWidth = (canvasHeight * hangar?.width) / hangar?.depth;
  const feetToPixels = (feet: number) => (feet * canvasWidth) / hangar?.width;

  if (!hangar) {
    return null;
  }

  const draw = React.useCallback(
    (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      drawDoors(ctx, hangar, feetToPixels);
      drawSpacingBuffers(ctx, hangar, feetToPixels);
      drawUnobstructiveObstacles(ctx, hangar, feetToPixels);
      drawGarageDoorMarkers(ctx, hangar, hangar.garageDoors, feetToPixels);
      drawDistanceMarkers(
        ctx,
        hangar,
        hangar.obstacles.find((o) => o.active),
        feetToPixels
      );
      clearBackgroundForObstacles(ctx, hangar, feetToPixels);
    },
    [hangar, canvasHeight]
  );

  const handleMouseDown = (evt) => {};

  const handleMouseMove = (evt) => {};

  const handleMouseExit = (evt) => {};

  const backgroundColor = "#E8EBF1";
  const dashColor = "#ADBDD5";
  const dashWidth = 3;
  const gridSize = feetToPixels(10);
  const dashLength = gridSize / 10;

  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <ObstacleToolbar
        hangar={hangar}
        onAdd={(obstacle) =>
          setHangar({ ...hangar, obstacles: [...hangar.obstacles, obstacle] })
        }
      />
      <Box
        ref={div}
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            // need to account for border here
            width: canvasWidth,
            height: canvasHeight,
            // border: `2px solid ${dashColor}`,
          }}
        >
          {hangar.obstacles
            .filter((o) => {
              const activeGarageDoor = hangar.garageDoors.find((g) => g.active);
              return !Boolean(activeGarageDoor);
            })
            .map((obstacle) => (
              <React.Fragment key={`obstacle-${obstacle.id}`}>
                <DraggableObstacle
                  hangar={hangar}
                  feetToPixels={feetToPixels(1)}
                  obstacle={obstacle}
                  onDrag={(obstacle) => {
                    setHangar({
                      ...hangar,
                      obstacles: hangar.obstacles.map((o) =>
                        o.id === obstacle.id ? obstacle : o
                      ),
                    });
                  }}
                  onDelete={(obstacle) =>
                    setHangar({
                      ...hangar,
                      obstacles: hangar.obstacles.filter(
                        (o) => o.id !== obstacle.id
                      ),
                    })
                  }
                />
              </React.Fragment>
            ))}
          {hangar.garageDoors
            .filter((o) => {
              const activeObstacle = hangar.obstacles.find((o) => o.active);
              return !Boolean(activeObstacle);
            })
            .map((garageDoor) => (
              <React.Fragment key={`garageDoor-${garageDoor.id}`}>
                <DraggableGarageDoor
                  hangar={hangar}
                  feetToPixels={feetToPixels(1)}
                  garageDoor={garageDoor}
                  onDrag={(garageDoor) => {
                    setHangar({
                      ...hangar,
                      garageDoors: hangar.garageDoors.map((g) =>
                        g.id === garageDoor.id ? garageDoor : g
                      ),
                    });
                  }}
                  onDelete={(garageDoor) =>
                    setHangar({
                      ...hangar,
                      garageDoors: hangar.garageDoors.filter(
                        (g) => g.id !== garageDoor.id
                      ),
                    })
                  }
                />
              </React.Fragment>
            ))}
        </Box>
        <Canvas
          ref={canvasRef}
          width={canvasWidth}
          height={canvasHeight}
          draw={draw}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseExit}
          onMouseLeave={handleMouseExit}
          style={{
            backgroundColor,
            backgroundSize: `${dashLength * 2}px ${dashLength *
              2}px, ${gridSize}px ${gridSize}px`,
            backgroundPosition: `${-dashWidth / 2}px ${-dashWidth / 2}px`,
            backgroundImage: `linear-gradient(to bottom, transparent 5px, ${backgroundColor} 5px),
              linear-gradient(to right, ${dashColor}50 3px, transparent 3px),
              linear-gradient(to right, transparent 5px, ${backgroundColor} 5px),
              linear-gradient(to bottom, ${dashColor}50 3px, transparent 3px)
            `,
          }}
        />
      </Box>
    </Stack>
  );
};
