import * as React from "react";
import { calculateObstacleImageDimensions } from "../../screens/Hangar/HangarLayout/DraggableAircraftNew/Scale";
import { DraggableAircraft } from "../../screens/Hangar/HangarLayout/DraggableAircraftNew";
import { MovableObstacle, Position, Stack } from "../../types";
import { MovableObstacleContextMenu } from "../CurrentAircraft/AircraftPreferencesListItemMenu";
import { Popover } from "@mui/material";
import { Preferences } from "../../hooks/useUserPreferences";
import { RightClick } from "./Layout";

type Props = {
  layoutHeight: number;
  feetToPixels: number;
  movableObstacle: MovableObstacle;
  stack: Stack;
  setStack: (stack: Stack) => void;
  preferences: Preferences;
  onPositionChange: (placement_id: string, position: Position) => void;
  onChange?: (placement_id: string, position: Position) => void;
  rightClick: RightClick;
  setRightClick: (rightClick: RightClick) => void;
  spacingBufferOverlayElement: React.ReactNode;
  readOnly?: boolean;
};

export const FullyLoadedDraggableObstacle: React.FC<Props> = ({
  movableObstacle,
  feetToPixels,
  stack,
  setStack,
  preferences,
  layoutHeight,
  onPositionChange,
  onChange = () => {},
  rightClick,
  setRightClick,
  spacingBufferOverlayElement,
  readOnly = false,
}) => {
  const imageDimensions = {
    ...calculateObstacleImageDimensions(feetToPixels, movableObstacle),
  };

  const position = {
    x: movableObstacle.position.x * feetToPixels,
    y: movableObstacle.position.y * feetToPixels,
    angle: movableObstacle.position.angle,
    timestamp: movableObstacle.position.timestamp,
  };

  // This assumes the y position is an alignment to the bottom of the
  // hangar and this reverses it to align to the top to pass on to
  // the DraggableAircraftNew
  position.y = layoutHeight - (position.y + imageDimensions.height);
  return (
    <>
      <DraggableAircraft
        entity_id={movableObstacle.entity_id}
        hideDragIndicator
        readOnly={readOnly}
        spacingBufferOverlayElement={
          !readOnly && preferences.showSOPSpacing
            ? spacingBufferOverlayElement
            : null
        }
        forceDisplayLabel={
          preferences.forceDisplayTailNumber || preferences.forceDisplayModel
        }
        label={movableObstacle.obstacle.name}
        aircraft={{
          ...movableObstacle.obstacle,
          selected: movableObstacle.selected,
          front_wheels: 0,
          rear_wheels: 300,
          position: position,
          image: `${movableObstacle.obstacle.id}.png`,
        }}
        imageDimensions={imageDimensions}
        onPositionChange={(position) => {
          // Adjust the position to be aligned to the bottom
          // as it will come from the component aligned to the top

          // Switch the y position to align to the bottom of the hangar
          position.y = layoutHeight - (position.y + imageDimensions.height);
          position = {
            ...position,
            x: position.x / feetToPixels,
            y: position.y / feetToPixels,
          };
          onPositionChange(movableObstacle.entity_id, position);
        }}
        onChange={(position) => {
          //
        }}
        onPlaneClick={() => {
          // moved to the global onclick event
        }}
        onPlaneRightClick={({ type, event }) => {
          // moved to the global onclick event
        }}
        onStartTowing={() => {
          return;
          // we want to make sure the aircraft is selected
          setStack({
            ...stack,
            movableObstacles: stack.movableObstacles.map((mo) => ({
              ...mo,
              selected: movableObstacle.id === mo.id ? true : false,
            })),
          });
        }}
      />
      <Popover
        id={`popover-right-click-${movableObstacle.entity_id}`}
        open={rightClick?.entity_id === movableObstacle.entity_id}
        onClose={() => setRightClick(null)}
        anchorReference="anchorPosition"
        anchorPosition={
          rightClick !== null
            ? {
                top: rightClick.mouseY as number,
                left: rightClick.mouseX as number,
              }
            : undefined
        }
      >
        <MovableObstacleContextMenu
          onDelete={async () => {
            setStack({
              ...stack,
              movableObstacles: stack.movableObstacles.filter(
                (e) => e.entity_id !== movableObstacle.entity_id
              ),
            });
            setRightClick(null);
          }}
          menuProps={{
            open: true,
          }}
        />
      </Popover>
    </>
  );
};
