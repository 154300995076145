import * as React from "react";
import { Typography, useTheme } from "@mui/material";

type Props = {
  columns: { id: string; label: string; render?: () => React.ReactNode }[];
  rows: {
    id: string;
    render: (columnId: string, row: any) => React.ReactNode;
  }[];
};

export const FrozenGrid: React.FC<Props> = ({ columns, rows }) => {
  const theme = useTheme();
  const gridStyle = {
    display: "grid",
    gridTemplateColumns: `200px repeat(${columns.length - 1}, 400px)`,
    width: "fit-content",
  };
  const cellStyle = {
    padding: theme.spacing(1),
  };
  const gridHeader = {
    ...cellStyle,
    backgroundColor: "white" as const,
    position: "sticky" as const,
    top: 0,
    zIndex: 100000,
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 2,
    borderBottomStyle: "solid" as const,
  };
  const gridHeaderFixed = {
    ...gridHeader,
    zIndex: 1000002,
    position: "sticky" as const,
    left: 0,
    borderBottomWidth: 0,
  };

  const gridEntry = {
    ...cellStyle,
    height: 200,
    backgroundColor: "white",
  };

  const gridEntryFixedLeft = {
    ...gridEntry,
    position: "sticky" as const,
    left: 0,
    zIndex: 999999,
  };

  return (
    <div style={{ overflow: "scroll", height: "100%" }}>
      <div style={gridStyle}>
        {columns.map((column, idx) => (
          <Typography
            key={`grid-header-${idx}`}
            style={idx === 0 ? gridHeaderFixed : gridHeader}
            textAlign="center"
          >
            {Boolean(column.render) ? column.render() : column.label}
          </Typography>
        ))}
        {rows.map((row, idx) => (
          <React.Fragment key={`row-${idx}`}>
            {columns.map((column, idx2) => (
              <div
                key={`row-${idx}-column-${idx2}`}
                style={idx2 === 0 ? gridEntryFixedLeft : gridEntry}
              >
                {row.render(column.id, row)}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default FrozenGrid;
