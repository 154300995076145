import InfoIcon from "@mui/icons-material/Info";
import {
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useTowingEquipmentState } from "../../containers/TowingEquipmentContainer";
import { useThisHangar } from "../../hooks/useThisHangar";
import { CornerStrategies, CornerStrategy, FBO, Hangar } from "../../types";
import { BorderedBox } from "../../widgets/BorderedBox";

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Select,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

export type CustomPlacementOptions = {
  wall_spacing_left: number;
  wall_spacing_back: number;
  wall_spacing_right: number;
  horizontal_spacing: number;
  vertical_spacing: number;
  overlap: boolean;
  ignore_nose_in_out: boolean;
  ignore_front_back: boolean;
  ignore_outside_hangar: boolean;
  density_threshold?: number;
  tug_spacing?: number;
  sample_size?: number;
  corner_strategies: CornerStrategy[][];
  cloud_position_prioritization: boolean;
  xy_weights: number[];
  towing_equipment_id: string;
};

type Props = {
  onClickRunStack: (customOptions: CustomPlacementOptions) => void;
  onClose: () => void;
  isDev?: boolean;
};

type CustomStackProps = {
  customPlacementOptions: CustomPlacementOptions;
  setCustomPlacementOptions: (customOptions: CustomPlacementOptions) => void;
  minimal?: boolean;
  isDev?: boolean;
};

export const CustomStackForm: React.FC<CustomStackProps> = ({
  customPlacementOptions,
  setCustomPlacementOptions,
  minimal = false,
  isDev = false,
}) => {
  const [ignoreTowingEquipment, setIgnoreTowingEquipment] = React.useState<
    boolean
  >(false);
  const { activeFBO } = useActiveFBOs();
  const { hangar } = useThisHangar();
  const { towingEquipments: allTowingEquipments } = useTowingEquipmentState();
  const towingEquipments = allTowingEquipments.filter((t) => t.in_use);
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
        <Typography textAlign="center">Spacing SOP</Typography>
        <Tooltip title="Override SOPs. These will default to the SOPs set for your FBO.">
          <InfoIcon fontSize="small" />
        </Tooltip>
      </Stack>
      <BorderedBox sx={{ border: minimal && "none", p: minimal && 0 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <TextField
            size="small"
            type="number"
            fullWidth
            inputProps={{ min: 0, max: 20 }}
            onChange={(evt) =>
              setCustomPlacementOptions({
                ...customPlacementOptions,
                horizontal_spacing: parseFloat(evt.target.value),
              })
            }
            value={customPlacementOptions.horizontal_spacing || 0}
            label="Horizontal (ft)"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Chip
                    size="small"
                    label={`${activeFBO.sop_horizontal_spacing} ft`}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            size="small"
            type="number"
            fullWidth
            inputProps={{ min: 0, max: 20 }}
            onChange={(evt) =>
              setCustomPlacementOptions({
                ...customPlacementOptions,
                vertical_spacing: parseFloat(evt.target.value),
              })
            }
            value={customPlacementOptions.vertical_spacing || 0}
            label="Vertical (ft)"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Chip
                    size="small"
                    label={`${activeFBO.sop_vertical_spacing} ft`}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <br />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <TextField
            size="small"
            type="number"
            fullWidth
            inputProps={{ min: 0, max: 12 }}
            onChange={(evt) =>
              setCustomPlacementOptions({
                ...customPlacementOptions,
                wall_spacing_left: parseFloat(evt.target.value),
              })
            }
            value={customPlacementOptions.wall_spacing_left || 0}
            label="Left Wall (ft)"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Chip
                    size="small"
                    label={`${hangar?.wall_spacing_left ?? 0} ft`}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            size="small"
            type="number"
            fullWidth
            inputProps={{ min: 0, max: 12 }}
            onChange={(evt) =>
              setCustomPlacementOptions({
                ...customPlacementOptions,
                wall_spacing_back: parseFloat(evt.target.value),
              })
            }
            value={customPlacementOptions.wall_spacing_back || 0}
            label="Back Wall (ft)"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Chip
                    size="small"
                    label={`${hangar?.wall_spacing_back ?? 0} ft`}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            size="small"
            type="number"
            fullWidth
            inputProps={{ min: 0, max: 20 }}
            onChange={(evt) =>
              setCustomPlacementOptions({
                ...customPlacementOptions,
                wall_spacing_right: parseFloat(evt.target.value),
              })
            }
            value={customPlacementOptions.wall_spacing_right || 0}
            label="Right Wall (ft)"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Chip
                    size="small"
                    label={`${hangar?.wall_spacing_right ?? 0} ft`}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <br />
        <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
          <TextField
            size="small"
            disabled={ignoreTowingEquipment}
            select
            fullWidth
            label="Towing Equipment For Stack"
            value={customPlacementOptions.towing_equipment_id}
            onChange={(evt) => {
              setCustomPlacementOptions({
                ...customPlacementOptions,
                towing_equipment_id: evt.target.value,
              });
            }}
          >
            {towingEquipments?.map((towingEquipment) => (
              <MenuItem
                key={`towing-equipment-select-${towingEquipment.towing_equipment_id}`}
                value={towingEquipment.towing_equipment_id}
              >
                {towingEquipment.name}
              </MenuItem>
            ))}
          </TextField>

          <FormControlLabel
            control={<Switch checked={ignoreTowingEquipment} />}
            onChange={(evt) => {
              setIgnoreTowingEquipment(!ignoreTowingEquipment);
              setCustomPlacementOptions({
                ...customPlacementOptions,
                towing_equipment_id: null,
              });
            }}
            label="Ignore?"
          />
        </Stack>
      </BorderedBox>
      <br />
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
        <Typography textAlign="center">Preferences</Typography>
        <Tooltip title="Customize your stacking preferences. These will apply to every aircraft.">
          <InfoIcon fontSize="small" />
        </Tooltip>
      </Stack>
      <Stack direction="column" spacing={1}>
        <BorderedBox sx={{ border: minimal && "none", p: minimal && 0 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="body2">Allow Overlap</Typography>
              {!minimal && (
                <Typography variant="caption" color="info.dark">
                  Allow the algorithm to overlap planes.
                </Typography>
              )}
            </Box>
            <Switch
              checked={customPlacementOptions.overlap}
              onChange={(evt) =>
                setCustomPlacementOptions({
                  ...customPlacementOptions,
                  overlap: evt.target.checked,
                })
              }
            />
          </Stack>
        </BorderedBox>
        <BorderedBox sx={{ border: minimal && "none", p: minimal && 0 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="body2">
                Ignore Nose In/Out Preferences
              </Typography>
              {!minimal && (
                <Typography variant="caption" color="info.dark">
                  Allow the algorithm to use your towing orientation
                  preferences.
                </Typography>
              )}
            </Box>
            <Switch
              checked={customPlacementOptions.ignore_nose_in_out}
              onChange={(evt) =>
                setCustomPlacementOptions({
                  ...customPlacementOptions,
                  ignore_nose_in_out: evt.target.checked,
                })
              }
            />
          </Stack>
        </BorderedBox>
        <BorderedBox sx={{ border: minimal && "none", p: minimal && 0 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="body2">
                Ignore Front/Back Preferences
              </Typography>
              {!minimal && (
                <Typography variant="caption" color="info.dark">
                  Allow the algorithm to use your front or back row preference.
                </Typography>
              )}
            </Box>
            <Switch
              checked={customPlacementOptions.ignore_front_back}
              onChange={(evt) =>
                setCustomPlacementOptions({
                  ...customPlacementOptions,
                  ignore_front_back: evt.target.checked,
                })
              }
            />
          </Stack>
        </BorderedBox>
        <BorderedBox sx={{ border: minimal && "none", p: minimal && 0 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="body2">
                Ignore Allow Outside Hangar Preferences
              </Typography>
              {!minimal && (
                <Typography variant="caption" color="info.dark">
                  Allow the algorithm to consider your "ok to leave out"
                  preferences.
                </Typography>
              )}
            </Box>
            <Switch
              checked={customPlacementOptions.ignore_outside_hangar}
              onChange={(evt) =>
                setCustomPlacementOptions({
                  ...customPlacementOptions,
                  ignore_outside_hangar: evt.target.checked,
                })
              }
            />
          </Stack>
        </BorderedBox>
        {isDev && (
          <Stack direction="column">
            <BorderedBox sx={{ border: minimal && "none", p: minimal && 0 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="body2">
                    Use Cloud Position Prioritization
                  </Typography>
                  {!minimal && (
                    <Typography variant="caption" color="info.dark">
                      Uses "Cloud" (ConvexHull) position prioritization to
                      determine which positions to evaluate.
                    </Typography>
                  )}
                </Box>
                <Switch
                  checked={customPlacementOptions.cloud_position_prioritization}
                  onChange={(evt) =>
                    setCustomPlacementOptions({
                      ...customPlacementOptions,
                      cloud_position_prioritization: evt.target.checked,
                    })
                  }
                />
              </Stack>
            </BorderedBox>
            <BorderedBox sx={{ border: minimal && "none", p: minimal && 0 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2">Sample Size</Typography>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  inputProps={{ min: 100, max: 1e9, step: 100 }}
                  onChange={(evt) =>
                    setCustomPlacementOptions({
                      ...customPlacementOptions,
                      sample_size: parseFloat(evt.target.value),
                    })
                  }
                  value={customPlacementOptions.sample_size || 1000}
                  label="Sample Size"
                />
              </Stack>
            </BorderedBox>
            <Stack direction="column">
              <Typography variant="body2">Corner Strategy</Typography>
              <Select
                multiple
                value={customPlacementOptions.corner_strategies}
                onChange={(evt) =>
                  setCustomPlacementOptions({
                    ...customPlacementOptions,
                    corner_strategies: evt.target.value as CornerStrategy[][],
                  })
                }
                input={<OutlinedInput size="small" />}
                renderValue={(selected) =>
                  selected.map((s) => `[${s.join(", ")}]`).join(", ")
                }
              >
                {CornerStrategies.map((strategy) => (
                  <MenuItem key={JSON.stringify(strategy)} value={strategy}>
                    <Checkbox
                      checked={
                        customPlacementOptions.corner_strategies.indexOf(
                          strategy
                        ) > -1
                      }
                    />
                    <ListItemText primary={strategy.join(", ")} />
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack direction="column">
              <Typography variant="body2">XY Weights</Typography>
              <Stack direction="row" spacing={1}>
                <TextField
                  size="small"
                  type="number"
                  inputProps={{ min: 0, max: 3, step: 0.1 }}
                  onChange={(evt) =>
                    setCustomPlacementOptions({
                      ...customPlacementOptions,
                      xy_weights: [
                        parseFloat(evt.target.value),
                        customPlacementOptions.xy_weights[1],
                      ],
                    })
                  }
                  value={customPlacementOptions.xy_weights[0] || 1}
                  label="X"
                />
                <TextField
                  size="small"
                  type="number"
                  inputProps={{ min: 0, max: 3, step: 0.1 }}
                  onChange={(evt) =>
                    setCustomPlacementOptions({
                      ...customPlacementOptions,
                      xy_weights: [
                        customPlacementOptions.xy_weights[0],
                        parseFloat(evt.target.value),
                      ],
                    })
                  }
                  value={customPlacementOptions.xy_weights[1] || 1}
                  label="Y"
                />
              </Stack>
            </Stack>
            <Stack direction="column" sx={{ pl: 2, width: "80%" }}>
              <Typography variant="caption">3D Density Threshold</Typography>
              <Slider
                valueLabelDisplay="auto"
                min={0}
                max={1}
                step={0.1}
                valueLabelFormat={(value) => `${value * 100}%`}
                marks={[
                  { value: 0, label: "0%" },
                  { value: 0.5, label: "50%" },
                  { value: 1, label: "100%" },
                ]}
                onChange={(evt, newValue: number) =>
                  setCustomPlacementOptions({
                    ...customPlacementOptions,
                    density_threshold: newValue,
                  })
                }
                value={customPlacementOptions.density_threshold}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export const CustomStackDialog: React.FC<Props> = ({
  onClickRunStack,
  onClose,
  isDev = false,
}) => {
  const { activeFBO } = useActiveFBOs();
  const [open, setOpen] = React.useState<boolean>(true);
  const [customPlacementOptions, setCustomPlacementOptions] = React.useState<
    CustomPlacementOptions
  >(defaultCustomPlacementOptions(activeFBO, null));
  const handleClose = () => {
    onClose();
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Customize your Stack</DialogTitle>
        <DialogContent sx={{ width: 550 }}>
          <CustomStackForm
            isDev={isDev}
            customPlacementOptions={customPlacementOptions}
            setCustomPlacementOptions={setCustomPlacementOptions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Back</Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              onClickRunStack(customPlacementOptions);
            }}
          >
            Run Stack
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const defaultCustomPlacementOptions = (
  activeFBO: FBO,
  hangar: Hangar
): CustomPlacementOptions => {
  return {
    horizontal_spacing: activeFBO.sop_horizontal_spacing ?? 2,
    vertical_spacing: activeFBO.sop_vertical_spacing ?? 2,
    wall_spacing_left: hangar?.wall_spacing_left ?? 2,
    wall_spacing_back: hangar?.wall_spacing_back ?? 2,
    wall_spacing_right: hangar?.wall_spacing_right ?? 2,
    overlap: activeFBO.sop_overlap ?? false,
    ignore_nose_in_out: false,
    ignore_front_back: false,
    ignore_outside_hangar: false,
    density_threshold: 0.8,
    sample_size: 1000,
    corner_strategies: CornerStrategies,
    cloud_position_prioritization: true,
    xy_weights: [1, 2],
    towing_equipment_id: null,
  };
};
