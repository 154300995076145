import * as React from "react";
import { useDebounce } from "react-use";

export const useDebouncedValue = <T>(
  func: () => T,
  delay: number,
  deps: any[]
) => {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(func());
  useDebounce(
    () => {
      const value = func();
      setDebouncedValue(value);
    },
    delay,
    deps
  );

  return debouncedValue;
};
