import * as React from "react";
import { useMultipleHangars } from "../hooks/useMultipleHangars";
import { Hangar } from "../types";

const EMPTY_HANGAR: Hangar = {
  id: "",
  name: "",
  width: 0,
  depth: 0,
  height: 0,
  fbo_id: "",
  garageDoors: [],
  left_door: null,
  right_door: null,
  wall_spacing_back: 0,
  wall_spacing_right: 0,
  wall_spacing_left: 0,
  obstacles: [],
  stack: {
    id: "",
    name: "",
    tenants: [],
    movableObstacles: [],
    options: {},
  },
  stacks: [],
};

const MultiHangarContainer = React.createContext<{
  ready: boolean;
  isMultiHangar: boolean;
  activeLocationId: string;
  setActiveLocationId: React.Dispatch<React.SetStateAction<string>>;
  hangarIds: string[];
  setHangarIds: React.Dispatch<React.SetStateAction<string[]>>;
  zoom: number;
  setZoom: (zoom: number) => void;
  focus: string;
  setFocus: React.Dispatch<React.SetStateAction<string>>;
  hangar: Hangar;
  hangars: Hangar[];
  getHangar: (hangar_id: string) => Hangar;
  setHangar: React.Dispatch<React.SetStateAction<Hangar>>;
  setHangars: React.Dispatch<React.SetStateAction<Hangar[]>>;
  history: {
    undo: () => void;
    redo: () => void;
  };
}>({
  ready: false,
  isMultiHangar: false,
  activeLocationId: "",
  setActiveLocationId: () => {},
  hangarIds: [],
  setHangarIds: () => {},
  zoom: 1,
  setZoom: () => {},
  focus: null,
  setFocus: () => {},
  //
  hangar: EMPTY_HANGAR as Hangar,
  hangars: [],
  getHangar: () => EMPTY_HANGAR as Hangar,
  setHangar: () => {},
  setHangars: () => {},
  history: {
    undo: () => {},
    redo: () => {},
  },
});

type Props = {
  activeHangarId?: string;
  hangarIds?: string[];
};

export const MultiHangarStateContainer: React.FC<{
  children?: React.ReactNode;
  initialState: Props;
}> = ({ children, initialState, ...props }) => {
  const [hangarIds, setHangarIds] = React.useState<string[]>(
    initialState.hangarIds ?? []
  );
  const {
    ready,
    hangars,
    getHangar,
    setHangar,
    setHangars,
    history,
  } = useMultipleHangars(initialState.hangarIds ?? [], false);
  const [activeLocationId, setActiveLocationId] = React.useState<string>(
    initialState.activeHangarId ?? ""
  );
  const [zoom, setZoom] = React.useState<number>(1);
  const [focus, setFocus] = React.useState<string>();
  const setZoomCapped = React.useCallback(
    (zoom: number) => {
      if (zoom < 0.7) {
        setZoom(0.7);
      } else if (zoom > 2) {
        setZoom(2);
      } else {
        setZoom(zoom);
      }
    },
    [setZoom]
  );

  return (
    <MultiHangarContainer.Provider
      value={{
        ready,
        isMultiHangar: hangars.length > 1,
        hangarIds,
        setHangarIds,
        activeLocationId,
        setActiveLocationId,
        zoom,
        setZoom: setZoomCapped,
        focus,
        setFocus,
        //
        hangar: hangars.find((h) => h.id === activeLocationId) ?? hangars[0],
        hangars,
        getHangar,
        setHangar,
        setHangars,
        history,
      }}
    >
      {children}
    </MultiHangarContainer.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useMultiHangarState = () => React.useContext(MultiHangarContainer);
