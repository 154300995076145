import { Box } from "@mui/material";
import * as React from "react";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { Hangar, ParamSet, Preference } from "../../types";
import { convertAircraftPreferencesToAlgorithmPreferences } from "../../utils";
import { HangarStack } from "../Hangar";
import { CustomPlacementOptions } from "../Hangar/CustomStackDialog";
import { StackStrategy } from "../Hangar/GenerateParamSets";
import { formatObstacles } from "../Hangar/HangarLayout/utils";
import { HangarLeftPanel } from "../Hangar/HangarLeftPanel";

type Props = {
  hangarId: string;
  width: number;
  stacks: HangarStack[];
  setStacks: (stacks: HangarStack[]) => void;
  stackStrategy: StackStrategy;
  setStackStrategy: (s: StackStrategy) => void;
  customPlacementOptions: CustomPlacementOptions;
  setCustomPlacementOptions: (options: CustomPlacementOptions) => void;
  viewableHangars: Hangar[];
  activeHangarId: string;
  setActiveHangarId: (id: string) => void;
};

export const HangarLeftPanelWrapper: React.FC<Props> = ({
  hangarId,
  width,
  stacks,
  setStacks,
  stackStrategy,
  setStackStrategy,
  customPlacementOptions,
  setCustomPlacementOptions,
  viewableHangars,
  activeHangarId,
  setActiveHangarId,
}) => {
  const { getHangar, setHangar } = useMultiHangarState();
  const hangar = getHangar(hangarId);

  const paramSet: ParamSet = {
    run_id: null,
    label: "base case",
    // slim down the hangar for the API call
    hangar: {
      id: hangar.id,
      width: hangar.width,
      depth: hangar.depth,
      left_door: hangar.left_door,
      right_door: hangar.right_door,
      garage_doors: hangar.garageDoors ?? [],
    },
    // slim down the tenant so it's just what we need to do the placement
    aircrafts_to_place: hangar.stack.tenants
      .filter((t) => t.position.preferences?.indexOf(Preference.LOCKED) === -1)
      .map((tenant) => ({
        id: tenant.aircraft.id,
        placement_id: tenant.placement_id,
        angles_to_try: [],
        sectors_to_try: null,
        preferences: convertAircraftPreferencesToAlgorithmPreferences(
          tenant.position.preferences
        ),
      })),
    locked_aircrafts: hangar.stack.tenants
      .filter((t) => t.position.preferences?.indexOf(Preference.LOCKED) > -1)
      .map((tenant) => ({
        id: tenant.aircraft.id,
        placement_id: tenant.placement_id,
        x: tenant.position.x,
        y: tenant.position.y,
        angle: tenant.position.angle,
      })),
    obstacles: formatObstacles(hangar),
    options: {
      ...hangar.stack.options,
      ...customPlacementOptions,
    },
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <HangarLeftPanel
        multiHangar
        hangar={hangar}
        setHangar={setHangar}
        stacks={stacks}
        setStacks={setStacks}
        stackStrategy={stackStrategy}
        setStackStrategy={setStackStrategy}
        paramSet={paramSet}
        customPlacementOptions={customPlacementOptions}
        setCustomPlacementOptions={setCustomPlacementOptions}
        isReference={false}
        isAlgorithmTesting={false}
        width={300}
        activeHangarId={activeHangarId}
        setActiveHangarId={setActiveHangarId}
        viewableHangars={viewableHangars}
      />
    </Box>
  );
};
