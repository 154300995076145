import * as React from "react";
import { useMultiHangarState } from "../../../containers/MultiHangarContainer";
import { Preferences } from "../../../hooks/useUserPreferences";
import { wrapUnplacedAirplanes } from "../../../screens/Hangar/HangarLayout/utils";
import { HOLDING_AREA_ID } from "../../../screens/MultiHangar/HoldingArea";
import { Hangar, Location, Position, Ramp, Stack } from "../../../types";
import { FullyLoadedDraggableAircraft } from "../FullyLoadedDraggableAircraft";
import { LayoutDisplayOptions, RightClick } from "../Layout";
import { useOnPositionChange } from "./useOnPositionChange";

export const isPositionValid = (position: Position): boolean => {
  return position.x !== null && position.y !== null && position.angle !== null;
};

type Props = {
  location: Location<Hangar | Ramp>;
  stack: Stack;
  setStack: (stack: Stack) => void;
  hideUnplaced: boolean;
  isInLayout: (tenant: any, buffer?: number) => boolean;
  printable: boolean;
  width: number;
  height: number;
  readOnly: boolean;
  feetToPixels: number;
  rightClick: RightClick;
  setRightClick: (rightClick: RightClick) => void;
  preferences: Preferences;
  spacingBufferOverlayElement: React.ReactNode;
  options: LayoutDisplayOptions;
};

export const useAircraft = (props: Props, deps: any[] = []) => {
  const {
    location,
    stack,
    setStack,
    hideUnplaced,
    isInLayout,
    printable,
    width,
    height,
    readOnly,
    feetToPixels,
    rightClick,
    setRightClick,
    preferences,
    spacingBufferOverlayElement,
    options,
  } = props;
  const { isMultiHangar } = useMultiHangarState();

  const onPositionChange = useOnPositionChange(
    {
      location,
      stack,
      setStack,
      disableDragProtection: options.disableDragProtection ?? false,
    },
    [stack.tenants, stack.movableObstacles, setStack, location]
  );

  const isHoldingArea = location.id === HOLDING_AREA_ID;

  console.log("spacingBufferOverlayElement", spacingBufferOverlayElement);

  return React.useMemo(
    () => (
      <>
        {wrapUnplacedAirplanes(
          stack?.tenants
            // this seems silly but if you move a plane to another hangar, it will still be in the same stack until
            // the page is refreshed. TODO: fix this?
            .filter((tenant) => tenant.position.stack_id === stack.id)
            // if we're not showing unplaced aircraft, then filter them out)
            .filter((tenant) => {
              return !hideUnplaced || isInLayout(tenant, 10);
            })
            // for printouts, we only want to show the planes that are in the hangar
            .filter((tenant) => {
              if (printable) {
                return isInLayout(tenant, 10);
              }
              return true;
            }),
          width,
          feetToPixels,
          isMultiHangar,
          isHoldingArea
        ).map((tenant) => {
          return (
            <React.Fragment
              key={`draggable-aircraft-${stack.id}-${tenant.entity_id}`}
            >
              <FullyLoadedDraggableAircraft
                isHangar={location.type === "hangar"}
                readOnly={readOnly}
                layoutHeight={height}
                feetToPixels={feetToPixels}
                tenant={{
                  ...tenant,
                  selected:
                    tenant.selected ||
                    rightClick?.entity_id === tenant.entity_id,
                }}
                stack={stack}
                // small problem. if we wrap the unplaced aircraft we've changed the position of the tenant
                // so we need to ignore positions that don't have a valid x, y, and angle.
                setStack={(stack) => {
                  setStack({
                    ...stack,
                    tenants: stack.tenants.map((t) => {
                      if (!isPositionValid(t.position)) {
                        return {
                          ...t,
                          position: {
                            ...t.position,
                            x: null,
                            y: null,
                            angle: null,
                          },
                        };
                      }
                      return t;
                    }),
                  });
                }}
                preferences={{
                  ...preferences,
                  forceDisplayTailNumber: options.hideTailNumbers
                    ? false
                    : preferences.forceDisplayTailNumber,
                }}
                onPositionChange={(placement_id, position) =>
                  // small problem. if we wrap the unplaced aircraft we've changed the position of the tenant
                  // so we need to ignore positions that don't have a valid x, y, and angle.
                  onPositionChange(placement_id, {
                    ...position,
                    ...(!isPositionValid(position)
                      ? { x: null, y: null, angle: null }
                      : {}),
                  })
                }
                onChange={(placement_id, position) => {}}
                rightClick={rightClick}
                setRightClick={setRightClick}
                spacingBufferOverlayElement={spacingBufferOverlayElement}
              />
            </React.Fragment>
          );
        })}
      </>
    ),
    deps
  );
};
