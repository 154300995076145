/**
 * @fileOverview Inherit from this class to support the object dispatching
 * events.
 * Follows the [EventTarget](https://developer.mozilla.org/en-US/docs/Web/API/EventTarget)
 * interface.
 */

import ListenerMap from "./ListenerMap";
import events from "./events";

const EventTarget = function() {
  this.listenerMap = new ListenerMap(this);
};

/**
 * @param {EventLike} e
 */
EventTarget.prototype.dispatchEvent = function(e) {
  var type, event;
  if (typeof e == "string") {
    type = e;
    event = { type };
  } else {
    type = e.type;
    event = e;
  }
  this.fireListeners(type, undefined, event);
};

EventTarget.prototype.fireListeners = function(type, capture, event) {
  var listenerArray = this.listenerMap.listeners[String(type)];
  if (listenerArray) {
    // Clone the array encase firing the listeners removes or adds new ones
    listenerArray = listenerArray.slice(0);
  } else {
    listenerArray = [];
  }
  for (var i = 0; i < listenerArray.length; i++) {
    var listener = listenerArray[i];
    const listenerFn = listener.listener;
    const listenerHandler = listener.handler || listener.src;
    listenerFn.call(listenerHandler, event);
  }
};

EventTarget.prototype.destroy = function() {
  this.listenerMap.destroy();
};

EventTarget.prototype.setLogFunction = function(func) {
  this.logFunction_ = func;
};

EventTarget.prototype.log_ = function() {
  console.log(...arguments);
};

EventTarget.prototype.addEventListener = function(
  type,
  listener,
  opt_options,
  opt_handler
) {
  events.listen(this, type, listener, opt_options, opt_handler);
};

EventTarget.prototype.removeEventListener = function(
  type,
  listener,
  opt_options,
  opt_handler
) {
  events.unlisten(this, type, listener, opt_options, opt_handler);
};

export default EventTarget;
