import Listener from "./Listener";

var ListenerMap = function(src) {
  this.src = src;
  this.listeners = {};
};

ListenerMap.prototype.add = function(
  type,
  listener,
  callOnce,
  opt_useCapture,
  opt_listenerScope
) {
  var listenerArray = this.listeners[type];
  if (!listenerArray) {
    listenerArray = this.listeners[type] = [];
  }

  var listenerObj = new Listener(
    listener,
    null,
    this.src,
    type,
    !!opt_useCapture,
    opt_listenerScope
  );

  listenerArray.push(listenerObj);
};

/**
 * Remove a listener from the map.
 */
ListenerMap.prototype.remove = function(type, listener) {
  var listenerArray = this.listeners[type] || [];

  var matchedIndex = listenerArray.findIndex((l) => {
    return l.listener == listener;
  });
  if (matchedIndex == -1) throw new Error("Listener not found.");
  listenerArray.splice(matchedIndex, 1);
};

/**
 * Remove a listener from the map.
 */
ListenerMap.prototype.removeAll = function(type) {
  var listenerArray = this.listeners[type] || [];
  listenerArray.splice(0, listenerArray.length);
};

/**
 * Remove a listener from the map.
 */
ListenerMap.prototype.hasListener = function(type, listener) {
  var listenerArray = this.listeners[type] || [];

  var matchedIndex = listenerArray.findIndex((l) => {
    return (l.listener = listener);
  });
  if (matchedIndex == -1) return false;
  return true;
};

ListenerMap.prototype.destroy = function() {
  delete this.src;
  // Could probably delete more here
  delete this.listeners;
};

export default ListenerMap;
