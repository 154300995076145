import * as React from "react";
import { useApi } from "../../../../containers/ApiContainer";
import { useInterval } from "react-use";
import { WorkerStatusPresenter } from "./WorkerStatusPresenter";

export type Healthcheck = {
  name: string;
  is_up: boolean;
  status: string;
  last_updated: string;
};

export const WorkerStatus: React.FC<{}> = () => {
  const { postgrest } = useApi();
  const [healthchecks, setHealthChecks] = React.useState<Healthcheck[]>([]);

  React.useEffect(() => {
    (async () => {
      const result = await postgrest.from("healthcheck").select();
      setHealthChecks(result.data);
    })();
  }, []);

  useInterval(async () => {
    const result = await postgrest.from("healthcheck").select();
    setHealthChecks(result.data);
  }, 10 * 1000);
  return <WorkerStatusPresenter healthchecks={healthchecks} />;
};
