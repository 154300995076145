import {
  PostgrestClient,
  PostgrestSingleResponse,
} from "@supabase/postgrest-js";
import { Identity } from "../../containers/IdentityContainer";
import {
  AircraftToPlace,
  HangarSlim,
  LockedAircraft,
  ObstacleSlim,
  ParamSet,
  PlacementOptions,
  Tenant,
} from "../../types";
import { getPostgrestUrl } from "../../utils";

const postgrest = new PostgrestClient(getPostgrestUrl());

export interface AlgorithmResult {
  complete: boolean;
  placements: any[];
  unplacedAircraft: any[];
}

export type StackMember = {
  aircraft_id: string;
  placement_id: string;
  x: number;
  y: number;
  angle: number;
  geom: any;
  geomz: any;
  error?: number;
  area?: number;
};

type SetupResult = {
  aircrafts_to_place: AircraftToPlace[];
  stack_members: StackMember[];
  hangar: HangarSlim;
  options: PlacementOptions;
};

type PlacementWeb = {
  point: any;
  angle: number;
  geom: string;
  geomz: string;
  error: number;
};

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const pollForResult = async (
  postgrest: PostgrestClient,
  queueId: string
): Promise<any> => {
  for (let i = 0; i < 60 * 4; i++) {
    if (window.globalThis.airplxKillSwitch) {
      return;
    }
    const response = await postgrest
      .from("queue_result")
      .select("result")
      .eq("qid", queueId);
    if (response.data?.length > 0) {
      return response.data[0].result;
    }
    await sleep(100);
  }
};

export const setup = async (
  postgrest: PostgrestClient,
  paramSet: ParamSet
): Promise<SetupResult> => {
  const response: PostgrestSingleResponse<string> = await postgrest
    .rpc("setup", {
      ...paramSet,
    })
    .single();
  const queueId = response.data;
  try {
    const setupResult: SetupResult = (await pollForResult(postgrest, queueId))
      .setup;
    return setupResult;
  } catch (err) {
    console.error(err);
    console.log(`could not fetch queueId: ${queueId}`);
  }
};

export type PlaceAircraftParameters = {
  run_id: string;
  aircraft: AircraftToPlace;
  stack_members_web: StackMember[];
  hangar: HangarSlim;
  options: PlacementOptions;
};

export const placeAircraft = async (
  postgrest: PostgrestClient,
  payload: PlaceAircraftParameters
): Promise<PlacementWeb> => {
  const response: PostgrestSingleResponse<string> = await postgrest
    .rpc("place_aircraft", {
      ...payload,
    })
    .single();
  // it will take at least 250ms, so wait until we start polling
  await sleep(100);
  const queueId = response.data;
  for (let retry = 1; retry <= 3; retry++) {
    try {
      const placement: PlacementWeb = (await pollForResult(postgrest, queueId))
        .placement;
      if (retry > 1) {
        console.log(`retry success for ${queueId}`);
      }
      return placement;
    } catch (err) {
      if (retry === 3) {
        console.error(err);
        console.log(
          `could not fetch queueId: ${queueId} -- ${payload.aircraft.id}`
        );
        continue;
      }
      console.log(
        `could not fetch queueId: ${queueId} -- ${payload.aircraft.id}. retrying ${retry} of 3`
      );
    }
  }
};

export async function* placeIncrementally(
  placementParams: ParamSet,
  tenants: Tenant[],
  airplxIdentity: Identity,
  airplxToken: string
): Generator<StackMember[], StackMember[], any> {
  postgrest.schema = "web";
  postgrest.auth(airplxToken);
  const { run_id } = placementParams;
  const setupResult = await setup(postgrest, placementParams);
  if (!setupResult) {
    return;
  }
  const { aircrafts_to_place, stack_members, hangar, options } = setupResult;

  let smallestPlacedArea = 9999999999999999;
  let n = 0;
  for (let aircraft_to_place of aircrafts_to_place) {
    n++;
    if (window.globalThis.airplxKillSwitch) {
      const unstackedTenants: StackMember[] = tenants
        .filter(
          (tenant) =>
            !Boolean(
              stack_members.find(
                ({ placement_id }) => placement_id === tenant.placement_id
              )
            )
        )
        .map((tenant) => {
          return {
            aircraft_id: tenant.aircraft.id,
            placement_id: tenant.placement_id,
            x: null,
            y: null,
            angle: null,
            geom: null,
            geomz: null,
            error: 0,
            area: tenant.aircraft.wingspan * tenant.aircraft.length,
          };
        });
      yield stack_members
        .filter(
          ({ aircraft_id }) =>
            !aircraft_id.startsWith("00000000-0000-0000-0000-00000000000")
        )
        .concat(unstackedTenants);
      return;
    }

    const { aircraft } = tenants.find(
      (tenant) => tenant.placement_id === aircraft_to_place.placement_id
    );
    const area = aircraft.wingspan * aircraft.length;

    if (area >= smallestPlacedArea) {
      continue;
    }

    const t0 = +new Date();
    const parameters: PlaceAircraftParameters = {
      run_id,
      aircraft: aircraft_to_place,
      stack_members_web: stack_members,
      hangar,
      options,
    };
    let placement: PlacementWeb;
    try {
      placement = await placeAircraft(postgrest, parameters);
    } catch (err) {
      console.error(err);
      continue;
    }
    if (!placement) {
      console.error("no placement returned");
      continue;
    }

    // for 2D we will only continue placing aircraft that are smaller than ones we've tried
    if (!placement?.point) {
      smallestPlacedArea = area;
      continue;
    }

    const new_stack_member: StackMember = {
      aircraft_id: aircraft_to_place.id,
      placement_id: aircraft_to_place.placement_id,
      x: placement.point.coordinates[0],
      y: placement.point.coordinates[1],
      angle: placement.angle,
      geom: placement.geom,
      geomz: placement.geomz,
      error: placement.error,
      area,
    };
    stack_members.push(new_stack_member);
    yield stack_members.filter(
      ({ aircraft_id }) =>
        !aircraft_id.startsWith("00000000-0000-0000-0000-00000000000")
    );
  }
  const unstackedTenants: StackMember[] = tenants
    .filter(
      (tenant) =>
        !Boolean(
          stack_members.find(
            ({ placement_id }) => placement_id === tenant.placement_id
          )
        )
    )
    .map((tenant) => {
      return {
        aircraft_id: tenant.aircraft.id,
        placement_id: tenant.placement_id,
        x: null,
        y: null,
        angle: null,
        geom: null,
        geomz: null,
        error: 0,
        area: tenant.aircraft.wingspan * tenant.aircraft.length,
      };
    });
  yield stack_members
    .filter(
      ({ aircraft_id }) =>
        !aircraft_id.startsWith("00000000-0000-0000-0000-00000000000")
    )
    .concat(unstackedTenants);
}

export const rpcAutocomplete = async (
  postgrest: PostgrestClient,
  paramSet: AutocompleteParamSet
): Promise<any> => {
  const response: PostgrestSingleResponse<string> = await postgrest
    .rpc("autocomplete", {
      ...paramSet,
    })
    .single();
  const queueId = response.data;
  if (!queueId) {
    console.error("no queueId returned", response);
    return;
  }
  try {
    const result: any = (await pollForResult(postgrest, queueId)).placement;
    return result;
  } catch (err) {
    console.error(err);
    console.log(`could not fetch queueId: ${queueId}`);
  }
};

export type AutocompleteParamSet = {
  run_id: string;
  aircraft: AircraftToPlace;
  stack_members_web: StackMember[];
  hangar: HangarSlim;
  locked_aircrafts: LockedAircraft[];
  obstacles: ObstacleSlim[];
  options: PlacementOptions;
};

export const oneShotAutocomplete = async (
  paramSet: ParamSet,
  airplxToken: string
): Promise<StackMember[]> => {
  postgrest.schema = "web";
  postgrest.auth(airplxToken);
  const aircraft = paramSet.aircrafts_to_place[0];
  const autocompleteParamSet: AutocompleteParamSet = {
    run_id: paramSet.run_id,
    aircraft,
    stack_members_web: [],
    hangar: paramSet.hangar,
    locked_aircrafts: paramSet.locked_aircrafts,
    obstacles: paramSet.obstacles,
    options: paramSet.options,
  };
  const placement: PlacementWeb = await rpcAutocomplete(
    postgrest,
    autocompleteParamSet
  );
  if (!placement) {
    return [];
  }
  return [
    {
      aircraft_id: aircraft.id,
      placement_id: aircraft.placement_id,
      x: placement.point?.coordinates?.[0],
      y: placement.point?.coordinates?.[1],
      angle: placement.angle,
      geom: placement.geom,
      geomz: placement.geomz,
      error: placement.error,
      area: null,
    },
  ];
};
