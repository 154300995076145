import * as React from "react";
import { Box } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  sx?: { [key: string]: any };
};

export const BorderedBox: React.FC<Props> = ({ sx, children }) => (
  <Box
    sx={{
      border: "1px solid lightgrey",
      p: 1,
      ...sx,
    }}
  >
    {children}
  </Box>
);
