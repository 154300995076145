import * as React from "react";
import { HangarPresenter } from "../Hangar/HangarPresenter";
import { HangarStateContainer } from "../../containers/HangarStateContainer";
import { ParamSet } from "../../types";
import { StackMember } from "../Hangar/PlaceIncrementally";
import { TowingEquipmentContainer } from "../../containers/TowingEquipmentContainer";
import { useParams } from "react-router-dom";

export type HangarStack = {
  paramSet: ParamSet;
  stackMembers: StackMember[];
};

type Props = {
  hangarId?: string;
  isReference?: boolean;
};

export const HangarAlgorithmDev: React.FC<Props> = ({
  hangarId,
  isReference,
  ...props
}) => {
  const { id: hangarIdFromRoute } = useParams();
  const [stacks, setStacks] = React.useState<HangarStack[]>([]);

  return (
    <HangarStateContainer
      initialState={{
        hangar_id: hangarId ?? hangarIdFromRoute,
        isReference: isReference ?? false,
      }}
    >
      <TowingEquipmentContainer>
        <HangarPresenter
          isAlgorithmTesting={true}
          stacks={stacks}
          setStacks={setStacks}
          isReference={isReference}
        />
      </TowingEquipmentContainer>
    </HangarStateContainer>
  );
};
