import * as React from "react";
import { TowingEquipment } from "../../../hooks/useTowingEquipment";
import {
  FormControlLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
} from "@mui/material";

type Props = {
  ignoreTowingEquipment: boolean;
  setIgnoreTowingEquipment: (ignore: boolean) => void;
  selectedTowingEquipment: TowingEquipment;
  setSelectedTowingEquipment: (towingEquipment: TowingEquipment) => void;
  towingEquipments: TowingEquipment[];
};

export const SelectTowingEquipment: React.FC<Props> = ({
  ignoreTowingEquipment,
  setIgnoreTowingEquipment,
  selectedTowingEquipment,
  setSelectedTowingEquipment,
  towingEquipments,
}) => (
  <Stack direction="row" spacing={1}>
    <TextField
      size="small"
      disabled={ignoreTowingEquipment}
      select
      fullWidth
      label="Towing Equipment For Stack"
      value={selectedTowingEquipment?.towing_equipment_id}
      onChange={(evt) => {
        setSelectedTowingEquipment(
          towingEquipments.find(
            (t) => t.towing_equipment_id === evt.target.value
          )
        );
      }}
    >
      {towingEquipments?.map((towingEquipment) => (
        <MenuItem
          key={`towing-equipment-select-${towingEquipment.towing_equipment_id}`}
          value={towingEquipment.towing_equipment_id}
        >
          {towingEquipment.name}
        </MenuItem>
      ))}
    </TextField>

    <FormControlLabel
      control={<Switch checked={ignoreTowingEquipment} />}
      onChange={(evt) => {
        setIgnoreTowingEquipment(!ignoreTowingEquipment);
        setSelectedTowingEquipment(null);
      }}
      label="Ignore?"
    />
  </Stack>
);
