import SaveIcon from "@mui/icons-material/Save";
import {
  Autocomplete,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { uniq } from "lodash";
import * as React from "react";
import { Hangar, Location, Ramp, Stack as StackType } from "../../../types";
import { uuidv4 } from "../../../utils";
import { FavoritePresenter } from "./FavoritePresenter";

type Props = {
  favorites: StackType[];
  location: Location<Ramp | Hangar>;
  setLocation: (location: Location<Ramp | Hangar>) => void;
  setOpen: (open: boolean) => void;
  setName: (name: string) => void;
};

export const SingleFavoritesPresenter: React.FC<Props> = ({
  favorites,
  location,
  setLocation,
  setOpen,
  setName,
}) => {
  const [stackFilter, setStackFilter] = React.useState<string>("");
  const stackNames = uniq(location.stacks.map((s) => s.name))
    .sort()
    .filter((name) => !["current", "reference"].includes(name))
    .filter((name) => {
      if (!stackFilter) {
        return true;
      }
      return (
        name.toLocaleLowerCase().indexOf(stackFilter.toLocaleLowerCase()) > -1
      );
    });
  return (
    <Stack direction="column" spacing={1}>
      <Autocomplete
        disablePortal
        size="small"
        onChange={(event: any, newValue: string | null) => {
          setStackFilter(newValue);
        }}
        options={stackNames}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search for stack"
          />
        )}
      />
      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="center"
        spacing={8}
      >
        {favorites.length === 0 && (
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center">
              <Typography textAlign="center" variant="subtitle2">
                No favorites yet. You can save a favorite stack by clicking the{" "}
                <i>Save Icon</i>
              </Typography>
              <SaveIcon sx={{ ml: 0.25, fontSize: 18, lineHeight: 11 }} />
              <Typography textAlign="center" variant="subtitle2">
                .
              </Typography>
            </Stack>
          </Grid>
        )}
        {favorites
          .filter((fav) =>
            stackFilter
              ? fav.name
                  .toLocaleLowerCase()
                  .indexOf(stackFilter.toLocaleLowerCase()) > -1
              : true
          )
          .map((favorite) => (
            <Grid
              key={`favorite-${favorite.id}`}
              item
              xs={6}
              sx={{ width: "100%" }}
            >
              <Stack direction="column" alignItems="center">
                <FavoritePresenter
                  location={location}
                  favorite={favorite}
                  onUse={(favorite: StackType) => {
                    setOpen(false);
                    setLocation({
                      ...location,
                      stack: {
                        ...location.stack,
                        tenants: [...favorite.tenants].map((t) => ({
                          ...t,
                          position: {
                            ...t.position,
                            id: uuidv4(),
                            stack_id: location.stack.id,
                            entity_id: t.entity_id,
                          },
                        })),
                        movableObstacles: [...favorite.movableObstacles].map(
                          (mo) => ({
                            ...mo,
                            position: {
                              ...mo.position,
                              id: uuidv4(),
                              stack_id: location.stack.id,
                              entity_id: mo.entity_id,
                            },
                          })
                        ),
                      },
                    });
                    setName(favorite.name);
                  }}
                  onDelete={(favorite: StackType) => {
                    setOpen(false);
                    setLocation({
                      ...location,
                      stacks: location.stacks.filter(
                        (s) => s.id !== favorite.id
                      ),
                    });
                  }}
                />
              </Stack>
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
};
