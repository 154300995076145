import * as React from "react";
import { useActiveFBOs } from "../containers/ActiveFBOContainer";
import { useApi } from "../containers/ApiContainer";
import { BenchmarkRate } from "../types";
import { fetchAircraftWithCache } from "./api";
import useSyncToTable from "./useSyncToTable";

export const useBenchmarkRates = () => {
  const { postgrest } = useApi();
  const { activeFBO } = useActiveFBOs();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [benchmarkRates, setBenchmarkRates] = React.useState<BenchmarkRate[]>();

  useSyncToTable<BenchmarkRate[]>({
    thingToWatch: benchmarkRates,
    tableName: "benchmark_rates",
  });

  React.useEffect(() => {
    (async () => {
      const { data } = await postgrest.from("benchmark_rates").select().eq("fbo_id", activeFBO?.id);
      const aircrafts = await fetchAircraftWithCache(
        postgrest,
        data.map((row) => row.aircraft_id).filter((id) => id),
        activeFBO
      );
      setBenchmarkRates(
        data.map((row) => ({
          ...row,
          aircraft: aircrafts.find((a) => a.id === row.aircraft_id),
        }))
      );
      setLoading(false);
    })();
  }, []);

  return {
    loading,
    benchmarkRates: benchmarkRates ?? [],
    setBenchmarkRates,
  };
};
