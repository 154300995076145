import * as React from "react";
import { getStackPolygons } from "../../../hooks/utils";
import { HOLDING_AREA_ID } from "../../../screens/MultiHangar/HoldingArea";
import { Polygon, polygonInPolygon } from "geometric";
import { useMultiHangarState } from "../../../containers/MultiHangarContainer";
import {
  EntityPolygon,
  Hangar,
  Location,
  Ramp,
  Stack,
  Tenant,
} from "../../../types";

type Props = {
  location: Location<Ramp | Hangar>;
  stack: Stack;
  polygons: EntityPolygon[];
};
export const useIsInLayout = (props: Props, deps: any[] = []) => {
  const { location, stack, polygons } = props;
  return React.useCallback((tenant: Tenant, buffer: number = 2.5) => {
    const { polygon } = polygons.find((p) => p.entity_id === tenant.entity_id);
    const layoutPolygon: Polygon = [
      [-buffer, -buffer],
      [location.width + buffer, -buffer],
      [location.width + buffer, location.depth + buffer],
      [-buffer, location.depth + buffer],
      [-buffer, -buffer],
    ];
    return polygonInPolygon(polygon, layoutPolygon);
  }, deps);
};

type useIsInAnyLayoutProps = {
  feetToPixels: number;
};
export const useIsInAnyLayout = (
  props: useIsInAnyLayoutProps,
  deps: any[] = []
) => {
  const { feetToPixels } = props;
  const { hangars } = useMultiHangarState();
  return React.useCallback(
    (tenant: Tenant, buffer: number = 2.5) => {
      const polygons = hangars
        .filter((h) => h.id !== HOLDING_AREA_ID)
        .map((hangar) => ({
          hangar: [
            [-buffer, -buffer],
            [hangar.width + buffer, -buffer],
            [hangar.width + buffer, hangar.depth + buffer],
            [-buffer, hangar.depth + buffer],
            [-buffer, -buffer],
          ],
          tenants: getStackPolygons(
            hangar.stack,
            hangar.width,
            feetToPixels,
            null,
            false
          ),
        }));
      for (const polygon of polygons) {
        const tenantPolygon = polygon.tenants.find(
          (t) => t.entity_id === tenant.entity_id
        )?.polygon;
        if (!tenantPolygon) {
          continue;
        }
        if (polygonInPolygon(tenantPolygon, polygon.hangar)) {
          return true;
        }
      }
      return false;
    },
    [...deps, hangars, feetToPixels]
  );
};
