import { MenuItem, Stack, TextField } from "@mui/material";
import { uniqBy } from "lodash";
import * as React from "react";
import { useLocationsState } from "../../../containers/LocationsStateContainer";
import { useTenants } from "../../../hooks/useTenants";
import { Hangar, Location, Ramp, Tenant } from "../../../types";
import { LocationTextField } from "../../../widgets/LocationTextField";
import { SelectTailNumber } from "../../../widgets/SelectTailNumber";
import { Aircraft } from "../../LabelingTool";
import { AutocompleteAircraft } from "./AutocompleteAircraft";

type Props = {
  disableAutocomplete: boolean;
  tailNumber: string;
  setTailNumber: (tailNumber: string) => void;
  owner: string;
  setOwner: (owner: string) => void;
  location: Location<Hangar | Ramp>;
  setShowConfirmation: (message: string) => void;
  type: string;
  setType: (type: string) => void;
  isReference: boolean;
  note: string;
  setNote: (note: string) => void;
  newAircraft: Aircraft;
  setNewAircraft: (aircraft: Aircraft) => void;
  currentLocation: Location<Ramp | Hangar>;
  setCurrentLocation: (l: Location<Ramp | Hangar>) => void;
  assignedLocation: Location<Ramp | Hangar>;
  setAssignedLocation: (l: Location<Ramp | Hangar>) => void;
};

export const NewAircraftForm: React.FC<Props> = ({
  disableAutocomplete = false,
  tailNumber,
  setTailNumber,
  owner,
  setOwner,
  type,
  setType,
  location,
  setShowConfirmation,
  isReference,
  newAircraft,
  setNewAircraft,
  note,
  setNote,
  assignedLocation,
  setAssignedLocation,
  currentLocation,
  setCurrentLocation,
}) => {
  const { tenants } = useTenants();
  const { locations } = useLocationsState();

  // for the time being tail numbers aren't unique. we're going to cheat a bit
  // and dedup on tail number here
  const tenantsWithTailNumber = React.useMemo(
    () =>
      uniqBy(
        tenants.filter((t) => t.tail_number),
        "tail_number"
      ),
    [tenants]
  );

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={2}>
        <SelectTailNumber
          disableAutocomplete={disableAutocomplete}
          defaultValue={tailNumber}
          tenants={tenantsWithTailNumber}
          onInputChange={(tailNumber: string) => {
            setTailNumber(tailNumber);
          }}
          onChange={(tenant: Tenant) => {
            setNewAircraft(tenant?.aircraft);
            setType(tenant?.type ?? "");
            setTailNumber(tenant?.tail_number ?? "");
            setOwner(tenant?.owner_manager ?? "");
          }}
        />

        <TextField
          inputProps={{ testid: "owner_manager" }}
          fullWidth
          size="small"
          label="Owner/Manager"
          onChange={(evt) => setOwner(evt.target.value)}
          value={owner}
        />
        <TextField
          select
          size="small"
          fullWidth
          label="Type"
          onChange={(evt) => {
            if (evt.target.value === "base") {
              const confirmationText =
                !location || location?.type === "hangar"
                  ? "We’ll assign this new Base Tenant to this hangar. If you need to change default hangar assignments, you can do that by editing this specific aircraft."
                  : "We’ll assign this new Tie-Down Tenant to this ramp. If you need to change default ramp assignments, you can do that by editing this specific aircraft.";
              setShowConfirmation(confirmationText);
              return;
            }
            setType(evt.target.value);
          }}
          value={type}
        >
          {(!location || isReference) && <MenuItem value="base">Base</MenuItem>}
          {(!location || !isReference) && (
            <MenuItem value="transient">Transient</MenuItem>
          )}
        </TextField>
      </Stack>
      <AutocompleteAircraft
        label="Search by make and model"
        onSelect={(aircraft) => setNewAircraft(aircraft)}
        defaultSelected={newAircraft}
      />
      <TextField
        autoFocus
        margin="dense"
        label="Notes"
        type="text"
        fullWidth
        placeholder="Departure Time: 05/24/2025 12:00 PM"
        multiline
        minRows={10}
        onChange={(evt) => setNote(evt.target.value)}
        value={note ?? ""}
      />
      {!location && type === "base" && (
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          <LocationTextField
            isReference={true}
            unassignedText={type === "base" ? "Unassigned" : "N/A"}
            selectedLocationId={assignedLocation?.location_id}
            onChange={(newLocationId) => {
              const newLocation = locations.find(
                (l) => l.location_id === newLocationId
              );
              setAssignedLocation(newLocation);
            }}
          />
          <LocationTextField
            isReference={false}
            selectedLocationId={currentLocation?.location_id}
            unassignedText="Not Hangared"
            onChange={(newLocationId) => {
              const newLocation = locations.find(
                (l) => l.location_id === newLocationId
              );
              setCurrentLocation(newLocation);
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
