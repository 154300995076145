import events from "../events/events";
import Component from "./Component";
/**
 * @fileOverview Builds a transparent plane SVG using the aircraft geom object,s
 * dispatches a click event when pressed.
 */

var PlanePolygon = function(geom, viewBox = null) {
  Component.call(this);
  this.geom_ = geom;
  this.bindedOnClick_ = this.onClick_.bind(this);
  this.bindedOnRightClick_ = this.onRightClick_.bind(this);
  this.bindedOnMouseUp_ = this.onMouseUp_.bind(this);

  if (viewBox) {
    this.viewBoxString_ = viewBox;
  } else {
    var yCoords = geom["coordinates"][0].map((coord) => coord[1]);
    var xCoords = geom["coordinates"][0].map((coord) => coord[0]);
    var maxX = Math.max(...xCoords);
    var maxY = Math.max(...yCoords);
    const maxValue = Math.max(maxX, maxY);
    this.viewBoxString_ = `0 0 ${maxX} ${maxY}`;
  }

  this.fillColor_ = "transparent";
  this.opacity_ = 1;
};

PlanePolygon.prototype = new Component();

PlanePolygon.prototype.createDom = function() {
  if (this.geom_["type"] != "Polygon")
    throw new Error("Can only render geom of type Polygon.");
  var pointsStr = this.geom_["coordinates"][0]
    .map((coord) => {
      return `${coord[0]}, ${coord[1]}`;
    })
    .join(" ");

  this.polygon_ = this.dom_.createElementNS(
    "http://www.w3.org/2000/svg",
    "polygon",
    {
      points: pointsStr,
      fill: this.fillColor_,
      opacity: this.opacity_,
    }
  );
  this.element_ = this.dom_.createElementNS(
    "http://www.w3.org/2000/svg",
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: this.viewBoxString_,
      class: "PlanePolygon",
    },
    this.polygon_
  );
  this.addEventListeners_();
};

PlanePolygon.prototype.onClick_ = function(e) {
  this.dispatchEvent(e);
};

PlanePolygon.prototype.onRightClick_ = function(e) {
  this.dispatchEvent(e);
};

PlanePolygon.prototype.onMouseUp_ = function(e) {
  this.dispatchEvent(e);
};

PlanePolygon.prototype.addEventListeners_ = function() {
  events.listen(this.polygon_, "click", this.bindedOnClick_);
  events.listen(this.polygon_, "contextmenu", this.bindedOnRightClick_);
  events.listen(this.polygon_, "mouseup", this.bindedOnMouseUp_);
};

PlanePolygon.prototype.removeEventListeners_ = function() {
  events.unlisten(this.polygon_, "click", this.bindedOnClick_);
  events.unlisten(this.polygon_, "contextmenu", this.bindedOnRightClick_);
  events.unlisten(this.polygon_, "mouseup", this.bindedOnMouseUp_);
};

PlanePolygon.prototype.destroy = function() {
  this.removeEventListeners_();
  Component.prototype.destroy.call(this);
};

PlanePolygon.prototype.getPolygonElement = function() {
  return this.polygon_;
};

PlanePolygon.prototype.setFillColor = function(color: string) {
  this.fillColor_ = color;
  if (this.polygon_) this.polygon_.setAttribute("fill", color);
};

PlanePolygon.prototype.setOpacity = function(opacity) {
  this.opacity_ = opacity;
  if (this.polygon_) this.polygon_.setAttribute("opacity", this.opacity_);
};

export default PlanePolygon;
