import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { Hangar } from "../../types";
import { sortHangars } from "./HangarsPresenter";

const CustomCheckbox: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid grey",
      borderRadius: 0.5,
      height: 20,
      width: 20,
    }}
  >
    <Typography
      alignSelf="center"
      alignContent="center"
      alignItems="center"
      textAlign="center"
      variant="inherit"
    >
      {children}
    </Typography>
  </Box>
);

type Props = {
  hangars: Hangar[];
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 15 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultiHangarButton: React.FC<Props> = ({ hangars }) => {
  const { activeFBO } = useActiveFBOs();
  const navigate = useNavigate();
  const [selectedHangarIds, setSelectedHangarIds] = React.useState<string[]>(
    []
  );
  const hangarsSorted = [...hangars].sort((a, b) =>
    sortHangars(a, b, activeFBO?.settings?.hangarDisplayOrder)
  );

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (value.includes("all")) {
      if (selectedHangarIds.length === hangars.length) {
        setSelectedHangarIds([]);
        return;
      }
      setSelectedHangarIds(hangars.map((h) => h.id));
      return;
    }
    setSelectedHangarIds(typeof value === "string" ? value.split(",") : value);
  };

  const urlIds = selectedHangarIds
    .map((id) => hangars.find((h) => h.id === id))
    .map((h) => h.id)
    .join("|");
  const goURL = `/multi-hangar/${urlIds}`;

  return (
    <Stack direction="row" spacing={1}>
      <FormControl sx={{ width: 300 }}>
        <InputLabel size="small">Multi-Hangar</InputLabel>
        <Select
          multiple
          value={selectedHangarIds}
          onChange={handleChange}
          input={<OutlinedInput size="small" label="Multi-Hangar" />}
          renderValue={(selected) =>
            selected
              .map((s) => hangarsSorted.find((h) => h.id === s).name)
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          <MenuItem key={`option-multihangar-all`} value={"all"}>
            <Checkbox
              checked={Boolean(selectedHangarIds.length === hangars.length)}
              icon={<CustomCheckbox>{""}</CustomCheckbox>}
              checkedIcon={<CustomCheckbox>{"✓"}</CustomCheckbox>}
            />
            <ListItemText primary={"Select All"} />
          </MenuItem>
          {hangarsSorted.map((hangar) => (
            <MenuItem key={`option-multihangar-${hangar.id}`} value={hangar.id}>
              <Checkbox
                checked={Boolean(selectedHangarIds.indexOf(hangar.id) > -1)}
                icon={<CustomCheckbox>{""}</CustomCheckbox>}
                checkedIcon={
                  <CustomCheckbox>
                    {selectedHangarIds.indexOf(hangar.id) + 1}
                  </CustomCheckbox>
                }
              />
              <ListItemText primary={hangar.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="success"
        disabled={selectedHangarIds.length === 0}
        onClick={() => navigate(goURL)}
      >
        Go
      </Button>
    </Stack>
  );
};
