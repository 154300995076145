import * as React from "react";
import loadingSvg from "../../../static/loading.svg";
import { Stack } from "@mui/material";

export const Loading: React.FC<{}> = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{ height: "100%", width: "100%" }}
  >
    <img src={loadingSvg} style={{ height: 150 }} />
  </Stack>
);
