import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { defaultTo } from "lodash";
import * as React from "react";
import { Aircraft } from "./LabelingTool";

const SmallTextArea = ({ isBeingSelected = false, ...props }) => {
  const extraProps = { ...props };
  if (!props.value && props.value !== 0 && props.required) {
    extraProps.color = "error";
    extraProps.focused = true;
    extraProps.required = null;
  }
  if (isBeingSelected) {
    extraProps.color = "success";
    extraProps.focused = true;
  }
  return (
    <TextField
      size="small"
      {...extraProps}
      sx={{ ...(props.sx || {}) }}
      InputLabelProps={{
        sx: {
          fontSize: 12,
        },
      }}
      inputProps={{
        sx: {
          fontSize: 12,
        },
        ...props.inputProps,
      }}
    />
  );
};

type Props = {
  aircrafts: Aircraft[];
  aircraft: Aircraft;
  setAircraft: (a: Aircraft) => void;
  setAircraftValue: (key: string, value: any) => void;
  // handlers
  onClickAutoOutline: () => void;
  onClickNose: () => void;
  contourNumber: number;
  setContourNumber: (x: number) => void;
  select: boolean;
  setSelect: (x: boolean) => void;
  setShapes: (shapes: number[][][]) => void;
  geomBeingSelected: string;
  setGeomBeingSelected: (x: string) => void;
  taggableArea: string;
  onChangeTaggableArea: (x: string) => void;
  onClickCalculateFromReferencePoints: () => void;
};

export const LabelingInputForm: React.FC<Props> = (props) => {
  const {
    aircrafts,
    aircraft,
    setAircraft,
    setAircraftValue,
    onClickAutoOutline,
    onClickNose,
    contourNumber,
    setContourNumber,
    setShapes,
    geomBeingSelected,
    setGeomBeingSelected,
    select,
    setSelect,
    taggableArea,
    onChangeTaggableArea,
    onClickCalculateFromReferencePoints,
  } = props;

  const [linkedAircraft, setLinkedAircraft] = React.useState<Aircraft>();
  const [noPropeller, setNoPropeller] = React.useState<boolean>(false);

  const needsPropellerSelection =
    !Boolean(aircraft.propeller_geom) && !noPropeller;

  React.useEffect(() => {
    setLinkedAircraft(aircrafts.find((a) => a.id === aircraft.linked_to));
  }, [aircraft.linked_to]);

  return (
    <Stack sx={{ mt: 1 }}>
      <Stack>
        <SmallTextArea
          required
          label="Make"
          onChange={(evt) => setAircraftValue("make", evt.target.value)}
          value={defaultTo(aircraft.make, "")}
        />
        <br />
        <SmallTextArea
          required
          label="Model"
          onChange={(evt) => setAircraftValue("model", evt.target.value)}
          value={defaultTo(aircraft.model, "")}
        />
        <br />
        <Stack direction="row">
          <Autocomplete
            disablePortal
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Link this aircraft to"
                size="small"
              />
            )}
            getOptionLabel={(option: Aircraft) =>
              `${option.make} ${option.model}`
            }
            options={aircrafts}
            onChange={(event: any, newValue: Aircraft | null) => {
              setLinkedAircraft(newValue);
            }}
            value={linkedAircraft || null}
          />

          <Button
            disabled={!Boolean(aircraft.linked_to)}
            size="small"
            onClick={() => {
              setAircraft({ ...aircraft, linked_to: null });
              setLinkedAircraft(null);
            }}
          >
            Remove
          </Button>
          <Button
            disabled={!Boolean(linkedAircraft)}
            size="small"
            onClick={() => {
              setAircraft({ ...aircraft, linked_to: linkedAircraft.id });
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      {Boolean(aircraft.linked_to) ? (
        <div />
      ) : (
        <>
          <Typography variant="subtitle1">Reference Points</Typography>
          <Stack>
            <Stack direction="row" spacing={2}>
              <SmallTextArea
                isBeingSelected={taggableArea === "reference_point_wing_bottom"}
                required
                label="Wing Bottom"
                inputProps={{
                  onFocus: () => {
                    onChangeTaggableArea("reference_point_wing_bottom");
                  },
                }}
                onChange={(evt) => {
                  setAircraftValue(
                    "reference_point_wing_bottom",
                    evt.target.value
                  );
                  onChangeTaggableArea("reference_point_wing_top");
                }}
                value={defaultTo(aircraft.reference_point_wing_bottom, "")}
              />
              <SmallTextArea
                isBeingSelected={taggableArea === "reference_point_wing_top"}
                required
                label="Wing Top"
                inputProps={{
                  onFocus: () => {
                    onChangeTaggableArea("reference_point_wing_top");
                  },
                }}
                onChange={(evt) =>
                  setAircraftValue("reference_point_wing_top", evt.target.value)
                }
                value={defaultTo(aircraft.reference_point_wing_top, "")}
              />
            </Stack>
            <br />
            <Stack direction="row" spacing={2}>
              <SmallTextArea
                isBeingSelected={taggableArea === "reference_point_tail_bottom"}
                required
                label="Horizontal Tail Bottom —"
                inputProps={{
                  onFocus: () => {
                    onChangeTaggableArea("reference_point_tail_bottom");
                  },
                }}
                onChange={(evt) => {
                  setAircraftValue(
                    "reference_point_tail_bottom",
                    evt.target.value
                  );
                }}
                value={defaultTo(aircraft.reference_point_tail_bottom, "")}
              />
              <SmallTextArea
                isBeingSelected={taggableArea === "reference_point_tail_top"}
                required
                label="Horizontal Tail Top —"
                inputProps={{
                  onFocus: () => {
                    onChangeTaggableArea("reference_point_tail_top");
                  },
                }}
                onChange={(evt) => {
                  setAircraftValue(
                    "reference_point_tail_top",
                    evt.target.value
                  );
                }}
                value={defaultTo(aircraft.reference_point_tail_top, "")}
              />
            </Stack>
            <br />
            <Stack direction="row" spacing={2}>
              <SmallTextArea
                isBeingSelected={
                  taggableArea === "reference_point_vertical_tail_fin_bottom"
                }
                required
                type="number"
                label="Vertical Tail Bottom |"
                inputProps={{
                  onFocus: () => {
                    onChangeTaggableArea(
                      "reference_point_vertical_tail_fin_bottom"
                    );
                  },
                }}
                onChange={(evt) => {
                  setAircraftValue(
                    "reference_point_vertical_tail_fin_bottom",
                    evt.target.value
                  );
                  setAircraftValue(
                    "reference_point_cabin_bottom",
                    evt.target.value
                  );
                }}
                value={defaultTo(
                  aircraft.reference_point_vertical_tail_fin_bottom,
                  ""
                )}
              />
              <SmallTextArea
                isBeingSelected={
                  taggableArea === "reference_point_vertical_tail_fin_top"
                }
                required
                label="Vertical Tail Top |"
                inputProps={{
                  onFocus: () => {
                    onChangeTaggableArea(
                      "reference_point_vertical_tail_fin_top"
                    );
                  },
                }}
                onChange={(evt) => {
                  setAircraftValue(
                    "reference_point_vertical_tail_fin_top",
                    evt.target.value
                  );
                }}
                value={defaultTo(
                  aircraft.reference_point_vertical_tail_fin_top,
                  ""
                )}
              />
            </Stack>
            <br />
            <Stack direction="row" spacing={2}>
              <SmallTextArea
                isBeingSelected={
                  taggableArea === "reference_point_cabin_bottom"
                }
                required
                type="number"
                label="Cabin Bottom"
                inputProps={{
                  onFocus: () => {
                    onChangeTaggableArea("reference_point_cabin_bottom");
                  },
                }}
                onChange={(evt) => {
                  setAircraftValue(
                    "reference_point_cabin_bottom",
                    evt.target.value
                  );
                }}
                value={defaultTo(aircraft.reference_point_cabin_bottom, "")}
              />
              <SmallTextArea
                isBeingSelected={taggableArea === "reference_point_cabin_top"}
                required
                label="Cabin Top"
                inputProps={{
                  onFocus: () => {
                    onChangeTaggableArea("reference_point_cabin_top");
                  },
                }}
                onChange={(evt) => {
                  setAircraftValue(
                    "reference_point_cabin_top",
                    evt.target.value
                  );
                }}
                value={defaultTo(aircraft.reference_point_cabin_top, "")}
              />
            </Stack>
            <hr />
            <Typography variant="subtitle1">Orientation</Typography>
            <Stack>
              <Stack direction="row" alignItems="center">
                <SmallTextArea
                  required
                  fullWidth
                  label="Nose"
                  inputProps={{
                    onFocus: () => {
                      setGeomBeingSelected("nose");
                    },
                  }}
                  onChange={(evt) => setAircraftValue("nose", evt.target.value)}
                  value={defaultTo(
                    JSON.stringify(aircraft.nose?.coordinates),
                    ""
                  )}
                />
                <SmallTextArea
                  required
                  fullWidth
                  type="number"
                  inputProps={{
                    onFocus: () => {
                      setGeomBeingSelected("midline");
                    },
                  }}
                  label="Midline"
                  onChange={(evt) =>
                    setAircraftValue("midline", evt.target.value)
                  }
                  value={defaultTo(aircraft.midline, "")}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={{ mt: 1 }}>
            <Typography variant="subtitle1">Propellers</Typography>
            <Stack direction="row" spacing={1}>
              <Button
                size="small"
                variant={noPropeller ? "contained" : "outlined"}
                color={needsPropellerSelection ? "error" : "primary"}
                onClick={() => {
                  // something?
                  setNoPropeller(!noPropeller);
                }}
              >
                no propeller
              </Button>
              <Button
                size="small"
                variant="outlined"
                color={needsPropellerSelection ? "error" : "primary"}
                onClick={() => {
                  setShapes([]);
                  setGeomBeingSelected("propeller_geom");
                  setNoPropeller(false);
                  setSelect(!select);
                }}
              >
                select
              </Button>
              <Button
                size="small"
                color="warning"
                disabled={!Boolean(aircraft.propeller_geom)}
                onClick={() => {
                  setAircraftValue("propeller_geom", null);
                }}
              >
                clear
              </Button>
              <Checkbox checked={Boolean(aircraft.propeller_geom)} />
            </Stack>
          </Stack>
          <Stack sx={{ mt: 1 }}>
            <Typography variant="subtitle1">Dimensions</Typography>
            <Stack direction="row">
              <SmallTextArea
                required
                type="number"
                label="Length"
                onChange={(evt) => setAircraftValue("length", evt.target.value)}
                value={defaultTo(aircraft.length, "")}
              />
              <SmallTextArea
                required
                type="number"
                label="Wingspan"
                onChange={(evt) =>
                  setAircraftValue("wingspan", evt.target.value)
                }
                value={defaultTo(aircraft.wingspan, "")}
              />
              <SmallTextArea
                required
                type="number"
                label="Tail Height"
                onChange={(evt) =>
                  setAircraftValue("tail_height", evt.target.value)
                }
                value={defaultTo(aircraft.tail_height, "")}
              />
            </Stack>
          </Stack>
          <Stack sx={{ mt: 1 }}>
            <Typography variant="subtitle1">Outline</Typography>
            <Stack direction="row" spacing={1}>
              <Button
                size="small"
                onClick={onClickAutoOutline}
                variant="outlined"
                color={aircraft.geom ? "primary" : "error"}
              >
                auto
              </Button>
              <SmallTextArea
                type="number"
                label="Contour #"
                onChange={(evt) => setContourNumber(evt.target.value)}
                value={defaultTo(contourNumber, 1)}
              />
              <SmallTextArea
                label="Simplify %"
                type="number"
                inputProps={{
                  step: 0.005,
                  min: 0,
                  max: 1,
                }}
                onChange={(evt) =>
                  setAircraftValue("simplify_pct", evt.target.value)
                }
                value={defaultTo(aircraft.simplify_pct, "")}
              />
            </Stack>
          </Stack>
          <Stack sx={{ mt: 1 }}>
            <Typography variant="subtitle1">Overlaps</Typography>
            <Typography variant="subtitle2">Wings</Typography>
            <Stack direction="row">
              <Button
                size="small"
                onClick={() => {
                  setShapes([]);
                  setGeomBeingSelected("wing_geom");
                  setSelect(!select);
                }}
              >
                select
              </Button>
              <Button
                size="small"
                color="warning"
                disabled={!Boolean(aircraft.wing_geom)}
                onClick={() => setAircraftValue("wing_geom", null)}
              >
                clear
              </Button>
              <Checkbox checked={Boolean(aircraft.wing_geom)} />
              <SmallTextArea
                required
                label="Bottom"
                onChange={(evt) =>
                  setAircraftValue("wing_bottom", evt.target.value)
                }
                value={defaultTo(aircraft.wing_bottom, "")}
              />
              <SmallTextArea
                required
                label="Top"
                onChange={(evt) =>
                  setAircraftValue("wing_top", evt.target.value)
                }
                value={defaultTo(aircraft.wing_top, "")}
              />
            </Stack>
            <Typography variant="subtitle2">Horizontal Tail</Typography>
            <Stack direction="row">
              <Button
                size="small"
                onClick={() => {
                  setShapes([]);
                  setGeomBeingSelected("tail_geom");
                  setSelect(!select);
                }}
              >
                select
              </Button>
              <Button
                size="small"
                color="warning"
                disabled={!Boolean(aircraft.tail_geom)}
                onClick={() => setAircraftValue("tail_geom", null)}
              >
                clear
              </Button>
              <Checkbox checked={Boolean(aircraft.tail_geom)} />
              <SmallTextArea
                required
                label="Bottom"
                onChange={(evt) =>
                  setAircraftValue("tail_bottom", evt.target.value)
                }
                value={defaultTo(aircraft.tail_bottom, "")}
              />
              <SmallTextArea
                required
                label="Top"
                onChange={(evt) =>
                  setAircraftValue("tail_top", evt.target.value)
                }
                value={defaultTo(aircraft.tail_top, "")}
                // helperText={
                //   aircraft.tail_top > aircraft.vertical_tail_fin_bottom
                //     ? "Tail top must be above vertical tail fin bottom"
                //     : ""
                // }
                // error={aircraft.tail_top > aircraft.vertical_tail_fin_bottom}
              />
            </Stack>
            <Typography variant="subtitle2">Vertical Tail Fin</Typography>
            <Stack direction="row">
              <Button
                size="small"
                onClick={() => {
                  setShapes([]);
                  setGeomBeingSelected("vertical_tail_fin_geom");
                  setSelect(!select);
                }}
              >
                select
              </Button>
              <Button
                size="small"
                color="warning"
                disabled={!Boolean(aircraft.vertical_tail_fin_geom)}
                onClick={() => setAircraftValue("vertical_tail_fin_geom", null)}
              >
                clear
              </Button>
              <Checkbox checked={Boolean(aircraft.vertical_tail_fin_geom)} />
              <SmallTextArea
                required
                label="Bottom"
                onChange={(evt) =>
                  setAircraftValue("vertical_tail_fin_bottom", evt.target.value)
                }
                value={defaultTo(aircraft.vertical_tail_fin_bottom, "")}
              />
              <SmallTextArea
                required
                label="Top"
                onChange={(evt) =>
                  setAircraftValue("vertical_tail_fin_top", evt.target.value)
                }
                value={defaultTo(aircraft.vertical_tail_fin_top, "")}
              />
            </Stack>
            <Typography variant="subtitle2">Cabin</Typography>
            <Stack direction="row">
              <SmallTextArea
                required
                type="number"
                label="Bottom"
                onChange={(evt) =>
                  setAircraftValue("cabin_bottom", evt.target.value)
                }
                value={defaultTo(aircraft.cabin_bottom, "")}
              />
              <SmallTextArea
                required
                type="number"
                label="Top"
                onChange={(evt) =>
                  setAircraftValue("cabin_top", evt.target.value)
                }
                value={defaultTo(aircraft.cabin_top, "")}
              />
            </Stack>
          </Stack>
          <Typography variant="subtitle1">Towing</Typography>
          <Stack direction="row">
            <SmallTextArea
              label="Limit Degrees"
              onChange={(evt) =>
                setAircraftValue("wheel_rotation_limit", evt.target.value)
              }
              value={defaultTo(aircraft.wheel_rotation_limit, "")}
            />
            <SmallTextArea
              required
              label="Front"
              type="number"
              inputProps={{
                onFocus: () => {
                  setGeomBeingSelected("front_wheels");
                },
              }}
              onChange={(evt) =>
                setAircraftValue("front_wheels", evt.target.value)
              }
              value={defaultTo(aircraft.front_wheels, "")}
            />
            <SmallTextArea
              required
              type="number"
              label="Rear"
              inputProps={{
                onFocus: () => {
                  setGeomBeingSelected("rear_wheels");
                },
              }}
              onChange={(evt) =>
                setAircraftValue("rear_wheels", evt.target.value)
              }
              value={defaultTo(aircraft.rear_wheels, "")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  onChange={(evt) =>
                    setAircraftValue("tail_wheel", evt.target.checked)
                  }
                  checked={Boolean(aircraft.tail_wheel)}
                />
              }
              label="Tail Wheel?"
            />
          </Stack>
          <Typography variant="subtitle1">Fuel</Typography>
          <Stack direction="row">
            <SmallTextArea
              label="Fuel Capacity"
              type="number"
              onChange={(evt) =>
                setAircraftValue("fuel_capacity", evt.target.value)
              }
              value={defaultTo(aircraft.fuel_capacity, "")}
            />
            <TextField
              size="small"
              fullWidth
              label="Fuel Type"
              value={aircraft.fuel_type ?? ""}
              select
              onChange={(evt) =>
                setAircraftValue("fuel_type", evt.target.value)
              }
            >
              <MenuItem value="Jet A">Jet A</MenuItem>
              <MenuItem value="AvGas">AvGas</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
          </Stack>
          <Typography variant="subtitle1">Helicopter</Typography>
          <Stack direction="row">
            <FormControlLabel
              control={
                <Checkbox
                  checked={aircraft.is_helicopter}
                  onChange={(evt) =>
                    setAircraftValue("is_helicopter", evt.target.checked)
                  }
                />
              }
              label="Is Helicopter?"
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
