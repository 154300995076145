import * as React from "react";
import { AutocompleteResults } from "../../../containers/AutocompleteResultsContainer";
import { Box } from "@mui/material";
import { EntityPolygon, Stack } from "../../../types";
import { findTenantsFullyInHangar } from "../../../screens/Hangar/HangarInfoCard";
import { FullyLoadedDraggableAircraft } from "../FullyLoadedDraggableAircraft";
import { useIsInAnyLayout } from "../hooks/useIsInLayout";
import { useMultiHangarState } from "../../../containers/MultiHangarContainer";
import { useThisHangar } from "../../../hooks/useThisHangar";

type Props = {
  stack: Stack;
  polygons: EntityPolygon[];
  autocompleteResults: AutocompleteResults;
  feetToPixels: number;
  mouseOverIndex: number;
  height: number;
};

export const GhostAircraft: React.FC<Props> = ({
  stack,
  polygons,
  autocompleteResults,
  feetToPixels,
  mouseOverIndex,
  height,
}) => {
  const { hangar } = useThisHangar();
  const { isMultiHangar, hangars } = useMultiHangarState();
  const isInAnyLayout = useIsInAnyLayout({ feetToPixels }, []);
  const selectedTenant =
    hangar?.stack.tenants.find((t) => t.selected) ||
    hangars
      .find((h) => h.stack.tenants.find((t) => t.selected))
      ?.stack.tenants.find((t) => t.selected);
  const { tenant: tenantBeingAutocompleted } = autocompleteResults;

  const selected = Boolean(
    hangar?.stack.tenants.find((t) => t.selected) ||
      hangars.find((h) => h.stack.tenants.find((t) => t.selected))
  );
  if (!selected) {
    return null;
  }

  if (!tenantBeingAutocompleted) {
    return null;
  }

  if (selectedTenant?.id !== tenantBeingAutocompleted.id) {
    return null;
  }

  if (
    findTenantsFullyInHangar(hangar, polygons).find(
      (t) => t.id === tenantBeingAutocompleted.id
    )
  ) {
    return null;
  }

  if (isMultiHangar && isInAnyLayout(tenantBeingAutocompleted)) {
    return null;
  }

  return (
    <React.Fragment
      key={`suggested-aircraft-${stack.id}-${tenantBeingAutocompleted?.entity_id}`}
    >
      {autocompleteResults.suggestedPositions
        .filter((x) => x)
        ?.map((suggestedPosition, idx) => {
          return (
            <Box
              key={`suggested-position-aircraft-${stack.id}-${suggestedPosition.id}`}
              sx={{
                opacity: idx === mouseOverIndex ? 0.9 : 0.4,
                filter:
                  idx === mouseOverIndex ? "brightness(0.3)" : "brightness(1)",
              }}
            >
              <FullyLoadedDraggableAircraft
                readOnly={true}
                layoutHeight={height}
                feetToPixels={feetToPixels}
                tenant={{
                  ...tenantBeingAutocompleted,
                  id: suggestedPosition.id,
                  selected: false,
                  position: {
                    ...tenantBeingAutocompleted.position,
                    ...suggestedPosition,
                  },
                }}
                stack={stack}
                setStack={() => {}}
                preferences={[]}
                onPositionChange={() => {}}
                rightClick={null}
                setRightClick={() => {}}
                spacingBufferOverlayElement={null}
              />
            </Box>
          );
        })}
    </React.Fragment>
  );
};
