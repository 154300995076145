/**
 * @fileOverview This only serves as a place to lump all interactive aircraft
 * into a global array, the same could likely be achieved by passing down
 * a ref from the parent component that makes use of it.
 * The reason for doing this is when the user taps anywhere on the screen
 * it deselects the current aircraft, but we don't want this behaviour if
 * the user has been rotating a plane around it's rotation handles. But it's
 * quite likely that releasing the mouse after rotating will not be over the
 * top of the draggable aircraft, and so we need to query at that point if
 * any aircraft are being dragged so we can cancel the deselection.
 */

var DraggableAircraftRegistry = function(){
  this.aircraft_ = []
}

DraggableAircraftRegistry.prototype.addAircraft = function(aircraft){
  this.aircraft_.push(aircraft)
}

DraggableAircraftRegistry.prototype.removeAircraft = function(aircraft){
  var foundIndex = this.aircraft_.findIndex(a => aircraft == a)
  if (foundIndex == -1)
    throw new Error('Aircraft instance not found in registry.')
  this.aircraft_.splice(foundIndex, 1)
}


DraggableAircraftRegistry.prototype.isAnyAircraftBeingInteractedWith = function(){
  return this.aircraft_.some(a => a.isBeingInteractedWith())
}

export default new DraggableAircraftRegistry()