import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useHangarsState } from "../../containers/HangarsStateContainer";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { useRampState } from "../../containers/RampStateContainer";
import { useRampsState } from "../../containers/RampsStateContainer";
import { useLocations } from "../../hooks/useLocations";
import { useThisHangar } from "../../hooks/useThisHangar";
import { sortHangars } from "../../screens/Hangars/HangarsPresenter";
import { sortRamps } from "../../screens/Ramps/RampsPresenter";
import { Hangar, Ramp, Stack as StackType, Tenant } from "../../types";

type Props = {
  tenant: Tenant;
  stack: StackType;
  setStack: (stack: StackType) => void;
};

export const ButtonMoveTo: React.FC<Props> = ({ tenant, stack, setStack }) => {
  const { activeFBO } = useActiveFBOs();
  const { hangar } = useThisHangar();
  const { isMultiHangar, hangars, setHangars } = useMultiHangarState();
  const { sendToLocation } = useLocations();
  const { ramp } = useRampState();
  const { hangars: allHangars } = useHangarsState();
  const { ramps } = useRampsState();
  const locations: Array<Ramp | Hangar> = [
    ...allHangars.sort((a, b) =>
      sortHangars(a, b, activeFBO?.settings?.hangarDisplayOrder)
    ),
    ...ramps.sort((a, b) =>
      sortRamps(a, b, activeFBO?.settings?.rampDisplayOrder)
    ),
  ];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const tenantLocationId = isMultiHangar
    ? hangars.find((h) => h.stack.tenants.find((t) => t.id === tenant.id))
        .location_id
    : ramp?.location_id || hangar?.location_id;

  return (
    <>
      <Button
        color="primary"
        endIcon={<ChevronRightIcon />}
        onClick={handleClick}
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        {stack.isReference ? "Assign To" : "Move To"}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {locations.map((location) => (
          <MenuItem
            key={`moveto-location-${location.id}`}
            onClick={() => {
              if (isMultiHangar) {
                setHangars(
                  hangars.map((hangar) => ({
                    ...hangar,
                    stack: {
                      ...hangar.stack,
                      tenants:
                        hangar.id === location.id
                          ? [
                              ...hangar.stack.tenants,
                              {
                                ...tenant,
                                position: {
                                  ...tenant.position,
                                  stack_id: location.stack.id,
                                  entity_id: tenant.entity_id,
                                  x: null,
                                  y: null,
                                  angle: null,
                                  preferences: tenant.position.preferences,
                                },
                              },
                            ]
                          : hangar.stack.tenants.filter(
                              (t) => t.id !== tenant.id
                            ),
                    },
                  }))
                );
                return;
              }
              // remove from the stack
              setStack({
                ...stack,
                tenants: stack.tenants.map((t) =>
                  t.id === tenant.id
                    ? {
                        ...t,
                        position: {
                          ...t.position,
                          stack_id: location.stack.id,
                          entity_id: tenant.entity_id,
                          x: null,
                          y: null,
                          angle: null,
                          preferences: tenant.position.preferences,
                        },
                      }
                    : t
                ),
              });
              handleClose();
            }}
          >
            {location.location_id === tenantLocationId ? "✓ " : ""}
            {location.name}
          </MenuItem>
        ))}
        {stack.isReference && (
          <MenuItem
            onClick={() => {
              sendToLocation(null, "reference", tenant);
              if (isMultiHangar) {
                setHangars(
                  hangars.map((hangar) => ({
                    ...hangar,
                    stack: {
                      ...hangar.stack,
                      tenants: hangar.stack.tenants.filter(
                        (t) => t.id !== tenant.id
                      ),
                    },
                  }))
                );
                return;
              }
              setStack({
                ...stack,
                tenants: stack.tenants.map((t) =>
                  t.id === tenant.id
                    ? {
                        ...t,
                        position: {
                          ...t.position,
                          stack_id: null,
                          entity_id: tenant.entity_id,
                          x: null,
                          y: null,
                          angle: null,
                          preferences: tenant.position.preferences,
                        },
                      }
                    : t
                ),
              });
              handleClose();
            }}
          >
            Unassigned
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
