import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import dateFormat from "dateformat";
import * as React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "../../../containers/SnackbarContainer";
import { Invitation } from "../../../types";

type Props = {
  invitations: Invitation[];
  setInvitations: (invitations: Invitation[]) => void;
};

export const InvitationsPresenter: React.FC<Props> = ({
  invitations,
  setInvitations,
}) => {
  const snackbar = useSnackbar();
  return (
    <Box sx={{ minWidth: 500 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6" component="div">
          Invites
        </Typography>
      </Stack>
      {invitations.length === 0 && (
        <Typography sx={{ pt: 3 }} align="center" variant="body2">
          No invites outstanding
        </Typography>
      )}
      <List>
        {invitations.map((invite) => (
          <React.Fragment key={`invite-${invite.id}`}>
            <ListItem
              disablePadding
              secondaryAction={
                <Stack direction="column" alignItems="flex-start">
                  <CopyToClipboard
                    text={`${window.location.origin}/#/claim-invite/${invite.id}`}
                  >
                    <Button
                      size="small"
                      startIcon={<LinkIcon />}
                      onClick={() => {
                        snackbar.notify({
                          text: "Copied to clipboard",
                          severity: "success",
                        });
                      }}
                    >
                      Copy Invite Link
                    </Button>
                  </CopyToClipboard>
                  <Button
                    size="small"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setInvitations(
                        invitations.filter((i) => i.id !== invite.id)
                      );
                    }}
                  >
                    Delete Invite
                  </Button>
                </Stack>
              }
            >
              <ListItemText
                primary={invite.email}
                secondary={`Sent on ${dateFormat(
                  invite.created_at,
                  "shortDate"
                )}
                 at ${dateFormat(invite.created_at, "shortTime")}`}
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};
