import { Hangar, Location, Ramp } from "../../../types";

type Props = {
  location: Location<Ramp | Hangar>;
  width?: number;
  height?: number;
};

export const useDimensions = (
  props: Props
): { width: number; height: number; feetToPixels: number } => {
  const { location } = props;
  let { width, height } = props;
  if (width) {
    height = width * (location?.depth / location?.width);
  } else if (height) {
    width = height * (location?.width / location?.depth);
  } else {
    width = 300;
    height = width * (location?.depth / location?.width);
  }

  const feetToPixels = width / location?.width;

  return {
    width,
    height,
    feetToPixels,
  };
};
