export const drawPoint = (
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number = 6,
  lineWidth: number = 2,
  fillStyle: string = "red",
  strokeStyle: string = "white"
) => {
  context.fillStyle = fillStyle;
  context.strokeStyle = strokeStyle;
  context.lineWidth = lineWidth;
  context.setLineDash([]);
  context.beginPath();
  context.arc(x, y, radius, 0, Math.PI * 2); // 6px radius for the point
  context.fill();
  context.stroke();
};

export const drawText = (
  context: CanvasRenderingContext2D,
  midX: number,
  midY: number,
  text: string,
  color: string = "black",
  fontSize: number = 12
) => {
  context.font = `${fontSize}px Arial`;
  context.fillStyle = color;
  context.fillText(text, midX, midY);
};

export const drawTextPill = (
  context: CanvasRenderingContext2D,
  midX: number,
  midY: number,
  text: string,
  fontSize: number = 12
) => {
  // Draw a rounded rectangle for the distance box
  const padding = 4;
  context.font = `${fontSize}px Arial`;
  const textWidth = context.measureText(text).width;
  const boxWidth = textWidth + padding * 2;
  const boxHeight = fontSize + padding * 2;
  const radius = 5;

  context.fillStyle = "white";
  context.strokeStyle = "black";
  context.lineWidth = 1;

  context.beginPath();
  context.moveTo(midX - boxWidth / 2 + radius, midY - boxHeight / 2);
  context.lineTo(midX + boxWidth / 2 - radius, midY - boxHeight / 2);
  context.quadraticCurveTo(
    midX + boxWidth / 2,
    midY - boxHeight / 2,
    midX + boxWidth / 2,
    midY - boxHeight / 2 + radius
  );
  context.lineTo(midX + boxWidth / 2, midY + boxHeight / 2 - radius);
  context.quadraticCurveTo(
    midX + boxWidth / 2,
    midY + boxHeight / 2,
    midX + boxWidth / 2 - radius,
    midY + boxHeight / 2
  );
  context.lineTo(midX - boxWidth / 2 + radius, midY + boxHeight / 2);
  context.quadraticCurveTo(
    midX - boxWidth / 2,
    midY + boxHeight / 2,
    midX - boxWidth / 2,
    midY + boxHeight / 2 - radius
  );
  context.lineTo(midX - boxWidth / 2, midY - boxHeight / 2 + radius);
  context.quadraticCurveTo(
    midX - boxWidth / 2,
    midY - boxHeight / 2,
    midX - boxWidth / 2 + radius,
    midY - boxHeight / 2
  );
  context.closePath();
  context.fill();
  context.stroke();

  context.fillStyle = "black";
  context.fillText(text, midX - textWidth / 2, midY + fontSize / 2 - padding);
};

export const lineStyleToDash = (lineStyle: string) => {
  switch (lineStyle) {
    case "dashed":
      return [5, 5];
    case "dotted":
      return [2, 2];
    case "solid":
    default:
      return [];
  }
};
