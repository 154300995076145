import CheckIcon from "@mui/icons-material/Check";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { LocationsContainer } from "../../containers/LocationsStateContainer";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { useRampState } from "../../containers/RampStateContainer";
import { useThisHangar } from "../../hooks/useThisHangar";
import { Preference, Stack as StackType, Tenant } from "../../types";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { EditTenant } from "../EditTenant";
import { ButtonMoveTo } from "./ButtonMoveTo";
import { ButtonNotes } from "./ButtonNotes";

type Props = {
  stack: StackType;
  setStack: (stack: StackType) => void;
  tenant: Tenant;
  setTenant: (tenant: Tenant) => void;
  preferences: Preference[];
  setPreferences: (preferences: Preference[]) => void;
  onDeleteAircraft: () => void;
};

export const AircraftPreferencesListItemMenuButton: React.FC<Props> = ({
  stack,
  setStack,
  tenant,
  setTenant,
  preferences,
  setPreferences,
  onDeleteAircraft,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton data-testid="more_aircraft_options" onClick={handleClick}>
        <MoreHorizIcon color="primary" />
      </IconButton>
      {openEdit && (
        <LocationsContainer>
          <EditTenant
            displayTypeAndLocation={false}
            stack={stack}
            setStack={setStack}
            tenant={tenant}
            setTenant={setTenant}
            onClose={() => {
              setOpenEdit(false);
            }}
          />
        </LocationsContainer>
      )}
      <AircraftPreferencesListItemMenu
        stack={stack}
        setStack={setStack}
        tenant={tenant}
        setTenant={setTenant}
        componentType="menu"
        onEditAircraft={() => {
          setAnchorEl(null);
          setOpenEdit(true);
        }}
        preferences={preferences}
        setPreferences={setPreferences}
        menuProps={{
          anchorEl,
          open,
          onClose: handleClose,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        onDeleteAircraft={() => {
          onDeleteAircraft();
          handleClose();
        }}
      />
    </>
  );
};

type MenuProps = Props & {
  componentType: string;
  onEditAircraft: () => void;
  menuProps: { [key: string]: any };
};
export const AircraftPreferencesListItemMenu: React.FC<MenuProps> = (props) => {
  const { componentType = "menulist", menuProps, ...otherProps } = props;
  if (componentType === "menu") {
    return (
      <Menu
        data-testid="preferences_menu"
        open={true}
        MenuListProps={{ dense: false }}
        {...menuProps}
      >
        <MenuListItems {...otherProps} />
      </Menu>
    );
  }

  return null;
};

type AircraftPreferencesContextMenuProps = Props & {
  stack: StackType;
  setStack: (stack: StackType) => void;
  tenant: Tenant;
  setTenant: (t: Tenant) => void;
  componentType: string;
  menuProps: { [key: string]: any };
};

type MovableObstacleContextMenuProps = {
  menuProps: { [key: string]: any };
  onDelete: () => void;
};
export const MovableObstacleContextMenu: React.FC<MovableObstacleContextMenuProps> = ({
  menuProps,
  onDelete,
  ...props
}) => {
  return (
    <MenuList dense={false} {...menuProps}>
      <Button fullWidth color="error" onClick={onDelete}>
        Remove Obstacle
      </Button>
    </MenuList>
  );
};

export const AircraftPreferencesContextMenu: React.FC<AircraftPreferencesContextMenuProps> = ({
  stack,
  setStack,
  ...props
}) => {
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const {
    tenant,
    setTenant,
    componentType = "menulist",
    menuProps,
    ...otherProps
  } = props;
  return (
    <>
      <MenuList dense={false} {...menuProps}>
        <MenuListItems
          stack={stack}
          setStack={setStack}
          tenant={tenant}
          setTenant={setTenant}
          onEditAircraft={() => setOpenEdit(true)}
          {...otherProps}
        />
      </MenuList>
      {openEdit && (
        <LocationsContainer>
          <EditTenant
            displayTypeAndLocation={false}
            stack={stack}
            setStack={setStack}
            tenant={tenant}
            setTenant={setTenant}
            onClose={() => {
              setOpenEdit(false);
            }}
          />
        </LocationsContainer>
      )}
    </>
  );
};

const StackOptionButton: React.FC<{
  text: string;
  selected: boolean;
  onClick: () => void;
}> = ({ text, selected, onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      {selected && (
        <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>
      )}
      <ListItemText inset={!selected}>
        <Typography variant="button" fontWeight={700}>
          {text}
        </Typography>
      </ListItemText>
    </MenuItem>
  );
};

const MenuListItems: React.FC<Props & {
  onEditAircraft: () => void;
}> = ({
  tenant,
  preferences,
  setPreferences,
  onEditAircraft,
  onDeleteAircraft,
  setStack,
}) => {
  const { activeLocationId } = useMultiHangarState();
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const { hangar } = useThisHangar();
  const { ramp } = useRampState();
  const isHangar = Boolean(hangar);
  const stack = hangar?.stack ?? ramp?.stack;

  const isNoseIn =
    preferences.indexOf(Preference.NOSE_IN) > -1 ||
    preferences.indexOf(Preference.TAIL_WHEEL_NOSE_IN) > -1;
  const isTailIn =
    preferences.indexOf(Preference.TAIL_IN) > -1 ||
    preferences.indexOf(Preference.TAIL_WHEEL_TAIL_IN) > -1;
  return (
    <>
      {confirmDelete && (
        <ConfirmationDialog
          title={`Please Confirm`}
          text={
            stack.isReference
              ? `Making this Tenant into a Transient means this aircraft is no longer based in your hangars. It will remain on your transient list of aircraft.`
              : `This aircraft will be removed from this ${
                  isHangar ? "hangar" : "ramp"
                }.`
          }
          okText="Yes, remove this aircraft"
          onOk={onDeleteAircraft}
          onClose={() => setConfirmDelete(false)}
          onCancel={() => setConfirmDelete(false)}
        />
      )}
      <Stack direction="column">
        <ButtonNotes
          tenant={tenant}
          setTenant={(tenant) => {
            setStack({
              ...stack,
              tenants: stack.tenants.map((t) =>
                t.id === tenant.id ? tenant : t
              ),
            });
          }}
        />
        <Divider />
      </Stack>
      {isHangar && (
        <div data-testid="preferences_menu">
          <StackOptionButton
            selected={isTailIn}
            text="Tail In"
            onClick={() => {
              if (isTailIn) {
                setPreferences(
                  preferences.filter(
                    (p) =>
                      p !== Preference.TAIL_IN &&
                      p !== Preference.TAIL_WHEEL_TAIL_IN
                  )
                );
              } else {
                const newPreference = tenant.aircraft.tail_wheel
                  ? Preference.TAIL_WHEEL_TAIL_IN
                  : Preference.TAIL_IN;
                setPreferences(
                  [...preferences, newPreference].filter(
                    (p) =>
                      p !== Preference.NOSE_IN &&
                      p !== Preference.TAIL_WHEEL_NOSE_IN
                  )
                );
              }
            }}
          />
          <StackOptionButton
            selected={isNoseIn}
            text="Nose In"
            onClick={() => {
              if (isNoseIn) {
                setPreferences(
                  preferences.filter(
                    (p) =>
                      p !== Preference.NOSE_IN &&
                      p !== Preference.TAIL_WHEEL_NOSE_IN
                  )
                );
              } else {
                const newPreference = tenant.aircraft.tail_wheel
                  ? Preference.TAIL_WHEEL_NOSE_IN
                  : Preference.NOSE_IN;
                setPreferences(
                  [...preferences, newPreference].filter(
                    (p) =>
                      p !== Preference.TAIL_IN &&
                      p !== Preference.TAIL_WHEEL_TAIL_IN
                  )
                );
              }
            }}
          />
          <StackOptionButton
            selected={preferences.indexOf(Preference.PREFER_FRONT_ROW) > -1}
            text="Front Row"
            onClick={() => {
              if (preferences.indexOf(Preference.PREFER_FRONT_ROW) > -1) {
                setPreferences(
                  preferences.filter((p) => p !== Preference.PREFER_FRONT_ROW)
                );
              } else {
                setPreferences(
                  [...preferences, Preference.PREFER_FRONT_ROW].filter(
                    (p) => p !== Preference.PREFER_BACK_ROW
                  )
                );
              }
            }}
          />
          <StackOptionButton
            selected={preferences.indexOf(Preference.PREFER_BACK_ROW) > -1}
            text="Back Row"
            onClick={() => {
              if (preferences.indexOf(Preference.PREFER_BACK_ROW) > -1) {
                setPreferences(
                  preferences.filter((p) => p !== Preference.PREFER_BACK_ROW)
                );
              } else {
                setPreferences(
                  [...preferences, Preference.PREFER_BACK_ROW].filter(
                    (p) => p !== Preference.PREFER_FRONT_ROW
                  )
                );
              }
            }}
          />
          <StackOptionButton
            selected={preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1}
            text="OK Outside"
            onClick={() => {
              if (preferences.indexOf(Preference.OK_TO_LEAVE_OUT) > -1) {
                setPreferences(
                  preferences.filter((p) => p !== Preference.OK_TO_LEAVE_OUT)
                );
              } else {
                setPreferences([...preferences, Preference.OK_TO_LEAVE_OUT]);
              }
            }}
          />
          <StackOptionButton
            selected={preferences.indexOf(Preference.LOCKED) > -1}
            text="Locked"
            onClick={() => {
              if (preferences.indexOf(Preference.LOCKED) > -1) {
                setPreferences(
                  preferences.filter((p) => p !== Preference.LOCKED)
                );
              } else {
                setPreferences([...preferences, Preference.LOCKED]);
              }
            }}
          />
          <Divider />
        </div>
      )}
      <Stack direction="column">
        <ButtonMoveTo tenant={tenant} stack={stack} setStack={setStack} />
        <Button fullWidth color="primary" onClick={onEditAircraft}>
          Edit Aircraft
        </Button>
        <Button fullWidth color="error" onClick={() => setConfirmDelete(true)}>
          Remove Aircraft
        </Button>
      </Stack>
    </>
  );
};
