import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import TuneIcon from "@mui/icons-material/Tune";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { ParamSet } from "../../../types";
import { StackStrategy } from "../GenerateParamSets";

type Props = {
  paramSet: ParamSet;
  stackStrategy: StackStrategy;
  setStackStrategy: (stackStrategy: StackStrategy) => void;
};

export const SelectStackStrategy: React.FC<Props> = ({
  paramSet,
  stackStrategy,
  setStackStrategy,
}) => {
  const { activeFBO } = useActiveFBOs();
  return (
    <Stack direction="column">
      <Stack direction="row" spacing={2}>
        <Card
          elevation={0}
          variant="outlined"
          onClick={() => setStackStrategy(StackStrategy.normal)}
          sx={{
            width: "100%",
            borderColor:
              stackStrategy === StackStrategy.normal && "success.main",
            borderWidth: stackStrategy === StackStrategy.normal && 2,
            cursor: "pointer",
          }}
        >
          <CardContent>
            <ThumbUpIcon />
            <Typography>Normal</Typography>
            <Typography variant="caption">
              Day-to-day operations using SOPs.
              <br />
              • Horizontal spacing: {
                paramSet.options.horizontal_spacing
              } ft <br />
              • Overlap min:{" "}
              {activeFBO?.sop_overlap
                ? `${activeFBO?.sop_vertical_spacing} ft`
                : "No Overlap"}
            </Typography>
          </CardContent>
        </Card>
        <Card
          elevation={0}
          variant="outlined"
          onClick={() => setStackStrategy(StackStrategy.tight)}
          sx={{
            width: "100%",
            borderColor:
              stackStrategy === StackStrategy.tight && "success.main",
            borderWidth: stackStrategy === StackStrategy.tight && 2,
            cursor: "pointer",
          }}
        >
          <CardContent>
            <Stack direction="row">
              <LocalFireDepartmentIcon />
            </Stack>
            <Typography>Tight</Typography>
            <Typography variant="caption">
              Optimized for maximum utilization.
              <br />
              • Horizontal spacing:{" "}
              {activeFBO?.sop_horizontal_spacing_tight ??
                paramSet.options.horizontal_spacing}{" "}
              ft <br />
              • Overlap min:{" "}
              {activeFBO?.sop_overlap_tight
                ? `${activeFBO?.sop_vertical_spacing_tight} ft`
                : "No Overlap"}
            </Typography>
          </CardContent>
        </Card>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Card
          elevation={0}
          variant="outlined"
          onClick={() => setStackStrategy(StackStrategy.loose)}
          sx={{
            width: "100%",
            borderColor:
              stackStrategy === StackStrategy.loose && "success.main",
            borderWidth: stackStrategy === StackStrategy.loose && 2,
            cursor: "pointer",
          }}
        >
          <CardContent>
            <BeachAccessIcon />
            <Typography>Loose</Typography>
            <Typography variant="caption">
              Optimized for easy towing, at the expense of utilization.
              <br />
              • Horizontal spacing: {
                activeFBO?.sop_horizontal_spacing_loose
              } ft <br />
              • Overlap min:{" "}
              {activeFBO?.sop_overlap_loose
                ? `${activeFBO?.sop_vertical_spacing_loose} ft`
                : "No Overlap"}
            </Typography>
          </CardContent>
        </Card>
        <Card
          elevation={0}
          variant="outlined"
          onClick={() => setStackStrategy(StackStrategy.custom)}
          sx={{
            width: "100%",
            borderColor:
              stackStrategy === StackStrategy.custom && "success.main",
            borderWidth: stackStrategy === StackStrategy.custom && 2,
            cursor: "pointer",
          }}
        >
          <CardContent>
            <TuneIcon />
            <Typography>Custom</Typography>
            <Typography variant="caption">
              Completely customize your stack preferences.
            </Typography>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
