import * as React from "react";
import { usePrevious } from "react-use";
import { v4 as uuidv4 } from "uuid";
import { useActiveFBOs } from "../containers/ActiveFBOContainer";
import { useApi } from "../containers/ApiContainer";

export type TowingEquipment = {
  id: string;
  towing_equipment_id: string;
  name: string;
  width: number;
  length: number;
  height: number;
  tow_bar_length: number;
  geom: number;
  geomz: number;
  in_use: boolean;
  is_default: boolean;
};

export const useTowingEquipment = (limitToInUse: boolean = false) => {
  const { postgrest } = useApi();
  const { activeFBO } = useActiveFBOs();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [towingEquipments, setTowingEquipments] = React.useState<
    TowingEquipment[]
  >();
  const previousTowingEquipments = usePrevious(towingEquipments);
  React.useEffect(() => {
    (async () => {
      const { data: allTowingEquipments } = await postgrest
        .from("towing_equipment")
        .select()
        .order("name");
      const { data: towingEquipmentsInUse } = await postgrest
        .from("towing_equipment_in_use")
        .select()
        .eq("fbo_id", activeFBO.id);
      let towingEquipmentsToDisplay = towingEquipmentsInUse.map(
        (towingEquipmentInUse) => {
          const towingEquipment = allTowingEquipments.find(
            (t) => t.id === towingEquipmentInUse.towing_equipment_id
          );
          return {
            id: towingEquipmentInUse.id,
            towing_equipment_id: towingEquipmentInUse.towing_equipment_id,
            is_default: towingEquipmentInUse.is_default,
            name: towingEquipment.name,
            width: towingEquipment.width,
            length: towingEquipment.length,
            height: towingEquipment.height,
            tow_bar_length: towingEquipment.tow_bar_length,
            geom: towingEquipment.geom,
            geomz: towingEquipment.geomz,
            in_use: true,
          };
        }
      );
      if (!limitToInUse) {
        towingEquipmentsToDisplay = towingEquipmentsToDisplay.concat(
          allTowingEquipments
            .filter(
              (t) =>
                !towingEquipmentsInUse.find(
                  (e) => e.towing_equipment_id === t.id
                )
            )
            .map((towingEquipment) => ({
              id: uuidv4(),
              towing_equipment_id: towingEquipment.id,
              is_default: null,
              name: towingEquipment.name,
              width: towingEquipment.width,
              length: towingEquipment.length,
              height: towingEquipment.height,
              tow_bar_length: towingEquipment.tow_bar_length,
              geom: towingEquipment.geom,
              geomz: towingEquipment.geomz,
              in_use: false,
            }))
        );
      }
      setTowingEquipments(towingEquipmentsToDisplay);
      setLoading(false);
    })();
  }, []);

  React.useEffect(() => {
    if (!towingEquipments || !previousTowingEquipments) {
      return;
    }
    (async () => {
      const towingEquipmentInUse = towingEquipments.filter((t) => t.in_use);
      const equipmentIdsInUseSQL = `(${towingEquipmentInUse
        .map((t) => t.id)
        .join(",")})`;
      await postgrest
        .from("towing_equipment_in_use")
        .delete()
        .eq("fbo_id", activeFBO.id)
        .not("id", "in", equipmentIdsInUseSQL);
      await postgrest.from("towing_equipment_in_use").upsert(
        towingEquipmentInUse.map((t) => ({
          id: t.id,
          fbo_id: activeFBO.id,
          towing_equipment_id: t.towing_equipment_id,
          is_default: t.is_default,
        }))
      );
    })();
  }, [towingEquipments]);

  return {
    loading,
    towingEquipments: towingEquipments ?? [],
    setTowingEquipments,
  };
};
