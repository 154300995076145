/**
 * Debounce a function
 */
export const debounce = function(func, ms) {
  var timer;
  return function() {
    var self = this;
    var args = arguments;
    clearTimeout(timer);
    var callMethod = function() {
      func.apply(self, args);
    };
    timer = setTimeout(callMethod, ms);
  };
};
