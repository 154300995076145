import * as React from "react";
import {
  Box,
  Button,
  Stack,
  Typography
  } from "@mui/material";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import {
  Hangar,
  Location,
  Ramp,
  Stack as StackType
  } from "../../../types";
import { Layout } from "../../Layout";

type Props = {
  location: Location<Ramp | Hangar>;
  favorite: StackType;
  onUse: (favorite: StackType) => void;
  onDelete: (favorite: StackType) => void;
};

export const FavoritePresenter: React.FC<Props> = ({
  location,
  favorite,
  onUse,
  onDelete,
}) => {
  const [confirm, setConfirm] = React.useState<{
    open: boolean;
    text: string;
    okText: string;
    onOk: () => void;
  }>({
    open: false,
    text: "",
    okText: "",
    onOk: () => {},
  });
  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      sx={{
        p: 1,
        height: 300,
        width: 300,
      }}
    >
      <Stack direction="column" spacing={0}>
        <Typography textAlign="center" variant="subtitle2">
          {favorite.name}
        </Typography>
        <Layout
          stack={favorite}
          setStack={() => {
            // not needed
          }}
          location={location}
          height={location.width / location.depth > 1.75 ? 175 : 250}
          options={{
            hideUnplaced: true,
            readOnly: true,
            selectable: false,
            printable: false,
          }}
        />
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setConfirm({
                open: true,
                text: `Remove all aircaft from your current ${location.type} and replace with aircraft from '${favorite.name}'.`,
                okText: `Yes, reset to this saved stack`,
                onOk: () => {
                  onUse(favorite);
                },
              });
            }}
          >
            Use
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setConfirm({
                open: true,
                text: `You are about to delete ${favorite.name}.`,
                okText: "Yes, delete this favorite",
                onOk: () => {
                  onDelete(favorite);
                },
              });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
      {confirm?.open && (
        <ConfirmationDialog
          title={"Please Confirm"}
          onCancel={() => setConfirm(null)}
          onClose={() => setConfirm(null)}
          {...confirm}
        />
      )}
    </Box>
  );
};
