import * as React from "react";
import logo from "../../../airplx-logo-transparent-square-no-text.png";
import { Box, Stack, Typography } from "@mui/material";
import { useFeatureFlags } from "../../containers/FeatureFlagsContainer";

export const Maintenance: React.FC<{}> = () => {
  const { maintenanceEndTime } = useFeatureFlags();

  return (
    <Box
      alignItems="center"
      sx={{
        maxWidth: 600,
        margin: "auto",
        border: "8px solid black",
        borderRadius: 3,
        p: 3,
        mt: "10%",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignContent="center"
        >
          <img src={logo} style={{ width: 50 }} />
          <Typography align="center" variant="h4">
            AirPlx
          </Typography>
        </Stack>
        <Typography variant="subtitle2">
          We are currently undergoing scheduled maintenance. AirPlx is scheduled
          to be back online at{" "}
          <u>
            <b>{maintenanceEndTime}</b>
          </u>
          .
        </Typography>
      </Stack>
    </Box>
  );
};
