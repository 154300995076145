import * as React from "react";
import { Hangar } from "../../types";
import { HOLDING_AREA_ID } from "./HoldingArea";

export interface HCP {
  id: string;
  x: number;
  y: number;
  widthOffset: number;
  depthOffset: number;
  width: number;
  depth: number;
  polygon: number[][];
}

type Props = {
  hangarRefs: React.RefObject<HTMLDivElement>[];
  holdingAreaRef: React.RefObject<HTMLDivElement>;
  multiHangarLayoutHeight: number;
  hangars: Hangar[];
  feetToPixels: number;
};

export const calculateHoldingAreaPolygon = (
  holdingAreaRef: React.RefObject<HTMLDivElement>,
  hangarRefs: React.RefObject<HTMLDivElement>[],
  feetToPixels: number
): HCP => {
  const leftmostX = Math.min(
    ...hangarRefs.map((hangarRef) => {
      const hangarRect = hangarRef.current.getBoundingClientRect();
      return hangarRect.left;
    })
  );
  const bottommostY = Math.max(
    ...hangarRefs.map((hangarRef) => {
      const hangarRect = hangarRef.current.getBoundingClientRect();
      return hangarRect.bottom;
    })
  );
  const holdingAreaRect = holdingAreaRef.current.getBoundingClientRect();
  const holdingAreaRectRelativeToBottomLeft = {
    x: holdingAreaRect.x - leftmostX,
    y: bottommostY - holdingAreaRect.bottom,
    width: holdingAreaRect.width,
    height: holdingAreaRect.height,
  };
  const holdingAreaPolygonPixels = [
    [
      holdingAreaRectRelativeToBottomLeft.x,
      holdingAreaRectRelativeToBottomLeft.y,
    ],
    [
      holdingAreaRectRelativeToBottomLeft.x,
      holdingAreaRectRelativeToBottomLeft.y +
        holdingAreaRectRelativeToBottomLeft.height,
    ],
    [
      holdingAreaRectRelativeToBottomLeft.x +
        holdingAreaRectRelativeToBottomLeft.width,
      holdingAreaRectRelativeToBottomLeft.y +
        holdingAreaRectRelativeToBottomLeft.height,
    ],
    [
      holdingAreaRectRelativeToBottomLeft.x +
        holdingAreaRectRelativeToBottomLeft.width,
      holdingAreaRectRelativeToBottomLeft.y,
    ],
    [
      holdingAreaRectRelativeToBottomLeft.x,
      holdingAreaRectRelativeToBottomLeft.y,
    ],
  ];
  const holdingAreaPolygonFeet = holdingAreaPolygonPixels.map((p) =>
    p.map((i) => i / feetToPixels)
  );
  const canvasRect = holdingAreaRef.current
    .querySelector("canvas")
    .getBoundingClientRect();
  const widthOffsetPixels = canvasRect.left - holdingAreaRect.left;
  const depthOffsetPixels = holdingAreaRect.bottom - canvasRect.bottom;
  return {
    id: HOLDING_AREA_ID,
    polygon: holdingAreaPolygonFeet,
    x: holdingAreaRectRelativeToBottomLeft.x / feetToPixels,
    y: holdingAreaRectRelativeToBottomLeft.y / feetToPixels,
    // distance from the bottom of the hangar container to the bottom of the hangar canvas
    widthOffset: widthOffsetPixels / feetToPixels,
    depthOffset: depthOffsetPixels / feetToPixels,
    width: holdingAreaRectRelativeToBottomLeft.width / feetToPixels,
    depth: holdingAreaRectRelativeToBottomLeft.height / feetToPixels,
  };
};

// this is the entire component (including the padding, title, etc.) not just the
// hangar canvas. we use thsi to create a set of "global coordinates" that are the same
// across different hangars. this allows us to see where a plane is in relation to the
// entire multi-hangar layout and if we need to, we can move it to a different hangar.
export const useMultiHangarLayoutPolygons = (props: Props, deps: any[]) => {
  const [layoutPolygons, setLayoutPolygons] = React.useState<HCP[]>([]);
  const {
    hangars,
    hangarRefs: allHangarRefs,
    holdingAreaRef,
    multiHangarLayoutHeight,
    feetToPixels,
  } = props;

  React.useEffect(() => {
    // limit to refs currently in the DOM
    const hangarRefs = allHangarRefs.filter((x) => x.current);
    const rects = hangarRefs.map((ref) => ref.current.getBoundingClientRect());
    const minX = Math.min(...rects.map((r) => r.x));
    const minY = Math.min(...rects.map((r) => r.y));
    const polygons = hangarRefs.map((ref, idx) => {
      const rect = ref.current.getBoundingClientRect();
      const canvasRect = ref.current
        .querySelector("canvas")
        .getBoundingClientRect();
      const widthOffsetPixels = canvasRect.left - rect.left;
      const depthOffsetPixels = rect.bottom - canvasRect.bottom;
      // we're going to invert the y axis so that the origin is in the bottom left
      const rectRelativeToBottomLeft = {
        x: rect.x - minX,
        y: multiHangarLayoutHeight - rect.y - rect.height + minY,
        width: rect.width,
        height: rect.height,
      };
      const polygonPixels = [
        [rectRelativeToBottomLeft.x, rectRelativeToBottomLeft.y],
        [
          rectRelativeToBottomLeft.x,
          rectRelativeToBottomLeft.y + rectRelativeToBottomLeft.height,
        ],
        [
          rectRelativeToBottomLeft.x + rectRelativeToBottomLeft.width,
          rectRelativeToBottomLeft.y + rectRelativeToBottomLeft.height,
        ],
        [
          rectRelativeToBottomLeft.x + rectRelativeToBottomLeft.width,
          rectRelativeToBottomLeft.y,
        ],
        [rectRelativeToBottomLeft.x, rectRelativeToBottomLeft.y],
      ];
      const polygonFeet = polygonPixels.map((p) =>
        p.map((i) => i / feetToPixels)
      );

      return {
        id: ref.current.getAttribute("data-id"),
        polygon: polygonFeet,
        x: rectRelativeToBottomLeft.x / feetToPixels,
        y: rectRelativeToBottomLeft.y / feetToPixels,
        // distance from the corner of the hangar container to the bottom of the hangar canvas
        widthOffset: widthOffsetPixels / feetToPixels,
        depthOffset: depthOffsetPixels / feetToPixels,
        width: rectRelativeToBottomLeft.width / feetToPixels,
        depth: rectRelativeToBottomLeft.height / feetToPixels,
        // extras
        pixels: polygonPixels,
        feet: polygonFeet,
        depthOffsetPixels,
      };
    });

    if (
      holdingAreaRef.current &&
      holdingAreaRef.current.querySelector("canvas")
    ) {
      polygons.push(
        calculateHoldingAreaPolygon(holdingAreaRef, hangarRefs, feetToPixels)
      );
    }
    setLayoutPolygons(polygons);
  }, [multiHangarLayoutHeight, feetToPixels, ...deps]);

  return layoutPolygons;
};
