import FilledFlagIcon from "@mui/icons-material/Flag";
import InfoIcon from "@mui/icons-material/Info";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { getStackPolygons } from "../../../hooks/utils";
import { Hangar, Stack as StackType, Tenant } from "../../../types";
import { HtmlTooltip } from "../../../widgets/HtmlTooltip";
import { Layout } from "../../../widgets/Layout";
import { calculateUtilization } from "../HangarInfoCard";
import { HANGAR_BACKGROUND_COLORS } from "../HangarPresenter";

const TooltipInfoAircraft: React.FC<{ tenants: Tenant[] }> = ({ tenants }) => {
  return (
    <HtmlTooltip
      title={
        tenants.length === 0 ? (
          ""
        ) : (
          <ul
            style={{
              margin: 0,
              padding: 0,
              paddingInlineStart: 20,
              fontSize: 18,
            }}
          >
            {tenants.map((t) => (
              <li key={`info-aircraft-${t.placement_id}`}>
                {t.aircraft.model}
              </li>
            ))}
          </ul>
        )
      }
      placement="right"
    >
      <InfoIcon sx={{ fontSize: 11 }} />
    </HtmlTooltip>
  );
};

type Props = {
  status: string;
  flagged?: boolean;
  readOnly?: boolean;
  hangar: Hangar;
  setHangar: (h: Hangar) => void;
  stack: StackType;
  onClose: () => void;
  onFlagResult: (id: string, isFlagSet: boolean) => Promise<void>;
  isReference?: boolean;
  height?: number;
};

export const StackResult: React.FC<Props> = ({
  status,
  flagged = false,
  readOnly = false,
  hangar,
  setHangar,
  stack,
  onClose,
  onFlagResult,
  isReference = false,
  height,
}) => {
  const { activeFBO } = useActiveFBOs();
  const [isPoor, setIsPoor] = React.useState<boolean>(false);
  const entityPolygons = getStackPolygons(stack, hangar.width, 1);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%" }}
    >
      <Layout
        stack={stack}
        setStack={() => {
          // not needed
        }}
        location={{
          ...hangar,
          type: "hangar",
        }}
        height={height ?? (hangar.width / hangar.depth > 1.75 ? 200 : 250)}
        options={{
          hideUnplaced: true,
          readOnly: true,
          selectable: false,
          printable: false,
          hideTailNumbers: true,
          dashColor: isReference ? "#FFFFFF" : null,
          backgroundColor:
            HANGAR_BACKGROUND_COLORS[isReference ? "reference" : null],
        }}
      />
      <Stack
        direction="column"
        justifyContent="space-around"
        sx={{ height: "100%" }}
      >
        <Stack direction="column" alignItems="center">
          <Typography>
            {calculateUtilization(
              hangar,
              entityPolygons,
              true,
              activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false
            )}
            %
          </Typography>
          <Typography variant="caption" color="info.dark" fontSize={12}>
            Utilization
          </Typography>
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack direction="column" alignItems="center">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={0.5}
          >
            <Typography>
              {
                stack.tenants.filter(({ position }) => position.x != null)
                  .length
              }
            </Typography>
            <TooltipInfoAircraft
              tenants={stack.tenants.filter(
                ({ position }) => position.x !== null
              )}
            />
          </Stack>
          <Typography variant="caption" color="info.dark" fontSize={12}>
            Inside
          </Typography>
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack direction="column" alignItems="center">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={0.5}
          >
            <Typography>
              {hangar.stack.tenants.length -
                stack.tenants.filter(({ position }) => position.x != null)
                  .length}
            </Typography>
            <TooltipInfoAircraft
              tenants={stack.tenants.filter(
                ({ position }) => position.x === null
              )}
            />
          </Stack>
          <Typography variant="caption" color="info.dark" fontSize={12}>
            Outside
          </Typography>
        </Stack>
        {flagged && (
          <>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Stack direction="column" alignItems="center">
              <FilledFlagIcon sx={{ color: "#ff2244" }} />
            </Stack>
          </>
        )}
        {status === "complete" && readOnly === false && (
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={() => {
                setHangar({
                  ...hangar,
                  stack: {
                    ...hangar.stack,
                    ...stack,
                    tenants: stack.tenants.map((t) => ({
                      ...t,
                      position: {
                        ...t.position,
                        stack_id: hangar.stack.id,
                      },
                    })),
                    movableObstacles: stack.movableObstacles.map((mo) => ({
                      ...mo,
                      position: {
                        ...mo.position,
                        stack_id: hangar.stack.id,
                      },
                    })),
                    id: hangar.stack.id,
                  },
                });
                onClose();
              }}
              sx={{ mb: 0.75, mt: 0.75 }}
            >
              Select Stack
            </Button>
            <Tooltip title="Flag poor layout">
              <IconButton
                size="small"
                onClick={async () => {
                  setIsPoor(!isPoor);
                  await onFlagResult(stack.id, !isPoor);
                }}
              >
                {isPoor ? (
                  <FilledFlagIcon sx={{ color: "#ff2244" }} />
                ) : (
                  <OutlinedFlagIcon />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
