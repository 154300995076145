import * as React from "react";
import { TowingEquipmentContainer } from "../../../containers/TowingEquipmentContainer";
import { TowingEquipmentPresenter } from "./TowingEquipmentPresenter";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";

export const TowingEquipment: React.FC<{}> = () => {
  const { activeFBO } = useActiveFBOs();

  return (
    <TowingEquipmentContainer>
      <TowingEquipmentPresenter />
    </TowingEquipmentContainer>
  );
};
