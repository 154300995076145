import { Chip, MenuItem, TextField } from "@mui/material";
import * as React from "react";
import { useHangarsState } from "../../containers/HangarsStateContainer";
import { useRampsState } from "../../containers/RampsStateContainer";

type Props = {
  isReference?: boolean;
  disabled?: boolean;
  selectedLocationId: string;
  onChange?: (id: string) => void;
  readOnly?: boolean;
  link?: boolean;
  unassignedText?: string;
};

export const LocationTextField: React.FC<Props> = ({
  isReference = false,
  disabled = false,
  selectedLocationId,
  onChange,
  readOnly = false,
  unassignedText = "Unassigned",
  link = false,
}) => {
  const { ramps } = useRampsState();
  const { hangars } = useHangarsState();

  if (readOnly) {
    const location =
      hangars?.find((h) => h.location_id === selectedLocationId) ??
      ramps?.find((r) => r.location_id === selectedLocationId);
    const isRamp = Boolean(
      ramps.find((r) => r.location_id === selectedLocationId)
    );

    const href = isRamp
      ? `#/${isReference ? "reference-" : ""}ramps/${location?.id}`
      : `#/${isReference ? "reference-" : ""}hangars/${location?.id}`;
    const hrefProps =
      link && Boolean(selectedLocationId)
        ? {
            href,
            sx: {
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                color: "#ffffff",
              },
            },
          }
        : {};

    return (
      <Chip
        variant="filled"
        size="small"
        component="a"
        label={location?.name ?? unassignedText}
        {...hrefProps}
      />
    );
  }

  const locationType = Boolean(
    ramps.find((r) => r.location_id === selectedLocationId)
  )
    ? "Ramp"
    : "Hangar";

  return (
    <TextField
      disabled={disabled}
      select
      size="small"
      fullWidth
      label={
        isReference ? `Assigned ${locationType}` : `Current ${locationType}`
      }
      onChange={(evt) =>
        onChange(evt.target.value === "Unassigned" ? null : evt.target.value)
      }
      value={selectedLocationId ?? "Unassigned"}
    >
      {hangars.map((hangar) => (
        <MenuItem
          key={`hangar-select-${hangar.location_id}`}
          value={hangar.location_id}
        >
          {hangar.name}
        </MenuItem>
      ))}
      {ramps.map((ramp) => (
        <MenuItem
          key={`ramp-select-${ramp.location_id}`}
          value={ramp.location_id}
        >
          {ramp.name}
        </MenuItem>
      ))}
      <MenuItem value={"Unassigned"}>{unassignedText}</MenuItem>
    </TextField>
  );
};
