import * as React from "react";
import {
  TowingEquipment,
  useTowingEquipment as useTowingEquipmentHook,
} from "../hooks/useTowingEquipment";

const TowingEquipmentContainerState = React.createContext<{
  loading: boolean;
  towingEquipments: TowingEquipment[];
  setTowingEquipments: (towingEquipments: TowingEquipment[]) => void;
}>({
  loading: false,
  towingEquipments: [],
  setTowingEquipments: function() {},
});

export const TowingEquipmentContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children, ...props }) => {
  const data = useTowingEquipmentHook(false);
  return (
    <TowingEquipmentContainerState.Provider value={data}>
      {/* {!data.loading ? children : <Loading />} */}
      {children}
    </TowingEquipmentContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useTowingEquipmentState = () =>
  React.useContext(TowingEquipmentContainerState);
