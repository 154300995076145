import * as React from "react";
import { AutocompletePosition, Preference, Tenant } from "../types";
import { createContainer } from "./StateContainer";

export type AutocompleteResults = {
  id: string;
  hash: string;
  tenant: Tenant;
  loading: boolean;
  orientation: Preference;
  suggestedPositions: AutocompletePosition[];
};

type Props = {};
const useAutocompleteResultsContainerState = (props: Props) => {
  const [autocompleteResults, setAutocompleteResults] = React.useState<
    AutocompleteResults
  >({
    id: null,
    hash: null,
    loading: false,
    tenant: null,
    orientation: null,
    suggestedPositions: [],
  });

  return {
    autocompleteResults,
    setAutocompleteResults,
  };
};
const AutocompleteResultsContainerState = createContainer(
  useAutocompleteResultsContainerState
);

export const AutocompleteResultsContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children, ...props }) => {
  const nested = AutocompleteResultsContainerState.useContainer();
  return (
    <AutocompleteResultsContainerState.Provider
      initialState={{ ...nested, ...props }}
    >
      {children}
    </AutocompleteResultsContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useAutocompleteResults =
  AutocompleteResultsContainerState.useContainer;
