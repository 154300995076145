import { Box, Stack } from "@mui/material";
import numeral from "numeral";
import * as React from "react";
import { useAsync, useKeyPress } from "react-use";
import { v4 as uuidv4 } from "uuid";
import { useApi } from "../../../../../containers/ApiContainer";
import { Marking, Ramp } from "../../../../../types";
import { MeasuringTool } from "../../../../../widgets/Layout/MeasuringTool";
import Canvas from "../../../../../widgets/useCanvas";
import { ZoomControl } from "../../../../../widgets/ZoomControl";
import { drawPoint, drawText, drawTextPill, lineStyleToDash } from "./drawing";
import {
  adjustSquare,
  calculateArea,
  getCenter,
  isPointInShapeOrOnEdge,
  pointToSegmentDistance,
  rotatePoints,
} from "./math";
import { SHAPE_COLORS, ShapeEditDialog } from "./ShapeEditDialog";

type Props = {
  shapes: Shape[];
  setShapes: (shapes: Shape[]) => void;
  ramp: Ramp;
  setRamp: (ramp: Ramp) => void;
  showReferenceImage: boolean;
  readOnly?: boolean;
  selectedTool: string;
  setSelectedTool: (tool: string) => void;
};

export type Point = { x: number; y: number };
export type Shape = {
  points: Point[];
  type: "Line" | "Text" | "Area" | "T" | "Circle";
  strokeStyle?: string;
  lineStyle?: "solid" | "dashed";
  lineWidth?: number;
  fillColor?: string;
  text?: string;
  fontSize?: number;
};

const DEFAULT_SHAPE: Shape = {
  points: [],
  type: "Line",
  lineStyle: "solid",
  lineWidth: 3,
  strokeStyle: SHAPE_COLORS[0],
  fillColor: null,
  fontSize: null,
  text: null,
};

export const RampCanvas: React.FC<Props> = ({
  shapes: shapesInFeet,
  setShapes: setShapesInFeet,
  selectedTool,
  setSelectedTool,
  showReferenceImage,
  ramp,
  setRamp,
  readOnly = false,
}) => {
  const { postgrest } = useApi();
  const [activeMarking, setActiveMarking] = React.useState<Marking>({
    id: uuidv4(),
    ramp_id: ramp.id,
    type: null,
    geom: {
      type: "Polygon",
      coordinates: [[]],
    },
  });

  const [canvasHeight, setCanvasHeight] = React.useState<number>(600);
  const div = React.useCallback((node) => {
    if (node?.offsetWidth && node?.offsetHeight) {
      setCanvasHeight(node.offsetHeight);
    }
  }, []);

  const rampImage = useAsync(async () => {
    if (ramp.id && showReferenceImage && ramp?.reference_image) {
      const { data } = await postgrest
        .from("ramp")
        .select("reference_image")
        .eq("id", ramp.id)
        .single();
      return data.reference_image;
    }
    return null;
  }, [ramp.reference_image]);

  const canvasRef = React.createRef<HTMLCanvasElement>();
  const pixelsToFeetRatio = canvasHeight / (ramp?.depth ?? 1);
  const canvasWidth = (canvasHeight * ramp?.width) / ramp?.depth;
  const pixelsToFeet = (px: number) => px / pixelsToFeetRatio;

  // convert shapes to pixels
  const shapes = shapesInFeet.map((shape) => ({
    ...shape,
    points: shape.points.map((point) => ({
      x: point.x * pixelsToFeetRatio,
      y: point.y * pixelsToFeetRatio,
    })),
  }));

  const setShapes = (shapes: Shape[]) => {
    setShapesInFeet(
      shapes.map((shape) => ({
        ...shape,
        points: shape.points.map((point) => ({
          x: point.x / pixelsToFeetRatio,
          y: point.y / pixelsToFeetRatio,
        })),
      }))
    );
  };

  const [selectedShapeIndex, setSelectedShapeIndex] = React.useState<number[]>(
    []
  );
  const [currentShape, setCurrentShape] = React.useState<Shape>(DEFAULT_SHAPE);
  const [draggingPoint, setDraggingPoint] = React.useState<{
    shapeIndex: number;
    pointIndex: number;
  } | null>(null);
  const [draggingShape, setDraggingShape] = React.useState<{
    shapeIndex: number;
    offset: Point;
  } | null>(null);
  const [hoveredShapeIndex, setHoveredShapeIndex] = React.useState<
    number | null
  >(null);
  const [mousePosition, setMousePosition] = React.useState<Point | null>(null);
  const [contextMenu, setContextMenu] = React.useState<{
    x: number;
    y: number;
    shapeIndex: number;
  } | null>(null);

  // Drawing function to render shapes on the canvas
  const draw = (context: CanvasRenderingContext2D) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    // Draw existing shapes
    shapes.forEach((shape, index) => {
      if (shape.type === "Text") {
        drawText(
          context,
          shape.points[0].x,
          shape.points[0].y,
          shape.text,
          shape.strokeStyle,
          shape.fontSize
        );
        return;
      }

      if (shape.type === "T") {
        // Draw the shape
        context.beginPath();
        context.lineWidth = 3;
        context.strokeStyle =
          hoveredShapeIndex === index || selectedShapeIndex.includes(index)
            ? "blue"
            : shape.strokeStyle;
        context.fillStyle = shape.strokeStyle;
        context.setLineDash(lineStyleToDash(shape.lineStyle));
        context.lineWidth = shape.lineWidth;
        const center = getCenter(shape.points);
        // horizontal line
        context.beginPath();
        context.moveTo(shape.points[0].x, shape.points[0].y);
        context.lineTo(shape.points[1].x, shape.points[1].y);
        context.stroke();
        // Vertical line
        context.beginPath();

        context.stroke();
        const angle = Math.atan2(
          shape.points[1].y - shape.points[0].y,
          shape.points[1].x - shape.points[0].x
        );
        const length = Math.hypot(
          shape.points[1].x - shape.points[0].x,
          shape.points[1].y - shape.points[0].y
        );
        const verticalLength = length / 2; // Adjust this value as needed
        const verticalPoint1 = {
          x: center.x - verticalLength * Math.sin(angle),
          y: center.y + verticalLength * Math.cos(angle),
        };
        const verticalPoint2 = {
          x: center.x + verticalLength * Math.sin(angle),
          y: center.y - verticalLength * Math.cos(angle),
        };
        context.moveTo(verticalPoint1.x, verticalPoint1.y);
        context.lineTo(verticalPoint2.x, verticalPoint2.y);
        context.stroke();
      }

      if (shape.type === "Circle") {
        const center = getCenter(shape.points);
        const radius =
          Math.hypot(
            shape.points[1].x - shape.points[0].x,
            shape.points[1].y - shape.points[0].y
          ) / 2;
        context.setLineDash(lineStyleToDash(shape.lineStyle));
        context.lineWidth = shape.lineWidth;
        context.strokeStyle =
          hoveredShapeIndex === index || selectedShapeIndex.includes(index)
            ? "blue"
            : shape.strokeStyle;
        context.beginPath();
        context.arc(center.x, center.y, radius, 0, 2 * Math.PI);
        context.stroke();
      }

      // Draw the shape
      context.beginPath();
      context.lineWidth = 3;
      context.setLineDash(lineStyleToDash(shape.lineStyle));
      context.lineWidth = shape.lineWidth;
      context.strokeStyle =
        hoveredShapeIndex === index || selectedShapeIndex.includes(index)
          ? "blue"
          : "black";
      context.fillStyle = shape.strokeStyle;
      shape.points.forEach((point, pointIndex) => {
        if (pointIndex === 0) {
          context.moveTo(point.x, point.y);
        } else {
          context.lineTo(point.x, point.y);
        }
      });

      // Areas should be a closed shape. And only closed shapes need a fill()
      // for Areas, we'll show the sqft of the area
      if (shape.type === "Area") {
        context.closePath();
        context.fill();
        if (hoveredShapeIndex === index || selectedShapeIndex.includes(index)) {
          const area = calculateArea(shape.points, 1 / pixelsToFeetRatio);
          drawTextPill(
            context,
            shape.points.reduce((acc, point) => acc + point.x, 0) /
              shape.points.length,
            shape.points.reduce((acc, point) => acc + point.y, 0) /
              shape.points.length,
            `${numeral(area).format("0,")} ft²`
          );
        }
      }

      if (shape.type === "T" || shape.type === "Circle") {
        context.closePath();
      }
      if (shape.type !== "T" && shape.type !== "Circle") {
        context.stroke();
      }

      // Annotate the line segments with their lengths
      if (hoveredShapeIndex === index || selectedShapeIndex.includes(index)) {
        shape.points.forEach((point, pointIndex) => {
          // this is the "closing" segment of a shape. we don't need to draw the length
          // for tha tone since the Line is not a closed form.
          if (
            (shape.type === "Line" ||
              shape.type === "T" ||
              shape.type === "Circle") &&
            pointIndex === shape.points.length - 1
          ) {
            return;
          }

          const nextPoint =
            shape.points[(pointIndex + 1) % shape.points.length];
          const length = pixelsToFeet(
            Math.hypot(nextPoint.x - point.x, nextPoint.y - point.y)
          );
          drawTextPill(
            context,
            (point.x + nextPoint.x) / 2,
            (point.y + nextPoint.y) / 2,
            `${length.toFixed(0)} ft`
          );
        });
      }

      // Draw points with red fill and white outline
      shape.points.forEach((point) => drawPoint(context, point.x, point.y));
    });

    // Draw the current shape in progress
    context.beginPath();
    context.lineWidth = 3;
    context.strokeStyle = "blue";
    context.setLineDash(lineStyleToDash(currentShape.lineStyle));
    context.lineWidth = currentShape.lineWidth;
    currentShape.points.forEach((point, pointIndex) => {
      if (pointIndex === 0) {
        context.moveTo(point.x, point.y);
      } else {
        context.lineTo(point.x, point.y);
      }
    });
    context.stroke();

    currentShape.points.forEach((point, index) => {
      if (index === 0) {
        context.moveTo(point.x, point.y);
      } else {
        context.lineTo(point.x, point.y);
      }

      // Draw points with red fill and white outline
      drawPoint(context, point.x, point.y);
    });

    // Draw the "live" line to the current mouse position if in progress
    if (mousePosition && currentShape.points.length > 0) {
      const lastPoint = currentShape.points[currentShape.points.length - 1];
      context.beginPath();
      context.moveTo(lastPoint.x, lastPoint.y);
      context.lineTo(mousePosition.x, mousePosition.y);
      context.strokeStyle = "blue";
      context.setLineDash(lineStyleToDash(currentShape.lineStyle));
      context.lineWidth = currentShape.lineWidth;
      context.lineWidth = 2;
      context.stroke();

      // Calculate the length of the line segment
      const length = pixelsToFeet(
        Math.hypot(mousePosition.x - lastPoint.x, mousePosition.y - lastPoint.y)
      );

      // Draw the length of the line segment
      drawTextPill(
        context,
        (lastPoint.x + mousePosition.x) / 2,
        (lastPoint.y + mousePosition.y) / 2,
        `${length.toFixed(0)} ft`
      );
    }

    // check if mouse position is close to a point
    if (mousePosition) {
      // convert mousePosition to canvas coordinates
      const rect = canvasRef.current?.getBoundingClientRect();
      if (!rect) return;
      currentShape.points.forEach((point) => {
        if (
          Math.hypot(point.x - mousePosition.x, point.y - mousePosition.y) < 10
        ) {
          drawPoint(
            context,
            point.x,
            point.y,
            null,
            null,
            "limegreen",
            "white"
          );
        }
      });
    }
  };

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (canvas && context) {
      draw(context);
    }
  }, [
    shapes,
    currentShape,
    hoveredShapeIndex,
    selectedShapeIndex,
    mousePosition,
  ]);

  /**
   * Rotate the shape we're hover over when shift + <arrow> is pressed
   */
  const [isShiftPressed] = useKeyPress("Shift");
  const [isRightArrowPressed] = useKeyPress("ArrowRight");
  const [isLeftArrowPressed] = useKeyPress("ArrowLeft");

  React.useEffect(() => {
    if (isShiftPressed && (isRightArrowPressed || isLeftArrowPressed)) {
      if (hoveredShapeIndex !== null) {
        if (hoveredShapeIndex !== null) {
          const shape = shapes[hoveredShapeIndex];
          const updatedShapes = [...shapes];
          updatedShapes[hoveredShapeIndex] = {
            ...shape,
            points: rotatePoints(
              shape.points,
              isRightArrowPressed ? 0.5 : -0.5
            ),
          };
          setShapes(updatedShapes);
        }
      }
      if (selectedShapeIndex.length > 0) {
        const updatedShapes = [...shapes];

        // if all selected shapes are T shapes, we're going to "snap" them
        // to the same angle. we'll pick the angle of the first one to snap to
        // to start.
        const selectedShapes = selectedShapeIndex.map((index) => shapes[index]);
        console.log(
          selectedShapes.map((shape) =>
            Math.atan2(
              shape.points[1].y - shape.points[0].y,
              shape.points[1].x - shape.points[0].x
            )
          )
        );
        if (
          selectedShapes.every((shape) => shape.type === "T") &&
          !selectedShapes
            .map((shape) =>
              Math.round(
                100 *
                  Math.atan2(
                    shape.points[1].y - shape.points[0].y,
                    shape.points[1].x - shape.points[0].x
                  )
              )
            )
            .every((val, i, arr) => val === arr[0])
        ) {
          const referenceAngle = Math.atan2(
            selectedShapes[0].points[1].y - selectedShapes[0].points[0].y,
            selectedShapes[0].points[1].x - selectedShapes[0].points[0].x
          );
          selectedShapeIndex.forEach((index) => {
            const shape = shapes[index];

            // Calculate the current angle of the shape
            const currentAngle = Math.atan2(
              shape.points[1].y - shape.points[0].y,
              shape.points[1].x - shape.points[0].x
            );

            // Calculate the angle difference to align with the reference angle
            const angleDifference = referenceAngle - currentAngle;

            const rotatedPoints = rotatePoints(
              shape.points,
              (angleDifference * 180) / Math.PI
            );

            updatedShapes[index] = {
              ...shape,
              points: rotatedPoints,
            };
          });
          setShapes(updatedShapes);
          return;
        }

        selectedShapeIndex.forEach((index) => {
          const shape = shapes[index];
          updatedShapes[index] = {
            ...shape,
            points: rotatePoints(
              shape.points,
              isRightArrowPressed ? 0.5 : -0.5
            ),
          };
        });
        setShapes(updatedShapes);
      }
    }
  }, [
    shapes,
    isShiftPressed,
    isRightArrowPressed,
    isLeftArrowPressed,
    hoveredShapeIndex,
    selectedShapeIndex,
  ]);

  // Add a point to the current shape or complete the shape if clicking on the first point
  const handleCanvasClick = (event: React.MouseEvent) => {
    if (selectedTool === "Measuring") {
      return;
    }

    const rect = canvasRef.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }

    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    if (selectedTool === "Select") {
      // look for any shapes that are clicked on
      const clickedShapeIndex = shapes.findIndex((shape) => {
        if (shape.type === "Text") {
          // since Text is just a single point, just evaluate that point w/ a nice buffer
          // adjust for the length of the text. the user will perceive the shape as being
          // the full length of the text on the screen
          const textWidth = shape.text.length * shape.fontSize;
          const textHeight = shape.fontSize;
          const points = [
            {
              x: shape.points[0].x - textWidth / 2,
              y: shape.points[0].y - textHeight / 2,
            },
            {
              x: shape.points[0].x + textWidth / 2,
              y: shape.points[0].y - textHeight / 2,
            },
            {
              x: shape.points[0].x + textWidth / 2,
              y: shape.points[0].y + textHeight / 2,
            },
            {
              x: shape.points[0].x - textWidth / 2,
              y: shape.points[0].y + textHeight / 2,
            },
          ];
          return isPointInShapeOrOnEdge({ x, y }, points);
        }

        return isPointInShapeOrOnEdge({ x, y }, shape.points);
      });
      if (clickedShapeIndex !== -1) {
        if (selectedShapeIndex.includes(clickedShapeIndex)) {
          setSelectedShapeIndex(
            selectedShapeIndex.filter((index) => index !== clickedShapeIndex)
          );
        } else {
          setSelectedShapeIndex([...selectedShapeIndex, clickedShapeIndex]);
        }
      } else {
        setSelectedShapeIndex([]);
      }
      return;
    }

    // if we're in Line mode, check to see if we're done
    if (currentShape.points.length > 0) {
      const lastPoint = currentShape.points[currentShape.points.length - 1];
      // Check if the click is on the starting point to complete the shape
      if (Math.hypot(lastPoint.x - x, lastPoint.y - y) < 10) {
        setShapes([...shapes, currentShape]);
        setCurrentShape(DEFAULT_SHAPE);
        setMousePosition(null);
        setSelectedTool("Select");
        return;
      }
    }

    // if we're in Area mode, check to see if we're done
    if (currentShape.points.length > 0) {
      const firstPoint = currentShape.points[0];
      // Check if the click is on the starting point to complete the shape
      if (Math.hypot(firstPoint.x - x, firstPoint.y - y) < 10) {
        setShapes([...shapes, currentShape]);
        setCurrentShape({ ...DEFAULT_SHAPE, fillColor: SHAPE_COLORS[0] });
        setMousePosition(null);
        setSelectedTool("Select");
        return;
      }
    }

    // Add a new point to the current shape
    setCurrentShape({
      ...currentShape,
      type: selectedTool === "Area" ? "Area" : "Line",
      points: [...currentShape.points, { x, y }],
    });
  };

  // Start dragging a point (either from existing shapes or the current shape)
  const handleMouseDown = (event: React.MouseEvent) => {
    if (selectedTool === "Measuring") {
      return;
    }
    const rect = canvasRef.current?.getBoundingClientRect();
    if (!rect) return;

    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // if we're in Text mode, add a text pill
    if (selectedTool === "Text") {
      const text = prompt("Enter text");
      if (text) {
        const updatedShapes = [...shapes];
        updatedShapes.push({
          points: [{ x, y }],
          type: "Text",
          strokeStyle: "white",
          lineStyle: "solid",
          lineWidth: 3,
          text,
          fontSize: 18,
        });
        setShapes(updatedShapes);
        setSelectedTool("Select");
        return;
      }
    }

    // Check if we're clicking on a point in an existing shape
    for (let i = 0; i < shapes.length; i++) {
      const shape = shapes[i];

      const pointIndex = shapes[i].points.findIndex(
        (point) => Math.hypot(point.x - x, point.y - y) < 10
      );

      if (pointIndex !== -1) {
        setDraggingPoint({ shapeIndex: i, pointIndex });
        return;
      }

      // check if the mouse is inside the shape
      if (isPointInShapeOrOnEdge({ x, y }, shape.points)) {
        setDraggingShape({ shapeIndex: i, offset: { x, y } });
        return;
      }
      // Check if the mouse is close to any line segment of the shape
      for (let j = 0; j < shape.points.length; j++) {
        const start = shape.points[j];
        const end = shape.points[(j + 1) % shape.points.length];
        const distToLine = pointToSegmentDistance({ x, y }, start, end);

        if (distToLine < 10) {
          // Threshold to detect the line
          setDraggingShape({ shapeIndex: i, offset: { x, y } });
          return;
        }
      }
    }

    // Check if we're clicking on a point in the current shape
    const pointIndex = currentShape.points.findIndex(
      (point) => Math.hypot(point.x - x, point.y - y) < 10
    );

    if (pointIndex !== -1) {
      setDraggingPoint({ shapeIndex: -1, pointIndex });
    }
  };

  // Drag the point
  const handleMouseMove = (event: React.MouseEvent) => {
    if (selectedTool === "Measuring") {
      return;
    }

    const rect = canvasRef.current?.getBoundingClientRect();
    if (!rect) return;

    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setMousePosition({ x, y });

    if (draggingPoint) {
      const { shapeIndex, pointIndex } = draggingPoint;
      if (shapeIndex === -1) {
        const updatedShape = { ...currentShape };
        if (updatedShape.type === "T" || updatedShape.type === "Circle") {
          // Fixed adjustment logic for square
          const center = getCenter(updatedShape.points);
          const adjustedPoints = adjustSquare(
            updatedShape.points,
            pointIndex,
            { x, y },
            center
          );
          updatedShape.points = adjustedPoints;
        } else {
          updatedShape.points[pointIndex] = { x, y };
        }
      } else {
        // Update point in an existing shape
        const updatedShapes = [...shapes];
        if (
          updatedShapes[shapeIndex].type === "T" ||
          updatedShapes[shapeIndex].type === "Circle"
        ) {
          // Fixed adjustment logic for square
          const center = getCenter(updatedShapes[shapeIndex].points);
          const adjustedPoints = adjustSquare(
            updatedShapes[shapeIndex].points,
            pointIndex,
            { x, y },
            center
          );
          updatedShapes[shapeIndex].points = adjustedPoints;
        } else {
          updatedShapes[shapeIndex].points[pointIndex] = { x, y };
        }
        setShapes(updatedShapes);
      }
    } else if (draggingShape) {
      const { shapeIndex, offset } = draggingShape;
      const dx = x - offset.x;
      const dy = y - offset.y;

      const updatedShapes = [...shapes];
      if (updatedShapes[shapeIndex]) {
        updatedShapes[shapeIndex].points = updatedShapes[shapeIndex].points.map(
          (point) => ({
            x: point.x + dx,
            y: point.y + dy,
          })
        );
      }

      setShapes(updatedShapes);
      setDraggingShape({ shapeIndex, offset: { x, y } });
    } else {
      // Check if hovering over a completed shape. this should look for
      // if it's inside a shape or on one of its edges
      const hoveredIndex = shapes.findIndex(
        (shape) =>
          shape.points.some(
            (point) => Math.hypot(point.x - x, point.y - y) < 10
          ) ||
          isPointInShapeOrOnEdge({ x, y }, shape.points) ||
          shape.points.some((point, pointIndex) => {
            const nextPoint =
              shape.points[(pointIndex + 1) % shape.points.length];
            return pointToSegmentDistance({ x, y }, point, nextPoint) < 10;
          })
      );
      setHoveredShapeIndex(hoveredIndex !== -1 ? hoveredIndex : null);
    }
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    if (selectedTool === "Measuring") {
      return;
    }
    event.preventDefault();
    const rect = canvasRef.current?.getBoundingClientRect();
    if (!rect) return;

    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Check if the right-click is within any shape
    const clickedShape = shapes.find((shape) => {
      // since Text is just a single point, just evaluate that point w/ a nice buffer
      if (shape.type === "Text") {
        // adjust for the length of the text. the user will perceive the shape as being
        // the full length of the text on the screen
        const textWidth = shape.text.length * shape.fontSize;
        const textHeight = shape.fontSize;
        const points = [
          {
            x: shape.points[0].x - textWidth / 2,
            y: shape.points[0].y - textHeight / 2,
          },
          {
            x: shape.points[0].x + textWidth / 2,
            y: shape.points[0].y - textHeight / 2,
          },
          {
            x: shape.points[0].x + textWidth / 2,
            y: shape.points[0].y + textHeight / 2,
          },
          {
            x: shape.points[0].x - textWidth / 2,
            y: shape.points[0].y + textHeight / 2,
          },
        ];
        return isPointInShapeOrOnEdge({ x, y }, points);
      }

      return isPointInShapeOrOnEdge({ x, y }, shape.points);
    });

    if (clickedShape) {
      console.log("Context menu triggered within a shape at:", { x, y });
      setContextMenu({ x, y, shapeIndex: shapes.indexOf(clickedShape) });
    } else {
      console.log("Right-click outside any shape at:", { x, y });
    }
  };

  // Finish dragging
  const handleMouseUp = (event: React.MouseEvent) => {
    setDraggingShape(null);
    setDraggingPoint(null);
  };

  return (
    <Stack direction="column" spacing={1}>
      <MeasuringTool pixelsToFeetRatio={pixelsToFeetRatio}>
        <Box
          ref={div}
          sx={{
            // cursor,
            position: "relative",
            // backgroundColor,
          }}
        >
          <Canvas
            ref={canvasRef as React.RefObject<HTMLCanvasElement>}
            draw={draw}
            width={canvasWidth}
            height={canvasHeight}
            onClick={handleCanvasClick}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onContextMenu={handleContextMenu} // Attach the right-click handler
            style={{
              border: "1px solid black",
              cursor: selectedTool === "Select" ? "default" : "crosshair",
              backgroundImage: showReferenceImage && `url(${rampImage?.value})`,
              backgroundSize: showReferenceImage && "cover",
            }}
          />
        </Box>
      </MeasuringTool>
      <ShapeEditDialog
        contextMenu={contextMenu}
        setContextMenu={setContextMenu}
        onClose={() => {
          setDraggingShape(null);
          setDraggingPoint(null);
          setContextMenu(null);
        }}
        shapes={shapes}
        setShapes={setShapes}
      />
      <ZoomControl
        currentZoom={canvasHeight}
        increment={0.1}
        onClickPlus={() => {
          setCanvasHeight(canvasHeight * 1.1);
          setCurrentShape({
            ...currentShape,
            points: currentShape.points.map((point) => ({
              x: point.x * 1.1,
              y: point.y * 1.1,
            })),
          });
        }}
        onClickMinus={() => {
          setCanvasHeight(canvasHeight * 0.9);
          setCurrentShape({
            ...currentShape,
            points: currentShape.points.map((point) => ({
              x: point.x * 0.9,
              y: point.y * 0.9,
            })),
          });
        }}
      />
    </Stack>
  );
};
