import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { uniqBy } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useIdentity } from "../../../containers/IdentityContainer";
import { useSnackbar } from "../../../containers/SnackbarContainer";
import { useTenants } from "../../../hooks/useTenants";
import { Stack as StackType, Tenant } from "../../../types";
import {
  consistentId,
  defaultPreferenceForAircraft,
  uuidv4,
} from "../../../utils";
import { Aircraft } from "../../LabelingTool";
import { AutocompleteAircraft } from "./AutocompleteAircraft";

type Props = {
  stack: StackType;
  bulkTenantsToAdd: Tenant[];
  setBulkTenantsToAdd: React.Dispatch<React.SetStateAction<Tenant[]>>;
};

export const BulkAdd: React.FC<Props> = ({
  stack,
  bulkTenantsToAdd,
  setBulkTenantsToAdd,
}) => {
  const { airplxIdentity } = useIdentity();
  const { activeFBO } = useActiveFBOs();
  const { tenants } = useTenants();
  const snackbar = useSnackbar();
  const [newAircraft, setNewAircraft] = React.useState<Aircraft | null>(null);
  const [nAircraft, setNAircraft] = React.useState<number>(0);
  const [tailNumbers, setTailNumbers] = React.useState<string>("");

  return (
    <Stack direction="column" spacing={2} width="100%" alignItems="center">
      <Stack direction="row" spacing={2} alignItems="flex-start" width="100%">
        <Box width={350} flexShrink={0}>
          <AutocompleteAircraft
            label="Search by make and model"
            onSelect={(aircraft) => setNewAircraft(aircraft)}
            defaultSelected={newAircraft}
          />
        </Box>
        {/* number of aircraft to add */}
        <TextField
          label="# Aircraft"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 1, max: 20 }}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value > 20) {
              snackbar.notify({
                severity: "warning",
                text: "Maximum number of aircraft to add is 20.",
                duration: 5000,
              });
            }
            setNAircraft(Math.min(20, value));
          }}
          value={nAircraft}
          sx={{
            width: 100,
          }}
        />
        {/* textfield for plugging in a comma delimited list of tail numbers */}
        <Box flexGrow={1}>
          <TextField
            label="Tail Numbers"
            placeholder="i.e. N12345, N54321, N67890"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => {
              // remove all commas or non-alphanumeric characters
              const value = e.target.value.replace(/[^a-zA-Z0-9,]/g, "");
              setTailNumbers(value);
            }}
            value={tailNumbers}
          />
          <Typography variant="caption" color="text.secondary">
            Optional. Separate tail numbers with commas.
          </Typography>
        </Box>
        <Box flexGrow={1}>
          <Button
            disabled={!newAircraft || nAircraft === 0}
            variant="contained"
            onClick={() => {
              const newTenants = Array.from({ length: nAircraft }, (_, i) => {
                // extract tail_number from comma delimited list. if it is "" then set it to null
                const tail_number = tailNumbers
                  .split(",")
                  .map((t) => t.trim())
                  .filter((t) => t.length)[i];
                const existingTenant = tenants.find(
                  (t) => t.tail_number === tail_number
                );

                if (tail_number?.length && existingTenant) {
                  // if the aircraft is different, tell the user
                  if (
                    existingTenant.aircraft.make !== newAircraft.make ||
                    existingTenant.aircraft.model !== newAircraft.model
                  ) {
                    snackbar.notify({
                      severity: "warning",
                      text: `Aircraft with tail number ${tail_number} already exists. We’ve added the existing tail number and associated aircraft model.`,
                      duration: 5000,
                    });
                  }
                  return {
                    ...existingTenant,
                    selected: false,
                    position: {
                      ...existingTenant.position,
                      stack_id: stack?.id,
                      // reset the position
                      x: null,
                      y:
                        -1 * Math.max(newAircraft.length, newAircraft.wingspan),
                      angle: null,
                      preferences: defaultPreferenceForAircraft(
                        newAircraft,
                        activeFBO
                      ),
                    },
                  };
                }
                const entity_id = uuidv4();
                const tenant: Tenant = {
                  id: uuidv4(),
                  placement_id: uuidv4(),
                  fbo_id: activeFBO.id,
                  entity_id,
                  tail_number: tail_number || "",
                  owner_manager: "",
                  note: "",
                  note_last_updated: new Date().toISOString(),
                  note_last_updated_by: airplxIdentity?.email ?? "Unknown",
                  type: "transient",
                  aircraft_id: newAircraft.id,
                  aircraft: newAircraft,
                  selected: false,
                  position: {
                    id: consistentId(entity_id, false),
                    stack_id: stack?.id,
                    entity_id,
                    x: null,
                    y: -1 * Math.max(newAircraft.length, newAircraft.wingspan),
                    angle: null,
                    preferences: defaultPreferenceForAircraft(
                      newAircraft,
                      activeFBO
                    ),
                  },
                };
                return tenant;
              });
              const newTenantsToAdd = uniqBy(
                [...bulkTenantsToAdd, ...newTenants],
                "id"
              );
              setBulkTenantsToAdd(newTenantsToAdd);
              // reset
              setNewAircraft(null);
              setNAircraft(0);
              setTailNumbers("");
            }}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        </Box>
      </Stack>
      {bulkTenantsToAdd.length > 0 && (
        <TableContainer sx={{ maxWidth: 640 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Tail #</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Type</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bulkTenantsToAdd.map((tenant) => (
                <TableRow key={`tenant-to-add-${tenant.id}`}>
                  <TableCell>{tenant.tail_number ?? "N/A"}</TableCell>
                  <TableCell>{tenant.aircraft.model}</TableCell>
                  <TableCell>{tenant.type}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() =>
                        setBulkTenantsToAdd((prev) =>
                          prev.filter((t) => t.id !== tenant.id)
                        )
                      }
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};
