import Component from "../ui/Component";
/**
 * @fileOverview Provides a tool for building DOM elements in a slightly easier
 * way to the native API and also auto renders low level Components
 * Normally to create a styled div with another div nested inside you would
 * write something like this.
 * ```
 * var div = document.createElement('div')
 * div.className = 'foo'
 * var childDiv = document.createElement('div')
 * childDiv.className = 'bar'
 * div.appendChild(childDiv)
 * ```
 * Using this tool you can chain the element creation to make it a little
 * less verbose
 * ```
 * var domHelper = new DomHelper()
 * var div = domHelper.createElement('div', { 'class': 'foo' },
 *   domHelper.createElement('div', { 'class': 'bar' })
 * ```
 *
 * If you pass in an instance of the Component class it will render that
 * component and append it to the parent node. e.g.
 * ```
 * var domHelper = new DomHelper()
 * var draggableAircraft = new DraggableAircraft(aircraft)
 * var container = domHelper.createElement(
 *  'div',
 *  { 'class': 'container' },
 *  draggableAircraft
 * )
 * ```
 * NOTE: Technically this isn't needed. At the time of writing it is used to
 * make things slightly easier in the `createDom` method of the `DraggablePlane`
 * class, but that could be rewritten to only use the native
 * `document.createElement` api.
 */


const DomHelper = function() {
  this.doc_ = window.document;
};

DomHelper.prototype.createElement = function(tagName, props, ...children) {
  var ele = this.doc_.createElement(tagName);
  if (props) DomHelper.applyAttributesToElement(ele, props);
  DomHelper.appendChildrenToElement(this.doc_, ele, children);
  return ele;
};

DomHelper.prototype.createElementNS = function(
  namespaceURI,
  tagName,
  props,
  ...children
) {
  var ele = this.doc_.createElementNS(namespaceURI, tagName);
  if (props) DomHelper.applyAttributesToElement(ele, props);
  DomHelper.appendChildrenToElement(this.doc_, ele, children);
  return ele;
};

DomHelper.prototype.getDocument = function() {
  return this.doc_;
};

DomHelper.applyAttributesToElement = function(ele, props) {
  var propKeys = Object.keys(props);
  for (var i = 0; i < propKeys.length; i++) {
    var key = propKeys[i];
    ele.setAttribute(key, props[key]);
  }
};

DomHelper.appendChildrenToElement = function(doc, ele, children) {
  for (var i = 0; i < children.length; i++) {
    var child = children[i];
    if (typeof child === "string" || typeof child === "number")
      child = doc.createTextNode(String(child));
    if (child instanceof Component) {
      var element = child.getElement();
      if (!element) {
        child.createDom();
        child = child.getElement();
        if (!child)
          throw new Error("Component has no element after calling createDom.");
      }
    }
    ele.appendChild(child);
  }
};

export default DomHelper;
