import { create } from "zustand";

interface MeasuringToolState {
  active: boolean;
  setActive: (active: boolean) => void;
}

export const useMeasuringToolState = create<MeasuringToolState>()((set) => ({
  active: false,
  setActive: (active) => set({ active }),
}));
