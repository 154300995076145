import { formatDistance } from "date-fns";

export const lastHangaredText = (
  value: string,
  currentlyInHangar: boolean
): string => {
  if (currentlyInHangar) {
    return "Today";
  }
  if (!value) {
    return "Never";
  }
  return formatDistance(new Date(value), new Date(), { addSuffix: true });
};
