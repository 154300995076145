import * as React from "react";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { LayoutToolbar } from "../../widgets/LayoutToolbar";
import { HangarStack } from "../Hangar";
import { MultiHangarPrintLayout } from "./MultiHangarPrintLayout";

type Props = {
  hangarId: string;
  setStacks: (stacks: HangarStack[]) => void;
};

export const LayoutToolbarWrapper: React.FC<Props> = ({
  hangarId,
  setStacks,
}) => {
  const { getHangar, setHangar, history } = useMultiHangarState();
  const hangar = getHangar(hangarId);

  return (
    <LayoutToolbar
      location={{ type: "hangar", ...hangar }}
      setLocation={({ type, ...location }) =>
        setHangar({ ...hangar, ...location })
      }
      stack={hangar.stack}
      setStack={(stack) => {
        setHangar({
          ...hangar,
          stack: {
            ...hangar.stack,
            ...stack,
          },
        });
      }}
      setStacks={setStacks}
      onClickUndo={() => {
        history && history.undo();
      }}
      renderOnPrint={(idsToPrint) => (
        <MultiHangarPrintLayout idsToPrint={idsToPrint} />
      )}
    />
  );
};
