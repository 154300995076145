import { Button, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import {
  CornerStrategy,
  Hangar,
  Location,
  Ramp,
  Stack as StackType,
} from "../../../types";
import { uuidv4 } from "../../../utils";

type Props = {
  location: Location<Ramp | Hangar>;
  setLocation: (location: Location<Ramp | Hangar>) => void;
  name: string;
  setName: (name: string) => void;
  setOpenNameField: (x: boolean) => void;
  setConfirm: (confirm: {
    open: boolean;
    text: string | React.ReactNode;
    okText: string;
    onOk: () => void;
  }) => void;
};

export const SingleLocationSave: React.FC<Props> = ({
  location,
  setLocation,
  name,
  setName,
  setOpenNameField,
  setConfirm,
}) => {
  const { activeFBO } = useActiveFBOs();
  return (
    <Stack direction="column" spacing={2} sx={{ minWidth: 400 }}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <TextField
          type="text"
          size="small"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button onClick={() => setOpenNameField(false)}>Cancel</Button>
        <Button
          variant="contained"
          color="success"
          disabled={name.length === 0}
          onClick={() => {
            const save = (stackId: string) => {
              setOpenNameField(false);
              const favoriteStack: StackType = {
                id: stackId,
                location_id: location.location_id,
                isReference: false,
                name,
                tenants: location.stack.tenants.map((t) => ({
                  ...t,
                  position: {
                    ...t.position,
                    id: uuidv4(),
                    stack_id: stackId,
                    entity_id: t.entity_id,
                  },
                })),
                movableObstacles: location.stack.movableObstacles.map((mo) => ({
                  ...mo,
                  position: {
                    ...mo.position,
                    id: uuidv4(),
                    stack_id: stackId,
                    entity_id: mo.entity_id,
                  },
                })),
                options: {
                  // these are the defaults
                  wall_spacing_left: 2,
                  wall_spacing_back: 2,
                  wall_spacing_right: 2,
                  horizontal_spacing: activeFBO?.sop_horizontal_spacing,
                  vertical_spacing: activeFBO?.sop_vertical_spacing,
                  overlap: activeFBO?.sop_overlap,
                  tug_spacing: 10,
                  density_threshold: 0.8,
                  towing_equipment_id: null,
                  corner_strategy: [
                    CornerStrategy.ANGLE_45,
                    CornerStrategy.ANGLE_45,
                  ],
                  cloud_position_prioritization: true,
                  sample_size: 1000,
                  xy_weights: [1, 2],
                },
              };

              setLocation({
                ...location,
                stacks: [
                  ...location.stacks.filter((s) => s.id !== stackId),
                  favoriteStack,
                ],
              });
            };
            const stackId = location.stacks.find((s) => s?.name === name)?.id;
            if (stackId) {
              setConfirm({
                open: true,
                text: `A favorite named '${name}' already exists. Do you want to overwrite it?`,
                okText: "Yes, overwrite it",
                onOk: () => {
                  save(stackId);
                },
              });
              return;
            }
            save(uuidv4());
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
