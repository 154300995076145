import EventTarget from "./EventTarget";

const events = {};

events.listen = function(src, type, listener, opt_options, opt_handler) {
  if (!src) throw new Error(`'src' is required.`);
  if (!type) throw new Error(`'type' is required.`);
  if (!listener) throw new Error(`'listener' is required.`);
  if (src instanceof EventTarget) {
    var listenerMap = src.listenerMap;
    listenerMap.add(type, listener, undefined, undefined, opt_handler);
  } else if (src instanceof Element) {
    if (opt_handler)
      throw new Error(
        `Cannot use the opt_helper handler context when listening
        to a browser element, instead bind the listener function before calling
        this method.`.replace(/\s/g, " ")
      );
    src.addEventListener(type, listener);
  } else {
    throw new Error(`Invalid 'src' value.`);
  }
};

events.unlisten = function(src, type, listener /*, opt_options, opt_handler*/) {
  if (src instanceof EventTarget) {
    var listenerMap = src.listenerMap;
    listenerMap.remove(type, listener);
  } else if (src instanceof Element) {
    src.removeEventListener(type, listener);
  } else {
    throw new Error(`Invalid 'src' value.`);
  }
};

events.unlistenAll = function(src, type) {
  if (src instanceof EventTarget) {
    var listenerMap = src.listenerMap;
    listenerMap.removeAll(type);
  } else if (src instanceof Element) {
    throw new Error(
      "unlistenAll method not yet supported for native elements."
    );
  } else {
    throw new Error(`Invalid 'src' value.`);
  }
};

events.hasListener = function(
  src,
  type,
  listener /*, opt_options, opt_handler*/
) {
  if (src instanceof EventTarget) {
    var listenerMap = src.listenerMap;
    listenerMap.hasListener(type, listener);
  } else if (src instanceof Element) {
    throw new Error(
      "hasListener method not yet supported for native elements."
    );
  } else {
    throw new Error(`Invalid 'src' value.`);
  }
};

events.listenOnce = function(src, type, listener, opt_options, opt_handler) {
  var tempListener = function() {
    events.unlisten(src, type, tempListener, opt_options, opt_handler);
    listener.apply(this, arguments);
  };
  events.listen(src, type, tempListener, opt_options, opt_handler);
};

export default events;
