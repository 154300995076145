import * as React from "react";
import { activeFeatures } from "../utils/FeatureFlags";
import { createContainer } from "./StateContainer";

type Props = {};
const useFeatureFlagsContainerState = (props: Props) => {
  return activeFeatures();
};
const FeatureFlagsContainerState = createContainer(
  useFeatureFlagsContainerState
);

export const FeatureFlagsContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children, ...props }) => {
  const nested = FeatureFlagsContainerState.useContainer();
  return (
    <FeatureFlagsContainerState.Provider initialState={{ ...nested, ...props }}>
      {children}
    </FeatureFlagsContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useFeatureFlags = FeatureFlagsContainerState.useContainer;
