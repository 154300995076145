import { Box, Divider, Stack } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Hangar, ParamSet } from "../../types";
import { CurrentAircraft } from "../../widgets/CurrentAircraft/CurrentAircraft";
import { HOLDING_AREA_ID } from "../MultiHangar/HoldingArea";
import { CustomPlacementOptions } from "./CustomStackDialog";
import { StackStrategy } from "./GenerateParamSets";
import { HangarStack } from "./Hangar";
import { HangarInfoCard } from "./HangarInfoCard";
import { RunStack } from "./RunStack";

type Props = {
  hangar: Hangar;
  setHangar: (hangar: Hangar) => void;
  stacks: HangarStack[];
  setStacks: (stacks: HangarStack[]) => void;
  stackStrategy: StackStrategy;
  setStackStrategy: (s: StackStrategy) => void;
  paramSet: ParamSet;
  customPlacementOptions: CustomPlacementOptions;
  setCustomPlacementOptions: (options: CustomPlacementOptions) => void;
  isAlgorithmTesting?: boolean;
  isReference?: boolean;
  multiHangar?: boolean;
  width?: number;
  viewableHangars?: Hangar[];
  activeHangarId?: string;
  setActiveHangarId?: (id: string) => void;
};

export const HangarLeftPanel: React.FC<Props> = ({
  hangar,
  setHangar,
  stacks,
  setStacks,
  stackStrategy,
  setStackStrategy,
  paramSet,
  customPlacementOptions,
  setCustomPlacementOptions,
  isReference = false,
  isAlgorithmTesting = false,
  multiHangar = false,
  width = 400,
  viewableHangars,
  activeHangarId,
  setActiveHangarId,
}) => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        maxHeight: "100%",
        height: "100%",
        width: width ?? 400,
        borderLeft: "1px solid lightgrey",
        borderRight: "1px solid lightgrey",
      }}
    >
      <HangarInfoCard
        customPlacementOptions={customPlacementOptions}
        setCustomPlacementOptions={setCustomPlacementOptions}
        isAlgorithmTesting={isAlgorithmTesting}
        hangar={hangar}
        onClickBack={() => navigate("/hangars")}
        isReference={isReference}
        multiHangar={multiHangar}
        activeHangarId={activeHangarId}
        setActiveHangarId={setActiveHangarId}
        hangars={viewableHangars}
      />
      {hangar.id !== HOLDING_AREA_ID && (
        <>
          <Divider />
          <RunStack
            hangar={hangar}
            isAlgorithmTesting={isAlgorithmTesting}
            customPlacementOptions={customPlacementOptions}
            paramSet={paramSet}
            stacks={stacks}
            setStacks={setStacks}
            stackStrategy={stackStrategy}
            setStackStrategy={setStackStrategy}
          />
        </>
      )}
      <Box sx={{ height: 8, width: "100%", backgroundColor: "#EEEEEE" }} />
      <CurrentAircraft
        width={width}
        location={{ type: "hangar", ...hangar }}
        stack={hangar.stack}
        setStack={(stack) => {
          setHangar({
            ...hangar,
            stack: {
              ...hangar.stack,
              ...stack,
            },
          });
        }}
      />
    </Stack>
  );
};
