import * as React from "react";
import { RampsPresenter } from "./RampsPresenter";
import { RampsStateContainer } from "../../containers/RampsStateContainer";

type Props = {};

export const Ramps: React.FC<Props> = ({ ...props }) => {
  return (
    <RampsStateContainer isReference={false}>
      <RampsPresenter />
    </RampsStateContainer>
  );
};
