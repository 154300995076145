import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as React from "react";
import { useCallback, useState } from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useApi } from "../../../containers/ApiContainer";

export const ButtonRemoveFBO: React.FC = () => {
  const { activeFBO } = useActiveFBOs();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { postgrest } = useApi();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveFBO = useCallback(async () => {
    try {
      setLoading(true);
      // Async call to remove the FBO (replace with your actual API call)
      await postgrest
        .from("fbo")
        .delete()
        .eq("id", activeFBO.id);
      // Handle success response
      console.log("FBO removed successfully");
      setOpen(false);
    } catch (error) {
      // Handle error
      console.error("Error removing FBO:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Button
        variant="contained"
        color="error"
        startIcon={<DeleteIcon />}
        onClick={handleOpen}
      >
        Remove FBO
      </Button>

      {/* Dialog for confirmation */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Confirm FBO Removal"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this FBO? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleRemoveFBO}
            color="error"
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
